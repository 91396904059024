import type { Action, Dispatch } from '@reduxjs/toolkit';
import { injectable } from 'inversify';
import type { IStoreState } from 'app/store';
import { ActionCreator, IAction, ThunkAction } from 'app/store';
import type {
    IUserInfo,
    ICommand,
    ICurrentProjectRepository,
    IPersistence,
    IItemEntity,
} from 'app/core/persistence';
import {
    ProjectDbOrigin,
    Id,
    ProjectCustomerInfoService,
    AuthenticationService,
    UserService,
    PartnerConfigService,
    OfflineService,
    PersistenceActions,
    ProjectService,
    ProjectModelService,
    UserSettingsService,
    ContactDetailsService,
    CurrentProjectService,
    GenericEntityRepository,
    UserCustomItemService,
    ItemService,
    ImageService,
    ProjectRepository,
} from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';
import { AppSettings } from 'app/AppSettings';
import { CommonActions } from '../actions';
import { PiaDevicesService } from '../piaDevices';
import { LoginService } from '../authentication/services';
import type { IProjectCustomerInfoResponse } from '../models';
import { IContactDetails } from '../models';
import { LocationService } from './Location.service';
import { MsrpService } from '../prices';
import { MapsActions } from 'app/modules/maps/actions';
import { UserProjectsActions } from 'app/modules/userProjects/userProjects/state/UserProjects.actions';
import {
    updateCreateOperations,
    getUndoQueue,
    getRedoQueue,
    canUndoNavigation,
    isUndoRedoDisabled,
    updateUpdateOperations,
    updateDeleteOperations,
} from '../undoredo';
import { last } from 'lodash-es';
import { getIsStandalone, getOverlayPanelOpen } from '../selectors';
import { RecordingSelectorActions } from 'app/modules/recordingSelector/state';
import { ProjectDefaultsService } from '../profile/services';
import { toaster } from 'app/toaster';
import { t } from 'app/translate';
import { isDefined } from 'axis-webtools-util';
import { IAuthTokens, clearAuthTokens, fetchAuthTokens } from '../utils/auth/authTokens';
import { InitializeDataStorageService } from '../services/InitializeUserDataStorage.service';
import { isCustomPouchError } from 'app/utils';
import type { CompactionStatus } from '../couchDBCompaction';
import { CouchDBCompactionService } from '../couchDBCompaction';
import { getUserSignedIn } from '../authentication';
import { getUrlPartnerId } from 'app/partnerUrl';
import type { IPiaItem, IPiaSoftware, IPiaSoftwareProperties } from 'app/core/pia';
import { PiaItemSoftwareCategory, PiaItemState } from 'app/core/pia';
import { isFeatureEnabled } from 'app/featureFlags';

const closeDelay = 10;

@injectable()
export class CommonActionService {
    private overlayPanelTimer = 0;
    private overlayPanelOpen = false;

    constructor(
        private authenticationService: AuthenticationService,
        private piaDevicesService: PiaDevicesService,
        private appSettings: AppSettings,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private imageService: ImageService,
        private locationService: LocationService,
        private loginService: LoginService,
        private msrpService: MsrpService,
        private projectCustomerInfoService: ProjectCustomerInfoService,
        private partnerConfigService: PartnerConfigService,
        private offlineService: OfflineService,
        private projectModelService: ProjectModelService,
        private projectService: ProjectService,
        private contactDetailsService: ContactDetailsService,
        private currentProjectService: CurrentProjectService,
        private genericEntityRepository: GenericEntityRepository,
        private userCustomItemService: UserCustomItemService,
        private projectDefaultService: ProjectDefaultsService,
        private itemService: ItemService,
        private initializeDataStorageService: InitializeDataStorageService,
        private couchDBCompactionService: CouchDBCompactionService,
        private projectRepository: ProjectRepository,
    ) {
        this.setupUndoRedoShortcuts();
    }

    @ActionCreator()
    public authenticateUser(): ThunkAction {
        return async (dispatch, getState) => {
            const isOnline = await this.authenticationService.isOnline();
            if (!isOnline) return;

            const isAuthenticated = await this.authenticationService.isAuthenticated();
            if (isAuthenticated !== getState().common.user.isSignedIn) {
                dispatch({
                    type: CommonActions.GetIsAuthenticated,
                    payload: isAuthenticated,
                });
            }
        };
    }

    @ActionCreator()
    public getUser(): ThunkAction {
        return async (dispatch, getState) => {
            if (getIsStandalone(getState())) {
                return;
            }

            const getUserPromise = this.userService.getUser();
            this.loadPriceLists(getUserPromise);

            dispatch({
                type: CommonActions.GetUser,
                payload: getUserPromise,
            });

            const user = await getUserPromise;
            this.authenticateUser();

            const userApiContactDetails =
                this.contactDetailsService.convertUserInfoToContactDetails(user);
            const contactDetails =
                await this.contactDetailsService.getContactDetails(userApiContactDetails);

            dispatch({
                type: CommonActions.GetContactDetails,
                payload: {
                    contactDetails,
                    userApiContactDetails,
                },
            });
        };
    }

    @ActionCreator()
    public saveContactDetails(newContactDetails: IContactDetails): ThunkAction {
        return async (dispatch, getState) => {
            await this.contactDetailsService.setContactDetails(newContactDetails);
            const state = getState();
            const userApiContactDetails =
                this.contactDetailsService.convertUserInfoToContactDetails(state.common.user.user);
            const contactDetails =
                await this.contactDetailsService.getContactDetails(userApiContactDetails);

            dispatch({
                type: CommonActions.UpdateContactDetails,
                payload: contactDetails,
            });
        };
    }

    @ActionCreator()
    public login(): IAction<void> {
        return {
            type: CommonActions.Login,
            payload: this.loginService.login(this.appSettings.axisLoginUrl),
        };
    }

    @ActionCreator()
    public logout(): IAction<Promise<void>> {
        return {
            type: CommonActions.Logout,
            payload: this.loginService.logout(this.appSettings.axisLogoutUrl),
        };
    }

    @ActionCreator()
    public authorize(): ThunkAction {
        return async (dispatch) => {
            dispatch({
                type: CommonActions.Authorize,
            });
        };
    }
    @ActionCreator()
    public exchangeTokens(code?: string): ThunkAction {
        return async (dispatch) => {
            dispatch({
                type: CommonActions.Reauthorize,
            });
            fetchAuthTokens(code)
                .then((tokens: IAuthTokens) =>
                    dispatch({
                        type: CommonActions.UpdateTokens,
                        payload: tokens,
                    }),
                )
                .catch((payload: any) => dispatch({ type: CommonActions.SetAuthFailure, payload }));
        };
    }

    @ActionCreator()
    public reauthorize(): ThunkAction {
        return async (dispatch) => {
            clearAuthTokens();
            dispatch({
                type: CommonActions.Reauthorize,
                payload: this.loginService.login(this.appSettings.axisLoginUrl),
            });
        };
    }

    @ActionCreator()
    public updateTokens(tokens: IAuthTokens): ThunkAction {
        return async (dispatch) => {
            return dispatch({
                type: CommonActions.UpdateTokens,
                payload: tokens,
            });
        };
    }

    private defaultProfileAndSchedulesAreMissing(project: ICurrentProjectRepository) {
        return (
            Object.entries(project.schedules).length < 1 ||
            Object.entries(project.timeSeries).length < 1 ||
            Object.entries(project.profiles).length < 1 ||
            this.associatedProfileMissingForItemIds(project.items).length > 0 ||
            !project.project?.defaultProfile
        );
    }

    private associatedProfileMissingForItemIds(
        items: Record<string, IPersistence<IItemEntity> | undefined>,
    ) {
        return Object.entries(items)
            .map(([id, item]) =>
                item?.properties.camera?.associatedProfile === '' ||
                item?.properties.sensorUnit?.associatedProfile === '' ||
                item?.properties.analogCamera?.associatedProfile === ''
                    ? id
                    : undefined,
            )
            .filter(isDefined);
    }

    @ActionCreator()
    public loadCurrentProject(projectId: Id): ThunkAction {
        return async (dispatch) => {
            try {
                await this.switchToProjectDatabase(projectId);
            } catch (e) {
                // project was not found in any of the available databases
                dispatch({
                    type: CommonActions.GetCurrentProject,
                    payload: {
                        project: null,
                        projectLoaded: true,
                        projectError: new Error('Project not found in any database'),
                    },
                });
            }

            this.loadCurrentCustomerInfo(projectId);

            dispatch({
                type: MapsActions.ResetToInitialState,
            });

            dispatch({
                type: CommonActions.GetCurrentProject,
                payload: this.projectModelService.getProjectModels(projectId).then((project) => {
                    if (project.project?.archived === true) {
                        // Unarchive archived project when opening
                        this.projectService.updateArchivedStatus(projectId, false);
                        toaster.success(t.projectWasRestoredHeader, t.projectWasRestoredMessage);
                    }
                    if (this.defaultProfileAndSchedulesAreMissing(project)) {
                        try {
                            const associatedProfileMissing =
                                this.associatedProfileMissingForItemIds(project.items);

                            this.projectDefaultService
                                .addMissingDefaults(projectId)
                                .then((updatedProject) =>
                                    associatedProfileMissing.forEach((id) =>
                                        this.itemService.updateProfile(
                                            id,
                                            updatedProject.defaultProfile,
                                        ),
                                    ),
                                );
                        } catch (e) {
                            toaster.error(t.errorBoundaryHeader, t.couldNotRepairProject);
                            return {
                                ...project,
                                projectLoaded: true,
                                projectError: new Error(),
                            };
                        }

                        return {
                            ...project,
                            projectBroken: true,
                        };
                    } else {
                        return project;
                    }
                }),
            });
        };
    }

    @ActionCreator()
    public confirmProjectBroken(projectId: Id): IAction<Promise<ICurrentProjectRepository>> {
        return {
            type: CommonActions.ConfirmBrokenProject,
            payload: this.projectModelService.getProjectModels(projectId).then((project) => {
                return {
                    ...project,
                    projectBroken: false,
                };
            }),
        };
    }

    @ActionCreator()
    public getUserSettings(): ThunkAction {
        return async (dispatch) => {
            const userSettings = await this.userSettingsService.getUserSettings();

            dispatch({
                type: PersistenceActions.UpdateUserSettings,
                payload: userSettings,
            });
        };
    }

    @ActionCreator()
    public getUserCustomItems(): ThunkAction {
        return async (dispatch) => {
            dispatch({
                type: PersistenceActions.GetUserCustomItems,
                payload: await this.userCustomItemService.getUserCustomItems(),
            });
        };
    }

    @ActionCreator()
    public unloadCurrentProject(): IAction<void> {
        return {
            type: CommonActions.UnloadCurrentProject,
            payload: undefined,
        };
    }

    @ActionCreator()
    public unloadProject(): IAction<void> {
        return {
            type: CommonActions.UnloadProject,
            payload: undefined,
        };
    }

    @ActionCreator()
    public loadProjectCustomerInfo(projectId: Id): IAction<Promise<IProjectCustomerInfoResponse>> {
        return {
            type: CommonActions.UpdateProjectCustomerInfo,
            payload: this.projectCustomerInfoService
                .getCustomerInfo(projectId)
                .then((customerInfo: string): IProjectCustomerInfoResponse => {
                    return {
                        projectId,
                        customerInfo,
                    };
                }),
        };
    }

    @ActionCreator()
    public loadCurrentCustomerInfo(projectId: Id): IAction<Promise<IProjectCustomerInfoResponse>> {
        return {
            type: CommonActions.UpdateProjectCustomerInfo,
            payload: this.projectCustomerInfoService
                .getCustomerInfo(projectId)
                .then((customerInfo: string): IProjectCustomerInfoResponse => {
                    return {
                        projectId,
                        customerInfo,
                    };
                }),
        };
    }

    @ActionCreator()
    public updateProjectCustomerInfo(
        customerInfo: string,
    ): IAction<Promise<IProjectCustomerInfoResponse>> {
        const projectId = this.currentProjectService.getProjectId();

        return {
            type: CommonActions.UpdateProjectCustomerInfo,
            payload: this.projectCustomerInfoService
                .setCustomerInfo(projectId, customerInfo)
                .then((): IProjectCustomerInfoResponse => {
                    return {
                        projectId,
                        customerInfo,
                    };
                }),
        };
    }

    @ActionCreator()
    public loadPiaDevices() {
        const mockedPiaItems = this.getMockedPiaItems();
        return {
            type: CommonActions.GetPiaDevices,
            payload: this.piaDevicesService.getPiaDevices().concat(mockedPiaItems),
        };
    }

    @ActionCreator()
    public loadLocations() {
        return {
            type: CommonActions.GetLocations,
            payload: this.locationService.getLocations(),
        };
    }

    @ActionCreator()
    public loadPriceLists(getUser: Promise<IUserInfo | null>): ThunkAction {
        return async (dispatch) => {
            const user = await getUser;
            if (user === null) {
                dispatch({
                    type: CommonActions.PricesNotAvailable,
                });
                return;
            }

            const distributors = await this.msrpService.getDistributors();
            if (distributors === null) {
                dispatch({
                    type: CommonActions.PricesNotAvailable,
                });
                return;
            }

            dispatch({
                type: CommonActions.GetDistributors,
                payload: distributors,
            });

            dispatch({
                type: CommonActions.GetMsrp,
                payload: this.msrpService.getMsrp(distributors),
            });

            dispatch({
                type: CommonActions.GetCurrencies,
                payload: this.msrpService.getCurrencies(),
            });

            dispatch({
                type: CommonActions.GetMsrpAuthStatus,
                payload: this.msrpService.getUserAuthStatus(),
            });
        };
    }

    @ActionCreator()
    public unloadProjectItems(): IAction<void> {
        return {
            type: CommonActions.UnloadProjectItems,
            payload: undefined,
        };
    }

    @ActionCreator()
    public getPartnerConfig(): ThunkAction {
        return async (dispatch, getStore) => {
            const store = getStore();
            const isStandalone = getIsStandalone(store);
            const partnerIdFromUrl = getUrlPartnerId();

            if (isStandalone) {
                return;
            }

            const isOnline = await this.offlineService.isOnline();
            const locid = store.common.user.user && store.common.user.user.locid;

            try {
                const partnerConfig = await this.partnerConfigService.getPartnerConfig(
                    locid,
                    isOnline,
                );

                // Don't load partner config if it is disabled and not loaded by partner URL
                if (!partnerConfig.allowLocIdAccess && !partnerIdFromUrl) {
                    dispatch({
                        type: CommonActions.GetPartnerConfig,
                        payload: null,
                    });
                    return;
                }

                dispatch({
                    type: CommonActions.GetPartnerConfig,
                    payload: partnerConfig,
                });

                /**
                 * Conditions for putting allowlist in use
                 * - An allowlist exists.
                 * - File has property set that does not allow showing other products OR
                 * - User has toggled allowlist to be in use (default).
                 */
                const useAllowlist = partnerConfig.allowlist
                    ? !partnerConfig.allowlist.allowExcludedProducts ||
                      (await this.partnerConfigService.getUseProductAllowlist(partnerConfig.id))
                    : false;

                dispatch({
                    type: CommonActions.UseAllowlistChanged,
                    payload: useAllowlist,
                });
            } catch {
                dispatch({
                    type: CommonActions.GetPartnerConfig,
                    payload: null,
                });
            }
        };
    }

    @ActionCreator()
    public getPartnerConfigFromConfigPage(): ThunkAction {
        return async (dispatch, getStore) => {
            const store = getStore();
            const isOnline = await this.offlineService.isOnline();
            const locid = store.common.user.user && store.common.user.user.locid;

            const partnerConfig = await this.partnerConfigService.getPartnerConfig(locid, isOnline);
            dispatch({
                type: CommonActions.GetPartnerConfig,
                payload: partnerConfig,
            });
        };
    }

    @ActionCreator()
    public useProductAllowlistChanged(partnerConfigId: string, useAllowlist: boolean): ThunkAction {
        return async (dispatch) =>
            dispatch({
                type: CommonActions.UseAllowlistChanged,
                payload: await this.partnerConfigService.setUseAllowlist(
                    partnerConfigId,
                    useAllowlist,
                ),
            });
    }

    @ActionCreator()
    public showOverlayPanel(panelOpen: boolean) {
        return (dispatch: Dispatch<Action>) => {
            clearTimeout(this.overlayPanelTimer);

            // don't close the panel if we just opened it
            this.overlayPanelOpen = this.overlayPanelOpen || panelOpen;

            this.overlayPanelTimer = window.setTimeout(() => {
                dispatch({
                    type: CommonActions.ShowOverlayPanel,
                    payload: this.overlayPanelOpen,
                });
                this.overlayPanelOpen = false; // reset after timeout
            }, closeDelay);
        };
    }

    /**
     * Sets either device details, network settings or product selector in overlay panel depending on arguments provided
     * @param deviceDetailsId Id for device which accessory panel should be shown
     * @param IdForNetworkSettings Id for device which network settings panel should be shown
     * @param openProductSelector Brings up product selector if true
     * @returns Applicable dispatches to set correct information in overlay panel
     */
    @ActionCreator()
    public setRecordingOverlay(
        deviceDetailsId?: Id,
        IdForNetworkSettings?: Id,
        openProductSelector?: boolean,
    ): ThunkAction {
        return (dispatch: Dispatch<Action>, getState) => {
            clearTimeout(this.overlayPanelTimer);

            const state = getState();
            const isOpen = getOverlayPanelOpen(state);

            const {
                ItemIdForNetworkSettings,
                selectedDeviceDetails,
                showProductSelector,
                smallOverlay,
            } = state.recordingSelector;

            // Compare arguments to current state
            const deviceDetailsChanged = deviceDetailsId !== selectedDeviceDetails;
            const networkSettingsIdChanged = IdForNetworkSettings !== ItemIdForNetworkSettings;
            const productSelectorHasChanged =
                (openProductSelector && !showProductSelector) ||
                (!openProductSelector && showProductSelector);

            const shouldShowPanel =
                !isOpen ||
                deviceDetailsChanged ||
                networkSettingsIdChanged ||
                productSelectorHasChanged;

            // Overlay should only be small for device details and network settings
            const shouldUpdateOverlaySize =
                ((deviceDetailsId || IdForNetworkSettings) && !smallOverlay) ||
                (openProductSelector && smallOverlay);

            if (shouldUpdateOverlaySize) {
                dispatch({
                    type: RecordingSelectorActions.SetSmallOverlay,
                    payload: !openProductSelector,
                });
            }

            if (deviceDetailsChanged) {
                dispatch({
                    type: RecordingSelectorActions.ToggleDeviceDetails,
                    payload: deviceDetailsId,
                });
            }

            if (networkSettingsIdChanged) {
                dispatch({
                    type: RecordingSelectorActions.SetNetworkSettingsDetails,
                    payload: IdForNetworkSettings,
                });
            }

            if (productSelectorHasChanged) {
                dispatch({
                    type: RecordingSelectorActions.ToggleProductSelector,
                    payload: openProductSelector,
                });
            }

            // Prevents overlay from closing and re-opening when it should
            // only change which device is being displayed
            this.overlayPanelTimer = window.setTimeout(() => {
                dispatch({
                    type: CommonActions.ShowOverlayPanel,
                    payload: shouldShowPanel,
                });
                this.overlayPanelOpen = false; // reset after timeout
            }, closeDelay);
        };
    }

    @ActionCreator()
    public syncAll(): IAction<Promise<void>> {
        return {
            type: UserProjectsActions.REPLICATE,
            payload: this.initializeDataStorageService.syncAll(),
        };
    }

    @ActionCreator()
    public getUserImageQuota(): ThunkAction {
        return (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const state = getState();
            const isStandalone = getIsStandalone(state);
            if (isStandalone) {
                return;
            }

            const isSignedIn = state.common.user.isSignedIn;
            if (isSignedIn) {
                dispatch({
                    type: CommonActions.GetUserImageQuota,
                    payload: this.imageService.getUserImageQuota(),
                });
            }
        };
    }

    @ActionCreator()
    public getCouchDBInfo(): ThunkAction {
        return async (dispatch, getState) => {
            const state = getState();
            const isStandalone = getIsStandalone(state);
            const isSignedIn = getUserSignedIn(state);
            if (!isSignedIn || isStandalone) {
                return;
            }

            const dbInfo = await this.couchDBCompactionService.getCouchDBInfo();
            dispatch({
                type: CommonActions.GetCouchDBInfo,
                payload: dbInfo,
            });
            dispatch({
                type: CommonActions.SetHasCouchDB,
                payload: dbInfo !== null,
            });
        };
    }

    @ActionCreator()
    public updateCompactStatus(): IAction<Promise<CompactionStatus | null>> {
        return {
            type: CommonActions.UpdateCompactStatus,
            payload: this.couchDBCompactionService.getCompactStatus(),
        };
    }

    @ActionCreator()
    public setCompactionInitiated(value: boolean): IAction<boolean> {
        return {
            type: CommonActions.SetCompactionInitiated,
            payload: value,
        };
    }

    public async executeCommand(command: ICommand, undoable: boolean, redoable: boolean) {
        const idMap = new Map<Id, Id>();
        // creates
        let creates = [...(command.creates ?? [])].reverse();
        let updates = [...(command.updates ?? [])].reverse();
        let deletes = [...(command.deletes ?? [])].reverse();
        while (creates.length > 0) {
            const item = creates.shift()!;
            const newEntity = await this.genericEntityRepository.create(
                item.props,
                undoable,
                redoable,
                false,
            );
            idMap.set(item.props._id, newEntity._id);
            creates = updateCreateOperations(creates, idMap);
        }
        updates = updateUpdateOperations(updates, idMap);
        deletes = updateDeleteOperations(deletes, idMap);
        // updates
        for (const item of updates) {
            await this.genericEntityRepository.updatePartial(
                item.id,
                item.props,
                undoable,
                redoable,
            );
        }
        // deletes
        for (const item of deletes) {
            await this.genericEntityRepository.deleteById(item.id, undoable, redoable, false);
        }
        return idMap;
    }

    @ActionCreator()
    public undo(): ThunkAction {
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const state = getState();
            const command = last(getUndoQueue(state));
            const commandRunning = state.currentProject.history.commandRunning;

            this.trackUndoEvent(command);

            if (canUndoNavigation()) {
                // go back to wherever we came from
                return history.back();
            }

            if (!command || commandRunning) return;

            dispatch({
                type: PersistenceActions.StartHistoryCommand,
            });

            const idMap = await this.executeCommand(command, false, true);

            dispatch({
                type: PersistenceActions.UpdateHistoryIds,
                payload: idMap,
            });
            dispatch({
                type: PersistenceActions.PopUndoAction,
            });
        };
    }

    @ActionCreator()
    public redo(): ThunkAction {
        eventTracking.logUserEvent('UndoRedo', 'Redo');
        return async (dispatch: Dispatch<Action>, getState: () => IStoreState) => {
            const state = getState();
            const command = last(getRedoQueue(state));
            const commandRunning = state.currentProject.history.commandRunning;

            if (canUndoNavigation()) {
                // disallow redo
                return;
            }

            if (!command || commandRunning) return;

            dispatch({
                type: PersistenceActions.StartHistoryCommand,
            });

            const idMap = await this.executeCommand(command, true, false);

            dispatch({
                type: PersistenceActions.UpdateHistoryIds,
                payload: idMap,
            });
            dispatch({
                type: PersistenceActions.PopRedoAction,
            });
        };
    }

    /**
     * Log tracking events for undo/redo actions.
     * @param command
     */
    private trackUndoEvent(command: ICommand | undefined) {
        // Log undo event when something has been deleted and what type that was deleted
        // e.g. 'UndoRedo' - 'Undo create' - 'mapLocation'
        if (command?.creates && command.creates.length > 0) {
            const deleteLabel = command.creates.map(({ props }) => props.type).join(', ');

            eventTracking.logUserEvent('UndoRedo', 'Undo delete', deleteLabel);
        }

        // Log undo event when something has been updated and what properties have been changed
        // e.g. 'UndoRedo' - 'Undo update' - 'location'
        else if (command?.updates && command.updates.length > 0) {
            const updateLabel = command.updates
                .map(({ props }) => {
                    return Object.getOwnPropertyNames(props).join(', ');
                })
                .join(', ');
            eventTracking.logUserEvent('UndoRedo', 'Undo update', updateLabel);
        }

        // Log undo event when something has been created and what type of item/ items
        // e.g. 'UndoRedo' - 'Undo create' - 'installationPoint'
        else if (command?.deletes && command.deletes.length > 0) {
            const createLabel = command.deletes.map(({ id }) => id.split(':')[0]).join(', ');
            eventTracking.logUserEvent('UndoRedo', 'Undo create', createLabel);
        }
    }
    private setupUndoRedoShortcuts() {
        document.addEventListener('keydown', (event) => {
            if (isUndoRedoDisabled()) {
                return;
            }

            if (event.ctrlKey || event.metaKey) {
                switch (event.key) {
                    case 'z':
                        this.undo();
                        event.preventDefault();
                        break;
                    case 'y':
                        this.redo();
                        event.preventDefault();
                        break;
                }
            }
        });
    }

    private async switchToProjectDatabase(projectId: Id): Promise<void> {
        try {
            // first try current repo
            await this.projectRepository.get(projectId);
            this.getUserSettings();
            return;
        } catch (_e) {
            // on failure, try other repos
            for (const dbOrigin of Object.values(ProjectDbOrigin)) {
                try {
                    await this.initializeDataStorageService.switchDataRepository(dbOrigin);
                    await this.projectRepository.get(projectId);
                    this.getUserSettings();
                    return;
                } catch (e) {
                    if (
                        (isCustomPouchError(e) && e.reason === 'missing') ||
                        (isCustomPouchError(e) && e.reason === 'deleted')
                    ) {
                        continue;
                    }
                    throw Error(
                        isCustomPouchError(e)
                            ? `Could not get project in ${dbOrigin} message: ${e.message} reason: ${e.reason}`
                            : `Could not get project in ${dbOrigin}`,
                    );
                }
            }
            eventTracking.logError(`Could not find project in any database`, 'CommonActionService');
            // default to asdUserData
            await this.initializeDataStorageService.switchDataRepository(
                ProjectDbOrigin.asdUserData,
            );
        }
    }

    private getMockedPiaItems(): IPiaItem[] {
        if (
            !isFeatureEnabled('mocked_acs_center_licenses') ||
            !isFeatureEnabled('acs_cloud_storage')
        )
            return [];

        const mockedACSCenterOneYearLicense = {
            id: 96249,
            name: 'AXIS Camera Station Center Device License 1y',
            category: PiaItemSoftwareCategory.VMS,
            categories: [PiaItemSoftwareCategory.VMS],
            externallyHidden: false,
            state: PiaItemState.EXTERNALLY_ANNOUNCED,
            relations: [],
            properties: {
                licenseProdCat: ['All'],
                licenseType: 'Device',
                vendor: 'Axis',
                isELicense: true,
                subscriptionIntervalInMonths: 12,
            } as IPiaSoftwareProperties,
            parentId: 96248,
            versions: [
                {
                    partno: '03081-001',
                    scaleQuantity: 1,
                    state: PiaItemState.EXTERNALLY_ANNOUNCED,
                    versions: ['EUR', 'UK', 'US', 'JP', 'AUS', 'KOR', 'AR', 'CH', 'BR', 'IND'],
                },
            ],
        } as IPiaSoftware;
        const mockedACSCenterFiveYearLicense = {
            id: 96252,
            name: 'AXIS Camera Station Center Device License 5y',
            category: PiaItemSoftwareCategory.VMS,
            categories: [PiaItemSoftwareCategory.VMS],
            externallyHidden: false,
            state: PiaItemState.EXTERNALLY_ANNOUNCED,
            relations: [],
            properties: {
                licenseProdCat: ['All'],
                licenseType: 'Device',
                vendor: 'Axis',
                isELicense: true,
                subscriptionIntervalInMonths: 60,
            } as IPiaSoftwareProperties,
            parentId: 96248,
            versions: [
                {
                    partno: '03082-001',
                    scaleQuantity: 1,
                    state: PiaItemState.EXTERNALLY_ANNOUNCED,
                    versions: ['EUR', 'UK', 'US', 'JP', 'AUS', 'KOR', 'AR', 'CH', 'BR', 'IND'],
                },
            ],
        } as IPiaSoftware;

        const mockedACSCloudStorage1YearLicense = {
            id: 97929,
            name: 'AXIS Camera Station Cloud Storage License 1y',
            category: 'vms',
            categories: ['vms'],
            externallyHidden: false,
            state: PiaItemState.EXTERNALLY_ANNOUNCED,
            relations: [],
            properties: {
                licenseProdCat: ['All'],
                licenseType: 'Device',
                vendor: 'Axis',
                isELicense: true,
                subscriptionIntervalInMonths: 12,
            } as IPiaSoftwareProperties,
            parentId: 97928,
            versions: [
                {
                    partno: '03148-001',
                    scaleQuantity: 1,
                    state: PiaItemState.EXTERNALLY_ANNOUNCED,
                    versions: ['EUR', 'UK', 'US', 'JP', 'AUS', 'KOR', 'AR', 'CH', 'BR', 'IND'],
                },
            ],
        } as IPiaSoftware;

        return [
            mockedACSCenterOneYearLicense,
            mockedACSCenterFiveYearLicense,
            mockedACSCloudStorage1YearLicense,
        ];
    }
}
