import type { ISustainabilityFilter } from 'app/core/persistence';
import {
    defaultSustainabilityFilter,
    isBodyWornCamera,
    isCameraExtension,
    isDockingStation,
    isSystemController,
} from 'app/core/persistence';
import type { IPiaItem, IPiaWearable } from 'app/core/pia';
import { meetsSustainabilityRequirements } from './SustainabilityFilter';

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const bodyWornCameraType = 'bwCameras';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const cameraExtensionType = 'cameraExtensions';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const dockingStationType = 'dockingStations';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const systemControllerType = 'systemControllers';

export type WearablesType =
    | typeof bodyWornCameraType
    | typeof cameraExtensionType
    | typeof dockingStationType
    | typeof systemControllerType;

export interface IWearablesFilter extends ISustainabilityFilter {
    selectedCategory?: WearablesType;
}

export const defaultWearablesFilter: IWearablesFilter = {
    selectedCategory: undefined,
    ...defaultSustainabilityFilter,
};
export class WearablesFilter {
    public static filter(
        wearablesFilter: IWearablesFilter,
        allWearables: IPiaWearable[],
        searchFilter: string,
    ): IPiaItem[] {
        const lowerCaseSearchFilter = searchFilter.toLowerCase();

        return allWearables.filter((wearable) => {
            if (!wearable.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }
            if (wearablesFilter.selectedCategory === 'bwCameras' && !isBodyWornCamera(wearable)) {
                return false;
            }
            if (
                wearablesFilter.selectedCategory === 'cameraExtensions' &&
                !isCameraExtension(wearable)
            ) {
                return false;
            }
            if (
                wearablesFilter.selectedCategory === 'dockingStations' &&
                !isDockingStation(wearable)
            ) {
                return false;
            }
            if (
                wearablesFilter.selectedCategory === 'systemControllers' &&
                !isSystemController(wearable)
            ) {
                return false;
            }
            if (!meetsSustainabilityRequirements(wearablesFilter, wearable)) {
                return false;
            }

            return true;
        });
    }
}
