import { createSelector } from 'reselect';
import { getSelectedRecordingSolutionType, getSelectedRecordingVendor } from 'app/modules/common';

export type VendorNameType = 'genetec' | 'milestone' | 'axis';

export const getSelectedVendorName = createSelector(
    [getSelectedRecordingSolutionType, getSelectedRecordingVendor],
    (recordingSolutionType, selectedRecordingVendor): VendorNameType => {
        const vendor = selectedRecordingVendor || recordingSolutionType;

        switch (vendor) {
            case 'Genetec':
                return 'genetec';
            case 'Milestone':
                return 'milestone';
            default:
                return 'axis';
        }
    },
);
