import { toShareModelNames } from '../../mapToShareItemNames';
import { injectable } from 'inversify';
import type { IPiaDecoder } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { IItemEntity, IPersistence, IDecoderItemEntity, Id } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { IExportablePersistedEntity, IExportedDecoder } from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';

@injectable()
export class DecoderExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDecoder>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedDecoders = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedDecoder[]> => {
        const allDecoders = items.filter(this.isDecoderItemEntity);
        const mappedDecoders = await Promise.all(
            allDecoders.map((decoder) => this.mapDecoderToExportedDecoder(decoder, projectId)),
        );
        return mappedDecoders.filter(isDefined);
    };

    private mapDecoderToExportedDecoder = async (
        decoderItem: IExportablePersistedEntity,
        projectId: Id,
    ): Promise<IExportedDecoder | null> => {
        if (!decoderItem.productId) {
            return null;
        }

        const decoderProduct = this.getPiaDevice(decoderItem.productId);
        const mappedBaseItem = await this.mapItemToExportedItemBase(decoderItem, projectId);

        return {
            ...mappedBaseItem,
            ...toShareModelNames(decoderProduct.name),
            piaId: decoderItem.productId,
        };
    };

    private isDecoderItemEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<IDecoderItemEntity> {
        return deviceTypeCheckers.isDecoder(item);
    }
}
