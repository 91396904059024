import type { PiaId } from 'app/core/pia';
import { injectable } from 'inversify';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import type { IStoreState } from 'app/store';
import { MapsActions } from '../actions';
import type {
    IMapsState,
    IFloorPlanGeolocation,
    ISelectedCoverageAreaInfo,
    ISensorCoverageAreaInfo,
    ISelectedMapItem,
    IDuplicationInfo,
} from '../models';
import {
    contextMenuType,
    IFloorPlanGeolocationChanges,
    IUploadFloorPlanOptions,
    IPressedModifierKeys,
    BlockerEditState,
    IAddDeviceTab,
    AddDeviceTabName,
} from '../models';
import { MapsService } from './Maps.service';
import type { IBlockerEntity, IFreeTextPointEntity, IBounds } from 'app/core/persistence';
import {
    Id,
    IFloorPlanEntity,
    IFloorPlanMapType,
    IInstallationPointModel,
    InstallationPointService,
    BlockerService,
    FreeTextPointService,
    IUploadImageOptions,
    MAX_IMAGE_SIZE_BYTES,
    ILatLng,
    MapLocationsService,
    setItemLocalStorage,
} from 'app/core/persistence';
import { t } from 'app/translate';
import {
    CommonActionService,
    getCurrentProjectLocked,
    getInstallationPointsArray,
    ILocation,
    isGeoMap,
    isGeoLocated,
    getFloorPlanGeoLocation,
    transformInstallationPoint,
    transformBlocker,
    transformFreeTextPoint,
    IPlaceSearchResult,
} from 'app/modules/common';
import { toaster } from 'app/toaster';
import { creationDateReverseComparator } from 'app/utils';
import { ModalService } from 'app/modal';
import { isEmpty, isEqual } from 'lodash-es';
import {
    getSelectedMapOrDefault,
    getIsFreeTextToolActive,
    getGeolocationChanges,
    getRotationTransform,
    getRotationAngle,
    getAllMaps,
    getGeoMapClosestToSelectedMap,
    getInstallationPointsPerFloorPlan,
    getBoundsPerGeoMap,
    getFreeTextPointsPerFloorPlan,
    getBlockersPerFloorPlan,
    getDefaultGeoMap,
    getMapViewBoundsFactory,
    getGeolocatedFloorPlans,
    getGeoLocatedInstallationPoints,
    getGeolocatedBlockers,
    getGeolocatedFreeTextPoints,
    getInstallationPointsForItemDerotated,
} from '../selectors';
import {
    globalizeEntity,
    calculateFloorPlanBounds,
    toBounds,
    removeFloorPlanLinkFromInstallationPoint,
    calculateLocationFromBounds,
} from '../utils';

import { createTransformer, isDefined } from 'axis-webtools-util';
import * as leaflet from 'leaflet';
import { eventTracking } from 'app/core/tracking';
const BOUNDS_PADDING_RATIO = 0.15; // extending bounds by 15%

@injectable()
export class MapsActionService {
    constructor(
        private mapsService: MapsService,
        private mapLocationService: MapLocationsService,
        private installationPointService: InstallationPointService,
        private blockerService: BlockerService,
        private freeTextPointService: FreeTextPointService,
        private commonActionService: CommonActionService,
        private modalService: ModalService,
    ) {}

    @ActionCreator()
    public selectMapItem(
        installationPoint: IInstallationPointModel | undefined,
        type: contextMenuType,
        sensorId?: number,
    ): ThunkAction {
        return (dispatch, _getState) => {
            if (!installationPoint) {
                return dispatch({
                    type: MapsActions.SelectItem,
                    payload: undefined,
                });
            }

            const selectedMapItem: ISelectedMapItem = {
                id: installationPoint._id,
                parentDeviceId: installationPoint.parentDevice._id,
                location: installationPoint.location,
                labelOffset: installationPoint.labelOffset,
                height: installationPoint.height,
            };

            dispatch({
                type: MapsActions.SelectItem,
                payload: {
                    mapItem: selectedMapItem,
                    multiSelected: {},
                    type,
                    coverageAreaInfo: this.getSelectedCoverageAreaInfo(installationPoint, sensorId),
                },
            });
        };
    }

    @ActionCreator()
    public updateSelectedMapItem(
        mapItem: Partial<ISelectedMapItem>,
    ): IAction<Partial<ISelectedMapItem>> {
        return {
            type: MapsActions.UpdateSelectedMapItem,
            payload: mapItem,
        };
    }

    @ActionCreator()
    public multiSelect(
        installationPoint: IInstallationPointModel | undefined,
        isDragging?: boolean,
    ): ThunkAction {
        return (dispatch, getState) => {
            if (!installationPoint?._id) return;
            const state = getState();

            const multiSelected = { ...state.maps.selected?.multiSelected };
            const currentlySelectedMapItem = state.maps.selected?.mapItem;
            const deselect = multiSelected[installationPoint._id] && !isDragging;

            // Clear multi select when deselecting last element in list
            if (Object.keys(multiSelected).length === 1 && deselect) {
                return dispatch({ type: MapsActions.ClearMultiSelected });
            }

            if (
                isEmpty(state.maps.selected?.multiSelected) &&
                currentlySelectedMapItem &&
                !deselect
            ) {
                // First add the currently selected map item to multi select list before shift clicking
                multiSelected[currentlySelectedMapItem.id] = currentlySelectedMapItem.location;
            }

            // add / delete installation point from list
            deselect
                ? delete multiSelected[installationPoint._id]
                : (multiSelected[installationPoint._id] = installationPoint.location);

            return dispatch({
                type: MapsActions.SelectItem,
                payload: {
                    ...state.maps.selected,
                    multiSelected: multiSelected,
                    type: 'multiInstallationPoint',
                },
            });
        };
    }

    @ActionCreator()
    public clearMultiSelected(): IAction<Record<Id, ILatLng>> {
        return {
            type: MapsActions.ClearMultiSelected,
            payload: {},
        };
    }

    @ActionCreator()
    public updateSelectedCoverageAreaInfo(
        coverageAreaInfo: ISelectedCoverageAreaInfo | ISensorCoverageAreaInfo | undefined,
    ): IAction<ISelectedCoverageAreaInfo | undefined> {
        return {
            type: MapsActions.UpdateSelectedCoverageArea,
            payload: coverageAreaInfo,
        };
    }

    @ActionCreator()
    public selectDevice(deviceId: Id): ThunkAction {
        return (dispatch, getState) => {
            const installationPoints = getInstallationPointsForItemDerotated(getState(), deviceId);
            if (installationPoints.length === 0) {
                return this.selectUnplacedDevice(deviceId);
            }

            const installationPoint = installationPoints.sort(creationDateReverseComparator)[0];
            const selectedMapItem = {
                id: installationPoint._id,
                parentDeviceId: deviceId,
                location: installationPoint.location,
                labelOffset: installationPoint.labelOffset,
                height: installationPoint.height,
            } as ISelectedMapItem;

            dispatch({
                type: MapsActions.SelectItem,
                payload: {
                    mapItem: selectedMapItem,
                    type: 'device',
                    multiSelected: {},
                },
            });
        };
    }

    @ActionCreator()
    public setCurrentFloorPlanUnDismissRadarWarning(): ThunkAction {
        return (_dispatch, getState) => {
            const currentFloorPlanId = getSelectedMapOrDefault(getState())?._id;
            if (currentFloorPlanId) {
                return this.mapsService.setUnDismissRadarWarning(currentFloorPlanId);
            }
        };
    }

    @ActionCreator()
    public onMapClick(latLng: ILatLng): ThunkAction {
        return (_dispatch, getState) => {
            const state = getState();
            const isFreeTextToolActive = getIsFreeTextToolActive(state);
            const selectedFloorPlan = getSelectedMapOrDefault(state);
            if (isFreeTextToolActive && selectedFloorPlan) {
                this.toggleFreeTextTool(false);
                this.mapsService.addFreeTextMapItem(selectedFloorPlan, latLng);
            }
            this.deselectItem();
        };
    }

    @ActionCreator()
    public setCurrentFloorPlan(floorPlanId: Id): IAction<Id> {
        return {
            type: MapsActions.SetFocusedFloorPlanId,
            payload: floorPlanId,
        };
    }

    @ActionCreator()
    public unsetCurrentFloorPlan(): IAction<void> {
        return {
            type: MapsActions.UnsetFocusedFloorPlanId,
            payload: undefined,
        };
    }

    @ActionCreator()
    public goToGeolocation(floorPlanId: Id): ThunkAction {
        eventTracking.logUserEvent('Maps', 'Go to floor plan geolocation');
        return async (_dispatch, getState) => {
            const state = getState();
            const geoMap = getGeoMapClosestToSelectedMap(state);
            // First set the selected map to the geo map
            this.setSelectedMap(geoMap?._id);

            const getMapViewBounds = getMapViewBoundsFactory(state);
            const mapViewBounds = getMapViewBounds(floorPlanId);
            this.setDesiredBounds(geoMap?._id, mapViewBounds);
        };
    }

    @ActionCreator()
    public addMapLocationBySearchResult(searchResult: IPlaceSearchResult): ThunkAction {
        return async (_dispatch, getState) => {
            const defaultMap = getDefaultGeoMap(getState());

            if (defaultMap) {
                this.setDesiredBounds(defaultMap._id, searchResult.bounds);
            }
            await this.mapLocationService.addMapLocation(
                searchResult.address?.split('\n')[0] ?? t.newLocation,
                searchResult.bounds,
            );
        };
    }

    @ActionCreator()
    public setNewFloorPlanBounds(selectedMap: IFloorPlanEntity, bounds: IBounds): ThunkAction {
        return async (_dispatch, getState) => {
            // Get default map
            const defaultMap = getDefaultGeoMap(getState());
            if (!defaultMap) return;

            // Then set the floor plan location
            this.setFloorPlanBounds(selectedMap, bounds);

            // Set desired bounds to the new location
            this.setDesiredBounds(defaultMap._id, {
                topLeft: bounds.topLeft,
                bottomRight: bounds.bottomRight,
            });
        };
    }

    @ActionCreator()
    public setSelectedAddTab(tab: IAddDeviceTab): IAction<IAddDeviceTab> {
        return {
            type: MapsActions.SetSelectedAddTab,
            payload: tab,
        };
    }

    @ActionCreator()
    public setSelectedDragProduct(
        tabName: AddDeviceTabName,
        piaId: PiaId | null,
    ): IAction<{ tabName: AddDeviceTabName; piaId: PiaId | null }> {
        return {
            type: MapsActions.SetSelectedDragProduct,
            payload: { tabName, piaId },
        };
    }
    @ActionCreator()
    public setAddCameraSearchFilter(searchText: string): IAction<string> {
        return {
            type: MapsActions.SetAddCameraSearchFilter,
            payload: searchText,
        };
    }

    @ActionCreator()
    public setZoomLevel(payload: number): IAction<number> {
        return {
            type: MapsActions.SetZoomLevel,
            payload,
        };
    }

    @ActionCreator()
    public setMapLocation(payload: ILatLng): IAction<ILatLng> {
        return {
            type: MapsActions.SetCurrentMapLocation,
            payload,
        };
    }

    @ActionCreator()
    public toggleDoriPixels(payload?: boolean): IAction<boolean | undefined> {
        return {
            type: MapsActions.ToggleDoriPixels,
            payload,
        };
    }

    @ActionCreator()
    public setRadarCoexistenceShowWarning(payload?: boolean): IAction<boolean | undefined> {
        return {
            type: MapsActions.SetRadarCoexistingShowWarning,
            payload,
        };
    }

    @ActionCreator()
    public setShowDevicesOnMap(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.ToggleShowDevicesOnMap,
            payload,
        };
    }

    @ActionCreator()
    public rescale(selectedFloorPlan: IFloorPlanEntity, scaleFactor: number): ThunkAction {
        return async (_dispatch, getState) => {
            // Get the installation points, free text points and blockers for the selected floor plan
            const state = getState();
            const installationPointMap = getInstallationPointsPerFloorPlan(state);
            const freeTextPointMap = getFreeTextPointsPerFloorPlan(state);
            const blockerMap = getBlockersPerFloorPlan(state);
            const installationPoints = installationPointMap[selectedFloorPlan._id] ?? [];
            const freeTextPoints = freeTextPointMap[selectedFloorPlan._id] ?? [];
            const blockers = blockerMap[selectedFloorPlan._id] ?? [];

            if (isGeoLocated(selectedFloorPlan)) {
                // We have a geolocated floor plan. Rescale it and all items on it

                const geoLocation = getFloorPlanGeoLocation(selectedFloorPlan)!;

                // Apply the scale to the geoLocation
                const rescaledGeoLocation = {
                    ...geoLocation,
                    width: geoLocation.width * scaleFactor,
                    height: geoLocation.height * scaleFactor,
                };

                // Apply the geolocation change
                this.applyGeolocationChange(
                    installationPoints,
                    freeTextPoints,
                    blockers,
                    selectedFloorPlan,
                    rescaledGeoLocation,
                    scaleFactor,
                    0, // no rotation
                );

                // Reset view bounds for changed floor plan
            } else {
                // We have an unlocated floor plan. Apply the rescale to the image bounds and all items on it
                await this.mapsService.rescale(
                    selectedFloorPlan,
                    blockers,
                    installationPoints,
                    freeTextPoints,
                    scaleFactor,
                );
            }

            // Force a re-render before resetting bounds
            await new Promise((resolve) => setTimeout(resolve, 300));
            this.setViewBounds(selectedFloorPlan._id, undefined);
        };
    }

    @ActionCreator()
    public deselectItem(): IAction<IMapsState['selected']> {
        return {
            type: MapsActions.SelectItem,
            payload: undefined,
        };
    }

    @ActionCreator()
    public removeInstallationPoint(
        installationPoint: IInstallationPointModel,
    ): IAction<Promise<string>> {
        return {
            type: MapsActions.RemoveInstallationPoint,
            payload: this.installationPointService
                .removeInstallationPoint(installationPoint._id, installationPoint._rev)
                .then(() => installationPoint.parentDevice._id),
        };
    }

    @ActionCreator()
    public removeFloorPlan(floorPlan: IFloorPlanEntity): IAction<Promise<string | void>> {
        return {
            type: MapsActions.RemoveFloorPlan,
            payload: this.mapsService.removeFloorPlan(floorPlan).catch((error: any) => {
                switch (error.message) {
                    case 'AuthError':
                        toaster.error(
                            t.deleteFloorPlanErrorHeader,
                            t.deleteFloorPlanAuthErrorMessage,
                        );
                        break;

                    case 'UnknownNetworkError':
                        toaster.error(
                            t.deleteFloorPlanErrorHeader,
                            t.deleteFloorPlanUnknownNetworkErrorMessage,
                        );
                        break;

                    default:
                        toaster.error(t.deleteFloorPlanErrorHeader, t.deleteFloorPlanUnknownError);
                }
            }),
        };
    }

    @ActionCreator()
    public changeFloorPlan(
        floorPlan: IFloorPlanMapType,
        uploadOptions: IUploadImageOptions,
    ): ThunkAction {
        return async (dispatch) => {
            const hasValidFileSize = uploadOptions.file.size <= MAX_IMAGE_SIZE_BYTES;

            if (hasValidFileSize) {
                this.toggleUploading(true);
                const changePromise = this.mapsService
                    .changeFloorPlan(floorPlan, uploadOptions)
                    .catch((error) => {
                        throw new Error(error.message);
                    });
                dispatch({
                    type: MapsActions.ChangeFloorPlan,
                    payload: changePromise,
                });

                await changePromise;
                this.commonActionService.getUserImageQuota();
            } else {
                await this.showFileTooLargeDialog();
            }
        };
    }

    @ActionCreator()
    public addFloorPlan(
        projectId: Id,
        uploadFloorPlanOptions: IUploadFloorPlanOptions,
    ): ThunkAction {
        return async (dispatch) => {
            const hasValidFileSize =
                uploadFloorPlanOptions.uploadOptions.file.size <= MAX_IMAGE_SIZE_BYTES;
            if (hasValidFileSize) {
                this.toggleUploading(true);
                const addPromise = this.mapsService
                    .addFloorPlan(projectId, uploadFloorPlanOptions)
                    .catch((error) => {
                        throw new Error(error.message);
                    });
                dispatch({
                    type: MapsActions.AddFloorPlan,
                    payload: addPromise,
                });

                await addPromise;
                this.commonActionService.getUserImageQuota();
            } else {
                await this.showFileTooLargeDialog();
            }
        };
    }

    @ActionCreator()
    public copyFloorPlan(projectId: Id, floorPlan: IFloorPlanEntity, name: string): ThunkAction {
        return async (dispatch) => {
            const copyPromise = this.mapsService
                .copyFloorPlan(projectId, floorPlan, name)
                .catch((error) => {
                    throw new Error(error.message);
                });
            dispatch({
                type: MapsActions.AddFloorPlan,
                payload: copyPromise,
            });

            await copyPromise;
            this.commonActionService.getUserImageQuota();
        };
    }

    @ActionCreator()
    public duplicateMapItem(
        installationPoint: IInstallationPointModel,
        newInstance: boolean,
    ): ThunkAction {
        return async (dispatch, getState) => {
            const state = getState();
            const projectLocked = getCurrentProjectLocked(state);
            const projectInstallationPoints = getInstallationPointsArray(state);
            const placedInstallationPoints = projectInstallationPoints.filter(
                (ip) =>
                    ip.path.includes(installationPoint.parentDevice._id) &&
                    ip.path.length <= installationPoint.parentDevice.path.length + 1,
            ).length;

            // transform installation point to derotated position
            const transform = getRotationTransform(state);
            const angle = getRotationAngle(state);
            const derotatedInstallationPoint = transformInstallationPoint(
                transform,
                angle,
                installationPoint,
            );

            const canDuplicate = await this.mapsService.canDuplicateMapItem(
                derotatedInstallationPoint,
                placedInstallationPoints,
                projectLocked,
                newInstance,
            );
            switch (canDuplicate) {
                case null:
                    return;
                case false:
                    return this.setErrorMessage(true);
                default:
                    break;
            }

            const duplicatePromise = this.mapsService.duplicateMapItem(
                derotatedInstallationPoint,
                newInstance,
                placedInstallationPoints,
            );
            dispatch({
                type: MapsActions.DuplicateMapItem,
                payload: duplicatePromise,
            });

            const newInstallationPointId = await duplicatePromise;
            this.selectMapItem(
                { ...installationPoint, _id: newInstallationPointId ?? installationPoint._id },
                'installationPoint',
            );
            this.setDuplicationInfo(null);
        };
    }

    @ActionCreator()
    public addMap(projectId: Id, name: string, location: ILocation): ThunkAction {
        return async (dispatch, getState) => {
            const state = getState();
            const newStreetMap = await this.mapsService.addStreetMap(
                projectId,
                name,
                location,
                false,
                true,
            );
            // Don't set it as selected if there are already floor plans (due to migration).
            const shouldBeSelected = Object.keys(state.currentProject.floorPlans).length === 0;

            dispatch({
                type: MapsActions.AddFloorPlan,
                payload: newStreetMap,
            });
            if (shouldBeSelected) {
                dispatch({
                    type: MapsActions.SetSelectedMap,
                    payload: newStreetMap._id,
                });
            }
        };
    }

    @ActionCreator()
    public defaultGeoLocationChanged(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.DefaultGeoLocationChanged,
            payload,
        };
    }

    @ActionCreator()
    public setSelectedMap(floorPlanId: Id): ThunkAction {
        return (dispatch) => {
            dispatch({
                type: MapsActions.SetSelectedMap,
                payload: floorPlanId,
            });
        };
    }

    @ActionCreator()
    public resetToInitialState(): IAction<undefined> {
        return {
            type: MapsActions.ResetToInitialState,
            payload: undefined,
        };
    }

    @ActionCreator()
    public close3dView(): IAction<undefined> {
        return {
            type: MapsActions.Close3dView,
            payload: undefined,
        };
    }

    @ActionCreator()
    public toggle3dView(): IAction<undefined> {
        return {
            type: MapsActions.Toggle3dView,
            payload: undefined,
        };
    }

    @ActionCreator()
    public toggleAddMapModal(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.ToggleAddMapModal,
            payload,
        };
    }

    @ActionCreator()
    public toggleAddLocationModal(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.ToggleAddLocationModal,
            payload,
        };
    }

    @ActionCreator()
    public toggleCopyMapModal(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.ToggleCopyMapModal,
            payload,
        };
    }

    @ActionCreator()
    public toggleScalingTool(payload?: boolean): IAction<boolean | undefined> {
        return {
            type: MapsActions.ToggleScalingTool,
            payload,
        };
    }

    @ActionCreator()
    public toggleUploading(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.ToggleUploading,
            payload,
        };
    }

    private async showFileTooLargeDialog() {
        this.toggleAddMapModal(false);
        await this.modalService.createConfirmDialog({
            header: t.addFileTooLargeHeading,
            body: t.addFileTooLarge,
            confirmButtonText: t.close,
        })();
        this.toggleAddMapModal(true);
    }

    private getSelectedCoverageAreaInfo(
        installationPoint: IInstallationPointModel,
        sensorId?: number,
    ): ISelectedCoverageAreaInfo | undefined {
        const sensor =
            sensorId && installationPoint.sensors.length > 0
                ? installationPoint.sensors[sensorId - 1]
                : undefined;

        const target: { height?: number; horizontalAngle?: number; distance?: number } | undefined =
            sensor
                ? sensor.target
                : (installationPoint.radar?.target ??
                  installationPoint.speaker?.target ??
                  installationPoint.panRange?.target);

        // Select sensor 1 if a pan range area was selected
        const selectedSensorId = sensorId ? sensorId : installationPoint.panRange ? 1 : undefined;

        let selectedCoverageAreaInfo = target
            ? ({
                  sensorId: selectedSensorId,
                  targetHeight: target?.height,
                  horizontalAngle: target?.horizontalAngle,
                  targetDistance: target?.distance,
                  location: installationPoint.location,
                  labelOffset: installationPoint.labelOffset,
              } as ISelectedCoverageAreaInfo)
            : undefined;

        if (sensor) {
            selectedCoverageAreaInfo = {
                ...selectedCoverageAreaInfo,
                fovLimits: sensor.fovLimits,
                corridorFormat: sensor.settings.corridorFormat,
                horizontalFov: sensor.settings.horizontalFov,
            } as ISensorCoverageAreaInfo;
        }

        return selectedCoverageAreaInfo;
    }

    @ActionCreator()
    private selectUnplacedDevice(deviceId: Id): IAction<IMapsState['selected']> {
        return {
            type: MapsActions.SelectItem,
            payload: {
                deviceId,
                type: 'device',
                multiSelected: {},
            },
        };
    }

    @ActionCreator()
    public setPressedModifierKeys(keysInfo: IPressedModifierKeys): IAction<IPressedModifierKeys> {
        return {
            type: MapsActions.SetPressedModifierKeys,
            payload: keysInfo,
        };
    }

    @ActionCreator()
    public setDuplicationInfo(
        duplicationInfo: IDuplicationInfo | null,
    ): IAction<IDuplicationInfo | null> {
        return {
            type: MapsActions.SetDuplicationInfo,
            payload: duplicationInfo,
        };
    }

    @ActionCreator()
    public updateInstallationPoint(installationPoint: IInstallationPointModel): ThunkAction {
        return (dispatch, getState) => {
            const transform = getRotationTransform(getState());
            const angle = getRotationAngle(getState());

            const newIp = transformInstallationPoint(transform, angle, installationPoint);
            dispatch({
                type: MapsActions.UpdateInstallationPoint,
                payload: this.installationPointService.updateInstallationPoint(newIp._id, newIp),
            });
        };
    }

    @ActionCreator()
    public setErrorMessage(status: boolean): IAction<boolean> {
        return {
            type: MapsActions.SetErrorMessage,
            payload: status,
        };
    }

    @ActionCreator()
    public setViewBounds(floorPlanId: Id, bounds: IBounds | undefined): ThunkAction {
        return (dispatch, getState) => {
            const state = getState();
            const currentBounds = state.maps.mapViewBounds[floorPlanId];

            if (bounds && isEqual(bounds, currentBounds)) return;

            dispatch({
                type: MapsActions.SetMapViewBounds,
                payload: { floorPlanId, bounds },
            });
        };
    }

    @ActionCreator()
    public fitBounds(mapId: Id): ThunkAction {
        return (_dispatch, getState) => {
            const state = getState();
            const map = getAllMaps(state)[mapId];

            if (isGeoMap(map)) {
                const boundsPerGeoMap = getBoundsPerGeoMap(state);
                const bounds = boundsPerGeoMap[mapId];

                if (bounds) {
                    this.setDesiredBounds(mapId, bounds);
                }
            } else if (map?.image) {
                // If the map is a floor plan, fit the bounds to the image bounds
                const bounds = toBounds(calculateFloorPlanBounds(map));
                this.setDesiredBounds(mapId, bounds);
            }
        };
    }

    /**
     * Set bounds for the provided map
     * 1. If geoMap - set bounds to include all geolocated floorplans, all installationPoints, all free text points
     * and all blockers placed on geoMaps
     * 2. If floorPlan - fit map to the image bounds.
     * @param mapId
     * @returns
     */
    @ActionCreator()
    public scaleToFit(mapId: Id): ThunkAction {
        return (_dispatch, getState) => {
            const state = getState();
            const map = getAllMaps(state)[mapId];

            let bounds;

            if (isGeoMap(map)) {
                const allLatLngsToInclude = this.getAllLatLngs(state);
                bounds = toBounds(
                    leaflet.latLngBounds(allLatLngsToInclude).pad(BOUNDS_PADDING_RATIO),
                );
            } else if (map?.image) {
                bounds = toBounds(calculateFloorPlanBounds(map));
            }

            if (bounds) {
                this.setDesiredBounds(mapId, bounds);
            }
        };
    }

    @ActionCreator()
    public goToLatestViewBounds(mapId: Id): ThunkAction {
        return (_dispatch, getState) => {
            const state = getState();
            const getMapViewBounds = getMapViewBoundsFactory(state);
            const currentBounds = getMapViewBounds(mapId);

            if (currentBounds) {
                this.setDesiredBounds(mapId, currentBounds);
                return;
            } else {
                return this.fitBounds(mapId);
            }
        };
    }

    @ActionCreator()
    public setDesiredBounds(
        id: Id,
        bounds: IBounds | undefined,
    ): IAction<{ id: Id; bounds: IBounds | undefined }> {
        return {
            type: MapsActions.SetDesiredBounds,
            payload: {
                id,
                bounds,
            },
        };
    }

    @ActionCreator()
    public toggleFreeTextTool(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.ToggleFreeTextTool,
            payload,
        };
    }

    @ActionCreator()
    public toggleMeasureTool(payload: boolean): IAction<boolean> {
        return {
            type: MapsActions.ToggleMeasureTool,
            payload,
        };
    }

    @ActionCreator()
    public updateBlockerEditState(payload: BlockerEditState): IAction<BlockerEditState> {
        return {
            type: MapsActions.UpdateBlockerEditState,
            payload,
        };
    }

    @ActionCreator()
    public toggleDeviceOverlayPanel(
        isOpen?: boolean,
        initialTab?: 'accessories' | 'applications',
    ): IAction<{ isOpen: boolean; initialTab: 'accessories' | 'applications' } | undefined> {
        if (!isOpen || !initialTab) {
            return {
                type: MapsActions.ToggleOverlayPanel,
                payload: undefined,
            };
        }

        return {
            type: MapsActions.ToggleOverlayPanel,
            payload: { isOpen, initialTab },
        };
    }

    @ActionCreator()
    public toggleFloorPlanConfigOverlayPanel(isOpen?: boolean): IAction<boolean | undefined> {
        eventTracking.logUserEvent('Maps', 'Show floor plan settings');
        return {
            type: MapsActions.ToggleFloorPlanConfigOverlayPanel,
            payload: isOpen,
        };
    }

    @ActionCreator()
    public toggleGetToKnowMaps(show?: boolean): IAction<boolean | undefined> {
        setItemLocalStorage('MapsHelpVideoShownVersion', '1');
        return {
            type: MapsActions.ToggleGetToKnowMaps,
            payload: show,
        };
    }

    /**
     * Action for updating the state of the floor plan geolocation changes
     * @param payload the new geolocation
     */
    @ActionCreator()
    public updateFloorPlanGeolocationChangeState(
        payload: IFloorPlanGeolocationChanges,
    ): IAction<IFloorPlanGeolocationChanges> {
        return {
            type: MapsActions.UpdateFloorPlanLocationChangeState,
            payload,
        };
    }

    /**
     * Set the pending location of a floor plan
     * @param floorPlan the floor plan to set the geolocation to
     * @param position the new position of the floor plan
     */
    public setFloorPlanLocation(floorPlan: IFloorPlanEntity, position: ILatLng) {
        const geoLocation = getFloorPlanGeoLocation(floorPlan);
        if (geoLocation) {
            this.updateFloorPlanGeolocationChangeState({
                [floorPlan._id]: {
                    position,
                    width: geoLocation.width,
                    height: geoLocation.height,
                    angle: geoLocation.angle,
                },
            });
            this.toggleGeoLocationTool(floorPlan._id);
        }
    }

    /**
     * Set the pending location of a floor plan
     * @param floorPlan the floor plan to set the geolocation to
     * @param position the new position of the floor plan
     */
    public setFloorPlanBounds(floorPlan: IFloorPlanEntity, bounds: IBounds) {
        const geoLocation = getFloorPlanGeoLocation(floorPlan);
        if (geoLocation) {
            const position = calculateLocationFromBounds(bounds);
            this.updateFloorPlanGeolocationChangeState({
                [floorPlan._id]: {
                    position,
                    width: geoLocation.width,
                    height: geoLocation.height,
                    angle: geoLocation.angle,
                },
            });
            this.toggleGeoLocationTool(floorPlan._id);
        }
    }

    /**
     * Get all geolocated latlngs placed on the only geomap
     * @param state - the store state
     * @returns  all geolocated latlngs placed on the only geomap
     */
    private getAllLatLngs = (state: IStoreState): ILatLng[] => {
        // get lat lng for all geoLocatedFloorPlans
        const geoLocatedFloorPlans = getGeolocatedFloorPlans(state);
        const floorPlansLatLngs = geoLocatedFloorPlans.map(
            (floorPlan) => floorPlan.image?.geoLocation?.position,
        );

        // get lat lng for all geoLocated installation points
        const geoLocatedInstallationPoints = getGeoLocatedInstallationPoints(state);
        const installationPointLatLngs = geoLocatedInstallationPoints.map(
            (installationPoint) => installationPoint.location,
        );
        const allGeoBlockers = getGeolocatedBlockers(state);
        const allGeoBlockersLatLng = allGeoBlockers.map((blocker) => blocker.latLngs).flat();

        // get lat lng for all free text points
        const geoLocatedFreeTextPoints = getGeolocatedFreeTextPoints(state);
        const freeTextPointsLatLngs = geoLocatedFreeTextPoints.map(
            (freeTextPoint) => freeTextPoint.location,
        );
        // All lat lngs to include in bounds
        return floorPlansLatLngs
            .concat(installationPointLatLngs)
            .concat(allGeoBlockersLatLng)
            .concat(freeTextPointsLatLngs)
            .filter(isDefined);
    };

    /**
     * Transform blockers
     * @param projectBlockers the blockers to transform
     * @param transform a function that transforms a latLng
     */
    private transformBlockers(blockers: IBlockerEntity[], transform: (latLng: ILatLng) => ILatLng) {
        return blockers.map((blocker) => {
            const newBlocker = {
                ...blocker,
                latLngs: transformBlocker(transform, blocker.latLngs),
            };

            return newBlocker;
        });
    }

    /**
     * Transform installation points
     * @param installationPoints the installation points to transform
     * @param transform a function that transforms a latLng
     * @param rotation the rotation of the floor plan
     */
    private transformInstallationPoints(
        installationPoints: IInstallationPointModel[],
        transform: (latLng: ILatLng) => ILatLng,
        rotation: number,
        scale?: number,
    ) {
        return installationPoints.map((ip) => {
            // update the position and orientation of the installation
            // point and all its sensors
            return transformInstallationPoint(transform, rotation, ip, scale);
        });
    }

    /**
     * Transform free text points
     * @param freeTextPoints the free text points to transform
     * @param transform a function that transforms a latLng
     */
    private transformFreeTextPoints(
        freeTextPoints: IFreeTextPointEntity[],
        transform: (latLng: ILatLng) => ILatLng,
    ) {
        return freeTextPoints.map((ftp) => {
            // update the position of all free text points
            return transformFreeTextPoint(transform, ftp);
        });
    }

    /**
     * Apply a geolocation change to a floor plan and all its associated entities
     * @param installationPoints the installation points associated with the floor plan
     * @param freeTextPoints the free text points associated with the floor plan
     * @param blockers the blockers associated with the floor plan
     * @param floorPlan the floor plan to apply the geolocation change to
     * @param newGeoLocation the new geolocation of the floor plan
     * @param offset the offset of the floor plan
     * @param scale the scale of the floor plan
     * @param rotation the rotation of the floor plan
     */
    private async applyGeolocationChange(
        installationPoints: IInstallationPointModel[],
        freeTextPoints: IFreeTextPointEntity[],
        blockers: IBlockerEntity[],
        floorPlan: IFloorPlanEntity,
        newGeoLocation: IFloorPlanGeolocation,
        scale: number,
        rotation: number,
    ) {
        const currentGeoLocation = getFloorPlanGeoLocation(floorPlan);
        if (!currentGeoLocation) {
            throw new Error('Could not change geolocation of floor plan');
        }

        // create a transform function
        const transform = createTransformer(
            currentGeoLocation.position,
            newGeoLocation.position,
            scale,
            rotation,
        );

        // transform blockers and globalize them
        const transformedBlockers = this.transformBlockers(blockers, transform).map(
            globalizeEntity,
        );
        this.blockerService.updateBlockers(transformedBlockers);

        // transform installation points and remove floor plan links
        const transformedInstallationPoints = this.transformInstallationPoints(
            installationPoints,
            transform,
            rotation,
            scale,
        ).map(removeFloorPlanLinkFromInstallationPoint);
        this.installationPointService.updateInstallationPoints(transformedInstallationPoints);

        // transform free text points and globalize them
        const transformedFreeTextPoints = this.transformFreeTextPoints(
            freeTextPoints,
            transform,
        ).map(globalizeEntity);
        this.freeTextPointService.updateFreeTextPoints(transformedFreeTextPoints);

        // save the new geolocation of the floor plan
        this.mapsService.saveFloorPlanGeolocation(
            floorPlan._id,
            newGeoLocation.position,
            newGeoLocation.width,
            newGeoLocation.height,
            newGeoLocation.angle,
        );
    }

    /**
     * Save the geolocation of all floor plans that have changed geolocation
     * Also apply the geolocation change to all associated entities
     */
    @ActionCreator()
    public saveFloorPlanGeolocation(): ThunkAction {
        return async (_dispatch, getState) => {
            const state = getState();

            const geoLocationChanges = getGeolocationChanges(state);
            const installationPointMap = getInstallationPointsPerFloorPlan(state);
            const freeTextPointMap = getFreeTextPointsPerFloorPlan(state);
            const blockerMap = getBlockersPerFloorPlan(state);

            // loop through all the floor plans that have changed geolocation
            for (const { floorPlan, geoLocation, change } of geoLocationChanges) {
                const installationPoints = installationPointMap[floorPlan._id] ?? [];
                const freeTextPoints = freeTextPointMap[floorPlan._id] ?? [];
                const blockers = blockerMap[floorPlan._id] ?? [];

                this.applyGeolocationChange(
                    installationPoints,
                    freeTextPoints,
                    blockers,
                    floorPlan,
                    geoLocation,
                    change.scale,
                    change.rotation,
                );

                // Reset view bounds for changed floor plan
                this.setViewBounds(floorPlan._id, undefined);
            }
        };
    }

    @ActionCreator()
    public toggleGeoLocationTool(payload: Id | null): IAction<Id | null> {
        return {
            type: MapsActions.ToggleGeoLocationTool,
            payload,
        };
    }

    @ActionCreator()
    public setOriginFilter(payload: Id | undefined): IAction<Id | undefined> {
        return {
            type: MapsActions.SetOriginFilter,
            payload,
        };
    }

    @ActionCreator()
    public setDraftInstallationPoint(
        payload: IInstallationPointModel,
    ): IAction<IInstallationPointModel> {
        return {
            type: MapsActions.SetDraftInstallationPoint,
            payload,
        };
    }

    @ActionCreator()
    public removeDraftInstallationPoint(payload: Id): IAction<Id> {
        return {
            type: MapsActions.RemoveDraftInstallationPoint,
            payload,
        };
    }
}
