import { Module } from 'app/ioc';
import { mapsReducer } from './MapsReducer';
import {
    MapsActionService,
    MapsService,
    FileTransferService,
    MapDeviceService,
    MapLayerFilterService,
    MapTypeService,
} from './services';
import { LeafletItemFactory } from './services/LeafletItemFactory';
import { ItemDropService } from './services/ItemDrop.service';

@Module({
    binder: (binder) => {
        binder.bindService(MapsActionService);
        binder.bindService(MapsService);
        binder.bindService(FileTransferService);
        binder.bindService(MapDeviceService);
        binder.bindService(ItemDropService);
        binder.bindService(MapLayerFilterService);
        binder.bindService(MapTypeService);
        binder.bindSingleton(LeafletItemFactory);
    },
    reducers: {
        maps: mapsReducer,
    },
})
export class MapsModule {}
