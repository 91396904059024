/**
 * Rounds the provided value to desired number of decimals
 * @param value Original value to round
 * @param decimals Nbr of desired decimals, defaults to 0
 * @returns Rounded value
 */
export function roundValue(value: number, decimals = 0): number {
    const decimalFactor = Math.pow(10, decimals);
    return Math.round(value * decimalFactor) / decimalFactor;
}
