import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';

/**
 * Get the draft installation points
 * These are installation points that are being edited but not yet persisted to pouchdb
 */
export const getDraftInstallationPoints = createSelector([getMapsState], (mapsState) => {
    return mapsState.draftInstallationPoints;
});
