import type { PiaCategory } from 'app/core/pia';
import { PiaItemCameraCategory } from 'app/core/pia';
import { t } from 'app/translate';

export const getCategoryName = (
    /** The category represented. I.E category === "ptz" => "Scenario (PTZ)"" */
    category: PiaCategory | undefined,
): string => {
    switch (category) {
        case PiaItemCameraCategory.CAMERAEX:
            return t.cameraTypeGROUP.explosionProtected;
        case PiaItemCameraCategory.COMPLETEMODULAR:
            return t.advancedFiltersGROUP.completeModular;
        case PiaItemCameraCategory.FIXEDDOME:
            return t.deviceDetailsOverviewHeading;
        case PiaItemCameraCategory.FIXED:
            return t.cameraTypeGROUP.fixed;
        case PiaItemCameraCategory.PTZ:
            return t.cameraTypeGROUP.ptz;
        case PiaItemCameraCategory.THERMAL:
            return t.cameraTypeGROUP.thermal;
        default:
            return '';
    }
};
