import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { Id, IInstallationPointModel, ILatLng } from 'app/core/persistence';
import type { BaseCone } from './BaseCone';
import { ServiceLocator } from 'app/ioc';
import { MapsActionService } from '../../../services/MapsAction.service';
import {
    getIsDoriPixelsOn,
    getIsInstallationPointSelected,
    getIsSensorSelected,
    getSelectedCoverageAreaInfo,
    getSensorCoverageAreaInfo,
    getUseBigImages,
    getRadarsWithCoexistenceWarning,
    getShowRadarCoexistenceWarning,
    getSelectedMapOrDefault,
    getDerotatedBlockerPolylines,
    getUseTinyIcons,
    getMultiSelectPositionRecord,
    getPressedModifierKeys,
} from '../../../selectors';
import { getMaxDistance } from './utils/getMaxDistance';
import type { Colors } from 'app/styles';
import { TargetHandle } from './TargetHandle';
import { FovHandle } from './FovHandle';
import type { ISelectedCoverageAreaInfo, ISensorCoverageAreaInfo } from '../../../models';
import { IconRenderer } from './IconRenderer';
import { getHasCoverageAreaIcons } from '../../../selectors/getHasCoverageAreaIcons';
import { DirectionalArrow } from './DirectionalArrow';
import { RadarCoexistenceZone } from './RadarCoexistenceZone';
import { usePrevious } from 'app/hooks';
import { isEmpty, isEqual } from 'lodash-es';
import type { LeafletMap } from '../LeafletMap';

interface ICoverageAreaProps {
    map: LeafletMap;
    cone: BaseCone;
    installationPoint: IInstallationPointModel;
    color: Colors;
    isOpaque: boolean;
    onTargetChange?: (angle: number, distance: number) => void;
    onFovChange?: (angle: number) => void;
    onDragEnd?: () => void;
}

export const CoverageArea: React.FC<ICoverageAreaProps> = ({
    map,
    cone,
    installationPoint,
    color,
    isOpaque,
    onTargetChange = (_angle, _distance) => {}, // noop if not provided
    onFovChange = (_angle) => {}, // noop if not provided
    onDragEnd = () => {}, // noop if not provided
}) => {
    const maxDistance = getMaxDistance(installationPoint);
    const [actions] = React.useState(ServiceLocator.get(MapsActionService));
    const previousInstallationPoint = usePrevious(installationPoint);
    const previousCoverageArea = usePrevious(cone);
    const blockers = useSelector(getDerotatedBlockerPolylines);
    const previousBlockers = usePrevious(blockers);
    const isInstallationPointSelected = useSelector<IStoreState, boolean>((state) =>
        getIsInstallationPointSelected(state, installationPoint._id),
    );
    const isSensorSelected = useSelector<IStoreState, boolean | undefined>((state) =>
        getIsSensorSelected(state, installationPoint._id, cone.sensor),
    );
    const sensorCoverageAreaInfo = useSelector<IStoreState, ISensorCoverageAreaInfo | undefined>(
        (state) => getSensorCoverageAreaInfo(state, cone.sensor?.sensorId),
    );
    const coverageAreaInfo = useSelector<IStoreState, ISelectedCoverageAreaInfo | undefined>(
        (state) => getSelectedCoverageAreaInfo(state),
    );
    const isDoriPixelsOn = useSelector<IStoreState, boolean>(getIsDoriPixelsOn) || isSensorSelected;
    const hasIcons = useSelector<IStoreState, boolean>((state) =>
        getHasCoverageAreaIcons(state, installationPoint._id),
    );
    const useTinyIcons = useSelector(getUseTinyIcons);
    const showArrow = useSelector(getUseBigImages) && !useTinyIcons;

    const multiSelectPositionRecord = useSelector<IStoreState, Record<Id, ILatLng>>(
        getMultiSelectPositionRecord,
    );
    const installationPointLatLng = multiSelectPositionRecord[installationPoint._id];
    const modifierKeys = useSelector(getPressedModifierKeys);

    // If this coverage area is selected we want to use horizontal angle from the state
    // otherwise we use the stored angle
    const rotationAngle = isSensorSelected
        ? (coverageAreaInfo?.horizontalAngle ?? cone.horizontalAngle)
        : cone.horizontalAngle;
    const showTargetHandle =
        isOpaque && cone.getIsTargetAdjustable() && !!isSensorSelected && !map.readOnly;
    const showFovHandle =
        isOpaque && cone.getIsFovAdjustable() && !!isSensorSelected && !map.readOnly;
    const showDirectionalArrow = cone.getHasDirectionalArrow() && showArrow;

    const showCoexistingWarning = useSelector(getShowRadarCoexistenceWarning);
    const isRadarWarningDismissed = useSelector(getSelectedMapOrDefault)?.isRadarWarningDismissed;
    const coexistenceIds = useSelector<IStoreState, Record<Id, boolean>>((state) =>
        getRadarsWithCoexistenceWarning(state, map.map),
    );

    // coexistingIds contains all coexistingIds for the current floorPlan.
    // If Ids exist (i.e if the record is not empty) we should show a warning
    React.useEffect(() => {
        const radarsWithCoexistingWarning = Object.keys(coexistenceIds).length > 0;
        if (radarsWithCoexistingWarning && !showCoexistingWarning) {
            actions.setRadarCoexistenceShowWarning(true);
        } else if (!radarsWithCoexistingWarning && showCoexistingWarning) {
            actions.setRadarCoexistenceShowWarning(false);
        }
    }, [actions, coexistenceIds, showCoexistingWarning, isRadarWarningDismissed]);

    React.useEffect(() => {
        cone.addToMap();
        return () => {
            cone.removeFromMap();
        };
    }, [cone]);

    React.useEffect(() => {
        cone.onClick = () => {
            if (map.readOnly) return;

            modifierKeys.isShiftDown
                ? actions.multiSelect(installationPoint)
                : actions.selectMapItem(
                      installationPoint,
                      'installationPoint',
                      cone.sensor?.sensorId,
                  );
        };
    }, [
        actions,
        cone,
        installationPoint,
        isSensorSelected,
        map.readOnly,
        modifierKeys.isShiftDown,
    ]);

    React.useEffect(() => {
        const newColor =
            !isRadarWarningDismissed && coexistenceIds[installationPoint._id] !== undefined
                ? 'grey4'
                : color;
        cone.setColor(newColor, useTinyIcons);
    }, [color, cone, coexistenceIds, installationPoint._id, isRadarWarningDismissed, useTinyIcons]);

    React.useEffect(() => {
        if (isOpaque) {
            cone.focus();
        } else {
            cone.blur();
        }
    }, [isOpaque, cone]);

    React.useEffect(() => {
        if (cone.getDoriPixelsOn() !== isDoriPixelsOn) {
            cone.updateDoriPixelsOn(blockers, isDoriPixelsOn);
        }
    }, [blockers, cone, isDoriPixelsOn]);

    React.useEffect(() => {
        if (cone.getUseTinyIcons() !== useTinyIcons) {
            cone.toggleTinyIcons(blockers, useTinyIcons);
        }
    }, [blockers, cone, useTinyIcons]);

    React.useEffect(() => {
        cone.updateRangeAnalytics(blockers, installationPoint.parentDevice.analyticRange);
    }, [blockers, cone, installationPoint.parentDevice.analyticRange]);

    // Effect used to trigger an update after a database change for an unselected installation point..
    React.useEffect(() => {
        const hasParentDeviceChanged = !isEqual(
            installationPoint.parentPiaDevice,
            previousInstallationPoint?.parentDevice,
        );
        const hasInstallationPointChanged = !isEqual(installationPoint, previousInstallationPoint);
        const hasCoverageAreaChanged = !isEqual(cone, previousCoverageArea);
        const hasBlockersChanged = !isEqual(blockers, previousBlockers);
        if (
            ((!isInstallationPointSelected || hasParentDeviceChanged) &&
                (hasInstallationPointChanged || hasCoverageAreaChanged)) ||
            hasBlockersChanged
        ) {
            cone.update(installationPoint, blockers, cone.sensor?.sensorId);
        }
    }, [
        cone,
        previousCoverageArea,
        installationPoint,
        previousInstallationPoint,
        isInstallationPointSelected,
        blockers,
        previousBlockers,
    ]);

    /**
     * Update cone when installation point is multi selected and moved
     */
    React.useEffect(() => {
        if (!installationPointLatLng || isEmpty(multiSelectPositionRecord)) {
            return;
        }
        cone.update(
            { ...installationPoint, location: installationPointLatLng },
            blockers,
            cone.sensor?.sensorId,
        );
    }, [blockers, cone, installationPoint, installationPointLatLng, multiSelectPositionRecord]);

    // Effect is used to update the currently selected installation point, it uses useMemo
    // since it is more performance critical.
    React.useMemo(() => {
        if (isInstallationPointSelected) {
            const filter =
                installationPoint.parentDevice.properties.camera?.filter ??
                installationPoint.parentDevice.properties.sensorUnit?.filter;
            cone.reDraw(blockers, {
                location: installationPoint.location,
                cameraHeight: installationPoint.height,
                panoramaMode: filter?.panoramaMode,
                requiredPixelDensity: filter?.pixelDensity,
                basicSolution: installationPoint.speaker?.settings.basicSolution,
                outdoor: installationPoint.speaker?.settings.outdoor,
                placement: installationPoint.parentDevice.properties.speaker?.filter.placement,
                tiltOffset: cone.sensor?.settings.tiltOffset ?? 0,
            });
        }
    }, [
        isInstallationPointSelected,
        installationPoint.parentDevice.properties.camera?.filter,
        installationPoint.parentDevice.properties.sensorUnit?.filter,
        installationPoint.parentDevice.properties.speaker?.filter.placement,
        installationPoint.location,
        installationPoint.height,
        installationPoint.speaker?.settings.basicSolution,
        installationPoint.speaker?.settings.outdoor,
        cone,
        blockers,
    ]);

    // Effect is used update changes of the currently selected sensor
    // Redraw the coverage area when specified props has changed.
    // useMemo needed so that coverageArea is updated before fovHandle is calculated.
    React.useMemo(() => {
        if (isSensorSelected) {
            cone.reDraw(blockers, {
                targetHeight: sensorCoverageAreaInfo?.targetHeight,
                corridorFormat: sensorCoverageAreaInfo?.corridorFormat,
                horizontalFov: sensorCoverageAreaInfo?.horizontalFov,
                tiltOffset: cone.sensor?.settings.tiltOffset ?? 0,
            });
        }
    }, [
        cone,
        isSensorSelected,
        sensorCoverageAreaInfo?.targetHeight,
        sensorCoverageAreaInfo?.corridorFormat,
        sensorCoverageAreaInfo?.horizontalFov,
        blockers,
    ]);

    return (
        <>
            {showTargetHandle && (
                <TargetHandle
                    map={map}
                    blockers={blockers}
                    color={color}
                    coverageArea={cone}
                    installationPoint={installationPoint}
                    maxDistance={maxDistance}
                    onChange={onTargetChange}
                    onDragEnd={onDragEnd}
                />
            )}
            {showFovHandle && sensorCoverageAreaInfo && (
                <FovHandle
                    map={map}
                    blockers={blockers}
                    color={color}
                    coverageArea={cone}
                    sensorCoverageAreaInfo={sensorCoverageAreaInfo}
                    installationPoint={installationPoint}
                    onChange={onFovChange}
                    onDragEnd={onDragEnd}
                />
            )}
            {hasIcons && (
                <IconRenderer
                    map={map}
                    coverageArea={cone}
                    isOpaque={isOpaque}
                    color={
                        !isRadarWarningDismissed &&
                        coexistenceIds[installationPoint._id] !== undefined
                            ? 'grey5'
                            : color
                    }
                    deviceId={installationPoint.parentDevice._id}
                />
            )}
            {showDirectionalArrow && (
                <DirectionalArrow
                    map={map}
                    color={color}
                    coverageArea={cone}
                    isOpaque={isOpaque}
                    installationPoint={installationPoint}
                />
            )}
            {coexistenceIds[installationPoint._id] && !isRadarWarningDismissed && (
                <RadarCoexistenceZone
                    map={map}
                    installationPoint={installationPoint}
                    rotationAngle={rotationAngle}
                />
            )}
        </>
    );
};

CoverageArea.displayName = 'CoverageArea';
