import { getVirtuallyIncludedIds } from 'app/core/persistence';
import type { IPiaCamera, IPiaCameraProperties, IPiaDevice } from 'app/core/pia';
import { isDefined } from 'axis-webtools-util';
import { getPiaItemsRecord } from './getPiaDevices';
import { getCurrentProjectItem } from '../../project/selectors/getCurrentProject';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey';

export const getVirtualItemBandwidthProps = createCachedSelector(
    [getCurrentProjectItem, getPiaItemsRecord],
    (mainCamera, piaItemsRecord): Pick<IPiaCameraProperties, 'channels' | 'imageSensors'> => {
        if (!mainCamera || mainCamera.productId === null) return { channels: 0, imageSensors: 0 };
        const mainPiaCamera = piaItemsRecord[mainCamera.productId];
        const virtualItemIds = getVirtuallyIncludedIds(mainPiaCamera as IPiaDevice);
        const virtualItems = virtualItemIds
            .map((virtualItemId) => piaItemsRecord[virtualItemId] as IPiaCamera)
            .filter(isDefined);
        const nbrVirtualItemChannels: number = virtualItems.reduce((nbrChannels, virtualItem) => {
            return virtualItem?.properties?.channels
                ? nbrChannels + virtualItem?.properties?.channels
                : nbrChannels;
        }, 0);
        const nbrVirtualItemImageSensors: number = virtualItems.reduce(
            (nbrSensors, virtualItem) => {
                return virtualItem?.properties?.imageSensors
                    ? nbrSensors + virtualItem?.properties?.imageSensors
                    : nbrSensors;
            },
            0,
        );
        return { channels: nbrVirtualItemChannels, imageSensors: nbrVirtualItemImageSensors };
    },
)(toCacheKey);
