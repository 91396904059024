import { injectable } from 'inversify';
import { ApplicationActionService } from 'app/modules/application';
import {
    CurrentProjectService,
    IMapType,
    UserPreferencesService,
    defaultProjectSettings,
} from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';

@injectable()
export class MapTypeService {
    constructor(
        private applicationActions: ApplicationActionService,
        private userPreferences: UserPreferencesService,
        private currentProject: CurrentProjectService,
    ) {}

    public setUseTinyIcons(useTinyIcons: boolean): void {
        eventTracking.logApplicationEvent('Maps', 'Map type', `Use tiny icons: ${useTinyIcons}`);
        const currentMapType = this.getCurrentMapType();
        const newMapType = { ...currentMapType, useTinyIcons };
        this.updateMapType(newMapType);
    }

    public setStreetMode(isOn: boolean): void {
        eventTracking.logApplicationEvent('Maps', 'Map type', `Street mode: ${isOn}`);
        const currentMapType = this.getCurrentMapType();
        const newMapType = { ...currentMapType, streetModeOn: isOn };
        this.updateMapType(newMapType);
    }

    private getCurrentMapType(): IMapType {
        const projectId = this.currentProject.getProjectId();
        const projectSettings = this.userPreferences.get().projectSettings;

        if (!projectSettings[projectId]) {
            projectSettings[projectId] = defaultProjectSettings;
        }

        return projectSettings[projectId].mapSettings.mapType;
    }

    private updateMapType(newMapType: IMapType): void {
        const projectId = this.currentProject.getProjectId();
        const currentProjectSettings = this.userPreferences.get().projectSettings;

        if (!currentProjectSettings[projectId]) {
            currentProjectSettings[projectId] = defaultProjectSettings;
        }

        currentProjectSettings[projectId].mapSettings.mapType = newMapType;

        this.applicationActions.setUserPreferences({
            projectSettings: currentProjectSettings,
        });
    }
}
