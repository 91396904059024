import * as React from 'react';
import type { IAutoTestable } from 'app/components';
import { Border, Box, Clickable, Icon, Text } from 'app/components';
import type { Colors } from 'app/styles';
import type { CoverageAreaLayerGroup, DeviceTypeLayerGroup } from 'app/core/persistence';
import { t } from 'app/translate';

interface IActiveFilterButtonProps extends IAutoTestable {
    color?: Colors;
    deviceTypeLayerGroup?: DeviceTypeLayerGroup;
    coverageAreaLayerGroup?: CoverageAreaLayerGroup;
    textBoxes?: boolean;
    labels?: boolean;
    onToggle?: () => void;
}

export const ActiveFilterButton: React.FC<IActiveFilterButtonProps> = ({
    color,
    deviceTypeLayerGroup,
    coverageAreaLayerGroup,
    textBoxes,
    labels,
    testId,
    onToggle,
}) => {
    return (
        <Clickable onClick={onToggle}>
            <Border color="grey3" radius="100px">
                <Box
                    testId={testId}
                    color="grey2"
                    hoverColor="white"
                    alignItems="center"
                    spacing="half"
                    paddingY="quart"
                    paddingX="quart"
                >
                    {(deviceTypeLayerGroup || coverageAreaLayerGroup) && (
                        <Icon opaque icon="visibility_off" size="ms" color="grey7" />
                    )}
                    {deviceTypeLayerGroup && <Text>{t[deviceTypeLayerGroup]}</Text>}
                    {coverageAreaLayerGroup && (
                        <Text>{`${t.coverageArea}: ${t[coverageAreaLayerGroup]}`}</Text>
                    )}
                    {color && (
                        <Box borderRadius="round" color={color} padding="halfQuart">
                            <Icon opaque icon="visibility_off" size="sm" color="white" />
                        </Box>
                    )}
                    {labels && <Icon opaque icon="label_off" size="ms" color="grey7" />}
                    {textBoxes && <Icon opaque icon="text_off" size="ms" color="grey7" />}
                    <Icon icon="close" color="grey7" size="ms" />
                </Box>
            </Border>
        </Clickable>
    );
};

ActiveFilterButton.displayName = 'ActiveFilterButton';
