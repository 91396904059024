import { css, cx } from '@emotion/css';
import { Border, Box, Stack } from 'app/components';
import { useWindowSize } from 'app/hooks';
import { TABLET_WINDOW_SIZE } from 'app/modules/accessorySelector/constants';
import { getSortedInstallationPointsForDevice } from 'app/modules/maps';
import type { Property } from 'csstype';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { TransitionStatus } from 'react-transition-group';
import { Transition } from 'react-transition-group';
import { InstallationPointList } from './InstallationPointList';
import type { IItemEntity, IPersistence } from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import DeviceListMap from './DeviceListMap';

const duration = 400;

const fadeInKeyframes = css`
    animation: fadeIn ${duration}ms ease-in forwards;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        75% {
            opacity: 0;
            visibility: visible;
        }
        100% {
            opacity: 1;
        }
    }
`;

const fadeOutKeyframes = css`
    animation: fadeOut ${duration}ms ease-out forwards;

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        25% {
            visibility: hidden;
            opacity: 0;
        }
        100% {
            visibility: hidden;
            opacity: 0;
        }
    }
`;

const stateToVisible: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: css`
        visibility: hidden;
    `,
    entering: css`
        ${fadeInKeyframes}
    `,
    entered: css`
        visibility: visible;
        opacity: 1;
    `,
    exiting: css`
        ${fadeOutKeyframes}
    `,
    exited: css`
        opacity: 0;
    `,
};

const defaultPanelStyle = css`
    padding: 0 !important;
`;

const transitionStyle = css`
    transition: all ${duration}ms ease-in-out;
`;

const stateToHeight: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: css`
        max-height: 0;
        height: 0;
        padding-bottom: 0;
    `,
    entering: css`
        max-height: 500px;
        height: auto;
        padding-bottom: 25px;
    `,
    entered: css`
        max-height: 500px;
        height: auto;
        padding-bottom: 25px;
    `,
    exiting: css`
        max-height: 0;
        height: auto;
        padding-bottom: 0px;
    `,
    exited: css`
        max-height: 0;
        height: 0px;
        padding-bottom: 0px;
    `,
};

export interface IDeviceDetailPanelProps {
    isOpen: boolean;
    item: IPersistence<IItemEntity>;
}

export const DevicesDetailPanel: React.FC<IDeviceDetailPanelProps> = ({ isOpen, item }) => {
    const [mapLoaded, setMapLoaded] = useState(false);
    // Ref to check if the node is mounted and used to trigger the transition
    const nodeRef = React.useRef(null);

    useEffect(() => {
        isOpen && setMapLoaded(true);
    }, [isOpen]);

    // Render map above installation point list for smaller screens
    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);

    const windowSize = useWindowSize();

    React.useEffect(() => setVerticalAlign(windowSize.width <= TABLET_WINDOW_SIZE), [windowSize]);
    const installationPoints = useSelector((state: IStoreState) =>
        getSortedInstallationPointsForDevice(state, item._id),
    );

    return (
        <Transition timeout={duration} in={isOpen} nodeRef={nodeRef}>
            {(state) => (
                <td
                    colSpan={10}
                    className={cx(defaultPanelStyle, 'td-device-detail-panel')}
                    ref={nodeRef}
                >
                    <Box
                        __htmlAttributes={{ className: cx(transitionStyle, stateToHeight[state]) }}
                        color="grey1"
                        overflow="hidden"
                        paddingX="panel"
                    >
                        <Border
                            topWidth={0}
                            width={1}
                            color="grey3"
                            radius="0 0 10px 10px" // top-left, top-right, bottom-right, bottom-left
                        >
                            <Box
                                padding="panel"
                                paddingRight={
                                    !verticalAlign && installationPoints.length ? 'none' : 'panel'
                                }
                                flex="fullWidth"
                                color="white"
                                __htmlAttributes={{ className: stateToVisible[state] }}
                            >
                                <Stack
                                    alignItems="start"
                                    vertical={verticalAlign}
                                    reverseDirection={verticalAlign}
                                    spacing={verticalAlign ? 'quart' : 'panel'}
                                    flex="fullWidth"
                                >
                                    <InstallationPointList
                                        installationPoints={installationPoints}
                                        item={item}
                                        fullWidth={verticalAlign}
                                    />
                                    {mapLoaded && !!installationPoints.length && (
                                        <DeviceListMap
                                            deviceId={item._id}
                                            fullWidth={verticalAlign}
                                            key={item._id}
                                        />
                                    )}
                                </Stack>
                            </Box>
                        </Border>
                    </Box>
                </td>
            )}
        </Transition>
    );
};
