import { Border, Box, PiaImage, Text, Icon, Clickable } from 'app/components';
import type { Id, DeviceAndSubType, DeviceType } from 'app/core/persistence';
import {
    deviceIcons,
    getDeviceAndSubTypeForItem,
    getDeviceTypeForItem,
    getItemColor,
    getModelName,
} from 'app/modules/common';
import { getPressedModifierKeys } from '../../../../selectors';
import type { IStoreState } from 'app/store';
import { type Colors } from 'app/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';

interface IMultiSelectMenuItemProps {
    deviceItemId: Id;
    piaId: number | null;
    name: string;
    installationPointName?: string;
    isChild?: boolean;
    isCustomCamera?: boolean;
    onClick: () => void;
}

export const MultiSelectMenuItem: React.FunctionComponent<IMultiSelectMenuItemProps> = ({
    deviceItemId,
    piaId,
    name,
    installationPointName,
    isChild,
    isCustomCamera,
    onClick,
}) => {
    const model = useSelector<IStoreState, string | undefined>((state) =>
        getModelName(state, deviceItemId),
    );
    const deviceType = useSelector<IStoreState, DeviceType | undefined>((state) =>
        getDeviceTypeForItem(state, deviceItemId),
    );
    const subType = useSelector<IStoreState, DeviceAndSubType | undefined>((state) =>
        getDeviceAndSubTypeForItem(state, deviceItemId),
    );
    const color = useSelector<IStoreState, Colors>((state) => getItemColor(state, deviceItemId));
    const deviceSubType = subType ?? deviceType;

    const isShiftDown = useSelector(getPressedModifierKeys).isShiftDown;

    return (
        <Border bottomWidth={1} leftWidth={6} sideColor={{ bottom: 'grey3', left: color }}>
            <Clickable onClick={onClick} readOnly={!isShiftDown}>
                <Box
                    testId={`multi_select_item_${piaId}`}
                    alignItems="center"
                    spacing="base"
                    color="white"
                    padding="quart"
                    width="100%"
                >
                    {deviceSubType && (
                        <Box
                            color="white"
                            padding="halfCell"
                            width="45px"
                            height="45px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <PiaImage
                                icon={
                                    isCustomCamera ? 'videocam' : deviceIcons.toIcon(deviceSubType)
                                }
                                piaId={piaId}
                                imageSize="max"
                                noPointerEvents
                            />
                        </Box>
                    )}
                    <Box direction="column" paddingX="halfQuart" paddingY="half" width="150px">
                        <Text style="semibold" color="grey8">
                            {installationPointName
                                ? `${name} - ${installationPointName}`
                                : `${name}`}
                        </Text>
                        <Text style="small" color="grey6" whiteSpace="nowrap">
                            {model}
                        </Text>
                    </Box>
                    {isChild && <Icon icon="info" color="blue" size="ms" />}
                </Box>
            </Clickable>
        </Border>
    );
};

MultiSelectMenuItem.displayName = 'MultiSelectMenuItem';
