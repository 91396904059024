import type { VendorNameType } from './getSelectedVendorName';
import { getSelectedVendorName } from './getSelectedVendorName';
import { createSelector } from 'reselect';
import type { IPiaSystemComponent, IPiaSoftware } from 'app/core/pia';
import {
    axisLicenseModels,
    filterProducts,
    PiaItemRecorderCategory,
    PiaAccessoryCategory,
    PiaItemState,
    PiaItemSoftwareCategory,
} from 'app/core/pia';
import {
    getPiaItemsRecord,
    getUseProductAllowlist,
    getProductAllowlist,
    createProductAllowlistFilter,
    getCurrentProjectRegions,
    getCurrentProjectSystemComponentsIncludingPartner,
    getCurrentProjectItemsArray,
    getCurrentSolutionIncludesAxisRecorder,
} from 'app/modules/common';

import type { IStoreState } from 'app/store';
import { recorderCategoryComparator } from 'app/utils';
import { PartnerSystemItemCategory } from 'app/core/partner';
import {
    getQuantityForPartnerRecordingProducts,
    getQuantityForRecordingProducts,
} from './getRecordingDeviceQuantities';
import { getNumberOfIncludedLicenses, getNumberOfPorts } from './utils';
import { xorWith } from 'lodash-es';
import type { IRecordingListItem } from '../models/IRecordingListItem';
import { CENTER_LICENSE_PIAID_FIVE_YEAR, CENTER_LICENSE_PIAID_ONE_YEAR } from '../constants';
import {
    AXIS_CAMERA_STATION_5,
    AXIS_CAMERA_STATION_PRO,
    getViewingAxisAcsType,
} from './recommendations';

import type { CameraStationType, IItemEntity, IPersistence } from 'app/core/persistence';

export const recordingCategoriesAll = [
    PiaItemRecorderCategory.RECORDERS2,
    PiaItemRecorderCategory.DESKTOPTERMINALS,
    PiaAccessoryCategory.NETWORKSWITCHES,
    PiaItemSoftwareCategory.VMS,
    PartnerSystemItemCategory.SERVER,
];

const recordingCategoriesRecorders2 = [PiaItemRecorderCategory.RECORDERS2];

const recordingCategoriesDesktopTerminals = [PiaItemRecorderCategory.DESKTOPTERMINALS];

const recordingCategoriesSwitches = [PiaAccessoryCategory.NETWORKSWITCHES];

const recordingCategoriesVms = [PiaItemSoftwareCategory.VMS];

const getGenetecRecommendationsState = (state: IStoreState) =>
    state.recordingSelector.genetecRecommendationsState;

export const getRecordingFilter = (state: IStoreState) => state.recordingSelector.filterByCategory;

export const getSearchFilter = (state: IStoreState) => state.recordingSelector.searchFilter;

export const getGenetecProjectId = (state: IStoreState) =>
    state.currentProject.project?.genetecProjectId;

export const getGenetecRecommendationType = (state: IStoreState) =>
    state.currentProject.project?.genetecRecommendationType;

export const getRecordingFilterCategories = createSelector([getRecordingFilter], (filter) => {
    switch (filter) {
        case 'all':
            return recordingCategoriesAll;
        case 'terminals':
            return recordingCategoriesDesktopTerminals;
        case 'licenses':
            return recordingCategoriesVms;
        case 'servers':
            return recordingCategoriesRecorders2;
        case 'switches':
            return recordingCategoriesSwitches;
        default:
            return recordingCategoriesAll;
    }
});

export const getGenetecRecommendations = createSelector(
    [getGenetecRecommendationsState],
    (genetecRecommendationsState) => genetecRecommendationsState?.recommendations ?? [],
);

export const getGenetecRecommendationError = createSelector(
    [getGenetecRecommendationsState],
    (genetecRecommendationsState) => genetecRecommendationsState?.recommendationError,
);

export const getIsFetchingGenetecRecommendations = createSelector(
    [getGenetecRecommendationsState],
    (genetecRecommendationsState) =>
        genetecRecommendationsState?.isFetchingRecommendations ?? false,
);

export const getGenetecProducts = createSelector(
    [getGenetecRecommendationsState],
    (genetecRecommendationsState) => genetecRecommendationsState?.products,
);

const getGenetecProductServers = createSelector([getGenetecProducts], (products) => {
    return products
        ? products?.filter((product) => product.category === PartnerSystemItemCategory.SERVER)
        : [];
});

export const getIsFetchingGenetecProducts = createSelector(
    [getGenetecRecommendationsState],
    (genetecRecommendationsState) => genetecRecommendationsState?.isFetchingProducts ?? false,
);

export const getSelectedGenetecRecommendationIndex = createSelector(
    [getGenetecRecommendations, getCurrentProjectSystemComponentsIncludingPartner],
    (recommendations, currentSystemComponents) => {
        return recommendations.findIndex(
            (recommendation) =>
                xorWith(
                    recommendation.components,
                    currentSystemComponents,
                    (
                        a: { quantity: number; name: string },
                        b: { quantity: number; name: string },
                    ) => a.quantity === b.quantity && a.name === b.name,
                ).length === 0,
        );
    },
);

/** get available recording pia items
 * Filters on allowlist, region and discontinued state.
 */
const getAvailableRecordingPiaItems = createSelector(
    [
        getRecordingFilterCategories,
        getPiaItemsRecord,
        getCurrentProjectRegions,
        getUseProductAllowlist,
        getProductAllowlist,
        getSelectedVendorName,
    ],
    (categories, piaItems, regions, useAllowlist, allowlist, vendorName) => {
        return Object.values(piaItems)
            .filter(filterProducts.byState(PiaItemState.EXTERNALLY_ANNOUNCED))
            .filter(filterProducts.byCategories(categories))
            .filter(filterProducts.byRegions(regions))
            .filter(filterProducts.byExternallyHidden())
            .filter((item) => filterThirdPartyOrAxis(item, vendorName))
            .filter((product) => createProductAllowlistFilter(allowlist, useAllowlist)(product.id));
    },
);

/**
 * Get added Axis recording products if vendor is Axis
 * Return empty array if other vendor
 */
const getSelectedVendorAxisRecordingItems = createSelector(
    [getCurrentProjectItemsArray, getSelectedVendorName],
    (items, vendor) =>
        vendor !== 'milestone' && vendor !== 'genetec'
            ? items.filter((item) => !!item.properties.systemComponent && item.productId)
            : [],
);

/**
 * get available recording pia items
 * Adds possible added recording items, including added items that are not included in available recording items
 */
const getCurrentPiaRecordingItems = createSelector(
    [getAvailableRecordingPiaItems, getSelectedVendorAxisRecordingItems, getPiaItemsRecord],
    (availableRecordingPiaItems, currentRecordingAxisItems, piaItems) => {
        currentRecordingAxisItems
            .filter((currentRecordingAxisItem) => !!currentRecordingAxisItem.productId)
            .forEach((currentRecordingAxisItem) => {
                if (
                    !availableRecordingPiaItems.find(
                        (item) => item.id === currentRecordingAxisItem.productId,
                    )
                ) {
                    availableRecordingPiaItems.push(piaItems[currentRecordingAxisItem.productId!]);
                }
            });
        return availableRecordingPiaItems;
    },
);

/**
 * Get all currently available recording devices (for the current searchFilter), including partner recording devices
 * sorted by category if selected vendor is partner
 */
export const getAvailableRecordingDevices = createSelector(
    [
        getCurrentPiaRecordingItems,
        getRecordingFilterCategories,
        getSearchFilter,
        getGenetecProductServers,
        getQuantityForRecordingProducts,
        getQuantityForPartnerRecordingProducts,
        getSelectedVendorName,
        getViewingAxisAcsType,
        getCurrentSolutionIncludesAxisRecorder,
        getCurrentProjectSystemComponentsIncludingPartner,
    ],
    (
        availableRecordingPiaItems,
        categories,
        searchFilter,
        genetecServers,
        quantitiesPiaItems,
        quantitiesPartner,
        selectedVendor,
        viewingAcsType,
        isRecorderIncluded,
        addedRecordingComponents,
    ) => {
        const piaRecordingItems: IRecordingListItem[] = availableRecordingPiaItems.map((item) => {
            const piaItem = item as IPiaSystemComponent & IPiaSoftware;
            const quantity = quantitiesPiaItems[piaItem.id];
            return {
                piaId: piaItem.id,
                parentId: piaItem.parentId,
                piaItem: piaItem,
                name: piaItem.name,
                category: piaItem.category,
                isDiscontinued: piaItem.state > PiaItemState.EXTERNALLY_ANNOUNCED,
                isELicense: piaItem.properties.isELicense,
                freeHddBays: piaItem.properties.freeHddBays,
                vendor: piaItem.properties.vendor,
                quantity,
                nrOfChannels: piaItem.properties.maxCameraCount,
                nrOfLicenses: getNumberOfIncludedLicenses(piaItem),
                storage: piaItem.properties.maxRecordingStorageMegaBytes,
                bandwidth: piaItem.properties.maxRecordingBandwidthBits,
                power: piaItem.properties.poeTotalPower,
                ports: getNumberOfPorts(piaItem, 1).totalPorts,
                video: piaItem.properties.monitorsSupported,
                subscriptionIntervalInMonths: piaItem.properties.subscriptionIntervalInMonths,
                licenseType: piaItem.properties.licenseType,
                licenseModel: piaItem.properties.licenseModel,
            };
        });
        const genetecRecordingItems: IRecordingListItem[] = genetecServers.map((item) => {
            const quantity = quantitiesPartner[item.name];
            return {
                piaItem: undefined,
                piaId: undefined,
                name: item.name,
                category: item.category,
                quantity,
                isDiscontinued: false,
                isELicense: undefined,
                freeHddBays: undefined,
                vendor: item.vendorName,
                nrOfChannels: item.maxCameraCount,
                nrOfLicenses: 0,
                storage: item.maxRecordingStorageMegaBytes,
                bandwidth: item.maxRecordingBandwidthBits,
                power: 0,
                ports: 0,
                video: 0,
                imageUrl: item.imageUrl,
                dataSheetUrl: item.dataSheetUrl,
            };
        });
        const allRecordingItems: IRecordingListItem[] =
            selectedVendor === 'genetec'
                ? piaRecordingItems.concat(genetecRecordingItems)
                : piaRecordingItems;

        const shouldFilterLicenses =
            categories.includes(PiaItemSoftwareCategory.VMS) && selectedVendor === 'axis';

        const categoryItems = allRecordingItems
            .filter(filterProducts.byCategories(categories))
            .filter(filterAllRecordingItems(searchFilter))
            .filter(
                shouldFilterLicenses
                    ? filterAxisLicenses(
                          viewingAcsType,
                          isRecorderIncluded,
                          addedRecordingComponents,
                      )
                    : () => true,
            )
            .filter(
                (item) =>
                    item.piaId !== CENTER_LICENSE_PIAID_ONE_YEAR &&
                    item.piaId !== CENTER_LICENSE_PIAID_FIVE_YEAR,
            );

        return categoryItems.sort(recorderCategoryComparator);
    },
);

export const getFilteredGenetecRecordingServers = createSelector(
    [getSearchFilter, getGenetecProductServers, getQuantityForPartnerRecordingProducts],
    (searchFilter, genetecServers, quantitiesPartner) => {
        const genetecRecordingItems: IRecordingListItem[] = genetecServers.map((item) => {
            const quantity = quantitiesPartner[item.name];
            return {
                piaItem: undefined,
                piaId: undefined,
                name: item.name,
                category: item.category,
                quantity,
                isDiscontinued: false,
                isELicense: undefined,
                freeHddBays: undefined,
                vendor: item.vendorName,
                nrOfChannels: item.maxCameraCount,
                nrOfLicenses: 0,
                storage: item.maxRecordingStorageMegaBytes,
                bandwidth: item.maxRecordingBandwidthBits,
                power: 0,
                ports: 0,
                video: 0,
                imageUrl: item.imageUrl,
                dataSheetUrl: item.dataSheetUrl,
            };
        });

        const categoryItems = genetecRecordingItems
            ?.filter((product) => product.category === PartnerSystemItemCategory.SERVER)
            .filter(filterAllRecordingItems(searchFilter));

        return categoryItems.sort(recorderCategoryComparator);
    },
);

const filterThirdPartyOrAxis = (
    item: { category: string; properties: { vendor: string } },
    vendorName: VendorNameType | undefined,
) => {
    if (item.category === PiaAccessoryCategory.NETWORKSWITCHES) {
        return true;
    }

    // if vendor is genetec and servers are get from online filter out genetec-pia-servers
    if (vendorName === 'genetec') {
        return (
            item.category !== PiaItemRecorderCategory.RECORDERS2 &&
            item.properties.vendor.toLowerCase() === 'genetec'
        );
    }

    return vendorName
        ? item.properties.vendor.toLowerCase() === vendorName
        : item.properties.vendor.toLowerCase() === 'axis';
};

const filterAllRecordingItems =
    (filter: string) =>
    ({ name }: IRecordingListItem) =>
        name.toLowerCase().includes(filter.toLowerCase());

const isCurrentItemAddedToProject = (
    addedRecordingComponents: IPersistence<IItemEntity>[],
    piaId: number | undefined,
) => {
    return (
        addedRecordingComponents.find((addedItem) => addedItem.productId === piaId) !== undefined
    );
};

// filter item if it is a license and it is not added to solution (all added licenses should be shown) by the following rules:
// 1. If ACS 5 selected - only show ACS 5 licenses
// 2. If ACS Pro selected - only show 'NVR' licenses if a recorder is added, only show 'Device' licenses if no recorder added
const filterAxisLicenses =
    (
        viewingAcsType: CameraStationType,
        isRecorderAdded: boolean,
        addedRecordingComponents: IPersistence<IItemEntity>[],
    ) =>
    (item: IRecordingListItem) => {
        if (
            item.piaId === CENTER_LICENSE_PIAID_ONE_YEAR ||
            item.piaId === CENTER_LICENSE_PIAID_FIVE_YEAR
        ) {
            // Never show center licenses in the list
            return false;
        }

        if (
            item.category !== PiaItemSoftwareCategory.VMS ||
            isCurrentItemAddedToProject(addedRecordingComponents, item.piaId)
        ) {
            return true;
        }

        if (viewingAcsType === 'CameraStation5') {
            // Only allow ACS 5 licenses
            return item.parentId === AXIS_CAMERA_STATION_5;
        }

        if (viewingAcsType === 'CameraStationPro') {
            if (item.parentId === AXIS_CAMERA_STATION_5) {
                // Never show ACS 5 licenses when viewing ACS Pro
                return false;
            }

            if (item.parentId !== AXIS_CAMERA_STATION_PRO) {
                // Do not filter items that are not tied to ACS Pro (e.g. Cloud storage)
                return true;
            }

            // Only allow 'NVR' licenses if a recorder is added, only allow 'Device' licenses if no recorder added
            return isRecorderAdded
                ? item.licenseModel === axisLicenseModels.tiedToHardware
                : item.licenseModel !== axisLicenseModels.tiedToHardware;
        }

        return true;
    };
