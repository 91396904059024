import { Box, Checkbox } from 'app/components';
import { useService } from 'app/ioc';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getFilteredMapLayers } from '../../selectors';
import { MapLayerFilterService } from '../../services';
import type { DeviceTypeLayerGroup, CoverageAreaLayerGroup } from 'app/core/persistence';
import { coverageAreaLayers } from 'app/core/persistence';

interface ILayerDeviceTypeFilterItem {
    label: string;
    layerGroup: DeviceTypeLayerGroup;
}
export const LayerDeviceTypeFilterItem: React.FC<ILayerDeviceTypeFilterItem> = ({
    label,
    layerGroup,
}) => {
    const mapLayerFilterService = useService(MapLayerFilterService);
    const mapFilter = useSelector(getFilteredMapLayers);

    const hasCoverageArea = (
        layerGroupFilter: DeviceTypeLayerGroup,
    ): layerGroupFilter is CoverageAreaLayerGroup => {
        return (coverageAreaLayers as any)[layerGroupFilter] !== undefined;
    };

    return (
        <Box justifyContent="between">
            <Checkbox
                slider
                selected={!mapFilter.deviceLayers.includes(layerGroup)}
                testId={label}
                onChange={() => mapLayerFilterService.toggleDeviceTypeLayerFilter(layerGroup)}
            >
                {label}
            </Checkbox>
            {hasCoverageArea(layerGroup) && (
                <Checkbox
                    testId={`${label}_${'coverage_area'}`}
                    selected={!mapFilter.coverageAreaLayers.includes(layerGroup)}
                    onChange={() => mapLayerFilterService.toggleCoverageAreaLayerFilter(layerGroup)}
                />
            )}
        </Box>
    );
};
