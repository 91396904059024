import type { IMapType } from 'app/core/persistence';
import { defaultMapType } from 'app/core/persistence';
import { getUserPreferences } from 'app/modules/application';
import { getCurrentProjectId } from 'app/modules/common';
import { createSelector } from 'reselect';

export const getMapType = createSelector(
    [getUserPreferences, getCurrentProjectId],
    (userPreferences, projectId): IMapType => {
        // Map type settings can be undefined if user preferences has not been set yet
        if (!userPreferences.projectSettings[projectId]) {
            return defaultMapType;
        }
        return userPreferences.projectSettings[projectId].mapSettings?.mapType ?? defaultMapType;
    },
);

export const getUseTinyIcons = createSelector([getMapType], ({ useTinyIcons }) => useTinyIcons);
export const getStreetModeOn = createSelector([getMapType], ({ streetModeOn }) => streetModeOn);
