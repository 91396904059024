import * as React from 'react';
import type { IClickableProps } from 'app/components';
import {
    Stack,
    Clickable,
    PiaImage,
    Text,
    Linkable,
    Border,
    Box,
    Title,
    DragHandle,
    Opacity,
} from 'app/components';
import {
    deviceIcons,
    getDeviceAndSubTypeForItem,
    getDeviceTypeForItem,
    getIsCustomCamera,
} from 'app/modules/common';
import type { IMapsDevice } from '../../../../models';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import type { DeviceAndSubType, DeviceType } from 'app/core/persistence';
import { useDrag, useIsLayerHidden } from '../../../../hooks';
import { toaster } from 'app/toaster';

interface IChangeDevice extends IClickableProps {
    device: IMapsDevice;
    link?: string;
    isDragDisabled?: boolean;
}

export const ChangeDeviceComponent: React.FunctionComponent<IChangeDevice> = ({
    device,
    link,
    isDragDisabled,
    ...restProps
}) => {
    const isCustomCamera = useSelector<IStoreState, boolean>((state) =>
        getIsCustomCamera(state, device.id),
    );
    const deviceType = useSelector<IStoreState, DeviceType | undefined>((storeState) =>
        getDeviceTypeForItem(storeState, device.id),
    );
    const deviceSubType = useSelector<IStoreState, DeviceAndSubType | undefined>((storeState) =>
        getDeviceAndSubTypeForItem(storeState, device.id),
    );

    const dragRef = React.useRef<HTMLDivElement>(null);
    const dragImageRef = React.useRef<HTMLDivElement>(null);

    const dragState = useDrag({
        effect: 'link',
        ref: dragRef,
        imageRef: dragImageRef,
        imageOffsetX: 25,
        imageOffsetY: 25,
        isDragDisabled,
        data: {
            type: deviceSubType ?? 'camera',
            id: device.id,
        },
    });

    const isLayerHidden = useIsLayerHidden(deviceType, device.color);
    React.useEffect(() => {
        if (isLayerHidden && dragState.dragState === 'dragEnd') {
            toaster.info(t.deviceIsHidden);
        }
    }, [dragState.dragState, isLayerHidden]);

    return (
        <Title title={t.clickToChangeModel}>
            <Linkable
                link={device.locked ? undefined : link}
                onNavigate={() =>
                    eventTracking.logUserEvent('Maps', 'Change device clicked', device.deviceType)
                }
            >
                <Clickable {...restProps} disabled={device.locked} allowDragThrough>
                    <Border color="grey3" radius="2px">
                        <Box
                            innerRef={dragRef}
                            testId="change_device_context_menu_btn"
                            paddingY="halfCell"
                            paddingX="halfCell"
                            width="100%"
                        >
                            <Stack
                                justifyContent="between"
                                alignItems="center"
                                flex="shrinkAndGrow"
                                spacing="none"
                            >
                                <Stack spacing="halfCell">
                                    <Opacity disabled={isLayerHidden}>
                                        <Border color={device.color} width={3} radius="50%">
                                            <Box
                                                innerRef={dragImageRef}
                                                color="white"
                                                padding="halfCell"
                                                width="48px"
                                                height="48px"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <PiaImage
                                                    icon={
                                                        isCustomCamera
                                                            ? 'videocam'
                                                            : deviceIcons.toIcon(
                                                                  deviceSubType ?? deviceType,
                                                              )
                                                    }
                                                    piaId={device.productId}
                                                    imageSize="max"
                                                    noPointerEvents
                                                />
                                            </Box>
                                        </Border>
                                    </Opacity>

                                    <Box maxWidth="130px" alignItems="center">
                                        <Text style="caption" color="grey7">
                                            {device.model}
                                        </Text>
                                    </Box>
                                </Stack>
                                <DragHandle disabled={isLayerHidden || isDragDisabled} />
                            </Stack>
                        </Box>
                    </Border>
                </Clickable>
            </Linkable>
        </Title>
    );
};

ChangeDeviceComponent.displayName = 'ChangeDeviceComponent';
