import { injectable } from 'inversify';
import type { IPiaSpeaker } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { Id, IItemEntity, IPersistence, ISpeakerItemEntity } from 'app/core/persistence';
import { ProjectModelService, ScheduleModelService } from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { IExportablePersistedEntity, IExportedSpeaker } from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import { toShareModelNames } from '../../mapToShareItemNames';

@injectable()
export class SpeakersExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaSpeaker>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedSpeakers = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedSpeaker[]> => {
        const allSpeakers = items.filter(this.isSpeakerItemEntity);
        const mappedSpeakers = await Promise.all(
            allSpeakers.map((speaker) => this.mapSpeakerToExportedSpeaker(speaker, projectId)),
        );
        return mappedSpeakers.filter(isDefined);
    };

    private mapSpeakerToExportedSpeaker = async (
        speakerItem: IExportablePersistedEntity,
        projectId: Id,
    ): Promise<IExportedSpeaker | null> => {
        if (!speakerItem.productId) {
            return null;
        }

        const speakerPiaDevice = this.getPiaDevice(speakerItem.productId);

        const { filter } = (speakerItem as IPersistence<ISpeakerItemEntity>).properties.speaker;
        const mappedBaseItem = await this.mapItemToExportedItemBase(speakerItem, projectId);

        return {
            ...mappedBaseItem,
            ...toShareModelNames(speakerPiaDevice.name),
            piaId: speakerItem.productId,
            installationHeight: filter.installationHeight,
            zones: [speakerItem._id],
        };
    };

    private isSpeakerItemEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<ISpeakerItemEntity> {
        return !!item.properties.speaker;
    }
}
