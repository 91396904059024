import { injectable } from 'inversify';
import type {
    IBaseProfileModel,
    IRecordingSettingsModel,
    IAudioSettingsModel,
    IZipstreamSettingsModel,
    IItemEntity,
    IPersistence,
    Id,
    ItemRelationType,
    ICurrentProjectRepository,
    ProjectZipType,
    RecordingType,
    BandwidthVersion,
} from 'app/core/persistence';
import {
    isCustomCamera,
    VideoEncoding,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';

import {
    BandwidthCalculatorService,
    ScenarioService,
    ProfileOverrideService,
    ProfileSupportService,
    StorageCalculationService,
    getZipStrengthValue,
    getDynamicFpsMode,
    getGopMode,
    getUseAverageBitrate,
    getZipProfileValue,
} from 'app/modules/common';
import type { Frequency } from 'app/modules/common';

import type {
    IPiaAccessory,
    IPiaDevice,
    PiaAccessoryCategory,
    PiaId,
    ProductBandwidthProperties,
} from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type {
    IExportedAudioSettings,
    IExportedZipstreamSettings,
    IExportedCamera,
    IExportedRecordingSettings,
    IExportedItemBase,
    IExportablePersistedEntity,
    IExportedAccessory,
    AccessoryRelation,
} from '../../../models';
import { convert, isDefined, UnreachableCaseError } from 'axis-webtools-util';
import type { IExportedItemNetworkSettings } from '../../../models/items/IExportedItemNetworkSettings';

interface IAccessoryItemWithRelationType extends IPersistence<IItemEntity> {
    relationType: AccessoryRelation;
}

@injectable()
export abstract class BaseItemExporterService {
    constructor(
        protected profileOverrideService: ProfileOverrideService,
        protected profileSupportService: ProfileSupportService,
        protected piaItemService: PiaItemService<IPiaDevice>,
        protected projectModelService: ProjectModelService,
        protected bandwidthCalculatorService: BandwidthCalculatorService,
        protected scenarioService: ScenarioService,
        protected storageCalculationService: StorageCalculationService,
        protected scheduleModelService: ScheduleModelService,
    ) {}

    protected mapCameraToExportedCamera = async (
        projectZipSetting: ProjectZipType,
        item: IExportablePersistedEntity,
        mergedProfile: IBaseProfileModel,
        productProperties: ProductBandwidthProperties,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        projectId: Id,
        customBandwidth?: number,
    ): Promise<IExportedCamera> => {
        let triggeredBwQuota = 0;
        let continuousBwQuota = 0;
        const isGenericCamera = isCustomCamera(item);
        if (customBandwidth) {
            const bwQuota = await this.getAverageBandwidthQuota(
                projectZipSetting,
                mergedProfile,
                productProperties,
                frequency,
                projectBandwidthVersion,
                isGenericCamera,
            );
            triggeredBwQuota = bwQuota.triggeredBwQuota;
            continuousBwQuota = bwQuota.continuousBwQuota;
        }
        const exportedBaseItem = await this.mapItemToExportedItemBase(item, projectId);
        return {
            ...exportedBaseItem,
            recording: {
                continuous: await this.mapRecordingSettingsToExportedRecordingSettings(
                    projectZipSetting,
                    mergedProfile,
                    productProperties,
                    mergedProfile.continuousRecording,
                    frequency,
                    'continuous',
                    projectBandwidthVersion,
                    isGenericCamera,
                    customBandwidth ? customBandwidth * continuousBwQuota : customBandwidth,
                ),
                motion: await this.mapRecordingSettingsToExportedRecordingSettings(
                    projectZipSetting,
                    mergedProfile,
                    productProperties,
                    mergedProfile.triggeredRecording,
                    frequency,
                    'triggered',
                    projectBandwidthVersion,
                    isGenericCamera,
                    customBandwidth ? customBandwidth * triggeredBwQuota : customBandwidth,
                ),
                live: await this.mapRecordingSettingsToExportedRecordingSettings(
                    projectZipSetting,
                    mergedProfile,
                    productProperties,
                    mergedProfile.liveView,
                    frequency,
                    'live',
                    projectBandwidthVersion,
                    isGenericCamera,
                    customBandwidth ? customBandwidth * triggeredBwQuota : customBandwidth,
                ),
                storage: {
                    retentionTime: mergedProfile.storage.retentionTime,
                },
                audio: this.mapAudioSettingsToExportedAudioSettings(
                    productProperties,
                    mergedProfile.audio,
                ),
                zipstream: this.mapZipstreamSettingsToExportedZipstreamSettings(
                    projectZipSetting,
                    productProperties,
                    mergedProfile.zipstream,
                ),
            },
        };
    };

    /**
     * Return the quotas of the totalAverageBandwidth for continuous and triggered recording parts
     * used do know how to divide the custom bandwidth for the two recording types
     */
    private getAverageBandwidthQuota = async (
        projectZipSetting: ProjectZipType,
        mergedProfile: IBaseProfileModel,
        productProperties: ProductBandwidthProperties,
        frequency: Frequency,
        projectBandwidthVersion: BandwidthVersion,
        isGenericCamera: boolean,
    ) => {
        mergedProfile.continuousRecording.schedule;
        const scheduleModelContinuous =
            mergedProfile.continuousRecording.schedule &&
            (await this.scheduleModelService.getSchedule(
                mergedProfile.continuousRecording.schedule,
            ));
        const scheduleModelTriggered =
            mergedProfile.triggeredRecording.schedule &&
            (await this.scheduleModelService.getSchedule(
                mergedProfile.triggeredRecording.schedule,
            ));
        const scenario = this.scenarioService.getScenarioOrThrow(mergedProfile.scenario.scenarioId);
        const estimateBandwidthContinuous =
            await this.bandwidthCalculatorService.getBandwidthEstimate(
                projectZipSetting,
                scenario,
                mergedProfile.scenario,
                mergedProfile.continuousRecording,
                mergedProfile.zipstream,
                mergedProfile.audio.recordingEnabled,
                productProperties,
                frequency,
                'continuous',
                projectBandwidthVersion,
                isGenericCamera,
            );
        const estimateBandwidthTriggered =
            await this.bandwidthCalculatorService.getBandwidthEstimate(
                projectZipSetting,
                scenario,
                mergedProfile.scenario,
                mergedProfile.triggeredRecording,
                mergedProfile.zipstream,
                mergedProfile.audio.recordingEnabled,
                productProperties,
                frequency,
                'triggered',
                projectBandwidthVersion,
                isGenericCamera,
            );

        const zipStrength = getZipStrengthValue(mergedProfile.zipstream, projectZipSetting);
        const fpsMode = getDynamicFpsMode(mergedProfile.zipstream, projectZipSetting);

        const usingDynamicFps =
            ProfileSupportService.getFpsMode(productProperties, zipStrength, fpsMode) === 'dynamic';

        const continuousStorage =
            mergedProfile.continuousRecording.schedule && scheduleModelContinuous
                ? this.storageCalculationService.getContinuousStorageEstimate(
                      mergedProfile.scenario,
                      mergedProfile.continuousRecording,
                      mergedProfile.storage,
                      estimateBandwidthContinuous,
                      scheduleModelContinuous,
                      usingDynamicFps,
                      undefined,
                  )
                : undefined;
        const triggeredStorage =
            mergedProfile.triggeredRecording.schedule && scheduleModelTriggered
                ? this.storageCalculationService.getTriggeredStorageEstimate(
                      mergedProfile.scenario,
                      mergedProfile.triggeredRecording,
                      mergedProfile.storage,
                      estimateBandwidthTriggered,
                      scheduleModelTriggered,
                      undefined,
                  )
                : undefined;
        const totalAverageBwRecordingEstimate =
            (triggeredStorage ? triggeredStorage?.averageBandwidth : 0) +
            (continuousStorage ? continuousStorage?.averageBandwidth : 0);
        const triggeredBwQuota = triggeredStorage
            ? triggeredStorage.averageBandwidth / totalAverageBwRecordingEstimate
            : 0;
        const continuousBwQuota = continuousStorage
            ? continuousStorage.averageBandwidth / totalAverageBwRecordingEstimate
            : 0;
        return {
            triggeredBwQuota: triggeredBwQuota,
            continuousBwQuota: continuousBwQuota,
        };
    };

    protected getPiaDevice(piaId: PiaId): IPiaDevice {
        const piaItem = this.piaItemService.get(piaId).first();
        if (!piaItem) {
            throw Error(`Could not find pia item with id: ${piaId}`);
        }
        return piaItem;
    }

    protected mapItemToExportedItemBase = async (
        item: IExportablePersistedEntity,
        projectId: Id,
    ): Promise<IExportedItemBase> => {
        const accessories = await this.mapItemToExportedAccessories(item._id, projectId);
        return {
            id: item.exportId,
            revision: item._rev,
            description: item.description,
            name: item.name,
            notes: item.notes,
            quantity: item.quantity,
            replaceWithBareboneId: item.replaceWithBareboneId,
            networkSettings: item.networkSettings
                ?.filter(isDefined)
                .map((settings): IExportedItemNetworkSettings => {
                    const { dhcp, addresses, subnetMask, defaultRouter } = settings;
                    return {
                        dhcp: dhcp ?? false,
                        ipV4: dhcp
                            ? undefined
                            : {
                                  addresses: addresses.filter((address) => !!address),
                                  subnetMask,
                                  defaultRouter,
                              },
                    };
                }),
            accessories,
        };
    };

    protected async getDeviceChildren(
        projectId: Id,
        itemId: Id,
        relationType: ItemRelationType,
    ): Promise<IPersistence<IItemEntity>[]> {
        const projectModels: ICurrentProjectRepository =
            await this.projectModelService.getProjectModels(projectId);
        const relations = projectModels?.itemRelations
            ? Object.values(projectModels.itemRelations)
                  .filter(isDefined)
                  .filter((item) => item?.parentId === itemId && item.relationType === relationType)
            : [];
        return relations.map((rel) =>
            this.getDeviceChild(projectId, rel.childId, projectModels.items),
        );
    }

    private getDeviceChild(
        projectId: Id,
        id: Id,
        items: Record<Id, IPersistence<IItemEntity> | undefined>,
    ): IPersistence<IItemEntity> {
        const entity = items[id];
        if (!entity) {
            throw new Error(
                `No entity with this id was found in Project: ${projectId}. Id: ${id}.`,
            );
        }
        return entity;
    }

    private mapZipstreamSettingsToExportedZipstreamSettings(
        projectZipSetting: ProjectZipType,
        productProperties: ProductBandwidthProperties,
        zipstreamSettings: IZipstreamSettingsModel,
    ): IExportedZipstreamSettings | null {
        const settingsZipStrength = getZipStrengthValue(zipstreamSettings, projectZipSetting);
        const settingsZipProfile = getZipProfileValue(zipstreamSettings);
        const settingsFpsMode = getDynamicFpsMode(zipstreamSettings, projectZipSetting);
        const settingsGopMode = getGopMode(zipstreamSettings, projectZipSetting);

        const zipStrength = ProfileSupportService.getZipstreamStrength(
            productProperties,
            settingsZipStrength,
        );
        const { minDynamicFps } = zipstreamSettings;

        if (!zipStrength) {
            return null;
        }

        return {
            gopDefault: zipstreamSettings.gopDefault,
            gopMax: zipstreamSettings.gopMax,
            gopMode: settingsGopMode,
            fpsMode: ProfileSupportService.getFpsMode(
                productProperties,
                settingsZipStrength,
                settingsFpsMode,
            ),
            zipStrength,
            profile: settingsZipProfile,
            useProjectSetting: zipstreamSettings.useProjectSetting,
            minDynamicFps,
        };
    }

    private mapAudioSettingsToExportedAudioSettings(
        productProperties: ProductBandwidthProperties,
        audioSettings: IAudioSettingsModel,
    ): IExportedAudioSettings {
        return {
            liveViewEnabled: !!productProperties.audioSupport && audioSettings.liveViewEnabled,
            recordingEnabled: !!productProperties.audioSupport && audioSettings.recordingEnabled,
        };
    }

    private mapRecordingSettingsToExportedRecordingSettings = async (
        projectZipSetting: ProjectZipType,
        mergedProfile: IBaseProfileModel,
        productProperties: ProductBandwidthProperties,
        recordingSetting: IRecordingSettingsModel,
        frequency: Frequency,
        recordingType: RecordingType,
        projectBandwidthVersion: BandwidthVersion,
        isGenericCamera: boolean,
        customBandwidth?: number,
    ): Promise<IExportedRecordingSettings | null> => {
        if (!recordingSetting.schedule) {
            return null;
        }

        const frameRate = ProfileSupportService.getFrameRate(
            productProperties,
            recordingSetting,
            frequency,
            isGenericCamera,
        );
        const maxResolution = ProfileSupportService.getMaxResolution(productProperties);
        const resolution = ProfileSupportService.getCameraResolution(
            maxResolution,
            recordingSetting.resolution,
        );
        const videoEncoding = ProfileSupportService.getVideoEncoding(
            productProperties,
            recordingSetting.videoEncoding,
        );

        const scheduleModelContinuous =
            mergedProfile.continuousRecording.schedule &&
            (await this.scheduleModelService.getSchedule(
                mergedProfile.continuousRecording.schedule,
            ));
        // for now we only have one systemDefined schedule which is 'Always'
        const continuousAlwaysScheduleUsed = scheduleModelContinuous
            ? scheduleModelContinuous?.systemDefined === true
            : false;

        const scenario = this.scenarioService.getScenarioOrThrow(mergedProfile.scenario.scenarioId);
        const profile = this.getMergedProfileToUse(recordingType, mergedProfile);

        const estimateBandwidth = this.bandwidthCalculatorService.getBandwidthEstimate(
            projectZipSetting,
            scenario,
            mergedProfile.scenario,
            profile,
            mergedProfile.zipstream,
            mergedProfile.audio.recordingEnabled,
            productProperties,
            frequency,
            recordingType,
            projectBandwidthVersion,
            isGenericCamera,
        );
        // If the useAverageBitrate is defined (i.e triggered recording),
        // get the useAverageBitrate setting from corresponding global (zipstream) setting if it is on
        // otherwise get the useAverageBitrate value from the specified value in the profile
        // (mergedProfile takes profile override into account)
        const useAverageBitrateValue =
            recordingSetting.useAverageBitrate !== undefined
                ? getUseAverageBitrate(
                      continuousAlwaysScheduleUsed,
                      mergedProfile.zipstream,
                      projectZipSetting,
                      recordingSetting.useAverageBitrate,
                  )
                : undefined;

        const zipStrength = getZipStrengthValue(mergedProfile.zipstream, projectZipSetting);
        const fpsMode = getDynamicFpsMode(mergedProfile.zipstream, projectZipSetting);

        const usingDynamicFps =
            ProfileSupportService.getFpsMode(productProperties, zipStrength, fpsMode) === 'dynamic';

        const scheduleModel = await this.scheduleModelService.getSchedule(
            recordingSetting.schedule,
        );

        const estimatedStorage =
            recordingSetting.schedule && recordingType === 'continuous'
                ? this.storageCalculationService.getContinuousStorageEstimate(
                      mergedProfile.scenario,
                      recordingSetting,
                      mergedProfile.storage,
                      estimateBandwidth,
                      scheduleModel,
                      usingDynamicFps,
                      customBandwidth,
                  )
                : recordingSetting.schedule && recordingType === 'triggered'
                  ? this.storageCalculationService.getTriggeredStorageEstimate(
                        mergedProfile.scenario,
                        recordingSetting,
                        mergedProfile.storage,
                        estimateBandwidth,
                        scheduleModel,
                        customBandwidth,
                    )
                  : 0;
        const estimatedStorageValueGB =
            Number(
                (estimatedStorage && convert.toGiga(estimatedStorage.storageEstimate)).toFixed(0),
            ) ?? 0;
        const estimatedBandwidthKbit =
            Number(
                (estimatedStorage && convert.toKilo(estimatedStorage.averageBandwidth)).toFixed(0),
            ) ?? 0;
        return {
            fps: frameRate,
            resolution: {
                horizontal: resolution.getHorizontal(),
                vertical: resolution.getVertical(),
            },
            compression: recordingSetting.compression,
            scheduleId: recordingSetting.schedule,
            videoEncoding: this.translateVideoEncoding(videoEncoding),
            estimatedBandwidthKbit: estimatedBandwidthKbit,
            estimatedStorageGB: estimatedStorageValueGB,
            averageBitrate: useAverageBitrateValue,
        };
    };

    private translateVideoEncoding(videoEncoding: VideoEncoding) {
        switch (videoEncoding) {
            case VideoEncoding.h264:
                return 'H.264';
            case VideoEncoding.h265:
                return 'H.265';
            case VideoEncoding.mjpeg:
                return 'MJPEG';
            default:
                throw new UnreachableCaseError(videoEncoding);
        }
    }

    private getMergedProfileToUse(recordingType: RecordingType, profile: IBaseProfileModel) {
        switch (recordingType) {
            case 'continuous':
                return profile.continuousRecording;
            case 'triggered':
                return profile.triggeredRecording;
            case 'live':
                return profile.liveView;
            default:
                throw new UnreachableCaseError(recordingType);
        }
    }

    private addRelationTypeToItems(
        items: IPersistence<IItemEntity>[],
        relationType: AccessoryRelation,
    ): IAccessoryItemWithRelationType[] {
        return items.map((item) => {
            return {
                ...item,
                relationType,
            };
        });
    }

    private mapItemToExportedAccessories = async (
        itemId: Id,
        projectId: Id,
    ): Promise<IExportedAccessory[]> => {
        const accessories = await this.getDeviceChildren(projectId, itemId, 'accessory');
        const deviceMounts = await this.getDeviceChildren(projectId, itemId, 'deviceMount');
        const primaryMounts = await this.getDeviceChildren(projectId, itemId, 'primaryMount');
        const environmentMounts = await this.getDeviceChildren(
            projectId,
            itemId,
            'environmentMount',
        );

        const accessoriesWithRelation = this.addRelationTypeToItems(accessories, 'accessory');
        const deviceMountsWithRelation = this.addRelationTypeToItems(deviceMounts, 'deviceMount');
        const primaryMountsWithRelation = this.addRelationTypeToItems(
            primaryMounts,
            'primaryMount',
        );
        const environmentMountsWithRelation = this.addRelationTypeToItems(
            environmentMounts,
            'environmentMount',
        );

        const allAccessoriesWithRelations = accessoriesWithRelation
            .concat(deviceMountsWithRelation)
            .concat(primaryMountsWithRelation)
            .concat(environmentMountsWithRelation);

        const mappedAccessories = await Promise.all(
            allAccessoriesWithRelations.map(async (accessory) => {
                if (!accessory.productId) {
                    return null;
                }
                const piaItem = this.getPiaDevice(accessory.productId) as IPiaAccessory;
                const piaDeviceShortName = piaItem.name.replace(/\s?(50|60)\s?hz\s?/i, ' ').trim();
                const accessoriesAndMounts = await this.mapItemToExportedAccessories(
                    accessory._id,
                    projectId,
                );
                return {
                    id: accessory._id,
                    revision: accessory._rev,
                    description: accessory.description,
                    name: accessory.name,
                    notes: accessory.notes,
                    quantity: accessory.quantity,
                    category: piaItem.category as PiaAccessoryCategory,
                    categories: piaItem.categories as PiaAccessoryCategory[],
                    piaId: piaItem.id,
                    modelName: piaDeviceShortName,
                    productVariantName: piaItem.name,
                    modelShortName: piaDeviceShortName,
                    accessories: accessoriesAndMounts,
                    relationType: accessory.relationType,
                } as IExportedAccessory;
            }),
        );
        return mappedAccessories.filter(isDefined);
    };
}
