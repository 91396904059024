import type { IPiaItem, IPiaLocation, IPiaItemVersion } from 'app/core/pia';
import { getPriceListForRegion } from './getPriceListForRegion';
import {
    filterAllButExternallyAnnounced,
    filterOutMultiPacks,
    hasMatchingVersion,
} from '../../piaDevices/versionFilters';
import { createSelector } from 'reselect';
import { getCurrencies } from './getCurrencies';
import {
    getCurrentProjectLocation,
    getCurrentProjectItems,
    getCurrentProjectCurrency,
} from '../../project';
import type { Price } from '../models/IPrice';
import type { Id } from 'app/core/persistence';
import { getPiaItemsRecord } from '../../piaDevices';
import { createCachedSelector } from 're-reselect';
import type { IStoreState } from 'app/store';
import { isDefined } from 'axis-webtools-util';

export interface IMsrpPiaIdProps {
    piaId: number;
    quantity?: number;
    includeMultipacks?: boolean;
}

interface IMsrpPiaIdsProps {
    piaIds: number[];
    quantities?: Record<number, number> | undefined;
}

interface IMsrpItemIdProps {
    itemId: Id;
    includeQuantity?: boolean;
}

/**
 * Get the PIA version of this product appropriate
 * for the specified location. Does not include
 * multi pack product numbers by default.
 */
export const getItemVersion = (
    piaItem: IPiaItem | undefined,
    location: IPiaLocation | undefined,
    includeMultiPacks: boolean = false,
) => {
    if (!location) {
        return undefined;
    }

    const versionsForLocation = includeMultiPacks
        ? (piaItem?.versions
              .filter(filterAllButExternallyAnnounced)
              .filter(hasMatchingVersion(location))
              .sort((a, b) => a.scaleQuantity - b.scaleQuantity) ?? [])
        : (piaItem?.versions
              .filter(filterOutMultiPacks)
              .filter(filterAllButExternallyAnnounced)
              .filter(hasMatchingVersion(location)) ?? []);

    // Prefer to use the product version that matches the first region in location
    const prioritizedVersion =
        versionsForLocation.find((v) =>
            v.versions.some(
                (region) => location !== undefined && region === location.productVersions[0],
            ),
        ) ?? versionsForLocation[0];

    return prioritizedVersion;
};

/**
 * Convert an array of PIA products to a record where the key is the PIA id and the value
 * is the appropriate PIA version of this product for the specified location
 */
const createVersionsRecord = (location: IPiaLocation | undefined, piaProducts: IPiaItem[]) =>
    piaProducts.reduce(
        (record, piaProduct) => {
            if (!location) {
                return record;
            }
            record[piaProduct.id] = getItemVersion(piaProduct, location)!;
            return record;
        },
        {} as Record<number, IPiaItemVersion>,
    );

const mapIdsToEntities = <T>(
    entities: Record<string | number, T | undefined>,
    ids: (string | number)[],
) => ids.map((id) => entities[id]).filter(isDefined);

const getPiaIdFromProps = (_state: IStoreState, props: IMsrpPiaIdProps) => props.piaId;
const getPiaIdsFromProps = (_state: IStoreState, props: IMsrpPiaIdsProps) => props.piaIds;

const getQuantityFromProps = (_state: IStoreState, props: IMsrpPiaIdProps) => props.quantity || 1;
const getQuantitiesFromProps = (_state: IStoreState, props: IMsrpPiaIdsProps) => props.quantities;

const getIncludeMultipacksFromProps = (_state: IStoreState, props: IMsrpPiaIdProps) =>
    props.includeMultipacks || false;

const getItemIdFromProps = (_state: IStoreState, props: IMsrpItemIdProps) => props.itemId;
const getShouldIncludeQuantityFromProps = (_state: IStoreState, props: IMsrpItemIdProps) =>
    props.includeQuantity || false;

const getPriceFromProps = (_state: IStoreState, price: Price) => price;

const getItemFromItemIdFromProps = createSelector(
    [getCurrentProjectItems, getItemIdFromProps],
    (items, itemId) => items[itemId],
);

const getPiaProductFromPiaIdFromProps = createSelector(
    [getPiaItemsRecord, getPiaIdFromProps],
    (piaProducts, piaId) => piaProducts[piaId],
);

const getPiaProductsFromPiaIdsFromProps = createSelector(
    [getPiaItemsRecord, getPiaIdsFromProps],
    mapIdsToEntities,
);

/**
 * Get the PIA product for the provided item id
 */
const getPiaProductFromItemIdFromProps = createSelector(
    [getPiaItemsRecord, getItemFromItemIdFromProps],
    (piaProducts, item) => (item ? piaProducts[item.productId!] : undefined),
);

/**
 * Get the appropriate PIA version for the current project location
 * for a specified item id
 * */
const getVersionFromItemIdProps = createSelector(
    [getPiaProductFromItemIdFromProps, getCurrentProjectLocation],
    getItemVersion,
);

/**
 * Get the appropriate PIA version for the current project location
 * for a specified PIA id
 */
const getVersionFromPiaIdFromProps = createSelector(
    [getPiaProductFromPiaIdFromProps, getCurrentProjectLocation, getIncludeMultipacksFromProps],
    getItemVersion,
);

/**
 * Get the PIA versions from the provided list of PIA id:s
 */
const getVersionsFromPiaIdsProps = createSelector(
    [getCurrentProjectLocation, getPiaProductsFromPiaIdsFromProps],
    createVersionsRecord,
);

/**
 * Get the MSRP for a specific PIA id and quantity
 */
export const getPiaIdMsrp = createCachedSelector(
    [getPriceListForRegion, getQuantityFromProps, getVersionFromPiaIdFromProps],
    (priceList, quantity, version): Price | undefined => {
        if (!priceList || !version) {
            return undefined;
        }

        return {
            value: priceList.list[version.partno] * quantity,
            currency: priceList.currencyCode!,
            scaleQuantity: version.scaleQuantity,
        };
    },
)((_state, props) => `${props.piaId}x${props.quantity}`);

/**
 * Returns if MSRP for a specific PIA id exists
 */
export const doesPiaIdMsrpExist = createCachedSelector(
    [getPriceListForRegion, getVersionFromPiaIdFromProps],
    (priceList, version): boolean => {
        if (!priceList || !version) {
            return false;
        }
        return true;
    },
)((_state, props) => `${props.piaId}`);
/**
 * Get the MSRP for a specific item id.
 * Will return the MSRP for one unit unless the includeQuantity flag is true.
 */
export const getItemIdMsrp = createCachedSelector(
    [
        getPriceListForRegion,
        getItemFromItemIdFromProps,
        getVersionFromItemIdProps,
        getShouldIncludeQuantityFromProps,
    ],
    (priceList, item, version, shouldIncludeQuantity): Price | undefined => {
        if (!priceList || !version) {
            return undefined;
        }

        return {
            value:
                priceList.list[version.partno] *
                (shouldIncludeQuantity ? (item?.quantity ?? 0) : 1),
            currency: priceList.currencyCode!,
            scaleQuantity: version.scaleQuantity,
        };
    },
)((_state, props) => `${props.itemId}x${props.includeQuantity}`);

/**
 * Get the total sum of the MSRP of the provided list of PIA id:s
 */
export const getTotalMsrpForPiaIds = createCachedSelector(
    [
        getPiaProductsFromPiaIdsFromProps,
        getQuantitiesFromProps,
        getVersionsFromPiaIdsProps,
        getPriceListForRegion,
    ],
    (piaProducts, quantities, versions, priceList) => {
        try {
            return piaProducts.reduce(
                (sum, piaProduct) => {
                    const version = versions[piaProduct.id];
                    if (!version || !priceList) {
                        return sum;
                    }
                    const msrp =
                        priceList.list[version.partno] *
                        (quantities ? quantities[piaProduct.id] : 1);
                    if (Number.isNaN(msrp)) {
                        throw new TypeError(`MSRP missing for piaProduct: ${piaProduct}`);
                    }
                    sum.currency = priceList.currencyCode!;
                    sum.value = sum.value + msrp;
                    return sum;
                },
                {
                    currency: '',
                    value: 0,
                } as Price,
            );
        } catch (e) {
            if (e instanceof TypeError) {
                return undefined;
            }
            throw e;
        }
    },
)((_state, props) =>
    props.piaIds
        .map((piaId) => `${piaId}x${props.quantities ? props.quantities[piaId] : 1}`)
        .join(),
);

/**
 * Can convert the provided MSRP
 * into the current project currency. Will return undefined
 * if no conversion was made.
 */
export const convertMsrpToProjectCurrency = createCachedSelector(
    [getCurrencies, getCurrentProjectCurrency, getPriceListForRegion, getPriceFromProps],
    (currencies, projectCurrency, priceList, msrp): Price | undefined => {
        const priceListCurrency = priceList?.currencyCode;
        if (
            !projectCurrency ||
            !currencies ||
            !priceListCurrency ||
            priceListCurrency === projectCurrency ||
            !msrp.value
        ) {
            return undefined;
        }

        const oldCurrency = currencies[priceListCurrency];
        const newCurrency = currencies[projectCurrency];

        if (!oldCurrency || !newCurrency) {
            return undefined;
        }
        const rate = oldCurrency.toUsdRate / newCurrency.toUsdRate;

        return {
            value: msrp.value * rate,
            currency: projectCurrency,
            scaleQuantity: msrp.scaleQuantity ?? 1,
        };
    },
)((_state, msrp) => msrp.value);
