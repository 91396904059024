import { createSelector } from 'reselect';
import { uniqBy } from 'lodash-es';
import {
    getInstallationPointsPerFloorPlan,
    createGetMapLegendId,
    getMapsSortedByDate,
} from 'app/modules/maps';
import { defaultColors } from 'app/core/common';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import { getParentId, deviceTypeCheckers } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { t } from 'app/translate';
import { getCurrentProjectItems, getPiaItemsRecord } from 'app/modules/common';
import { nameComparator } from 'app/utils';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemPacCategory } from 'app/core/pia';

export interface IFloorPlanLegendItem {
    id: string;
    name: string;
    legendId: string;
    model: string;
    quantity: number;
    color: Colors;
}

const getDeviceColor = (
    device: IPersistence<IItemEntity>,
    items: Record<string, IPersistence<IItemEntity> | undefined>,
    piaItem?: IPiaItem,
) => {
    const fallbackColor = defaultColors.DEFAULT_DEVICE_COLOR;
    const isChild =
        device.properties.analogCamera ||
        device.properties.sensorUnit ||
        device.properties.door ||
        (device.properties.pac && piaItem?.category === PiaItemPacCategory.RELAYEXPMODULES);
    let deviceColor = device.color;
    if (isChild) {
        const parentId = getParentId(device);
        deviceColor = parentId ? (items[parentId]?.color ?? fallbackColor) : fallbackColor;
    }
    return deviceColor ?? fallbackColor;
};

export const getLegendForFloorPlan = createSelector(
    [
        getInstallationPointsPerFloorPlan,
        getPiaItemsRecord,
        createGetMapLegendId,
        getCurrentProjectItems,
    ],
    (installationPoints, piaItems, getLegendId, currentProjectItems) => {
        return Object.keys(installationPoints).reduce(
            (groupedByFloorPlan, floorPlanId) => {
                const devices = uniqBy(
                    installationPoints[floorPlanId].map((ip) => ip.parentDevice),
                    '_id',
                )
                    .filter(({ productId, properties }) => productId || properties.analogCamera)
                    .sort(nameComparator);

                groupedByFloorPlan[floorPlanId] = devices.map((device) => {
                    const legendId = getLegendId(floorPlanId, device._id);

                    const deviceColor = getDeviceColor(
                        device,
                        currentProjectItems,
                        device.productId ? piaItems[device.productId] : undefined,
                    );

                    const model = deviceTypeCheckers.isAnalogCamera(device)
                        ? t.devicesGROUP.analogCamera
                        : device.productId
                          ? piaItems[device.productId].name
                          : t.notAvailable;
                    return {
                        id: `${floorPlanId}_${device._id}`,
                        name: device.name,
                        legendId,
                        model,
                        quantity: installationPoints[floorPlanId].reduce(
                            (count, ip) => count + (ip.parentDevice._id === device._id ? 1 : 0),
                            0,
                        ),
                        color: deviceColor,
                    } as IFloorPlanLegendItem;
                });
                return groupedByFloorPlan;
            },
            {} as Record<Id, IFloorPlanLegendItem[]>,
        );
    },
);

export const getFloorPlansForSystemProposal = createSelector(
    [getLegendForFloorPlan, getMapsSortedByDate],
    (itemsForFloorPlan, floorPlans) => {
        return floorPlans.filter(
            (fp) => fp.mapType === 'FloorPlan' && itemsForFloorPlan[fp._id]?.length > 0,
        );
    },
);
