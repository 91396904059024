import { createSelector } from 'reselect';
import type { IStoreState } from 'app/store';
import { createFrequencyFilter, getProjectFrequencies } from './createFrequencyFilter';
import type { IPiaItem, IPiaDevice, PiaId, IPiaCamera, IPiaSensorUnit } from 'app/core/pia';
import {
    PiaItemBareboneCategory,
    PiaItemVirtualProductCategory,
    PiaItemAlerterCategory,
    PiaAccessoryCategory,
    PiaItemState,
    PiaItemEncoderCategory,
    PiaItemMainUnitCategory,
    PiaItemSpeakerCategory,
    PiaItemSensorUnitCategory,
    PiaItemPacCategory,
    PiaItemDecoderCategory,
    PiaItemDetectorCategory,
    PiaItemSoftwareCategory,
    filterProducts,
    PiaEnvironmentCategory,
    getAllCameraCategories,
    PiaItemPeopleCounterCategory,
    PiaItemWearablesCategory,
    PiaItemConnectivityDevicesCategory,
    PiaItemPagingConsoleCategory,
} from 'app/core/pia';

import { createProductAllowlistFilter } from './createProductAllowlistFilter';
import { getCurrentProjectRegions } from '../../project';
import { createCachedSelector } from 're-reselect';
import { toCacheKey } from '../../cacheKey/toCacheKey';
import {
    isCamera,
    isSensorUnit,
    isEncoder,
    isMainUnit,
    isSpeaker,
    isPac,
    isDecoder,
    isRadar,
    isDoorStation,
    isApplication,
    isRecordingSolutionComponent,
} from 'app/core/persistence';
import { getPiaIdFromProps } from '../../selectors/getIdFromProps';
import { isDefined } from 'axis-webtools-util';
import { getFallbackPiaItem } from '../utils/getFallbackPiaItem';

export const getPiaItems = (state: IStoreState) => state.common.piaItems;
export const getProductAllowlist = (state: IStoreState) =>
    state.common.partnerConfig ? state.common.partnerConfig.allowlist : undefined;
export const getUseProductAllowlist = (state: IStoreState) =>
    state.common.partnerConfig ? state.common.partnerConfig.useAllowlist : false;

// keep a set of all requested pia items that are not available
const piaItemsNotFound = new Set<PiaId>();

/**
 * Returns a record with PiaId as key and PiaItem as value.
 */
export const getPiaItemsRecord = createSelector([getPiaItems], (items) => {
    const itemsRecord = items.reduce(
        (obj: Record<PiaId, IPiaItem>, item) => {
            obj[item.id] = item;
            return obj;
        },
        {} as Record<PiaId, IPiaItem>,
    );

    const handler = {
        // get trap will return the PiaItem if it exists, otherwise it will return a default PiaItem
        get: (target: Record<PiaId, IPiaItem>, piaId: any) => {
            if (piaId in target) {
                return target[piaId];
            }

            if (!piaItemsNotFound.has(piaId)) {
                piaItemsNotFound.add(piaId);
            }

            const parsedPiaId = Number(piaId) || 0; // try to parse the id, 0 otherwise

            return getFallbackPiaItem(parsedPiaId);
        },
    };

    const proxiedRecord = new Proxy(itemsRecord, handler);

    return proxiedRecord;
});

export const getAvailablePiaItems = createSelector(
    [
        getPiaItems,
        getProjectFrequencies,
        getCurrentProjectRegions,
        getProductAllowlist,
        getUseProductAllowlist,
    ],
    (items, frequencies, regions, allowlist, useAllowlist) =>
        items
            .filter(createFrequencyFilter(frequencies))
            .filter(filterProducts.byRegions(regions))
            .filter(filterProducts.byExternallyHidden())
            .filter((device) => createProductAllowlistFilter(allowlist, useAllowlist)(device.id)),
);

export const getAvailablePiaItemsRecord = createSelector([getAvailablePiaItems], (items) =>
    items.reduce(
        (obj: Record<PiaId, IPiaItem>, item) => {
            obj[item.id] = item;
            return obj;
        },
        {} as Record<PiaId, IPiaItem>,
    ),
);

export const getAvailablePiaDevices = createSelector(
    [getAvailablePiaItems],
    (items) =>
        items.filter(
            filterProducts.byCategories([
                ...getAllCameraCategories(),
                PiaItemEncoderCategory.ENCODER,
                PiaItemDecoderCategory.DECODER,
                PiaItemMainUnitCategory.MAINUNIT,
                PiaItemSensorUnitCategory.SENSORUNIT,
                PiaItemSensorUnitCategory.THERMALSENSOR,
                PiaItemSpeakerCategory.SPEAKER,
                PiaItemPacCategory.ACCESSSERVER,
                PiaItemPacCategory.ANSWERINGUNIT,
                PiaItemPacCategory.NETWORKREADER,
                PiaItemPacCategory.DOORCONTROLLERS,
                PiaItemPacCategory.IORELAYS,
                PiaItemPacCategory.DOORSTATIONS,
                PiaItemPacCategory.RELAYEXPMODULES,
                PiaItemDetectorCategory.RADARDETECTORS,
                PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES,
                PiaItemPeopleCounterCategory.PEOPLECOUNTERS,
                PiaItemWearablesCategory.CAMERAS,
                PiaItemWearablesCategory.DOCKING,
                PiaItemWearablesCategory.CONTROLLER,
                PiaItemWearablesCategory.CAMERAEXTENSIONS,
                PiaItemAlerterCategory.ALERTERS,
                PiaItemPagingConsoleCategory.PAGINGCONSOLE,
            ]),
        ) as IPiaDevice[],
);

/** Gets all pia cameras as IPiaCamera[] */
export const getPiaCameras = createSelector(
    [getAvailablePiaItems],
    (items) =>
        items.filter(filterProducts.byCategories([...getAllCameraCategories()])) as IPiaCamera[],
);

export const getPiaLensesRecord = createSelector([getAvailablePiaItems], (piaItems) =>
    piaItems.reduce(
        (record, current) => {
            if (
                current.category === PiaAccessoryCategory.LENSES &&
                current.state === PiaItemState.EXTERNALLY_ANNOUNCED
            ) {
                record[current.id] = current;
            }
            return record;
        },
        {} as Record<PiaId, IPiaItem>,
    ),
);

const twoNDeviceCategories = [
    PiaItemPacCategory.ACCESSSERVER,
    PiaItemPacCategory.ANSWERINGUNIT,
    PiaItemPacCategory.DOORSTATIONS,
    PiaItemPacCategory.NETWORKREADER,
];

export const getAllTwoNDevices = createSelector([getPiaItems], (piaItems) => {
    return piaItems.filter(filterProducts.byCategories(twoNDeviceCategories));
});

/**
 * Returns a record with available pia devices with PiaId as key and PiaDevice as value.
 */
export const getAvailablePiaDevicesRecord = createSelector([getAvailablePiaDevices], (items) =>
    items.reduce((obj: Record<PiaId, IPiaDevice>, item) => {
        obj[item.id] = item;
        return obj;
    }, {}),
);

export const externallyAnnouncedOnly = ({ state }: IPiaItem) =>
    state === PiaItemState.EXTERNALLY_ANNOUNCED;

export const exceptSensorUnits = (device: IPiaItem) =>
    device.category !== PiaItemSensorUnitCategory.SENSORUNIT &&
    device.category !== PiaItemSensorUnitCategory.THERMALSENSOR;

export const exceptApplications = (device: IPiaItem) =>
    device.category !== PiaItemSoftwareCategory.ACAP;

export const exceptVirtualProducts = (device: IPiaItem) =>
    device.category !== PiaItemVirtualProductCategory.VIRTUAL_PRODUCT;

export const exceptAxisAnd2N = (device: IPiaItem) =>
    device.properties.vendor === 'Axis' || device.properties.vendor === '2N';

export const exceptBarebones = (device: IPiaItem) =>
    device.category !== PiaItemBareboneCategory.BAREBONE;

export const exceptCamerasWithoutLens = (device: IPiaItem) =>
    !(isCamera(device) && isCameraWithoutLens(device as IPiaCamera));

export const exceptWearables = (device: IPiaItem) =>
    device.category !== PiaItemWearablesCategory.CAMERAEXTENSIONS &&
    device.category !== PiaItemWearablesCategory.CAMERAS &&
    device.category !== PiaItemWearablesCategory.CONTROLLER &&
    device.category !== PiaItemWearablesCategory.DOCKING;

export const exceptDiscontinued = (device: IPiaItem) =>
    device.state <= PiaItemState.EXTERNALLY_ANNOUNCED;

export const exceptEnvironments = (device: IPiaItem) =>
    device.category !== PiaEnvironmentCategory.ENVIRONMENTS;

export const getPiaItemsExceptDiscontinuedExceptEnvironments = createSelector(
    [getPiaItems],
    (devices) => devices.filter(exceptDiscontinued).filter(exceptEnvironments),
);

const isCameraWithoutLens = (piaCamera: IPiaCamera) =>
    piaCamera.properties.maxHorizontalFOV === 0 && piaCamera.properties.minHorizontalFOV === 0;

const categories = [
    ...getAllCameraCategories(),
    PiaItemEncoderCategory.ENCODER,
    PiaItemDecoderCategory.DECODER,
    PiaItemMainUnitCategory.MAINUNIT,
    PiaItemSensorUnitCategory.SENSORUNIT,
    PiaItemSensorUnitCategory.THERMALSENSOR,
    PiaItemSpeakerCategory.SPEAKER,
    PiaItemPacCategory.ACCESSSERVER,
    PiaItemPacCategory.ANSWERINGUNIT,
    PiaItemPacCategory.DOORCONTROLLERS,
    PiaItemPacCategory.DOORSTATIONS,
    PiaItemPacCategory.IORELAYS,
    PiaItemPacCategory.NETWORKREADER,
    PiaItemPacCategory.RELAYEXPMODULES,
    PiaItemDetectorCategory.RADARDETECTORS,
    PiaItemConnectivityDevicesCategory.CONNECTIVITYDEVICES,
    PiaItemPeopleCounterCategory.PEOPLECOUNTERS,
    PiaItemVirtualProductCategory.VIRTUAL_PRODUCT,
    PiaItemWearablesCategory.CAMERAEXTENSIONS,
    PiaItemWearablesCategory.CAMERAS,
    PiaItemWearablesCategory.CONTROLLER,
    PiaItemWearablesCategory.DOCKING,
    PiaItemAlerterCategory.ALERTERS,
    PiaItemPagingConsoleCategory.PAGINGCONSOLE,
];

export const getAllPiaDevices = createSelector(
    [getPiaItems],
    (items) => items.filter(filterProducts.byCategories(categories)) as IPiaDevice[],
);

export const getAllPiaCameras = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isCamera),
);

const getPiaIdOrNullFromProps = (_state: IStoreState, piaId: PiaId | null) => piaId;

export const getPiaItemForProductId = createCachedSelector(
    [getPiaItemsRecord, getPiaIdOrNullFromProps],
    (items, productId) => {
        if (!productId) {
            return null;
        }
        return items[productId];
    },
)(toCacheKey);

export const getPiaCameraForProductId = createCachedSelector(
    [getPiaItemForProductId, getPiaIdOrNullFromProps],
    (item): IPiaCamera | IPiaSensorUnit | undefined => {
        if (!item) {
            return undefined;
        }

        // TODO: Check that virtual product is a camera!
        return isCamera(item) || item.category === PiaItemVirtualProductCategory.VIRTUAL_PRODUCT
            ? (item as IPiaCamera)
            : isSensorUnit(item)
              ? (item as IPiaSensorUnit)
              : undefined;
    },
)(toCacheKey);

export const getAvailablePiaCameras = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isCamera),
);

export const getCurrentAvailablePiaCameras = createSelector([getAvailablePiaCameras], (devices) =>
    devices.filter(externallyAnnouncedOnly),
);

export const getCurrentAvailablePiaDevices = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(externallyAnnouncedOnly),
);

export const getAllPiaEncoders = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isEncoder),
);

export const getAvailablePiaEncoders = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isEncoder),
);

export const getCurrentAvailablePiaEncoders = createSelector([getAvailablePiaEncoders], (devices) =>
    devices.filter(externallyAnnouncedOnly),
);

export const getAllPiaMainUnits = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isMainUnit),
);

export const getAvailablePiaMainUnits = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isMainUnit),
);

export const getAllSpeakers = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isSpeaker),
);

export const getAvailableSpeakers = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isSpeaker),
);

export const getCurrentAvailablePiaMainUnits = createSelector(
    [getAvailablePiaMainUnits],
    (devices) => devices.filter(externallyAnnouncedOnly),
);

export const getAllPiaSensorUnits = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isSensorUnit),
);

export const getAvailablePiaSensorUnits = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isSensorUnit),
);

export const getCurrentAvailablePiaSensorUnits = createSelector(
    [getAvailablePiaSensorUnits],
    (devices) => devices.filter(externallyAnnouncedOnly),
);

export const getAvailablePiaSensorDevices = createSelector(
    [getAvailablePiaSensorUnits, getAvailablePiaCameras],
    (sensorUnits, cameras) => [...sensorUnits, ...cameras],
);

export const getAllPiaPacs = createSelector([getAllPiaDevices], (devices) => devices.filter(isPac));

export const getAvailablePiaPacs = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isPac),
);

export const getCurrentAvailablePiaPacs = createSelector([getAvailablePiaPacs], (devices) =>
    devices.filter(externallyAnnouncedOnly),
);

export const getAllPiaDecoders = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isDecoder),
);

export const getAvailablePiaDecoders = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isDecoder),
);

export const getCurrentAvailablePiaDecoders = createSelector([getAvailablePiaDecoders], (devices) =>
    devices.filter(externallyAnnouncedOnly),
);

export const getAllPiaRadarDetectors = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isRadar),
);

export const getAvailablePiaRadarDetectors = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isRadar),
);

export const getCurrentAvailableRadarDetectors = createSelector(
    [getAvailablePiaRadarDetectors],
    (devices) => devices.filter(externallyAnnouncedOnly),
);

export const getAllPiaDoorStations = createSelector([getAllPiaDevices], (devices) =>
    devices.filter(isDoorStation),
);

export const getAvailablePiaDoorStations = createSelector([getAvailablePiaDevices], (devices) =>
    devices.filter(isDoorStation),
);

export const getCurrentAvailablePiaDoorStations = createSelector(
    [getAvailablePiaDoorStations],
    (devices) => devices.filter(externallyAnnouncedOnly),
);

export const getAllApplications = createSelector([getAvailablePiaItems], (devices) =>
    devices.filter(isApplication),
);

export const getCurrentAvailableApplications = createSelector([getAllApplications], (devices) =>
    devices.filter(externallyAnnouncedOnly),
);

export const getAllRecordingSolutionComponents = createSelector([getAvailablePiaItems], (devices) =>
    devices.filter(isRecordingSolutionComponent),
);

export const getCurrentAvailableRecordingSolutionComponents = createSelector(
    [getAllRecordingSolutionComponents],
    (devices) => devices.filter(externallyAnnouncedOnly),
);

export const getRequiredComponentsForPiaItem = createCachedSelector(
    [getAvailablePiaItemsRecord, getPiaIdFromProps],
    (piaItems, piaId): IPiaItem[][] => {
        if (!piaId) return [];

        const reqComponentIds = piaItems[piaId]?.properties.reqComponents ?? [];

        return reqComponentIds.reduce((reqPiaItems, reqPiaIdGroup) => {
            const piaItemGroup = reqPiaIdGroup.map((reqPiaItemId) => piaItems[reqPiaItemId]);
            const isAllPiaItemsAvailable = piaItemGroup.every(isDefined);
            if (isAllPiaItemsAvailable) {
                reqPiaItems.push(piaItemGroup);
            }
            return reqPiaItems;
        }, [] as IPiaItem[][]);
    },
)(toCacheKey);
