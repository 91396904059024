import type { IInstallationPointSensorModel } from 'app/core/persistence';
import { trigonometry } from 'axis-webtools-util';
import { getAspectRatio } from './getAspectRatio';

/**
 * Calculate the sensors based on the changes to a sensor, assuming that the sensors
 * are fixed and should follow each other
 */
export const calculateFixedSubSensors = (
    sensors: IInstallationPointSensorModel[],
    changedSensor: IInstallationPointSensorModel,
): IInstallationPointSensorModel[] => {
    const angle = changedSensor.target.horizontalAngle;
    const distance = changedSensor.target.distance;
    const fov = changedSensor.settings.horizontalFov;
    const height = changedSensor.target.height;

    // Apply the changes to the sensors
    const newSensors = sensors.map((s) => {
        if (s.sensorId === changedSensor.sensorId) {
            return changedSensor;
        } else {
            return s;
        }
    });

    // Find the sensor with the smallest fov. This is the "leader" sensor, i.e. the sensor
    // that the other sensors should follow
    const leaderSensor = newSensors.reduce((prev, current) => {
        const prevAspectRatio = getAspectRatio(prev);
        const currentAspectRatio = getAspectRatio(current);
        return prev.settings.horizontalFov * prevAspectRatio <
            current.settings.horizontalFov * currentAspectRatio
            ? prev
            : current;
    }, sensors[0]);

    // Calculate the vertical fov for the leader sensor
    const leaderAspectRatio = getAspectRatio(leaderSensor);
    const leaderVFov = leaderSensor.settings.horizontalFov * leaderAspectRatio;

    return sensors.map((s) => {
        const newSensor = {
            ...s,
        };
        newSensor.target.horizontalAngle = angle;
        newSensor.target.distance = distance;
        newSensor.target.height = height;

        if (s.sensorId === changedSensor.sensorId) {
            newSensor.settings.horizontalFov = fov;
        }

        if (s.sensorId === leaderSensor.sensorId) {
            // The leader sensor should always have a tilt offset of 0
            newSensor.settings.tiltOffset = 0;
        } else {
            // Calculate the tilt offset for the follower sensors
            const followerAspectRatio = getAspectRatio(newSensor);
            const followerVFov = newSensor.settings.horizontalFov * followerAspectRatio;
            const tiltOffset = trigonometry.toRadians(Math.abs(followerVFov - leaderVFov) / 2);
            newSensor.settings.tiltOffset = tiltOffset;
        }
        return newSensor;
    });
};
