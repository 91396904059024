const jpegSoiBitValue = 0xffd8; // Start of Image (SOI) marker for jpeg
const exifBitValue = 0x45786966;
const littleEndianBitValue = 0x4949;
const app1BitValue = 0xffe1;
const orientationTagBitValue = 0x0112;

/**
 * @returns True if the image is rotated, false if not rotated or if the EXIF orientation was unreadable.
 */
export const readExifOrientation = (file: Blob) =>
    new Promise<boolean>((resolve) => {
        const noExifOrientation = () => resolve(false);
        const hasExifOrientation = () => resolve(true);

        const reader = new FileReader();
        reader.onload = () => {
            const view = new DataView(reader.result as ArrayBuffer);
            if (view.getUint16(0, false) !== jpegSoiBitValue) {
                return noExifOrientation(); // not JPEG
            }

            let offset = 2;

            // Search for EXIF marker
            while (offset < view.byteLength) {
                const marker = view.getUint16(offset, false);
                offset += 2;

                if (marker === app1BitValue) {
                    offset += 2;

                    if (view.getUint32(offset, false) !== exifBitValue) {
                        return noExifOrientation();
                    }

                    offset += 6;
                    const littleEndian = view.getUint16(offset, false) === littleEndianBitValue;
                    offset += view.getUint32(offset + 4, littleEndian);
                    const tags = view.getUint16(offset, littleEndian);
                    offset += 2;

                    for (let i = 0; i < tags; i++) {
                        if (
                            view.getUint16(offset + i * 12, littleEndian) === orientationTagBitValue
                        ) {
                            const foundOrientationTag =
                                view.getUint16(offset + i * 12 + 8, littleEndian) > 1;
                            return foundOrientationTag ? hasExifOrientation() : noExifOrientation();
                        }
                    }
                } else if ((marker & 0xff00) !== 0xff00) {
                    break;
                } else {
                    // Go to next segment
                    offset += view.getUint16(offset, false);
                }
            }
            return noExifOrientation();
        };

        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    });
