import type { ISustainabilityFilter } from 'app/core/persistence';
import {
    isDecoder,
    isRadar,
    isPeopleCounter,
    isAlerter,
    defaultSustainabilityFilter,
    isConnectivityDevice,
    isPagingConsole,
} from 'app/core/persistence';
import type { IPiaOtherDevice } from 'app/core/pia';
import { meetsSustainabilityRequirements } from './SustainabilityFilter';

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const peopleCounterType = 'peopleCounters';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const securityRadarType = 'securityRadars';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const videoDecoderType = 'videoDecoders';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const alerterType = 'alerters';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const connectivityDeviceType = 'connectivityDevices';
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const pagingConsoleType = 'pagingConsoles';

export type otherDeviceType =
    | typeof peopleCounterType
    | typeof securityRadarType
    | typeof alerterType
    | typeof videoDecoderType
    | typeof connectivityDeviceType
    | typeof pagingConsoleType;
export interface IOtherDeviceFilter extends ISustainabilityFilter {
    selectedCategory?: otherDeviceType;
}

export const defaultOtherDeviceFilter: IOtherDeviceFilter = {
    selectedCategory: undefined,
    ...defaultSustainabilityFilter,
};

export class OtherDeviceFilter {
    public static filter(
        otherDeviceFilter: IOtherDeviceFilter,
        allOtherDevices: IPiaOtherDevice[],
        searchFilter: string,
    ): IPiaOtherDevice[] {
        const lowerCaseSearchFilter = searchFilter.toLowerCase();

        return allOtherDevices.filter((otherDevice) => {
            if (!otherDevice.name.toLowerCase().includes(lowerCaseSearchFilter)) {
                return false;
            }

            if (otherDeviceFilter.selectedCategory === 'videoDecoders' && !isDecoder(otherDevice)) {
                return false;
            }
            if (otherDeviceFilter.selectedCategory === 'securityRadars' && !isRadar(otherDevice)) {
                return false;
            }
            if (
                otherDeviceFilter.selectedCategory === 'peopleCounters' &&
                !isPeopleCounter(otherDevice)
            ) {
                return false;
            }
            if (otherDeviceFilter.selectedCategory === 'alerters' && !isAlerter(otherDevice)) {
                return false;
            }
            if (
                otherDeviceFilter.selectedCategory === 'connectivityDevices' &&
                !isConnectivityDevice(otherDevice)
            ) {
                return false;
            }
            if (
                otherDeviceFilter.selectedCategory === 'pagingConsoles' &&
                !isPagingConsole(otherDevice)
            ) {
                return false;
            }
            if (!meetsSustainabilityRequirements(otherDeviceFilter, otherDevice)) {
                return false;
            }

            return true;
        });
    }
}
