import type { IPiaSensorDevice } from 'app/core/pia';
import { isCustomCamera } from 'app/core/persistence';
import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectInstallationPoints,
    getCurrentProjectItemRelationsArray,
    getCurrentProjectItems,
} from '../../project';
import { getIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';
import { calculateFOVLimits } from '../../piaDevices';
import { mapInstallationEntityToModel } from '../mapInstallationEntityToModel';
import { getVerticalFovOfCamera } from '../../utils';

export const getVerticalFovForIp = createCachedSelector(
    [
        getIdFromProps,
        getCurrentProjectInstallationPoints,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
    ],
    (installationPointId, installationPoints, currentProjectItems, cpItemRelations) => {
        if (!installationPointId || !installationPoints) return [];

        const ip = installationPoints[installationPointId];
        if (!ip) return [];

        const ipModel = mapInstallationEntityToModel(ip, currentProjectItems, cpItemRelations);

        return ip.sensors.map((ipSensor) => {
            const horizontalFov = ipSensor.settings.horizontalFov;

            const lensItemEntity = ipModel?.lenses?.find(
                (lens) => (lens.properties.lens?.sensorIndex ?? 0) === ipSensor.sensorId - 1,
            );

            // Find the parent device of the sensor if there is one
            const sensorParentDevice = ipModel?.sensors.find(
                (sensor) => sensor.sensorId === ipSensor.sensorId,
            )?.parentPiaDevice;

            const piaCamera = (sensorParentDevice ?? ipModel?.parentPiaDevice) as IPiaSensorDevice;
            const lensRelation = (piaCamera?.relations ?? []).find(
                (relation) => relation.id === lensItemEntity?.productId,
            );
            const parentDevice = ipModel?.parentDevice;

            const customCameraProperties =
                parentDevice && isCustomCamera(parentDevice)
                    ? parentDevice.properties.camera.customCameraProperties
                    : undefined;
            const aspectRatio = customCameraProperties
                ? customCameraProperties.resolutionHorizontal /
                  customCameraProperties.resolutionVertical
                : undefined;

            const lensFOVLimits =
                piaCamera && lensRelation?.relationProperties
                    ? calculateFOVLimits(piaCamera, lensRelation.relationProperties, parentDevice)
                    : undefined;

            const verticalFov = getVerticalFovOfCamera(
                horizontalFov,
                piaCamera?.properties,
                lensFOVLimits,
                aspectRatio,
            );

            return verticalFov;
        });
    },
)(toCacheKey);
