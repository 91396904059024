import { getFovLimits, getPixelDensityForSensor, toCacheKey } from 'app/modules/common';
import { clamp } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { getSelectedSensorId } from './getSelectedId';
import { getSelectedInstallationPoint } from './getSelectedInstallationPoint';

export const getPixelDensity = createCachedSelector(
    [getSelectedInstallationPoint, getSelectedSensorId, getFovLimits],
    (installationPoint, selectedSensorId, fovLimits) => {
        const sensorCount = installationPoint?.sensors.length ?? 0;
        const sensorId = clamp(selectedSensorId || 1, 1, sensorCount);

        const currentSensor = installationPoint?.sensors.find(
            (sensor) => sensor.sensorId === sensorId,
        );

        const pixelDensityPerMeter = getPixelDensityForSensor(
            currentSensor,
            sensorId,
            installationPoint,
            fovLimits,
        );

        return pixelDensityPerMeter;
    },
)(toCacheKey);
