import * as React from 'react';
import { Box, Closeable, Border, IconButton, NoPrint } from 'app/components';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { connect } from 'react-redux';
import type { IMapsState, contextMenuType } from '../../models';
import { t } from 'app/translate';
import { UnreachableCaseError } from 'axis-webtools-util';
import {
    InstallationPointContextMenu,
    DevicesContextMenu,
    MultiSelectContextMenu,
} from './contextMenus';
import { getMapsSortedByDate } from '../../selectors';
import { MapsActionService } from '../../services';
import { getCurrentProjectHasFloorPlans } from 'app/modules/common';

interface IMapMenuComponentStateProps {
    selected: IMapsState['selected'];
    floorPlansCount: number;
}

interface IMapMenuComponentSelectorProps {
    projectHasMaps: boolean;
}

type MapMenuComponentProps = IMapMenuComponentStateProps & IMapMenuComponentSelectorProps;

const mapStateToProps = (storeState: IStoreState): MapMenuComponentProps => {
    const state = storeState.maps;
    const floorPlans = getMapsSortedByDate(storeState);
    const selected = state.selected;

    return {
        selected,
        floorPlansCount: floorPlans.length,
        projectHasMaps: getCurrentProjectHasFloorPlans(storeState),
    };
};
class MapMenuComponent extends React.Component<MapMenuComponentProps> {
    private mapsActionService: MapsActionService;

    constructor(props: MapMenuComponentProps) {
        super(props);
        this.mapsActionService = ServiceLocator.get(MapsActionService);
    }

    public render() {
        return (
            <Closeable customShadow="0px 2px 4px rgba(0, 0, 0, 0.25)" close={() => {}}>
                <NoPrint>
                    <Box
                        testId="map_main_menu"
                        direction="column"
                        alignItems="start"
                        color="grey1"
                        height="100%"
                        minWidth="280px"
                        maxWidth="280px"
                    >
                        {this.props.selected ? this.renderContextMenu() : this.renderMainMenu()}
                    </Box>
                </NoPrint>
            </Closeable>
        );
    }

    private renderContextMenu = () => (
        <>
            <Border bottomWidth={1} color="grey3">
                <Box width="100%" paddingX="half" height={56} alignItems="center" color="white">
                    <IconButton
                        onClick={() => {
                            this.mapsActionService.deselectItem();
                        }}
                        text={t.devices}
                        icon="arrow_back"
                        color="blue"
                        testId="btn_arrow_back"
                    ></IconButton>
                </Box>
            </Border>
            <Box
                direction="column"
                justifyContent="between"
                overflowY="auto"
                height="100%"
                width="100%"
                padding="half"
            >
                {this.getContextMenu(this.props.selected?.type)}
            </Box>
        </>
    );

    private getContextMenu = (type: contextMenuType | undefined) => {
        switch (type) {
            case 'device':
            case 'installationPoint':
                return <InstallationPointContextMenu />;
            case 'multiInstallationPoint':
                return <MultiSelectContextMenu />;
            default:
                throw new UnreachableCaseError(type as never);
        }
    };

    private renderMainMenu = () => <DevicesContextMenu />;
}

export const MapMenu = connect(mapStateToProps)(MapMenuComponent);
