import { injectable } from 'inversify';
import type { Id, IPersistence, IItemEntity } from 'app/core/persistence';
import {
    getVirtuallyIncludedIds,
    getDefaultProfileOverrideEntity,
    ItemService,
    CurrentProjectService,
} from 'app/core/persistence';
import type { IPiaDevice } from 'app/core/pia';

@injectable()
export class VirtualProductService {
    constructor(
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
    ) {}
    public async addVirtualProducts(id: Id, desiredPiaDevice: IPiaDevice | null, profileId?: Id) {
        if (!profileId) return;
        const virtualProducts = getVirtuallyIncludedIds(desiredPiaDevice);

        for (const piaId of virtualProducts) {
            const virtualProductItem = await this.itemService.addByParentId(id, {
                name: '',
                quantity: 1,
                description: '',
                notes: '',
                productId: piaId,
                properties: {
                    virtualProduct: {
                        associatedProfile: profileId,
                        profileOverride: getDefaultProfileOverrideEntity(),
                    },
                },
            });

            await this.itemService.addItemRelation(id, virtualProductItem._id, 'virtualProduct');
        }
    }

    public async removeAllVirtualProducts(id: Id): Promise<IPersistence<IItemEntity>> {
        const currentProjectRelations =
            this.currentProjectService.getAllEntitiesOfType('itemRelation');

        const virtualProductRelations = currentProjectRelations
            .filter((entity) => entity.parentId === id)
            .filter(({ relationType }) => relationType === 'virtualProduct');

        if (virtualProductRelations.length > 0) {
            await Promise.all(
                virtualProductRelations.map((item) => this.itemService.deleteItem(item.childId)),
            );
        }
        return this.itemService.getItem(id);
    }
}
