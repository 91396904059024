import { injectable } from 'inversify';
import type { IPiaPac } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type {
    IPersistence,
    IItemEntity,
    IPacItemEntity,
    Id,
    IDoorItemEntity,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    isDeviceSpecified,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type {
    IExportablePersistedEntity,
    IExportedPhysicalAccessController,
    IExportedDoor,
    IExportedDoorSide,
    IExportedPiaProduct,
    IExportedItemBase,
} from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import { toShareModelNames } from '../../mapToShareItemNames';
import { ID_QUANTITY_DIVIDER, generateUniqueSplitId } from '../../splitByQuantity';

type ProductItem = IPersistence<IItemEntity & { productId: number }>;

@injectable()
export class PacsExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaPac>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedPacs = async (
        projectId: Id,
        items: IExportablePersistedEntity[],
    ): Promise<IExportedPhysicalAccessController[]> => {
        const allPacs = items.filter(this.isPacItemEntity);
        const mappedPacs = await Promise.all(
            allPacs.map((pac) => this.mapPacToExportedPac(projectId, pac)),
        );
        return mappedPacs.filter(isDefined);
    };

    private isPacItemEntity(item: IPersistence<IItemEntity>): item is IPersistence<IPacItemEntity> {
        return (
            (deviceTypeCheckers.isPac(item) && !deviceTypeCheckers.isDoorStation(item)) ||
            deviceTypeCheckers.isDoorController(item)
        );
    }

    private mapPacToExportedPac = async (
        projectId: Id,
        pacItem: IExportablePersistedEntity,
    ): Promise<IExportedPhysicalAccessController | null> => {
        if (!pacItem.productId) {
            return null;
        }

        const pacPiaDevice = this.getPiaDevice(pacItem.productId);
        const mappedBaseItem = await this.mapItemToExportedItemBase(pacItem, projectId);

        return {
            ...mappedBaseItem,
            ...toShareModelNames(pacPiaDevice.name),
            piaId: pacItem.productId,
            doors: await this.mapDoors(projectId, pacItem),
        };
    };

    private mapDoors = async (
        projectId: Id,
        pacItem: IExportablePersistedEntity,
    ): Promise<IExportedDoor[]> => {
        const doors = (await this.getDeviceChildren(projectId, pacItem._id, 'door')).filter(
            deviceTypeCheckers.isDoor,
        ) as IPersistence<IDoorItemEntity>[];

        const mappedDoors = await Promise.all(
            doors.map((door) => this.mapDoorToExportedDoor(projectId, pacItem, door)),
        );
        return mappedDoors.filter(isDefined);
    };

    private mapDoorToExportedDoor = async (
        projectId: Id,
        pacItem: IExportablePersistedEntity,
        door: IPersistence<IDoorItemEntity>,
    ): Promise<IExportedDoor | undefined> => {
        const accessoriesSideA = await this.getDeviceChildren(projectId, door._id, 'doorSideA');
        const accessoriesSideB = await this.getDeviceChildren(projectId, door._id, 'doorSideB');

        const hasRexOnSideA = accessoriesSideA.some((accessory) => accessory.productId === null);
        const hasRexOnSideB = accessoriesSideB.some((reader) => reader.productId === null);

        const readersSideA = accessoriesSideA.filter(isDeviceSpecified) as ProductItem[];
        const readersSideB = accessoriesSideB.filter(isDeviceSpecified) as ProductItem[];

        const sideA: IExportedDoorSide = {
            rex: hasRexOnSideA,
            readers: await Promise.all(
                readersSideA.map((accessory) => this.mapToReader(pacItem, accessory, projectId)),
            ),
        };

        const sideB: IExportedDoorSide = {
            rex: hasRexOnSideB,
            readers: await Promise.all(
                readersSideB.map((accessory) => this.mapToReader(pacItem, accessory, projectId)),
            ),
        };

        const mappedBaseItem = await this.mapItemToExportedItemBase(
            {
                exportId: this.getExportId(pacItem.exportId, door._id),
                ...door,
            },
            projectId,
        );

        return {
            ...mappedBaseItem,
            nbrOfLocks: door.properties.door.nbrOfLocks,
            sideA,
            sideB,
        };
    };

    private mapToReader = async (
        pacItem: IExportablePersistedEntity,
        reader: ProductItem,
        projectId: Id,
    ): Promise<IExportedPiaProduct & IExportedItemBase> => {
        const piaDevice = this.getPiaDevice(reader.productId);
        const mappedBaseItem = await this.mapItemToExportedItemBase(
            {
                exportId: this.getExportId(pacItem.exportId, reader._id),
                ...reader,
            },
            projectId,
        );

        return {
            ...mappedBaseItem,
            piaId: reader.productId,
            ...toShareModelNames(piaDevice.name),
        };
    };

    private getExportId = (parentExportId: string, id: string): string => {
        const dividerIndex = parentExportId.indexOf(ID_QUANTITY_DIVIDER);
        if (dividerIndex < 0) {
            return id;
        }
        const quantityIndex = Number(parentExportId.slice(dividerIndex + 1));
        return generateUniqueSplitId(id, quantityIndex);
    };
}
