import { t } from 'app/translate';
import * as React from 'react';

import { FilterPanel } from './FilterPanel';
import {
    Action,
    Text,
    Checkbox,
    DropDown,
    DropDownMenuButton,
    Grid,
    PillButton,
    Stack,
} from 'app/components';
import { useSelector } from 'react-redux';
import type { IStoreState } from 'app/store';
import { DeviceSelectorActionService } from '../../services';
import { ServiceLocator } from 'app/ioc';
import type { UnitSystem } from 'app/core/persistence';
import {
    LightConditions,
    getDefaultCameraFilterEntity,
    SensorUnitType,
} from 'app/core/persistence';
import type { IDesiredCamera } from 'app/modules/common';
import {
    desiredCameraUtils,
    getCurrentProjectCustomInstallationHeight,
    getCurrentProjectUnitSystem,
} from 'app/modules/common';
import type { ISensorUnitFilter } from '../../models';
import { getDefaultSensorUnitFilter } from '../../models';

import type { IDesiredSensorUnit } from '../../models/cameras/IDesiredSensorUnit';

import { OperationalTemperatureFilter } from './common/OperationalTemperatureFilter.container';
import { SceneFilterSettingsContainer } from './scene';
import { SearchField } from './common';
import { css } from '@emotion/css';

export const SensorUnitFilterPanel: React.FC = () => {
    const [actionService] = React.useState<DeviceSelectorActionService>(() =>
        ServiceLocator.get(DeviceSelectorActionService),
    );
    const desiredSensorUnit = useSelector<IStoreState, IDesiredCamera>(
        (store) => store.deviceSelector.sensorUnitFilter.desiredSensorUnit,
    );

    const desiredSensorUnitFilter = useSelector<IStoreState, ISensorUnitFilter>(
        (store) => store.deviceSelector.sensorUnitFilter,
    );

    const searchText = useSelector<IStoreState, string>((store) => store.deviceSelector.searchText);

    const installationHeight = useSelector<IStoreState, number>((store) =>
        getCurrentProjectCustomInstallationHeight(store),
    );
    const unitSystem = useSelector<IStoreState, UnitSystem>((store) =>
        getCurrentProjectUnitSystem(store),
    );

    const isFilterActive = desiredSensorUnitFilter.operationalTemperature.active;
    const operationalMin = desiredSensorUnitFilter.operationalTemperature.option;
    const operationalMax = desiredSensorUnitFilter.operationalTemperature.secondaryOption;

    /*update methods*/

    const setOperationalTemperature = (min: number, max: number) => {
        updateSensorUnitFilter({
            operationalTemperature: {
                option: min,
                secondaryOption: max,
                active: true,
            },
        });
    };

    const inactivateOperationalTemperature = () => {
        actionService.setIsOperationalTemperatureFilterUsed(false);
    };

    const updateDesiredSensorUnit = actionService.updateDesiredSensorUnit;

    const updateSensorUnitFilter = actionService.updateSensorUnitFilter;

    const updateDesiredFilter = (desiredSensorUnitUpdates: Partial<IDesiredSensorUnit>) => {
        updateDesiredSensorUnit(desiredSensorUnitUpdates);
    };

    const updateSensorUnitTypes = (sensorUnitType: SensorUnitType) => {
        if (desiredSensorUnit.sensorUnitTypes.includes(sensorUnitType)) {
            updateDesiredSensorUnit({
                sensorUnitTypes: desiredSensorUnit.sensorUnitTypes.filter(
                    (type) => type !== sensorUnitType,
                ),
            });
        } else {
            updateDesiredSensorUnit({
                sensorUnitTypes: [sensorUnitType],
            });
        }
    };

    const toggleBuiltInIR = () => {
        updateSensorUnitFilter({
            IRLEDs: !desiredSensorUnitFilter.IRLEDs,
        });
    };

    const toggleVarifocal = () => {
        updateSensorUnitFilter({
            varifocal: !desiredSensorUnitFilter.varifocal,
        });
    };

    const togglePvcFree = () => {
        updateSensorUnitFilter({
            pvcFree: !desiredSensorUnitFilter.pvcFree,
        });
    };

    const defaultDesiredSensorUnit = desiredCameraUtils.convertPropertiesToDesiredCamera(
        getDefaultCameraFilterEntity(unitSystem, installationHeight),
    );

    const defaultSensorUnitFilter = getDefaultSensorUnitFilter(unitSystem, installationHeight);

    /**
     * Resets sensor unit filter to its default values.
     * Also sets includeDiscontinued to false and clears search text.
     */
    const resetFilter = () => {
        if (
            desiredSensorUnit.isSceneFilterActive !== defaultDesiredSensorUnit.isSceneFilterActive
        ) {
            actionService.updateDesiredSensorUnit({
                isSceneFilterActive: defaultDesiredSensorUnit.isSceneFilterActive,
            });
        }

        if (desiredSensorUnit.sensorUnitTypes !== defaultDesiredSensorUnit.sensorUnitTypes) {
            actionService.updateDesiredSensorUnit({
                sensorUnitTypes: defaultDesiredSensorUnit.sensorUnitTypes,
            });
        }

        if (desiredSensorUnit.outdoor !== defaultDesiredSensorUnit.outdoor) {
            actionService.updateDesiredSensorUnit({
                outdoor: defaultDesiredSensorUnit.outdoor,
            });
        }

        if (desiredSensorUnit.lightConditions !== defaultDesiredSensorUnit.lightConditions) {
            actionService.updateDesiredSensorUnit({
                lightConditions: defaultDesiredSensorUnit.lightConditions,
            });
        }

        if (desiredSensorUnitFilter.IRLEDs !== defaultSensorUnitFilter.IRLEDs) {
            updateSensorUnitFilter({
                IRLEDs: defaultSensorUnitFilter.IRLEDs,
            });
        }

        if (desiredSensorUnitFilter.varifocal !== defaultSensorUnitFilter.varifocal) {
            updateSensorUnitFilter({
                varifocal: defaultSensorUnitFilter.varifocal,
            });
        }

        if (desiredSensorUnitFilter.pvcFree !== defaultSensorUnitFilter.pvcFree) {
            updateSensorUnitFilter({
                pvcFree: defaultSensorUnitFilter.pvcFree,
            });
        }

        if (
            desiredSensorUnitFilter.operationalTemperature !==
            defaultSensorUnitFilter.operationalTemperature
        ) {
            updateSensorUnitFilter({
                operationalTemperature: defaultSensorUnitFilter.operationalTemperature,
            });
        }
        if (searchText) {
            actionService.setSearchText('');
        }
    };

    const resetSceneSettings = () => {
        if (
            desiredSensorUnit.horizontalFOVRadians !== defaultDesiredSensorUnit.horizontalFOVRadians
        ) {
            actionService.updateDesiredSensorUnit({
                horizontalFOVRadians: defaultDesiredSensorUnit.horizontalFOVRadians,
            });
        }
        if (desiredSensorUnit.distanceToTarget !== defaultDesiredSensorUnit.distanceToTarget) {
            actionService.updateDesiredSensorUnit({
                distanceToTarget: defaultDesiredSensorUnit.distanceToTarget,
            });
        }
        if (desiredSensorUnit.pixelDensity !== defaultDesiredSensorUnit.pixelDensity) {
            actionService.updateDesiredSensorUnit({
                pixelDensity: defaultDesiredSensorUnit.pixelDensity,
            });
        }
        if (desiredSensorUnit.installationHeight !== defaultDesiredSensorUnit.installationHeight) {
            actionService.updateDesiredSensorUnit({
                installationHeight: defaultDesiredSensorUnit.installationHeight,
            });
        }
        if (desiredSensorUnit.targetHeight !== defaultDesiredSensorUnit.targetHeight) {
            actionService.updateDesiredSensorUnit({
                targetHeight: defaultDesiredSensorUnit.targetHeight,
            });
        }
    };

    return (
        <FilterPanel>
            <Stack vertical spacing="half">
                <SearchField />
                <Stack alignItems="center" justifyContent="between">
                    <Text style="semibold" color="grey6">
                        {t.cameraSelectorRequirementsTitle}
                    </Text>
                    <Action title={t.reset} onClick={resetFilter} />
                </Stack>
                <Grid spacing="halfQuart">
                    {/* sensor unit types */}
                    <DropDown
                        trigger={
                            <PillButton
                                text={
                                    desiredSensorUnit.sensorUnitTypes.length > 0
                                        ? desiredSensorUnit.sensorUnitTypes
                                              .map((type) => t.sensorUnitTypeGROUP[type])
                                              .join(', ')
                                        : t.deviceSelectorSensorUnitType
                                }
                                icon="keyboard_arrow_down"
                                selected={desiredSensorUnit.sensorUnitTypes.length > 0}
                            />
                        }
                        contents={
                            <>
                                <DropDownMenuButton
                                    label={t.sensorUnitTypeGROUP.sensorunits}
                                    selected={desiredSensorUnit.sensorUnitTypes.includes(
                                        SensorUnitType.SensorUnit,
                                    )}
                                    onClick={() => updateSensorUnitTypes(SensorUnitType.SensorUnit)}
                                />
                                <DropDownMenuButton
                                    label={t.sensorUnitTypeGROUP.thermalsensor}
                                    selected={desiredSensorUnit.sensorUnitTypes.includes(
                                        SensorUnitType.Thermal,
                                    )}
                                    onClick={() => updateSensorUnitTypes(SensorUnitType.Thermal)}
                                />
                            </>
                        }
                    />
                    <PillButton
                        checkable
                        text={t.outdoor}
                        selected={desiredSensorUnit.outdoor}
                        onClick={() =>
                            updateDesiredSensorUnit({ outdoor: !desiredSensorUnit.outdoor })
                        }
                    />
                    <PillButton
                        checkable
                        text={t.advancedFiltersGROUP.builtInIR}
                        selected={desiredSensorUnitFilter.IRLEDs}
                        onClick={() => toggleBuiltInIR()}
                    />
                    <PillButton
                        checkable
                        text={t.advancedFiltersGROUP.varifocal}
                        selected={desiredSensorUnitFilter.varifocal}
                        onClick={() => toggleVarifocal()}
                    />
                    <PillButton
                        checkable
                        text={t.advancedFiltersGROUP.pvcFree}
                        selected={desiredSensorUnitFilter.pvcFree}
                        onClick={() => togglePvcFree()}
                    />
                    <PillButton
                        checkable
                        text={t.cameraSelectorFieldOfViewBacklight}
                        selected={desiredSensorUnit.lightConditions.includes(
                            LightConditions.BACKLIGHT,
                        )}
                        onClick={() =>
                            desiredSensorUnit.lightConditions.includes(LightConditions.BACKLIGHT)
                                ? updateDesiredSensorUnit({
                                      lightConditions: desiredSensorUnit.lightConditions.filter(
                                          (condition) => condition !== LightConditions.BACKLIGHT,
                                      ),
                                  })
                                : updateDesiredSensorUnit({
                                      lightConditions: [
                                          ...desiredSensorUnit.lightConditions,
                                          LightConditions.BACKLIGHT,
                                      ],
                                  })
                        }
                    />
                    <PillButton
                        testId="corridor_btn"
                        checkable
                        text={t.cameraSelectorFieldOfViewCorridor}
                        selected={desiredSensorUnit.corridorFormat}
                        onClick={() =>
                            updateDesiredSensorUnit({
                                corridorFormat: !desiredSensorUnit.corridorFormat,
                            })
                        }
                    />
                    <OperationalTemperatureFilter
                        filterActive={isFilterActive}
                        filterMin={operationalMin}
                        filterMax={operationalMax}
                        setOperationalTemperature={setOperationalTemperature}
                        inactivateOperationalTemperature={inactivateOperationalTemperature}
                    />
                </Grid>
            </Stack>
            <Stack vertical>
                <Stack justifyContent="between" alignItems="center">
                    <Checkbox
                        slider
                        selected={Boolean(desiredSensorUnit.isSceneFilterActive)}
                        onChange={() =>
                            updateDesiredSensorUnit({
                                isSceneFilterActive: !desiredSensorUnit.isSceneFilterActive,
                            })
                        }
                        testId="scene_requirements"
                    >
                        {t.sceneRequirements}
                    </Checkbox>
                    <Action title={t.reset} onClick={resetSceneSettings} />
                </Stack>
                <div
                    className={css`
                        opacity: ${desiredSensorUnit.isSceneFilterActive ? 100 : 54}%;
                    `}
                >
                    <SceneFilterSettingsContainer updateFilter={updateDesiredFilter} />
                </div>
            </Stack>
        </FilterPanel>
    );
};
