import * as React from 'react';
import { Box, Card, Clickable, Icon, PiaImage, Text } from 'app/components';
import type { IRecordingSolutionItem } from '../selectors';
import { isAxisProduct, isPartnerProduct } from '../types';

import {
    hoverContainerStyle,
    hoverItemCardStyle,
    hoverSectionStyle,
    itemCardStyle,
} from 'app/modules/common';
import { css, cx } from '@emotion/css';
import { ColorsEnum } from 'app/styles';
import type { IPartnerSystemComponent } from 'app/core/persistence';
import { t } from 'app/translate';
import { PartnerImage } from './PartnerImage';
import { RecordingSolutionMsrp } from '../RecordingSolutionMsrp';
import { useResponsive } from 'app/hooks';

const CARD_WIDTH_TABLET = 160;
const CARD_WIDTH_DESKTOP = 200;
const HOVER_STYLE_DEPTH = 3;

const customHoverStyle = (isSelected: boolean) => css`
    z-index: ${HOVER_STYLE_DEPTH};
    border-color: ${ColorsEnum['grey3']};
    background-color: ${isSelected ? ColorsEnum.yellow1 : ''};
`;

const selectedStyle = css`
    background-color: ${ColorsEnum.yellow1};
`;

interface ISolutionCardProps {
    name: string;
    description: string | React.ReactNode;
    children: IRecordingSolutionItem[] | IPartnerSystemComponent[];
    onSelect: () => void;
    isSelected: boolean;
}

export const SolutionItemCard: React.FC<ISolutionCardProps> = ({
    name,
    description,
    children,
    onSelect,
    isSelected = false,
}) => {
    const [hover, setHover] = React.useState<boolean>(false);

    const cardWidth =
        useResponsive(CARD_WIDTH_DESKTOP, CARD_WIDTH_TABLET, CARD_WIDTH_TABLET, {
            desktop: 1280,
            mobile: 1024,
            tablet: 1024,
        }) ?? CARD_WIDTH_DESKTOP;

    const renderTextLayout = (text: string, alignRight?: boolean, testId?: string) => {
        return (
            <Text
                color="grey6"
                style="small"
                align={alignRight ? 'right' : undefined}
                testId={testId}
            >
                {text}
            </Text>
        );
    };

    const renderHoverSection = (containerStyle: string) => {
        return (
            <div className={containerStyle}>
                <div className={cx(hoverSectionStyle(cardWidth), customHoverStyle(isSelected))}>
                    <Box
                        testId={`${name}_camera_hover_section`}
                        width="100%"
                        minHeight={50}
                        padding="half"
                        paddingLeft="base"
                    >
                        <Box
                            display="grid"
                            grid={{
                                gridTemplateColumns: 'min-content min-content 1fr',
                            }}
                            spacing="halfQuart"
                        >
                            {children.map((item, index) => (
                                <React.Fragment key={`${item.name}_${index}`}>
                                    {renderTextLayout(
                                        `${item.quantity}`,
                                        true,
                                        `item_quantity_name_${item.quantity}_${item.name}`,
                                    )}
                                    {renderTextLayout('×')}
                                    {renderTextLayout(item.name)}
                                    {isAxisProduct(children) &&
                                        renderAccessoriesList(item as IRecordingSolutionItem)}
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                </div>
            </div>
        );
    };

    const renderAccessoriesList = (item: IRecordingSolutionItem) => {
        return (item.accessories ?? []).map((accessory, index) => (
            <React.Fragment key={`${item.name}_${index}`}>
                {renderTextLayout(`${accessory.quantity * item.quantity}`, true)}
                {renderTextLayout(`×`)}
                {renderTextLayout(accessory.name)}
            </React.Fragment>
        ));
    };

    return (
        <div
            className={cx(
                itemCardStyle,
                isSelected && selectedStyle,
                children.length > 0 && hoverItemCardStyle,
            )}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Card
                color={isSelected ? 'yellow1' : 'grey1'}
                testId={`recording_solution_item_${name}`}
            >
                <Clickable
                    onClick={children.length === 0 ? undefined : onSelect}
                    noInteraction={children.length == 0}
                >
                    <Box
                        testId={`recording_solution_item_${name}_click`}
                        display="grid"
                        grid={{
                            gridTemplateRows: '90px 50px 45px auto',
                        }}
                        paddingY="base"
                        width={cardWidth}
                    >
                        <Box width="100%" justifyContent="center">
                            {isAxisProduct(children) && (
                                <PiaImage
                                    icon="recording"
                                    piaId={children.length > 0 ? children[0].piaId : null}
                                    imageSize="xxl"
                                />
                            )}
                            {isPartnerProduct(children) && (
                                <PartnerImage
                                    imageUrl={children[0].imageUrl}
                                    imageSize="xxl"
                                    icon="recording"
                                />
                            )}
                            {children.length === 0 && (
                                <Icon icon="recording" size="xxl" color="grey8" />
                            )}
                        </Box>
                        <Box justifyContent="center" paddingX="half">
                            <Text align="center" style="subheader">
                                {name}
                            </Text>
                        </Box>
                        <Box paddingX="base" justifyContent="center">
                            <Text
                                lineClamp={3}
                                style="small"
                                color="grey6"
                                align="center"
                                wordBreak="normal"
                            >
                                {description}
                            </Text>
                        </Box>
                        {children.length === 0 && (
                            <Box padding="base">
                                <Text
                                    color="grey5"
                                    style="small"
                                    align="center"
                                    testId="recommendations_empty_project"
                                >
                                    {t.addDevicesToGetRecommendation}
                                </Text>
                            </Box>
                        )}
                        {isAxisProduct(children) && (
                            <Box justifyContent="center" alignItems="end" paddingTop="quart">
                                <RecordingSolutionMsrp items={children} />
                            </Box>
                        )}
                    </Box>
                </Clickable>
            </Card>
            {hover && children.length > 0 && renderHoverSection(hoverContainerStyle)}
        </div>
    );
};
SolutionItemCard.displayName = 'SolutionItemCard';
