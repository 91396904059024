import * as L from 'leaflet';
import { css } from '@emotion/css';
import type { ColorsEnum } from 'app/styles';
import type { Icons } from 'app/components';

const hideOnPrint = css`
    @media print {
        display: none;
    }
`;

const mapButtonClass = css`
    font-family: 'axis-wt-icons';
    font-size: 24px;
    position: relative;
    cursor: pointer;
`;

export interface ILeafletMenuButtonControl extends L.ControlOptions {
    button: {
        icon: Icons;
        text: string;
        testId?: string;
        color?: ColorsEnum;
        disabled?: boolean;
        onClick: () => void;
    };
}

export class LeafletMenuButtonControl extends L.Control {
    private button!: HTMLElement;

    constructor(public options: ILeafletMenuButtonControl) {
        super(options);
    }

    public onAdd() {
        const container = L.DomUtil.create(
            'div',
            `leaflet-bar leaflet-container leaflet-control ${hideOnPrint}`,
        );

        this.button = this.createButton(
            container,
            this.options.button.icon,
            this.options.button.text,
            false,
            this.options.button.testId,
            this.options.button.color,
            this.options.button.disabled,
        );
        L.DomEvent.on(this.button, 'click', this.onClick, this);

        return container;
    }

    public onRemove() {
        L.DomEvent.off(this.button, 'click', this.onClick, this);
    }

    private createButton(
        container: HTMLElement,
        icon: Icons,
        text: string,
        disable?: boolean,
        dataTestId?: string,
        color?: ColorsEnum,
        disabled?: boolean,
    ) {
        const button = L.DomUtil.create(
            'a',
            `${mapButtonClass} ${disable && 'leaflet-disabled'}`,
            container,
        );

        button.textContent = icon;
        button.setAttribute('role', 'button');
        button.setAttribute('title', text);
        button.style.fontWeight = '400';
        dataTestId && button.setAttribute('data-test-id', dataTestId);

        if (disabled) {
            button.setAttribute('pointer-events', 'none');
            button.setAttribute('aria-disabled', 'true');
        }

        if (color) {
            button.style.color = color;
        }

        return button;
    }

    private onClick(event: Event) {
        L.DomEvent.stopPropagation(event);
        L.DomEvent.preventDefault(event);
        this.options.button.onClick();
    }
}
