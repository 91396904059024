import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    Border,
    Box,
    DropDown,
    DropDownMenuButton,
    DropDownMenuItem,
    GridItem,
    Icon,
    IconButton,
    NoPrint,
    Text,
} from 'app/components';
import type { ILocation } from 'app/modules/common';
import { getCurrentProjectMapLocationsArray } from 'app/modules/common';
import { MapButton, type IButtonAction } from '../map/MapButton';
import {
    getCurrentMapViewBounds,
    getDefaultGeoMap,
    getDefaultMapCurrentLocation,
    getGeolocatedFloorPlans,
    getOriginFilter,
    getShouldHaveMapOriginFilter,
} from '../../selectors';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../services';
import { calculateBoundsFromLocation } from '../../utils';
import type {
    IBounds,
    IFloorPlanEntity,
    IIdRev,
    IMapLocationEntity,
    IPersistence,
} from 'app/core/persistence';
import { FloorPlanService, MapLocationsService } from 'app/core/persistence';
import { AddLocationButton } from './AddLocationButton';
import { useInView } from 'app/hooks';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { ChangeMapNameModal } from './ChangeMapLocationNameModal';
import { FloorPlanOpacityRange } from '../opacityRange';
import { FloorplanOpacity } from './FloorplanOpacity';

interface IMapLocationsProps {
    hideAddButton?: boolean;
}

export const MapLocations: React.FC<IMapLocationsProps> = ({ hideAddButton }) => {
    const overflowingDivRef = React.useRef<HTMLDivElement>(null);
    const showAsButtons = useInView(overflowingDivRef, 1);

    const [editMapLocation, setEditMapLocation] =
        React.useState<IPersistence<IMapLocationEntity>>();
    const [floorPlanAction, setFloorPlanAction] = React.useState<{
        action: 'changeName' | 'changeOpacity';
        floorPlan: IFloorPlanEntity | undefined;
    }>();
    const mapsActionService = useService(MapsActionService);
    const mapLocationService = useService(MapLocationsService);
    const floorPlanService = useService(FloorPlanService);

    const mapLocations = useSelector(getCurrentProjectMapLocationsArray);
    const floorPlanLocations = useSelector(getGeolocatedFloorPlans);
    const defaultMap = useSelector(getDefaultGeoMap);
    const defaultMapCurrentLocation = useSelector(getDefaultMapCurrentLocation);
    const currentBounds = useSelector(getCurrentMapViewBounds);
    const originFilter = useSelector(getOriginFilter);
    const shouldHaveMapOriginFilter = useSelector(getShouldHaveMapOriginFilter);

    const goToLocation = (location: ILocation | undefined) => {
        if (!location) return;
        const bounds = calculateBoundsFromLocation(location);
        eventTracking.logUserEvent('Maps', 'Go to location');
        defaultMap?._id &&
            mapsActionService.setDesiredBounds(defaultMap._id, {
                topLeft: bounds.getNorthWest(),
                bottomRight: bounds.getSouthEast(),
            });
    };

    const goToBounds = (bounds: IBounds | undefined) => {
        if (!bounds) return;
        eventTracking.logUserEvent('Maps', 'Go to bounds');

        mapsActionService.toggleGeoLocationTool(null);

        defaultMap?._id &&
            mapsActionService.setDesiredBounds(defaultMap._id, {
                topLeft: bounds.topLeft,
                bottomRight: bounds.bottomRight,
            });
    };

    const updateToCurrentBounds = (mapLocation: IPersistence<IMapLocationEntity>) => {
        if (!defaultMapCurrentLocation) return;
        eventTracking.logUserEvent('Maps', 'Update map location to current location');
        const newMapLocation = {
            ...mapLocation,
            bounds: currentBounds ?? mapLocation.bounds,
        };

        mapLocationService.updateMapLocation(newMapLocation);
    };

    const onDelete = (id: IIdRev) => {
        eventTracking.logUserEvent('Maps', 'Delete map location');
        mapLocationService.deleteMapLocation(id);
    };

    const toggleMapOriginFilter = (mapLocation: IPersistence<IMapLocationEntity>) => {
        eventTracking.logUserEvent('Maps', 'Toggle map origin filter');
        mapsActionService.setOriginFilter(mapLocation._id);
    };

    return (
        <>
            {editMapLocation && (
                <ChangeMapNameModal
                    name={editMapLocation.name}
                    onClose={() => setEditMapLocation(undefined)}
                    onNameChange={(newName: string) => {
                        eventTracking.logUserEvent('Maps', 'Change map location name');
                        mapLocationService.updateMapLocation({
                            ...editMapLocation,
                            name: newName.trim(),
                        });
                        setEditMapLocation(undefined);
                    }}
                />
            )}
            {floorPlanAction?.floorPlan && floorPlanAction.action === 'changeName' && (
                <ChangeMapNameModal
                    name={floorPlanAction.floorPlan.name}
                    onNameChange={(newName: string) => {
                        eventTracking.logUserEvent('Maps', 'Change map location name');
                        if (floorPlanAction?.floorPlan) {
                            floorPlanService.updateFloorPlan({
                                ...floorPlanAction.floorPlan,
                                name: newName.trim(),
                            });
                        }

                        setFloorPlanAction({ action: 'changeName', floorPlan: undefined });
                    }}
                    onClose={() =>
                        setFloorPlanAction({ action: 'changeName', floorPlan: undefined })
                    }
                />
            )}
            <NoPrint>
                <Box
                    display="grid"
                    grid={{
                        gridTemplateColumns:
                            'minmax(min-content, 1fr) fit-content minmax(min-content, 1fr)',
                        gridTemplateRows: 'min-content',
                        gridTemplateAreas: '"left content right"',
                    }}
                    noPointerEvents
                >
                    <Box noPointerEvents minWidth={245} />
                    <GridItem gridArea="content">
                        <Box spacing="quart" hidden={!showAsButtons} justifyContent="center">
                            {floorPlanLocations.map((floorPlan) => (
                                <MapButton
                                    key={floorPlan._id}
                                    text={floorPlan.name}
                                    icon="floorplan_geo"
                                    onClick={() =>
                                        goToLocation(floorPlan.image?.geoLocation?.position)
                                    }
                                    dropDownContent={
                                        <DropDownMenuItem>
                                            <FloorPlanOpacityRange
                                                hideValue
                                                floorPlan={floorPlan}
                                            />
                                        </DropDownMenuItem>
                                    }
                                />
                            ))}
                            {mapLocations.map((location) => {
                                const isFiltered = originFilter === location._id;
                                return (
                                    <MapButton
                                        key={location._id}
                                        text={location.name}
                                        icon={isFiltered ? 'filter_list' : 'place'}
                                        highlight={isFiltered}
                                        actions={[
                                            {
                                                text: t.setToCurrentLocation,
                                                icon: 'move_location',
                                                onClick: () => updateToCurrentBounds(location),
                                            },
                                            {
                                                text: t.changeName,
                                                icon: 'edit',
                                                onClick: () => setEditMapLocation(location),
                                            },
                                            ...(shouldHaveMapOriginFilter
                                                ? [
                                                      {
                                                          text: t.onlyItemsOnThisLocation,
                                                          icon: 'filter_list',
                                                          selected: isFiltered,
                                                          onClick: () =>
                                                              toggleMapOriginFilter(location),
                                                      } as IButtonAction,
                                                  ]
                                                : []),
                                            {
                                                text: t.delete,
                                                icon: 'delete_forever',
                                                iconColor: 'red',
                                                onClick: () => onDelete(location),
                                            },
                                        ]}
                                        onClick={() => goToBounds(location.bounds)}
                                    />
                                );
                            })}
                            {!hideAddButton && <AddLocationButton />}
                        </Box>
                    </GridItem>

                    {/* Collapsed locations in drop down */}
                    <GridItem gridArea="content">
                        <Box
                            direction="column"
                            alignItems="center"
                            spacing="quart"
                            display={
                                showAsButtons ||
                                [...mapLocations, ...floorPlanLocations].length === 0
                                    ? 'none'
                                    : 'flex'
                            }
                        >
                            <Box justifyContent="center" spacing="half">
                                <DropDown
                                    minWidth={200}
                                    trigger={
                                        <Border
                                            width={1}
                                            radius="25px"
                                            color="grey4"
                                            hoverColor="grey6"
                                        >
                                            <Box
                                                justifyContent="between"
                                                alignItems="center"
                                                paddingY="quart"
                                                paddingX="base"
                                                color="white"
                                                hoverColor="grey2"
                                                spacing="half"
                                            >
                                                <Text
                                                    style="heading"
                                                    whiteSpace="nowrap"
                                                    color="grey7"
                                                >
                                                    {t.goToLocation}
                                                </Text>
                                                <Icon
                                                    testId="maps_drop_down_menu"
                                                    icon="keyboard_arrow_down"
                                                    size="ms"
                                                />
                                            </Box>
                                        </Border>
                                    }
                                    contents={[
                                        ...floorPlanLocations.map((floorPlan) => (
                                            <DropDownMenuButton
                                                key={floorPlan._id}
                                                icon="floorplan_geo"
                                                testId={floorPlan.name}
                                                onClick={() =>
                                                    goToLocation(
                                                        floorPlan.image?.geoLocation?.position,
                                                    )
                                                }
                                            >
                                                <Box
                                                    alignItems="center"
                                                    spacing="half"
                                                    justifyContent="between"
                                                >
                                                    <Text whiteSpace="nowrap">
                                                        {floorPlan.name}
                                                    </Text>
                                                    <Box spacing="quart" alignItems="center">
                                                        <IconButton
                                                            icon="edit"
                                                            color="blue"
                                                            size="ms"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setFloorPlanAction({
                                                                    action: 'changeName',
                                                                    floorPlan,
                                                                });
                                                            }}
                                                        />
                                                        <IconButton
                                                            icon="settings"
                                                            color="blue"
                                                            size="ms"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                goToLocation(
                                                                    floorPlan.image?.geoLocation
                                                                        ?.position,
                                                                );
                                                                setFloorPlanAction({
                                                                    action: 'changeOpacity',
                                                                    floorPlan,
                                                                });
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </DropDownMenuButton>
                                        )),
                                        ...mapLocations.map((location) => (
                                            <DropDownMenuButton
                                                key={location._id}
                                                icon="place"
                                                testId={location.name}
                                                onClick={() => goToBounds(location.bounds)}
                                            >
                                                <Box
                                                    alignItems="center"
                                                    spacing="half"
                                                    justifyContent="between"
                                                >
                                                    <Text whiteSpace="nowrap">{location.name}</Text>
                                                    <Box spacing="quart" alignItems="center">
                                                        <Box
                                                            borderRadius="round"
                                                            color="transparent"
                                                            hoverColor="blue1"
                                                            padding="quart"
                                                        >
                                                            <IconButton
                                                                icon="edit"
                                                                size="ms"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setEditMapLocation(location);
                                                                }}
                                                            />
                                                        </Box>
                                                        {shouldHaveMapOriginFilter && (
                                                            <Box
                                                                borderRadius="round"
                                                                color={
                                                                    originFilter === location._id
                                                                        ? 'blue'
                                                                        : 'transparent'
                                                                }
                                                                hoverColor="blue1"
                                                                padding="quart"
                                                            >
                                                                <IconButton
                                                                    icon="filter_list"
                                                                    color={
                                                                        originFilter ===
                                                                        location._id
                                                                            ? 'white'
                                                                            : 'blue'
                                                                    }
                                                                    size="ms"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        toggleMapOriginFilter(
                                                                            location,
                                                                        );
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                        <IconButton
                                                            icon="delete_forever"
                                                            color="red"
                                                            size="ms"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                onDelete(location);
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </DropDownMenuButton>
                                        )),
                                    ]}
                                />
                                {!hideAddButton && <AddLocationButton />}
                            </Box>
                            {floorPlanAction?.floorPlan &&
                                floorPlanAction.action === 'changeOpacity' &&
                                !showAsButtons && (
                                    <FloorplanOpacity
                                        floorPlan={floorPlanAction.floorPlan}
                                        onClose={() =>
                                            setFloorPlanAction({
                                                action: 'changeOpacity',
                                                floorPlan: undefined,
                                            })
                                        }
                                    />
                                )}
                        </Box>
                    </GridItem>

                    <Box noPointerEvents innerRef={overflowingDivRef} minWidth={245} />
                </Box>
            </NoPrint>
        </>
    );
};

MapLocations.displayName = 'MapLocations';
