import type { IInstallationPointSensorModel } from 'app/core/persistence';
import { PiaItemCameraCategory, type PiaCategory } from 'app/core/pia';
import { calculateFixedSubSensors } from './calculateFixedSubSensors';
import { calculateSemiFixedSubSensors } from './calculateSemiFixedSubSensors';
import { calculateIndependentSensors } from './calculateIndependentSensors';

type SensorDependency = 'fixed' | 'semi-fixed' | 'independent';

/**
 * Check if the sensors have a virtual sensor of the given type
 */
const hasVirtualSensorOfType = (sensors: IInstallationPointSensorModel[], type: PiaCategory) => {
    return sensors.some(
        (sensor) =>
            sensor.parentPiaDevice?.properties.virtualProductCategory?.includes(type) ?? false,
    );
};

const getSensorDependency = (sensors: IInstallationPointSensorModel[]): SensorDependency => {
    if (hasVirtualSensorOfType(sensors, PiaItemCameraCategory.THERMAL)) {
        return 'fixed';
    }

    if (hasVirtualSensorOfType(sensors, PiaItemCameraCategory.PTZ)) {
        return 'semi-fixed';
    }

    return 'independent';
};

/**
 * Calculate the sensors based on the changes to a sensor
 */
export const calculateSensors = (
    sensors: IInstallationPointSensorModel[],
    changedSensor: IInstallationPointSensorModel,
): IInstallationPointSensorModel[] => {
    const dependency = getSensorDependency(sensors);

    switch (dependency) {
        case 'fixed':
            return calculateFixedSubSensors(sensors, changedSensor);
        case 'semi-fixed':
            return calculateSemiFixedSubSensors(sensors, changedSensor);
        case 'independent':
            return calculateIndependentSensors(sensors, changedSensor);
    }
};
