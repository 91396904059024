import { AXIS_PERIMETER_DEFENDER } from 'app/core/common';
import type { IDeviceAnalyticRange } from 'app/core/persistence';
import { isSensorDevice, isCamera } from 'app/core/persistence';

import type {
    IPiaCamera,
    IPiaSpeaker,
    IPiaMainUnit,
    IPiaRadarDetector,
    IPiaVirtualProduct,
} from 'app/core/pia';
import { PiaItemCameraCategory } from 'app/core/pia';
import { UnreachableCaseError } from 'axis-webtools-util';
import { hasApdParams } from 'app/modules/common';

/**
 *
 * max limit in meters for APD weather and light conditions.
 * See https://jira.se.axis.com/browse/WT-7070
 *
 * @param analyticRange analyticRangeValues for the device
 * @param parentPiaDevice The device to get the limit for
 * @param hasExternIR True if the device has an added 'illuminator' as accessory
 * @returns max limit in meters for APD weather and light conditions. Undefined if not applicable
 */
export const getAnalyticAPDMaxValues = (
    analyticRange?: IDeviceAnalyticRange | undefined,
    parentPiaDevice?:
        | IPiaCamera
        | IPiaSpeaker
        | IPiaMainUnit
        | IPiaRadarDetector
        | IPiaVirtualProduct
        | null,
    hasExternIR?: boolean,
): number | undefined => {
    if (parentPiaDevice && analyticRange?.applicationId === AXIS_PERIMETER_DEFENDER) {
        if (
            isCamera(parentPiaDevice) &&
            hasApdParams(parentPiaDevice) &&
            analyticRange.weatherCondition &&
            analyticRange.dayCondition
        ) {
            if (parentPiaDevice.categories.includes(PiaItemCameraCategory.THERMAL)) {
                switch (analyticRange.weatherCondition) {
                    case 'clear':
                        return 600;
                    case 'fog':
                        return 400;
                    case 'heavy_fog':
                        return 200;
                    default:
                        throw new UnreachableCaseError(
                            analyticRange.weatherCondition,
                            `Unexpected weather condition for Thermal camera : ${analyticRange.weatherCondition}`,
                        );
                }
            } else {
                switch (analyticRange.dayCondition) {
                    case 'day': {
                        switch (analyticRange.weatherCondition) {
                            case 'clear':
                                return 300;
                            case 'fog':
                                return 100;
                            case 'heavy_fog':
                                return 50;
                            default:
                                throw new UnreachableCaseError(
                                    analyticRange.weatherCondition,
                                    `Unexpected weather condition for non Thermal camera with light ${analyticRange.dayCondition}: ${analyticRange.weatherCondition}`,
                                );
                        }
                    }

                    case 'night': {
                        if (hasExternIR) {
                            // if extern IR
                            switch (analyticRange.weatherCondition) {
                                case 'clear':
                                    return 80;
                                case 'fog':
                                    return 50;
                                case 'heavy_fog':
                                    return 0;
                                default:
                                    throw new UnreachableCaseError(
                                        analyticRange.weatherCondition,
                                        `Unexpected weather condition for non Thermal camera with external IR with light ${analyticRange.dayCondition}: ${analyticRange.weatherCondition}`,
                                    );
                            }
                        } else if (
                            isSensorDevice(parentPiaDevice) &&
                            parentPiaDevice.properties.IRLEDs === true
                        ) {
                            // if builtin IR
                            switch (analyticRange.weatherCondition) {
                                case 'clear':
                                    return 20;
                                case 'fog':
                                    return 0;
                                case 'heavy_fog':
                                    return 0;
                                default:
                                    throw new UnreachableCaseError(
                                        analyticRange.weatherCondition,
                                        `Unexpected weather condition for non Thermal camera with internal IR with light ${analyticRange.dayCondition}: ${analyticRange.weatherCondition}`,
                                    );
                            }
                        } else {
                            // if for some reason APD is added although missing APD props
                            return 0;
                        }
                    }
                    default:
                        throw new UnreachableCaseError(
                            analyticRange.dayCondition,
                            `Unexpected day condition for non Thermal camera ${analyticRange.dayCondition}: ${analyticRange.weatherCondition}`,
                        );
                }
            }
        } else if (isCamera(parentPiaDevice)) {
            return 0;
        }
        return undefined;
    }
};
