import type { IPiaCamera, IPiaCameraProperties, IPiaSensorUnit } from 'app/core/pia';
import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectInstallationPoints,
    getCurrentProjectItemRelationsArray,
    getCurrentProjectItems,
} from '../../project';
import { getIdFromProps } from '../../selectors';
import { toCacheKey } from '../../cacheKey';
import { mapInstallationEntityToModel } from '../mapInstallationEntityToModel';
import { toTiltAngle, isTiltableMultisensor } from '../../utils';

export const getTiltAnglesForIp = createCachedSelector(
    [
        getIdFromProps,
        getCurrentProjectInstallationPoints,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
    ],
    (installationPointId, installationPoints, currentProjectItems, cpItemRelations) => {
        if (!installationPointId || !installationPoints) return [];

        const ip = installationPoints[installationPointId];
        if (!ip) return [];

        const ipModel = mapInstallationEntityToModel(ip, currentProjectItems, cpItemRelations);
        if (
            !ipModel ||
            ipModel.parentDevice.properties.camera?.filter.panoramaMode === 'horizontal' ||
            (ipModel.parentDevice.properties.camera?.filter.panoramaMode === 'vertical' &&
                !isTiltableMultisensor(ipModel.parentPiaDevice)) ||
            ipModel.parentDevice.properties.sensorUnit?.filter.panoramaMode ||
            ipModel.parentDevice.properties.pac
        ) {
            return [];
        }

        return ipModel.sensors.map((ipSensor) => {
            const isVirtual = ipModel.parentDevice.productId !== ipSensor.parentPiaDeviceId;

            const sensorParentPiaDevice = ipSensor.parentPiaDevice;
            // how to handle if the virtual item is not a camera?
            const sensorPanoramaMode =
                isVirtual && sensorParentPiaDevice
                    ? (sensorParentPiaDevice.properties as IPiaCameraProperties).defaultPanoramaMode
                    : false;
            if (sensorPanoramaMode) {
                return 0;
            }
            const horizontalFov = ipSensor.settings.horizontalFov;
            const targetHeight = ipSensor.target.height;
            const distanceToTarget = ipSensor.target.distance;
            const corridorFormat = ipSensor.settings.corridorFormat;

            const lensItemEntity = ipModel.lenses?.find(
                (lens) => (lens.properties.lens?.sensorIndex ?? 0) === ipSensor.sensorId - 1,
            );
            const piaCamera =
                ipSensor.parentPiaDevice ??
                (ipModel.parentPiaDevice as IPiaCamera | IPiaSensorUnit);
            const lensRelation = (piaCamera?.relations ?? []).find(
                (relation) => relation.id === lensItemEntity?.productId,
            );

            const offset = ipSensor.settings.tiltOffset ?? 0;

            return toTiltAngle(
                ipModel.height,
                targetHeight,
                distanceToTarget,
                corridorFormat,
                horizontalFov,
                ipModel.parentDevice,
                piaCamera as IPiaCamera | IPiaSensorUnit,
                offset,
                lensRelation?.relationProperties,
            );
        });
    },
)(toCacheKey);
