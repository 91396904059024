import { createCachedSelector } from 're-reselect';
import { toCacheKey } from 'app/modules/common';

import { getSelectedSensorId } from './getSelectedId';
import { getSelectedInstallationPoint } from './getSelectedInstallationPoint';

export const getSelectedSensorCategory = createCachedSelector(
    [getSelectedSensorId, getSelectedInstallationPoint],
    (sensorId, installationPoint) => {
        const currentSensor = installationPoint?.sensors.find(
            (sensor) => sensor.sensorId === sensorId,
        );

        return (
            currentSensor?.parentPiaDevice?.properties?.virtualProductCategory?.[0] ??
            installationPoint?.parentPiaDevice?.category
        );
    },
)(toCacheKey);
