import { AppConstants } from 'app/AppConstants';
import type { Icons } from 'app/components';
import { Border, Box, Clickable, PiaImage, Textarea } from 'app/components';
import type { DeviceAndSubType, IItemEntity, IPersistence, Id } from 'app/core/persistence';
import { getParentId, ItemService } from 'app/core/persistence';

import { ServiceLocator, useService } from 'app/ioc';
import {
    getCurrentProjectItem,
    getDeviceAndSubTypeForItem,
    getItemColor,
    getModelName,
    getPiaLoadingIcon,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import type { Colors } from 'app/styles';
import { SpacingsEnum } from 'app/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ProjectDevicesActionService } from '../../../services';
import type { PiaId } from 'app/core/pia';

interface INameInputAndIconCol {
    itemId: Id;
    isChild?: boolean;
    isLast?: boolean;
    piaId?: PiaId | null;
    autoFocusNameInput?: boolean;
}

export const NameInputAndIconCol: React.FC<INameInputAndIconCol> = ({
    itemId,
    isChild,
    isLast,
    piaId,
    autoFocusNameInput,
}) => {
    const item = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((state) =>
        getCurrentProjectItem(state, itemId),
    );
    const actionService = useService(ProjectDevicesActionService);

    const model = useSelector<IStoreState, string>((state) => getModelName(state, itemId));
    const deviceType = useSelector<IStoreState, DeviceAndSubType | undefined>((state) =>
        getDeviceAndSubTypeForItem(state, itemId),
    );

    const parentId = isChild ? item && getParentId(item) : undefined;

    const piaLoadingIcon = useSelector<IStoreState, Icons>((state) =>
        getPiaLoadingIcon(state, itemId),
    );

    const color = useSelector<IStoreState, Colors>((state) => getItemColor(state, itemId));
    const [itemService] = React.useState<ItemService>(ServiceLocator.get(ItemService));

    const centerAlignStyle = { verticalAlign: 'middle' };

    const onNameChange = (name: string) => {
        itemService.updateItem(itemId, { name });
    };

    const onImageClick = () => {
        if (deviceType === 'door') {
            parentId && actionService.togglePanel(parentId, 'doors');
        } else if (deviceType === 'doorcontroller' && piaId) {
            actionService.togglePanel(itemId, 'doors');
        } else {
            actionService.togglePanel(itemId);
        }
    };

    const renderNameInput = () => (
        <Textarea
            noBorder
            noLineBreaks
            maxLength={AppConstants.deviceNameMaxLength}
            value={item?.name ?? ''}
            onChange={onNameChange}
            hideOverflow
            rows={2}
            autoFocus={autoFocusNameInput}
            required
            transparent
            height="40px"
        />
    );

    const renderPiaImage = () => (
        <Border radius="50%" width={3} color={color}>
            <Box
                color="white"
                padding="quart"
                width={48}
                height={48}
                minHeight={48}
                minWidth={48}
                alignItems="center"
                justifyContent="center"
                testId={`pia_image_${model}`}
            >
                <Clickable onClick={onImageClick}>
                    <PiaImage
                        testId={`td_open_${model}`}
                        icon={piaLoadingIcon}
                        piaId={item?.productId ?? null}
                        imageSize="ml"
                    />
                </Clickable>
            </Box>
        </Border>
    );

    const renderChildItem = () => (
        <>
            <td
                style={{
                    padding: SpacingsEnum.quart,
                    paddingTop: SpacingsEnum.half,
                    width: '55px',
                }}
                data-symbol={isLast ? '\u2514' : '\u251C'}
            ></td>
            <td style={centerAlignStyle}>
                <Box spacing="base" alignItems="start">
                    <Box flex="none">{renderPiaImage()}</Box>
                    <Box paddingTop={'half'}>{renderNameInput()}</Box>
                </Box>
            </td>
        </>
    );

    const renderParentItem = () => (
        <>
            <td style={{ ...centerAlignStyle, width: '55px', maxWidth: '55px' }}>
                {renderPiaImage()}
            </td>
            <td style={{ paddingTop: SpacingsEnum.base }}>{renderNameInput()}</td>
        </>
    );

    return isChild ? renderChildItem() : renderParentItem();
};

NameInputAndIconCol.displayName = 'NameInputAndIconCol';
