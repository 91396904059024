import { injectable } from 'inversify';
import { getOffset, mul, offset } from 'axis-webtools-util';
import { t } from 'app/translate';
import { ModalService } from 'app/modal';
import { toaster } from 'app/toaster';
import { eventTracking } from 'app/core/tracking';
import {
    isGeoLocated,
    CommonActionService,
    utils,
    transformBlockers,
    transformBlockerEntities,
    transformInstallationPointSensor,
    transformInstallationPointPanRange,
    transformInstallationPointSpeaker,
    transformInstallationPointRadar,
    transformBlocker,
    AXIS_HQ,
    createDerotationTransform,
} from 'app/modules/common';
import type { ILocation } from 'app/modules/common';
import type {
    Id,
    IBounds,
    ILatLng,
    IInstallationPoint,
    IInstallationPointEntity,
    IFloorPlanImage,
    IFloorPlanEntity,
    IBlockerEntity,
    IFloorPlanMapType,
    IInstallationPointModel,
    IInstallationPointSensorModel,
    IUploadImageOptions,
    PolyLine,
    IFreeTextPointEntity,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    InstallationPointRepository,
    ImageService,
    InstallationPointService,
    ItemService,
    BlockerService,
    FloorPlanService,
    ParentChildInstallationPointService,
    DuplicationService,
    FreeTextPointService,
    FloorPlanRepository,
} from 'app/core/persistence';
import type { IUploadFloorPlanOptions, INonSensorCoverageArea } from '../models';
import {
    getRotationTransform,
    getRotationAngle,
    getSelectedMapOrDefault,
    getInstallationPointsPerFloorPlan,
    getFreeTextPointsPerFloorPlan,
    getBlockersPerFloorPlan,
    getOriginFilter,
} from '../selectors';
import { throttle } from 'lodash-es';
import { AppStore } from 'app/store';
import { calculateNewBoundsForPosition } from '../utils';
import { PiaItemPacCategory } from 'app/core/pia';

const axisHQLocation = { lat: AXIS_HQ[0], lng: AXIS_HQ[1] };

@injectable()
export class MapsService {
    constructor(
        private floorPlanRepository: FloorPlanRepository,
        private floorPlanService: FloorPlanService,
        private blockerService: BlockerService,
        private installationPointService: InstallationPointService,
        private freeTextPointService: FreeTextPointService,
        private itemService: ItemService,
        private installationPointRepository: InstallationPointRepository,
        private parentChildInstallationPointService: ParentChildInstallationPointService,
        private imageService: ImageService,
        private modalService: ModalService,
        private commonActionService: CommonActionService,
        private duplicationService: DuplicationService,
        private appStore: AppStore,
    ) {}

    public async addFloorPlan(
        projectId: Id,
        options: IUploadFloorPlanOptions,
    ): Promise<IFloorPlanEntity> {
        const uploadOptions = options.uploadOptions;
        const newImage = await this.imageService.addImage(uploadOptions);

        return this.floorPlanService.addFloorPlan(projectId, {
            name: options.name,
            mapType: 'FloorPlan',
            image: {
                name: uploadOptions.file.name,
                key: newImage.key,
                dimensions: newImage.dimensions,
                opacity: 0.5,
            },
        });
    }

    public async copyFloorPlan(
        projectId: Id,
        floorPlan: IFloorPlanEntity,
        name: string,
    ): Promise<IFloorPlanEntity | undefined> {
        if (!floorPlan.image) {
            return;
        }
        const newKey = await this.imageService.copyImageByKey(floorPlan.name, floorPlan.image.key);
        if (!newKey) {
            return;
        }

        // Recalculate bounds if floor plan is geolocated
        const newBounds: IBounds | undefined =
            floorPlan.image.bounds && floorPlan.image.geoLocation
                ? calculateNewBoundsForPosition(floorPlan.image.bounds, axisHQLocation)
                : floorPlan.image.bounds;

        const addedFloorPlan = await this.floorPlanService.addFloorPlan(projectId, {
            name,
            mapType: 'FloorPlan',
            image: {
                name: `${floorPlan.image.name}`,
                key: newKey,
                dimensions: floorPlan.image.dimensions,
                opacity: floorPlan.image.opacity,
                bounds: newBounds,
                geoLocation: undefined,
            },
        });
        const blockers = getBlockersPerFloorPlan(this.appStore.Store.getState())[floorPlan._id];

        if (blockers) {
            const newBlockers = this.getBlockersForCopy(floorPlan, blockers);
            Promise.all(
                Object.values(newBlockers).map((blocker) =>
                    this.blockerService.addBlocker(projectId, addedFloorPlan._id, blocker.latLngs, {
                        global: false,
                    }),
                ),
            );
        }

        return addedFloorPlan;
    }

    public async changeFloorPlan(
        floorPlan: IFloorPlanMapType,
        options: IUploadImageOptions,
    ): Promise<IFloorPlanEntity> {
        const newImage = await this.imageService.addImage(options);
        eventTracking.logUserEvent('Maps', 'Change floor plan', `File size: ${options.file.size}`);

        if (!newImage) {
            return floorPlan;
        }

        // Need to reset image bounds if aspect ratio differ from previous image
        const hasSameAspectRatio = this.hasSameAspectRatio(
            floorPlan.image.dimensions,
            newImage.dimensions,
        );

        const updatedImage: IFloorPlanImage = {
            ...floorPlan.image,
            name: options.file.name,
            key: newImage.key,
            dimensions: newImage.dimensions,
            bounds: hasSameAspectRatio ? floorPlan.image.bounds : undefined,
        };
        const updatedFloorPlan: IFloorPlanMapType = {
            ...floorPlan,
            image: updatedImage,
        };

        // Await so quota calculation is correct - not really interested in the result.
        const updateResult = await this.floorPlanService.updateFloorPlan(updatedFloorPlan);
        await this.imageService.deleteImage(floorPlan.image.key, false);
        return updateResult;
    }

    public async addStreetMap(
        projectId: Id,
        name: string,
        location: ILocation,
        isCopy?: boolean,
        isDefault?: boolean,
    ): Promise<IFloorPlanEntity> {
        eventTracking.logUserEvent('Maps', isCopy ? 'Copy street map' : 'Add street map');
        return this.floorPlanService.addFloorPlan(projectId, {
            name,
            mapType: 'StreetMap',
            location,
            isDefault,
        });
    }

    public async removeFloorPlan(floorPlan: IFloorPlanEntity) {
        const remove = await this.modalService.createConfirmDialog({
            header: t.deleteHeader(floorPlan.name),
            body: '',
            confirmButtonText: t.deleteMap,
            cancelButtonText: t.cancel,
        })();
        if (remove) {
            eventTracking.logUserEvent('Maps', 'Remove floor plan');

            // determine which installation points, free text points and blockers to remove
            // when removing this floor plan
            const state = this.appStore.Store.getState();

            const installationPointMap = getInstallationPointsPerFloorPlan(state);
            const freeTextPointMap = getFreeTextPointsPerFloorPlan(state);
            const blockerMap = getBlockersPerFloorPlan(state);

            const installationPoints = installationPointMap[floorPlan._id] ?? [];
            const freeTextPoints = freeTextPointMap[floorPlan._id] ?? [];
            const blockers = blockerMap[floorPlan._id] ?? [];

            // delete the installation points, free text points and blockers belonging to this floor plan
            await this.installationPointService.removeInstallationPoints(installationPoints);
            await this.freeTextPointService.removeFreeTextPoints(freeTextPoints);
            await this.blockerService.removeBlockers(blockers);

            // delete the floor plan
            const deletedId = await this.floorPlanService.removeFloorPlan(floorPlan);
            this.commonActionService.getUserImageQuota();
            return deletedId;
        }
        return Promise.resolve();
    }

    public async setFloorPlanLocation(
        floorPlan: IFloorPlanEntity,
        location: ILatLng,
    ): Promise<void> {
        if (!FloorPlanService.isFloorPlanMapType(floorPlan)) {
            return;
        }

        await this.floorPlanService.updateFloorPlanGeolocation(floorPlan._id, location);
    }

    public async rescale(
        floorPlan: IFloorPlanEntity,
        blockers: IBlockerEntity[],
        installationPoints: IInstallationPointEntity[],
        freeTextPoints: IFreeTextPointEntity[],
        factor: number,
    ): Promise<void> {
        if (!FloorPlanService.isFloorPlanMapType(floorPlan)) {
            return;
        }

        const bounds =
            floorPlan.image.bounds ?? utils.getFloorPlanImageTemporaryBounds(floorPlan.image);

        const centerPoint = this.getCenterPoint(bounds);

        // helper functions
        const getOffsetFromCenter = getOffset(centerPoint);
        const offsetFromCenter = offset(centerPoint);
        const rescaleFromCenter = (latLng: ILatLng) =>
            offsetFromCenter(mul(getOffsetFromCenter(latLng), factor));
        const rescaleArrayFromCenter = (arr: PolyLine) => arr.map(rescaleFromCenter);

        // rescale floor plan (and old blockers)
        const floorPlanUpdatedPromise = this.rescaleFloorPlan(floorPlan, rescaleFromCenter, bounds);

        // rescale blockers
        const blockerUpdatedPromise = this.rescaleBlockers(blockers, rescaleArrayFromCenter);

        // rescale all installation points
        const installationPointsUpdatedPromise = this.rescaleInstallationPoints(
            installationPoints,
            rescaleFromCenter,
            factor,
        );

        // rescale all free text points
        const freeTextPointsUpdatedPromise = this.rescaleFreeTextPoints(
            freeTextPoints,
            rescaleFromCenter,
        );

        return Promise.all([
            floorPlanUpdatedPromise,
            blockerUpdatedPromise,
            installationPointsUpdatedPromise,
            freeTextPointsUpdatedPromise,
        ]).then();
    }

    public async addFreeTextMapItem(map: IFloorPlanEntity, location?: ILatLng) {
        if (location) {
            const state = this.appStore.Store.getState();
            const transform = getRotationTransform(state);
            const originFilter = getOriginFilter(state);
            const geoLocated = isGeoLocated(map);
            const mapOrigin = originFilter ?? map._id;
            const projectId = map.path[0];
            this.freeTextPointService.add(mapOrigin, projectId, transform(location), {
                global: geoLocated,
            });
        }
    }

    /**
     * Add new blocker entities to map or project
     * @param blockers - Array of blocker coordinates to add
     * @returns Promise of array of added blockers
     */
    public async addBlockers(blockers: PolyLine[]): Promise<IBlockerEntity[]> {
        const state = this.appStore.Store.getState();
        const transform = getRotationTransform(state);
        const map = getSelectedMapOrDefault(state);
        const originFilter = getOriginFilter(state);

        if (!map) return [];

        const geoLocated = isGeoLocated(map);
        const projectId = map.path[0];

        // Remove empty blockers
        const noEmptyBlockers = blockers.filter((blocker) => blocker.length);
        // Rotate blockers to real world coordinates
        const rotatedBlockers = transformBlockers(transform, undefined, noEmptyBlockers);

        const mapOrigin = originFilter ?? map._id;

        // if map is geolocated, make blockers global
        return Promise.all(
            rotatedBlockers.map((blocker) =>
                this.blockerService.addBlocker(projectId, mapOrigin, blocker, {
                    global: geoLocated,
                }),
            ),
        );
    }

    /**
     * Remove blockers from map or project
     * @param blockerIds - Array of blocker ids to remove
     * @returns Promise of array of removed blocker ids
     */
    public async deleteBlockers(blockerIds: Id[]): Promise<Id[]> {
        return Promise.all(
            blockerIds.map((blockerId) => this.blockerService.removeBlocker(blockerId)),
        );
    }

    /**
     * Update blocker entities
     * @param blockers - Array of blocker entities to update
     * @returns Promise of array of updated blocker entities
     */
    public async updateBlockers(blockers: IBlockerEntity[]): Promise<IBlockerEntity[]> {
        const state = this.appStore.Store.getState();
        const transform = getRotationTransform(state);

        // Rotate blockers to real world coordinates
        const rotatedBlockers = transformBlockerEntities(transform, blockers);

        return Promise.all(
            rotatedBlockers.map((blocker) => this.blockerService.updateBlocker(blocker)),
        );
    }

    public async setDismissRadarWarning(floorPlanId: Id): Promise<IFloorPlanEntity> {
        return this.floorPlanService.setDismissRadarWarning(floorPlanId);
    }

    public async setUnDismissRadarWarning(floorPlanId: Id): Promise<IFloorPlanEntity> {
        const floorPlan = await this.floorPlanRepository.get(floorPlanId);
        if (floorPlan && floorPlan.isRadarWarningDismissed) {
            return this.floorPlanService.setUnDismissRadarWarning(floorPlanId);
        }
        return floorPlan;
    }

    public setUnDismissRadarWarningThrottled = throttle(this.setUnDismissRadarWarning, 1000);

    public async duplicateMapItem(
        installationPoint: IInstallationPointModel,
        newInstance: boolean,
        placedInstallationPoints: number,
    ) {
        try {
            let newId: Id;

            if (newInstance) {
                const newItems = await this.duplicationService.copyItem(
                    installationPoint.parentDevice._id,
                    1,
                );
                newId = await this.installationPointService.duplicateInstallationPointToDevice(
                    installationPoint._id,
                    newItems[0]._id,
                    installationPoint.name ?? '',
                    installationPoint.location,
                );
            } else {
                // We only need to update qty if all ip:s already placed on map
                if (placedInstallationPoints >= installationPoint.parentDevice.quantity) {
                    await this.itemService.updateItem(installationPoint.parentDevice._id, {
                        quantity: placedInstallationPoints + 1,
                    });
                }
                newId = await this.installationPointService.duplicateInstallationPoint(
                    installationPoint._id,
                    installationPoint.parentId,
                    installationPoint.location,
                );
            }

            if (!this.isParentMapItem(installationPoint)) return newId;

            // Create children
            const oldEntity = await this.installationPointRepository.get(installationPoint._id);
            const newEntity = await this.installationPointRepository.get(newId);

            await this.parentChildInstallationPointService.createChildrenAfterDuplicate(
                oldEntity,
                newEntity,
            );

            return newId;
        } catch (error) {
            console.error(error);
            toaster.error(t.projectDuplicateErrorHeader, t.mapItemDuplicationErrorMsg);
            return undefined;
        }
    }

    /**
     * Checks if it is ok to duplicate a mapItem (increase quantity)
     * Returns true, false or null if not applicable, since we don't want toaster to show.
     */
    public async canDuplicateMapItem(
        installationPoint: IInstallationPointModel,
        placedInstallationPoints: number,
        projectLocked: boolean,
        newInstance: boolean,
    ): Promise<boolean | null> {
        const device = installationPoint.parentDevice;
        if (projectLocked && (newInstance || placedInstallationPoints >= device.quantity)) {
            return false;
        }

        if (
            device.properties.camera ||
            device.properties.mainUnit ||
            device.properties.encoder ||
            device.properties.speaker ||
            device.properties.radarDetector ||
            device.properties.dockingStation ||
            device.properties.doorController ||
            device.properties.decoder ||
            (device.properties.pac &&
                installationPoint.parentPiaDevice?.category !==
                    PiaItemPacCategory.RELAYEXPMODULES) ||
            device.properties.alerter ||
            device.properties.systemController ||
            device.properties.peopleCounter ||
            device.properties.connectivityDevice ||
            device.properties.pagingConsole
        ) {
            return true;
        } else if (
            device.properties.sensorUnit ||
            device.properties.analogCamera ||
            device.properties.door ||
            installationPoint.parentPiaDevice?.category === PiaItemPacCategory.RELAYEXPMODULES
        ) {
            // We don't support drag-copy operations of child items
            return null;
        } else {
            throw new Error(`Device not supported: ${device}`);
        }
    }

    public async saveFloorPlanGeolocation(
        floorPlanId: Id,
        position: ILatLng,
        width: number,
        height: number,
        angle: number,
    ): Promise<void> {
        await this.floorPlanService.updateFloorPlanGeolocation(
            floorPlanId,
            position,
            width,
            height,
            angle,
        );
    }

    public updateInstallationPointLabelOffset(
        installationPointId: Id,
        labelOffset: IInstallationPoint['labelOffset'],
    ) {
        return this.installationPointService.updateLabelOffset(installationPointId, labelOffset);
    }

    /** Updates a single sensor for an installation point. */
    public updateSensor(installationPointId: Id, sensor: IInstallationPointSensorModel) {
        const state = this.appStore.Store.getState();
        const transform = getRotationTransform(state);
        const angle = getRotationAngle(state);

        return this.installationPointService.updateSensor(
            installationPointId,
            transformInstallationPointSensor(transform, angle, sensor),
        );
    }

    public updateSensors(installationPointId: Id, sensors: IInstallationPointSensorModel[]) {
        const state = this.appStore.Store.getState();
        const transform = getRotationTransform(state);
        const angle = getRotationAngle(state);

        return this.installationPointService.updateSensors(
            installationPointId,
            sensors.map((sensor) => transformInstallationPointSensor(transform, angle, sensor)),
        );
    }

    public updateCoverageArea(installationPointId: Id, area: INonSensorCoverageArea) {
        const state = this.appStore.Store.getState();
        const angle = getRotationAngle(state);

        return this.installationPointService.updateCoverageArea(installationPointId, {
            panRange: area.panRange && transformInstallationPointPanRange(angle, area.panRange),
            speaker: area.speaker && transformInstallationPointSpeaker(angle, area.speaker),
            radar: area.radar && transformInstallationPointRadar(angle, area.radar),
        });
    }

    public async updateFreeTextPointLocation(id: Id, location: ILatLng) {
        const state = this.appStore.Store.getState();
        const transform = getRotationTransform(state);

        return this.freeTextPointService.update(id, {
            location: transform(location),
        });
    }

    public getCenterPoint(bounds: IBounds) {
        return offset(bounds.topLeft)(mul(getOffset(bounds.topLeft)(bounds.bottomRight), 0.5));
    }

    private getBlockersForCopy(floorPlan: IFloorPlanEntity, blockers: IBlockerEntity[]) {
        // if floor plan is not geolocated, no need to transform blockers
        if (!floorPlan.image?.geoLocation) return blockers;

        const transform = createDerotationTransform(floorPlan, axisHQLocation);

        const newBlockers = blockers.map((blocker) => {
            const newBlocker = {
                ...blocker,
                latLngs: transformBlocker(transform, blocker.latLngs),
            };
            return newBlocker;
        });
        return newBlockers;
    }

    private isParentMapItem = (installationPoint: IInstallationPointModel) =>
        deviceTypeCheckers.isMainUnit(installationPoint.parentDevice) ||
        deviceTypeCheckers.isEncoder(installationPoint.parentDevice) ||
        deviceTypeCheckers.isDoorController(installationPoint.parentDevice) ||
        (deviceTypeCheckers.isPac(installationPoint.parentDevice) &&
            installationPoint.parentPiaDevice?.category === PiaItemPacCategory.IORELAYS);

    /** Rescales bounds (and old blockers) for the specified floor plan */
    private rescaleFloorPlan(
        floorPlan: IFloorPlanMapType,
        rescaleFromCenter: (latLng: ILatLng) => { lat: number; lng: number },
        bounds: IBounds,
    ) {
        // rescale bounds
        const rescaledBounds: IBounds = {
            topLeft: rescaleFromCenter(bounds.topLeft),
            bottomRight: rescaleFromCenter(bounds.bottomRight),
        };

        const updatedFloorPlan: IFloorPlanMapType = {
            ...floorPlan,
            image: {
                ...floorPlan.image,
                bounds: rescaledBounds,
            },
        };

        // update floorplan
        return this.floorPlanService.updateFloorPlan(updatedFloorPlan);
    }

    /** Rescales the specified blockers */
    private rescaleBlockers(
        blockers: IBlockerEntity[],
        rescaleArrayFromCenter: (arr: PolyLine) => { lat: number; lng: number }[],
    ) {
        const rescaledBlockers = blockers.map((blocker) => {
            const rescaledLatLang = rescaleArrayFromCenter(blocker.latLngs);
            return {
                ...blocker,
                latLngs: rescaledLatLang,
            };
        });

        return this.blockerService.updateBlockers(rescaledBlockers);
    }

    /** Rescales the specified installation points */
    private rescaleInstallationPoints(
        installationPoints: IInstallationPointEntity[],
        rescaleFromCenter: (latLng: ILatLng) => { lat: number; lng: number },
        factor: number,
    ) {
        return Promise.all(
            installationPoints.map((installationPoint) => {
                // recalculate location
                const location = rescaleFromCenter(installationPoint.location);

                // recalculate distance to target
                const sensors = installationPoint.sensors.map((sensor) => ({
                    ...sensor,
                    target: {
                        ...sensor.target,
                        distance: sensor.target.distance * factor,
                    },
                }));

                const updatedInstallationPoint: IInstallationPoint = {
                    ...installationPoint,
                    location,
                    sensors,
                };

                return this.installationPointService.updateInstallationPoint(
                    installationPoint._id,
                    updatedInstallationPoint,
                );
            }),
        );
    }

    /** Rescales the specified free text points */
    private rescaleFreeTextPoints(
        freeTextPoints: IFreeTextPointEntity[],
        rescaleFromCenter: (latLng: ILatLng) => { lat: number; lng: number },
    ) {
        const freeTextPointsWithNewLocation = freeTextPoints.map((freeTextPoint) => {
            // recalculate location
            const newLocation = rescaleFromCenter(freeTextPoint.location);

            return {
                ...freeTextPoint,
                location: newLocation,
            } as IFreeTextPointEntity;
        });

        return this.freeTextPointService.updateFreeTextPoints(freeTextPointsWithNewLocation);
    }

    private hasSameAspectRatio(
        a: { width: number; height: number },
        b: { width: number; height: number },
    ) {
        return Math.abs(a.width / a.height - b.width / b.height) < 0.01;
    }
}
