import type { Icons } from 'app/components';
import {
    Paginator,
    ActionSelect,
    Box,
    Checkbox,
    Heading,
    PiaImage,
    PrintPanel,
    SectionHeader,
    Stack,
    Text,
    Border,
} from 'app/components';
import type {
    Id,
    IItemEntity,
    IPersistence,
    IProjectNetworkSettings,
    MapType,
    IInstallationPointEntity,
} from 'app/core/persistence';
import { isCustomCamera, deviceTypeCheckers, getDeviceType } from 'app/core/persistence';
import type { IPiaItem } from 'app/core/pia';
import { eventTracking } from 'app/core/tracking';
import { ServiceLocator } from 'app/ioc';
import {
    getCurrentProjectCustomCameras,
    getCurrentProjectNetworkSettings,
    getIsLocalProject,
    getItemIcons,
    getPiaItemsRecord,
    getUserSignedIn,
} from 'app/modules/common';
import {
    MiniMap,
    getInstallationPointsForDeviceSortByCreationFactory,
    getInstallationPointsOnFloorPlansSortByCreationFactory,
} from 'app/modules/maps';
import type { IStoreState } from 'app/store';
import { t } from 'app/translate';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ReportHeader } from '../../components';
import { itemsPerPage } from '../../utils/itemsPerPage';
import type { IInstallationReportSortBy } from '../models';
import type { IItemWithChildren } from '../selectors';
import { getSortedCurrentProjectDevices } from '../selectors';
import { InstallationReportActionService } from '../services';
import { NetworkTable } from './common';
import { InstallationDetails } from './common/InstallationDetails';
import { InstallationNotes } from './InstallationNotes';
import { ProjectSummary } from './projectSummary/';
import { ReportItem, SpeakerReportItem } from './reportItems';
import { Schedules } from './schedules';
import { StorageAndBandwidth } from './storageAndBandwidth';
import { InstallationDetailsLocation } from './common/InstallationDetailsLocation';

export const InstallationReport: React.FC = () => {
    const installationReportActionService = ServiceLocator.get(InstallationReportActionService);

    const isSignedIn = useSelector<IStoreState, boolean | undefined>(getUserSignedIn);
    const isLocalProject: boolean = useSelector(getIsLocalProject);
    const sortBy = useSelector<IStoreState, string>((state) => state.installationReport.sortBy);
    const getType = (device: IPersistence<IItemEntity>) => getDeviceType(device);
    const showMiniMap = isSignedIn || isLocalProject;

    const devices = useSelector<IStoreState, IItemWithChildren[]>(getSortedCurrentProjectDevices);
    const customCameras = useSelector<IStoreState, IPersistence<IItemEntity>[]>(
        getCurrentProjectCustomCameras,
    );

    const piaItemsRecord = useSelector<IStoreState, Record<number, IPiaItem>>(getPiaItemsRecord);

    const itemIcons = useSelector<IStoreState, Record<Id, Icons>>(getItemIcons);

    interface IInstallationPointEntityWithMapType extends IInstallationPointEntity {
        mapType: MapType;
    }

    const getInstallationPointsOnAllMapsForDeviceSortByCreation = useSelector<
        IStoreState,
        (deviceId: string) => IInstallationPointEntityWithMapType[]
    >(getInstallationPointsOnFloorPlansSortByCreationFactory);

    const installationPointForDevice = useSelector(
        getInstallationPointsForDeviceSortByCreationFactory,
    );

    const [printOnePage, setPrintOnePage] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const itemCount = [...devices, ...customCameras].length;
    const pageCount = Math.ceil(itemCount / itemsPerPage);

    const onPrint = () => {
        eventTracking.logUserEvent('Reports', 'Print', 'Installation Report');
        eventTracking.pushToGA4('SiteDesignerClick', 'Print', 'Installation Report');
    };

    const onSortingChanged = (sortProperty: IInstallationReportSortBy) => {
        eventTracking.logUserEvent('Reports', 'Change Sort Order', sortProperty);
        installationReportActionService.setSortOrder(sortProperty);
    };

    const onOneSideChanged = () => {
        setPrintOnePage(!printOnePage);
    };

    const getSelectedSorting = () => {
        const selectedSorting = sortOptions.find((sort) => sort.value === sortBy);

        if (selectedSorting === undefined) {
            throw Error(`Selected sorting: ${sortBy} not found in options`);
        }

        return selectedSorting;
    };

    const projectNetworkSettings: IProjectNetworkSettings | undefined = useSelector(
        getCurrentProjectNetworkSettings,
    );
    const displayInstallationDetails = (device: IItemWithChildren | IPersistence<IItemEntity>) => {
        return Boolean(
            deviceTypeCheckers.isCamera(device) ||
                deviceTypeCheckers.isSpeaker(device) ||
                deviceTypeCheckers.isSensorUnit(device),
        );
    };

    const renderDeviceContent = (
        device: IItemWithChildren | IPersistence<IItemEntity>,
        ipParentId?: string | undefined,
        deviceName?: string | undefined,
        deviceQuantityIndex?: number,
    ): JSX.Element | null => {
        const deviceType = getType(device);
        switch (deviceType) {
            case 'camera':
            case 'analogCamera':
            case 'decoder':
            case 'doorstation':
            case 'pac':
            case 'radardetector':
            case 'sensorUnit':
            case 'peopleCounter':
            case 'bodyWornCamera':
            case 'systemController':
            case 'dockingStation':
            case 'cameraExtension':
            case 'alerter':
            case 'door':
            case 'encoder':
            case 'mainUnit':
            case 'doorcontroller':
                return (
                    <>
                        <ReportItem
                            id={device._id}
                            deviceQuantityIndex={deviceQuantityIndex}
                            isBodyWorn={deviceTypeCheckers.isBodyWornCamera(device)}
                            isDoor={deviceTypeCheckers.isDoor(device)}
                        />
                        {'children' in device &&
                            device.children
                                .filter((child) => {
                                    const isAnalogCamera = deviceTypeCheckers.isAnalogCamera(child);
                                    const isDoor = deviceTypeCheckers.isDoor(child);
                                    const isGeneric = child.productId === null;

                                    return !isGeneric || isAnalogCamera || isDoor;
                                })
                                .map(
                                    renderChildDevice(
                                        deviceName ?? device.name,
                                        ipParentId,
                                        device.children.length,
                                    ),
                                )}
                    </>
                );
            case 'speaker':
                return (
                    <SpeakerReportItem id={device._id} deviceQuantityIndex={deviceQuantityIndex} />
                );
            default:
                return null;
        }
    };

    const sortOptions = [
        { text: t.sortingGROUP.name, value: 'byName' },
        { text: t.sortingGROUP.model, value: 'byModel' },
    ];

    const renderSortingSelect = () => {
        return (
            <Stack>
                {pageCount > 1 && (
                    <Paginator
                        pageCount={pageCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                )}
                <Checkbox
                    slider
                    selected={printOnePage}
                    onChange={onOneSideChanged}
                    testId={'one_page_per_device_checkbox'}
                >
                    {t.installationReportToggleOneDevice}
                </Checkbox>
                <ActionSelect
                    label={t.sortingGROUP.label}
                    selected={getSelectedSorting()}
                    options={sortOptions}
                    onChange={onSortingChanged}
                />
            </Stack>
        );
    };
    const renderChildDevice =
        (parentName: string, ipParentId?: string | undefined, childQuantity?: number) =>
        (device: IPersistence<IItemEntity>, index: number) => {
            return renderDeviceQuantity(device, parentName, ipParentId, index, childQuantity);
        };

    const renderDeviceQuantity = (
        device: IItemWithChildren | IPersistence<IItemEntity>,
        parentName?: string,
        ipParentId?: string | undefined,
        childIndex?: number,
        childQuantity?: number,
        firstDevice?: boolean,
    ) => {
        // get the installation points for the device that match the parent id if it is provided
        const ipsForDevice = installationPointForDevice(device._id).filter(
            ({ parentId }) => !ipParentId || parentId === ipParentId,
        );

        if (printOnePage && device.quantity > 1) {
            const deviceName = device.name;
            const itemsToRender = [];

            for (let index = 0; index < device.quantity; index++) {
                const installationPoint = ipsForDevice[index];
                const ipName = installationPoint?.name ? ` - ${installationPoint.name}` : '';
                const addedProperties = {
                    ipId: installationPoint?._id ?? 'unplaced',
                    nameWithNumber: `(${index + 1}/${device.quantity}) ${deviceName}${ipName}`,
                };
                const itemToAdd = { device, addedProperties };
                itemsToRender.push(itemToAdd);
            }

            return itemsToRender.map((item, index) =>
                renderDevice(
                    item.device,
                    parentName,
                    item.addedProperties.ipId,
                    `${item.device._id}+${index}`,
                    item.addedProperties.nameWithNumber,
                    index,
                    index === 0 && firstDevice,
                ),
            );
        } else if (printOnePage && ipParentId && childIndex !== undefined) {
            // render correct child unit

            const ipToRender = ipsForDevice[childIndex];
            const ipName = ipToRender?.name ? ` - ${ipToRender.name}` : '';
            const childName = `${device.name} (${childIndex + 1}/${childQuantity})${ipName}`;
            return renderDevice(
                device,
                parentName,
                ipToRender?._id,
                device._id,
                childName,
                childIndex,
                firstDevice,
            );
        } else {
            // if the device has any installation point on a floor plan, render the miniMap with all ip:s

            const installationPoints = installationPointForDevice(device._id);
            const installationPoint = installationPoints[0];

            // if there is only one installation point, add the name to the device
            const ipName =
                installationPoint && installationPoints.length === 1 && installationPoint.name
                    ? ` - ${installationPoint.name}`
                    : '';

            return renderDevice(
                device,
                parentName,
                installationPoint?._id,
                device._id,
                `${device.name}${ipName}`,
                undefined,
                firstDevice,
            );
        }
    };

    const renderDevice = (
        device: IItemWithChildren | IPersistence<IItemEntity>,
        parentName?: string,
        ipId?: string | undefined,
        key?: string,
        deviceName?: string,
        deviceQuantityIndex?: number,
        firstDevice?: boolean,
    ): JSX.Element => {
        const idForName = device.replaceWithBareboneId ?? device.productId;
        const piaItemName = idForName
            ? piaItemsRecord[idForName].name
            : deviceTypeCheckers.isAnalogCamera(device)
              ? t.devicesGROUP.analogCamera
              : '';

        const customCameraName = isCustomCamera(device)
            ? device.properties.camera.customCameraProperties.modelName
            : undefined;

        const modelName = customCameraName ?? piaItemName;

        const ipsPlacedOnFloorPlanMap = getInstallationPointsOnAllMapsForDeviceSortByCreation(
            device._id,
        );
        const isFloorPlanMap = ipsPlacedOnFloorPlanMap.find(
            (ip) => ip._id === ipId && ip.mapType === 'FloorPlan',
        );

        return (
            <Box
                key={key ? key : device._id}
                display="block"
                paddingTop="base"
                pageBreakBefore={currentPage === 1 || !firstDevice}
            >
                <SectionHeader
                    testId={'installation_report_item_header'}
                    text={
                        !printOnePage && device.quantity > 1
                            ? `${device.quantity} × ${device.name}`
                            : deviceName
                              ? deviceName
                              : device.name
                    }
                    breadCrumb={parentName}
                />
                <Box direction={'column'} paddingY={'panel'} spacing="panel">
                    <Box spacing="panel" alignItems="center">
                        <PiaImage
                            icon={itemIcons[device._id]}
                            piaId={device.productId}
                            imageSize="xxl"
                        />
                        <Box direction="column">
                            <Heading style="title">{modelName}</Heading>
                            <Text style="caption">{device.description}</Text>
                            <Text style="caption">{device.notes}</Text>
                        </Box>
                    </Box>
                    {(printOnePage || device.quantity === 1) && (
                        <Border
                            color="grey3"
                            width={0}
                            sideColor={{ top: 'yellow' }}
                            topWidth={2}
                            bottomWidth={1}
                        >
                            <Box
                                spacing="panel"
                                direction="row"
                                paddingX="panel"
                                paddingBottom={'base'}
                            >
                                {displayInstallationDetails(device) ? (
                                    <InstallationDetails
                                        installationPointId={ipId}
                                        itemId={device._id}
                                        modelName={modelName}
                                    />
                                ) : (
                                    <InstallationDetailsLocation
                                        installationPointId={ipId}
                                        modelName={modelName}
                                    />
                                )}
                            </Box>
                        </Border>
                    )}
                </Box>
                {showMiniMap && (
                    <Box>
                        {isFloorPlanMap && (
                            <MiniMap
                                testId={`mini-map-installation-report`}
                                showName
                                deviceIds={[device._id]}
                                ipId={printOnePage ? ipId : undefined}
                                noBorder
                            />
                        )}
                    </Box>
                )}
                {renderDeviceContent(device, ipId, deviceName, deviceQuantityIndex)}
            </Box>
        );
    };

    return (
        <PrintPanel
            testId="panel_reports_installation_report_panel"
            onPrint={onPrint}
            buttons={renderSortingSelect()}
            printable
        >
            {currentPage === 1 && (
                <>
                    <ReportHeader title={t.projectReportsInstallationReportHeading} />
                    <InstallationNotes />
                    <ProjectSummary />
                </>
            )}

            {[...devices, ...customCameras]
                .slice((currentPage - 1) * itemsPerPage, itemsPerPage * currentPage)
                .map((device, index) =>
                    renderDeviceQuantity(
                        device,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        index === 0,
                    ),
                )}
            {currentPage === pageCount && (
                <>
                    <StorageAndBandwidth />
                    <Schedules />
                    {projectNetworkSettings && <NetworkTable />}
                </>
            )}
        </PrintPanel>
    );
};
