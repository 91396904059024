import { convert } from 'axis-webtools-util';
import { DistanceUnit } from '../persistence/models/DistanceUnit';

/**
 * Convert pixeldensity to px/m - input pixeldensity can be px/m or px/ft
 *
 * calculation for feet density * convert.metersToFeet(1) same as convert.metersToFeet(density)
 * @param density the pixel density in px/m or px/ft
 * @param displayUnit the unit you want the density to be converted from (DistanceUnit.Meter ir DistanceUnit.Feet)
 * @returns pixeldensity in px/m
 */
export const convertDensityToMeters = (density: number, displayUnit: DistanceUnit) => {
    if (displayUnit === DistanceUnit.Feet) {
        return convert.metersToFeet(density);
    }
    return density;
};

/**
 * Convert pixeldensity to desired unit px/m or px/ft Note the input density is always in px/m
 *
 * px/ft = density / convert.metersToFeet(1) same as convert.feetToMeters(density)
 * @param density the pixel density in px/m
 * @param displayUnit the unit you want the density to be converted to (DistanceUnit.Meter ir DistanceUnit.Feet)
 * @returns pixeldensity in px/m or px/ft
 */
export const convertDensityToDisplayUnit = (density: number, displayUnit: DistanceUnit) => {
    if (displayUnit === DistanceUnit.Feet) {
        return Math.round(convert.feetToMeters(density));
    }
    return Math.round(density);
};
