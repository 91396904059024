import { defaultColors } from 'app/core/common';
import { EventEmitter } from 'events';
import * as leaflet from 'leaflet';
import type { IBounds, ILatLng, PolyLine, UnitSystem } from 'app/core/persistence';
import { ColorsEnum } from 'app/styles';
import { css } from '@emotion/css';
import { clientIsWindowsOs } from 'app/modules/common';
import { debounce } from 'lodash-es';
import { GoogleTileLayer } from './GoogleTileLayer';
import type { MapsActionService } from '../../services';
import { DEFAULT_GOOGLE_MAP_ID } from '../../constants';

const MAX_STREET_ZOOM_LEVEL = 20.2;
const BOUNDS_PADDING_RATIO = 0.15; // extending bounds by 15%
const METER_DISTANCE_FLY_TO_LIMIT = 5000; // distance in m
const TIME_SECONDS_FLY_TO = 2; // time in seconds

const animationStyle = css`
    transition:
        stroke 150ms ease-in-out,
        stroke-width 200ms ease-in-out;
`;

export interface ILine {
    begin: leaflet.LatLng;
    end: leaflet.LatLng;
}

export class LeafletMap {
    /**
     * Our Leaflet map instance
     */
    public map: leaflet.Map;
    /**
     * The Leaflet feature group containing our blockers
     */
    public blockersFeatureGroup: leaflet.FeatureGroup;
    /**
     * The Leaflet feature group containing image error loading info
     */
    private infoFeatureGroup: leaflet.FeatureGroup;

    private scaleIndicator: leaflet.Control.Scale;
    private googleTiles: GoogleTileLayer;
    private hasMapMouseDown: boolean = false;
    private emitter = new EventEmitter();

    constructor(
        private mapElement: HTMLElement,
        private onZoomLevelChanged: (level: number) => void,
        private onLocationChanged: (location: ILatLng) => void,
        private mapsActionService: MapsActionService,
        private unitSystem: UnitSystem,
        /** Removes map controls except zoom +/-, scale to fit, toggle labels and toggle DORI */
        public readOnly: boolean = false,
        googleMapDivId = DEFAULT_GOOGLE_MAP_ID,
    ) {
        this.blockersFeatureGroup = new leaflet.FeatureGroup();
        this.infoFeatureGroup = new leaflet.FeatureGroup();
        this.googleTiles = new GoogleTileLayer('roads', googleMapDivId);

        this.scaleIndicator = leaflet.control.scale({
            imperial: this.unitSystem === 'imperial',
            metric: this.unitSystem === 'metric',
        });

        this.map = leaflet.map(mapElement, {
            zoomControl: false,
            zoomAnimation: false,
            fadeAnimation: false,
            attributionControl: true,
            boxZoom: false,
            zoomDelta: 0.1,
            zoomSnap: 0.1,
            wheelPxPerZoomLevel: 300,
        });

        // Remove default 'leaflet' attribution
        this.map.attributionControl?.setPrefix('');
        this.map.attributionControl?.addAttribution(' '); // Should contain empty space

        // Makes sure that all items are rendered correctly after the map is resized
        const resizeObserver = new ResizeObserver(
            debounce((resizeEntries: ResizeObserverEntry[]) => {
                if (
                    resizeEntries[0].contentRect.width > 0 &&
                    resizeEntries[0].contentRect.height > 0
                ) {
                    // If size is 0, the component was unloaded
                    this.map.invalidateSize();
                }
            }, 100),
        );
        resizeObserver.observe(mapElement);

        this.map
            .on('click', (clickEvent: leaflet.LeafletMouseEvent) => {
                if (this.hasMapMouseDown) {
                    this.mapsActionService.onMapClick(clickEvent.latlng);
                    this.hasMapMouseDown = false;
                }
            })
            .on('mousedown', () => {
                // Need to track mouse down to make sure the click event only fires when
                // the user actually clicked on the map first.
                // Otherwise we might lose selection when moving target or fov handle.
                this.hasMapMouseDown = true;
            })
            .on('dragend', () => (this.hasMapMouseDown = false))
            .on('moveend', () => {
                //get the current location of the map center
                const center = this.map.getCenter();
                this.onLocationChanged(center);
            })
            .on('zoom', () => {
                const zoomLevel = Number(this.map.getZoom().toFixed(1));
                this.onZoomLevelChanged(zoomLevel);
            });

        this.infoFeatureGroup.addTo(this.map);
        this.blockersFeatureGroup.addTo(this.map);
        this.scaleIndicator.addTo(this.map);

        if (!this.readOnly) {
            this.map.on('keydown', (event: L.LeafletKeyboardEvent) =>
                this.handleKeys(event.originalEvent),
            );
            this.map.on('keyup', (event: L.LeafletKeyboardEvent) =>
                this.handleKeys(event.originalEvent),
            );
            window.addEventListener('blur', this.removeAttributes);
        }
    }

    public hideTiles() {
        this.googleTiles.setMapType('floorplan');
    }

    /**
     * Set street mode
     */
    public setStreetMode(isStreetModeOn: boolean) {
        this.googleTiles.setMapType(isStreetModeOn ? 'roads' : 'satellite');
    }

    /**
     * Switch to streetmap location
     */
    public async initializeGeoMap(streetModeOn: boolean = false, fitBounds: boolean = false) {
        this.googleTiles.addTo(this.map);
        this.googleTiles.setMapType(streetModeOn ? 'roads' : 'satellite');

        if (fitBounds) {
            // Had to reintroduce this timeout that was previously removed. The
            // reason is that we in some cases need to fit bounds to all items
            // in the map, but the map is not ready to do so immediately and there
            // is no event to listen to.
            setTimeout(() => {
                this.fitMarkerBounds();
            }, 100);
        }
    }

    /**
     * Removes the old blockers and adds the new ones
     */
    public swapBlockers(newBlockers: PolyLine[]) {
        if (!newBlockers) {
            return;
        }
        this.removeBlockers();
        this.addBlockers(newBlockers);
    }

    /**
     * Remove all blockers from the map
     */
    public removeBlockers() {
        this.blockersFeatureGroup.clearLayers();
    }

    /**
     * Adds new blockers
     */
    public addBlockers(blockers: PolyLine[]) {
        blockers.forEach((blockerLatLng) => {
            const newBlocker = new leaflet.Polyline(blockerLatLng, {
                color: ColorsEnum[defaultColors.DEFAULT_BLOCKER_COLOR],
                className: animationStyle,
            });

            this.blockersFeatureGroup.addLayer(newBlocker);
        });
        this.emitter.emit('blockers-updated');
    }

    public getBlockerLayers() {
        return this.blockersFeatureGroup.getLayers();
    }

    /**
     * Get the blockers currently in the map
     */
    public getBlockers() {
        const blockerLayers = this.blockersFeatureGroup.getLayers() as Array<
            leaflet.Polygon | leaflet.Polyline
        >;
        const blockerLatLngs = blockerLayers.map((blocker) => blocker.getLatLngs() as L.LatLng[]);

        return blockerLatLngs.filter((blocker) => blocker.length > 0);
    }

    /**
     * Get the current floor plan image bounds in IBounds format
     */
    public getCurrentMapIBounds(): IBounds | undefined {
        try {
            const bounds = this.map.getBounds();

            if (bounds) {
                const { lat: south, lng: west } = bounds.getSouthWest();
                const { lat: north, lng: east } = bounds.getNorthEast();
                return {
                    topLeft: new leaflet.LatLng(north, west),
                    bottomRight: new leaflet.LatLng(south, east),
                };
            }
        } catch (e) {
            if (e instanceof TypeError) {
                // Reading bounds sometimes fails when positions are not updated
                // error caused in leaflet, no need to pollute the console.
                return undefined;
            }

            throw e;
        }
    }

    /**
     * Fly to bounds
     */
    public flyTo(bounds: L.LatLngBounds, animate = true) {
        return new Promise<void>((resolve) => {
            if (this.map.getZoom() === undefined || this.map.getCenter() === undefined) {
                this.map.fitBounds(bounds);
                resolve();
            } else {
                if (animate) {
                    this.map.once('moveend', () => {
                        resolve();
                    });
                    const currentBounds = this.map.getBounds();
                    const distance = currentBounds.getCenter().distanceTo(bounds.getCenter());

                    this.map.flyToBounds(bounds, {
                        animate,
                        duration:
                            distance > METER_DISTANCE_FLY_TO_LIMIT
                                ? TIME_SECONDS_FLY_TO
                                : undefined,
                    });
                } else {
                    this.map.fitBounds(bounds);
                    resolve();
                }
            }
        });
    }
    /**
     * Jump to bounds
     */
    public jumpTo(bounds: L.LatLngBounds) {
        return this.flyTo(bounds, false);
    }

    public getCurrentMapScaleToolPosition(): ILine {
        const mapBounds = this.map.getBounds();
        const left = (mapBounds.getCenter().lng + mapBounds.getWest()) / 2;
        const right = (mapBounds.getCenter().lng + mapBounds.getEast()) / 2;

        const begin = leaflet.latLng(mapBounds.getCenter().lat, left);
        const end = leaflet.latLng(mapBounds.getCenter().lat, right);

        return {
            begin: begin,
            end: end,
        };
    }

    /**
     * Remove the map and its event handlers
     */
    public destroyMap = () => {
        this.map.off();
        this.map.remove();
        window.removeEventListener('blur', this.removeAttributes);
    };

    private fitMarkerBounds() {
        const bounds = this.getMarkerBounds();
        if (bounds) {
            this.map.fitBounds(bounds, { maxZoom: MAX_STREET_ZOOM_LEVEL });
        }
    }

    private getMarkerBounds = (): leaflet.LatLngBounds => {
        const markers: leaflet.Marker[] = [];
        this.map.eachLayer((layer) => {
            if (layer instanceof leaflet.Marker) markers.push(layer);
        });
        const latLngs = markers.map((marker) => marker.getLatLng());

        return leaflet.latLngBounds(latLngs).pad(BOUNDS_PADDING_RATIO);
    };

    private handleKeys(event: KeyboardEvent) {
        const ctrlKey = event.metaKey || event.ctrlKey;
        if (ctrlKey) {
            this.mapElement.setAttribute('data-control-pressed', '');
        } else {
            this.mapElement.removeAttribute('data-control-pressed');
        }
        if (event.altKey) {
            this.mapElement.setAttribute('data-alt-pressed', '');
            // to stop some Windows Alt key feature interfering
            if (clientIsWindowsOs()) {
                event.preventDefault();
            }
        } else {
            this.mapElement.removeAttribute('data-alt-pressed');
        }
    }

    private removeAttributes = () => {
        this.mapElement.removeAttribute('data-control-pressed');
        this.mapElement.removeAttribute('data-alt-pressed');
    };
}
