import { isCamera, isSensorUnit, isVirtualCamera } from 'app/core/persistence';
import type {
    IInstallationPointModel,
    IInstallationPointSensorModel,
    DistanceUnit,
    IItemEntity,
    IPersistence,
} from 'app/core/persistence';
import { trigonometry } from 'axis-webtools-util';
import { calculate } from './calculations';
import { type IFOVLimits } from 'app/modules/common';
import { calculateFOVLimits } from '../piaDevices';
import { calculatePixelDensity } from '../pixelDensity';
import { clamp } from '../3dView';

import { convertDensityToDisplayUnit } from 'app/core/common';
import type { IPiaCamera, IPiaVirtualCamera, IPiaRelationReference } from 'app/core/pia';

/**
 * Returns fov limits for the sensor
 * @param currentSensor the sensor to get pixel density for
 * @param piaCamera current sensors parentPiaDevice if it exists, installationPoint parentPiaDevice otherwise
 * @param lensRelation lens relation if an optional additional lens
 * @param itemEntity current sensor parent device or installationPoint parentPiaDevice
 * @param fieldOfViewLimits the FOV limit of a camera with an optional additional lens
 */
const getFovLimits = (
    currentSensor: IInstallationPointSensorModel | undefined,
    piaCamera: IPiaCamera | IPiaVirtualCamera | null | undefined,
    lensRelation: IPiaRelationReference | undefined,
    itemEntity: IPersistence<IItemEntity> | undefined,
    fieldOfViewLimits?: IFOVLimits,
) => {
    if (fieldOfViewLimits) {
        return fieldOfViewLimits;
    }
    if (currentSensor) {
        return currentSensor.fovLimits;
    }
    if (piaCamera) {
        calculateFOVLimits(piaCamera, lensRelation?.relationProperties, itemEntity);
    }
    return undefined;
};
/**
 * Returns pixel density for one sensor
 * @param currentSensor the sensor to get pixel density for
 * @param selectedSensorId the id for the selected sensor
 * @param installationPoint installation point for the sensor
 * @param fieldOfViewLimits the FOV limit of a camera with an optional additional lens
 */
export const getPixelDensityForSensor = (
    currentSensor: IInstallationPointSensorModel | undefined,
    selectedSensorId: number,
    installationPoint: IInstallationPointModel | undefined,
    fieldOfViewLimits?: IFOVLimits,
) => {
    const sensorCount = installationPoint?.sensors.length ?? 0;
    const sensorId = clamp(selectedSensorId || 1, 1, sensorCount);
    const piaCamera = currentSensor?.parentPiaDevice //sensor and installation point parentPiaDevice can differ if sensor is virtual
        ? currentSensor.parentPiaDevice
        : installationPoint?.parentPiaDevice;
    const itemEntity = currentSensor?.parentDevice //currentSensor.parentDevice is missing if it is a custom camera and then installationPoint.parentDevice is needed
        ? currentSensor?.parentDevice
        : installationPoint?.parentDevice;

    if (
        piaCamera &&
        !(isCamera(piaCamera) || isSensorUnit(piaCamera) || isVirtualCamera(piaCamera))
    ) {
        return 0;
    }

    const lensItemEntity = installationPoint?.lenses?.find((lens) => {
        // sensorIndex starts at 0 and sensorId starts at 1.
        const sensorIndex = lens.properties.lens?.sensorIndex || 0;
        return sensorIndex + 1 === sensorId;
    });

    const lensRelation = piaCamera?.relations.find(
        (relation) => relation.id === lensItemEntity?.productId,
    );

    const fovLimits = getFovLimits(
        currentSensor,
        piaCamera,
        lensRelation,
        itemEntity,
        fieldOfViewLimits,
    );

    // calculate lensFovLimits also if lensRelation is undefined since we want the sensor's piaCamera to be used
    // (due to different piaDevice for virtual sensors). Selector getFovLimits takes the selected parentPiaCamera
    // for calculations
    const lensFovLimits =
        piaCamera && (lensRelation || currentSensor?.isVirtual)
            ? calculateFOVLimits(piaCamera, lensRelation?.relationProperties, itemEntity)
            : fovLimits;

    const horizontalFovRadians = currentSensor
        ? trigonometry.toRadians(currentSensor.settings.horizontalFov)
        : 0;

    const distance = calculate.trueDistance(
        installationPoint?.height ?? 0,
        currentSensor?.target.distance ?? 0,
        currentSensor?.target.height ?? 0,
    );

    const pixelDensityPerMeter = calculatePixelDensity({
        distance,
        horizontalFovRadians,
        lensFovLimits,
        parentDevice: itemEntity,
        piaCamera,
    });
    return pixelDensityPerMeter;
};

/**
 * Returns pixel density for one sensor
 * @param currentSensor the sensor to get pixel density for
 * @param cameraHeight the height of the camera
 * @param distanceUnit unit used for distance
 * @param piaCamera The pia camera corresponding the installation point
 * @returns string including pixel density value and unit
 */
export const getPixelDensityTextForSensor = (
    currentSensor: IInstallationPointSensorModel,
    selectedSensorId: number,
    installationPoint: IInstallationPointModel | undefined,
    unit: string,
    distanceUnit: DistanceUnit,
) => {
    const pixelDensityPerMeter = getPixelDensityForSensor(
        currentSensor,
        selectedSensorId,
        installationPoint,
    );

    return pixelDensityPerMeter
        ? `${convertDensityToDisplayUnit(pixelDensityPerMeter, distanceUnit)} ${unit}`
        : '';
};
