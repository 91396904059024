import * as React from 'react';
import * as leaflet from 'leaflet';
import type { PolyLine, IInstallationPointModel } from 'app/core/persistence';
import type { BaseCone } from './BaseCone';
import { AppConstants } from 'app/AppConstants';
import { css } from '@emotion/css';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import { debounce, getBearing, trigonometry, offset, rotate } from 'axis-webtools-util';
import type { LeafletMap } from '../LeafletMap';
import { useService } from 'app/ioc';
import { LeafletItemFactory, MapsActionService } from '../../../services';
import type { ISelectedCoverageAreaInfo } from '../../../models';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { getSelectedCoverageAreaInfo } from '../../../selectors';
import {
    useDragEndEvent,
    useDragEvent,
    useMouseClickEvent,
    useMouseDownEvent,
} from '../../../hooks';
import { popupStyle } from './utils';
import { usePopupCloseDelay } from './usePopupDelay.hook';

const targetMarkerStyle = css`
    border-radius: 50px;
    background-color: ${ColorsEnum.white};
    outline: 1px solid ${ColorsEnum.grey9};
    border-radius: 100%;
`;

const invisibleStyle = css`
    opacity: 0;
`;

interface ITargetHandleProps {
    map: LeafletMap;
    blockers: PolyLine[] | undefined;
    color: Colors;
    coverageArea: BaseCone;
    installationPoint: IInstallationPointModel;
    maxDistance: number;
    onChange: (angle: number, distance: number) => void;
    onDragEnd: () => void;
}

/**
 * Component responsible for creating the target handle, and line from installation point to target handle
 */
export const TargetHandle: React.FC<ITargetHandleProps> = ({
    map,
    blockers,
    color,
    coverageArea,
    installationPoint,
    maxDistance,
    onChange,
    onDragEnd,
}) => {
    const coverageAreaInfo = React.useRef<ISelectedCoverageAreaInfo | undefined>();
    coverageAreaInfo.current = useSelector<IStoreState, ISelectedCoverageAreaInfo | undefined>(
        (state) => getSelectedCoverageAreaInfo(state),
    );

    // Create an invisible draggable handle for the target. We listen to drag events on this handle
    const createDragHandle = () => {
        return leaflet.marker(coverageArea.getTargetCoords(), {
            draggable: !map.readOnly,
            interactive: !map.readOnly,
            icon: new leaflet.DivIcon({
                html: `<div data-test-id="target_drag_handle/>`,
                className: invisibleStyle,
                iconSize: [20, 20],
            }),
            zIndexOffset: AppConstants.targetHandleDepth + 1,
        });
    };

    // Create a target marker. This is the rendered target handle. This way we avoid
    // lagging issues when the drag handle is dragged quicker than we can redraw the
    // coverage areas
    const createTargetMarker = () => {
        return leaflet.marker(coverageArea.getTargetCoords(), {
            draggable: false,
            interactive: false,
            icon: new leaflet.DivIcon({
                html: `<div/>`,
                className: targetMarkerStyle,
                iconSize: [16, 16],
            }),
            zIndexOffset: AppConstants.targetHandleDepth,
        });
    };

    const factory = useService(LeafletItemFactory);
    const [dragHandle] = React.useState<leaflet.Marker>(createDragHandle);
    const [targetMarker] = React.useState<leaflet.Marker>(createTargetMarker);
    const [targetLine] = React.useState(
        coverageArea.getHasTargetLine()
            ? factory.createDashedPolyline(
                  [installationPoint.location, coverageArea.getTargetCoords()],
                  color,
              )
            : null,
    );
    const actions = useService(MapsActionService);
    const [distance, setDistance] = React.useState(0);
    const [angleWithoutOffset, setAngle] = React.useState(0);

    const setPopupContent = React.useCallback(
        (popupContent: string | null) => {
            if (popupContent) {
                targetMarker.setPopupContent(popupContent);
            }
        },
        [targetMarker],
    );

    const closePopup = React.useCallback(() => targetMarker.closePopup(), [targetMarker]);
    const closeWhenNoActivity = usePopupCloseDelay(closePopup);

    const showPopup = React.useCallback(() => {
        // if the popup content is null we do not want to show an empty popup (i.e PanRangeCone). Close it
        if (coverageArea.getTargetPopupContent(0) !== null) {
            targetMarker.openPopup();
        } else {
            targetMarker.closePopup();
        }

        closeWhenNoActivity();
    }, [coverageArea, closeWhenNoActivity, targetMarker]);

    // when clicking the drag handle the installation point is deselected by default
    // This code makes sure that it is still selected
    useMouseDownEvent(dragHandle, () => {
        actions.selectMapItem(
            installationPoint,
            'installationPoint',
            coverageArea.sensor?.sensorId,
        );
    });

    // update polar coordinates on drag
    useDragEvent(dragHandle, (e) => {
        // useMouseDownEvent does not trigger on touch when moving target without first clicking on it
        // therefore we need to set the cone as selected also on dragging if no selected coverage area
        if (!coverageAreaInfo.current && coverageArea.sensor?.sensorId) {
            actions.selectMapItem(
                installationPoint,
                'installationPoint',
                coverageArea.sensor?.sensorId,
            );
        }
        if (!coverageAreaInfo.current) return;

        const latLng = leaflet.latLng(
            installationPoint.location.lat,
            installationPoint.location.lng,
        );
        const event = e as leaflet.LeafletMouseEvent;

        const targetDistance = latLng.distanceTo(event.latlng);
        // target handle has taken targetRotationOffset into account (moved the handle -90 degrees) so we need to
        // rotate the drag position back (+90 degrees) to get the correct horizontal angle
        const horizontalAngle =
            trigonometry.toDegrees(getBearing(event.latlng, latLng)) +
            trigonometry.toDegrees(coverageArea.getTargetRotationOffset());

        setDistance(targetDistance);
        setAngle(horizontalAngle);
        onChange(horizontalAngle, targetDistance);

        // show the popup while dragging
        showPopup();
    });

    useDragEndEvent(
        dragHandle,
        debounce(() => {
            onDragEnd();
        }),
    );

    useMouseClickEvent(dragHandle, () => showPopup());

    // setup event listeners for target handle
    React.useEffect(() => {
        targetMarker.bindPopup('', {
            closeOnClick: false,
            className: popupStyle,
            closeButton: false,
        });

        dragHandle.addTo(map.map);
        targetMarker.addTo(map.map);
        targetLine?.addTo(map.map);

        return () => {
            dragHandle.removeFrom(map.map);
            targetMarker.removeFrom(map.map);
            targetLine?.removeFrom(map.map);
        };
    }, [map.map, dragHandle, targetMarker, targetLine]);

    // update line when color changes
    React.useEffect(() => {
        targetLine?.setStyle({ color: ColorsEnum[color] });
    }, [color, targetLine]);

    // recalculate distance and angle when coverageArea changes
    React.useLayoutEffect(() => {
        const targetCoords = coverageArea.getTargetCoords();

        const coords = coverageArea.getCoords();
        const latLng = leaflet.latLng(coords.lat, coords.lng);

        const targetDistance = latLng.distanceTo(targetCoords);

        // since targetCoords has taken targetRotationOffset into account (moved the handle -90 degrees) we need to
        // rotate the angle back again (+90 degrees) to get the correct horizontal angle
        const horizontalAngle =
            trigonometry.toDegrees(getBearing(targetCoords, latLng)) +
            trigonometry.toDegrees(coverageArea.getTargetRotationOffset());

        setDistance(targetDistance);
        setAngle(horizontalAngle);
    }, [coverageArea]);

    // Update leaflet marker and line when target has moved
    // When changes are coming from an actual drag event, it is important to always
    // redraw. This is why we need the forceRedraw flag
    React.useLayoutEffect(() => {
        const latLng = leaflet.latLng(
            installationPoint.location.lat,
            installationPoint.location.lng,
        );

        const targetDistance = Math.min(distance, maxDistance);

        // calculate new target position
        // since angleWithoutOffset has not taken possible rotationOffset into account we need to rotate
        // targetPosition if needed
        const rotatedPos = rotate(
            trigonometry.toRadians(angleWithoutOffset) - coverageArea.getTargetRotationOffset(),
        )([0, -targetDistance]);

        const newCoords = offset(latLng)(rotatedPos);

        targetLine?.setLatLngs([installationPoint.location, newCoords]);
        targetMarker.setLatLng(newCoords);
        dragHandle.setLatLng(newCoords);
    }, [
        dragHandle,
        targetMarker,
        targetLine,
        installationPoint.location,
        distance,
        angleWithoutOffset,
        maxDistance,
        coverageArea,
        coverageArea.sensor?.parentDevice.properties.camera?.filter.panoramaMode,
    ]);

    // redraw and update coverage area when target has moved
    React.useLayoutEffect(() => {
        const targetDistance = Math.min(distance, maxDistance);
        coverageArea.reDraw(blockers, {
            targetDistance,
            horizontalAngle: angleWithoutOffset,
        });

        // update popup content with latest target distance
        setPopupContent(coverageArea.getTargetPopupContent(targetDistance));
    }, [
        actions,
        coverageArea,
        setPopupContent,
        distance,
        angleWithoutOffset,
        maxDistance,
        blockers,
    ]);

    return null;
};

TargetHandle.displayName = 'TargetHandle';
