import { injectable } from 'inversify';
import type { IPiaDetector } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type { IPersistence, IItemEntity, IRadarItemEntity, Id } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { IExportablePersistedEntity, IExportedRadar } from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import { toShareModelNames } from '../../mapToShareItemNames';

@injectable()
export class RadarsExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaDetector>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedRadars = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedRadar[]> => {
        const allRadars = items.filter(this.isRadarItemEntity);
        const mappedRadars = await Promise.all(
            allRadars.map((radar) => this.mapRadarToExportedRadar(radar, projectId)),
        );
        return mappedRadars.filter(isDefined);
    };

    private mapRadarToExportedRadar = async (
        radarItem: IExportablePersistedEntity,
        projectId: Id,
    ): Promise<IExportedRadar | null> => {
        if (!radarItem.productId) {
            return null;
        }

        const radarPiaDevice = this.getPiaDevice(radarItem.productId);
        const mappedBaseItem = await this.mapItemToExportedItemBase(radarItem, projectId);

        return {
            ...mappedBaseItem,
            ...toShareModelNames(radarPiaDevice.name),
            piaId: radarItem.productId,
        };
    };

    private isRadarItemEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<IRadarItemEntity> {
        return deviceTypeCheckers.isRadarDetector(item);
    }
}
