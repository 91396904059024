import * as React from 'react';
import { Box, Button, Card, Clickable, IconText, Linkable, Stack, Text } from 'app/components';
import { t } from 'app/translate';
import { useSelector } from 'react-redux';
import { getSelectedMapOrDefault, getShowRadarCoexistenceWarning } from '../../selectors';
import { useService } from 'app/ioc';
import { MapsService } from '../../services';
// The reason why we do not show a link to the actual radar is that we cannot know which radar the coexistence warning is shown for.
// The warning is shown depending of radarFrequencyRange, radarCoexistenceRadius and radarCoexistenceMax.
const LINK = 'https://help.axis.com';

const iconColor = 'red';
const iconSymbol = 'warning';
const textColor = 'red';

/**
 * An info box that shows an icon and a text. Default is info but could be changed to error or warning by props.
 *
 */
export const MapRadarWarning: React.FC = () => {
    const isRadarWarningDismissed = useSelector(getSelectedMapOrDefault)?.isRadarWarningDismissed;
    const showRadarCoexistenceWarning = useSelector(getShowRadarCoexistenceWarning);
    const selectedFloorPlan = useSelector(getSelectedMapOrDefault);
    const mapsService = useService(MapsService);

    if (!showRadarCoexistenceWarning || isRadarWarningDismissed || !selectedFloorPlan) return null;

    const toggleCoexistenceWarning = () => {
        mapsService.setDismissRadarWarning(selectedFloorPlan._id);
    };

    return (
        <Card
            paddingX="base"
            paddingY="base"
            maxWidth="400px"
            shadow
            borderWidth={1}
            borderColor="grey5"
        >
            <Stack vertical spacing="base" testId="radar_coexistence">
                <IconText
                    icon={iconSymbol}
                    textProps={{
                        color: textColor,
                        style: 'semibold',
                        whiteSpace: 'pre-line',
                        wordBreak: 'unset',
                    }}
                    iconProps={{ color: iconColor, opaque: true }}
                    spacing="base"
                >
                    {t.radarCoexistenceWarning}
                </IconText>
                <Box justifyContent="between">
                    <Linkable external link={LINK}>
                        <Clickable>
                            <Box padding="quart" justifyContent="center" alignItems="center">
                                <Text color="blue" style="semibold" align="center">
                                    {t.readManual}
                                </Text>
                            </Box>
                        </Clickable>
                    </Linkable>
                    <Button text onClick={toggleCoexistenceWarning} testId="dismiss_coexistence">
                        {t.radarWarningDismiss}
                    </Button>
                </Box>
            </Stack>
        </Card>
    );
};

MapRadarWarning.displayName = 'MapRadarWarning';
