import { isVirtualProduct } from 'app/core/persistence';
import type { IInstallationPointSensorModel, IInstallationPointModel } from 'app/core/persistence';

/**
 * Check whether a sensor is a virtual product
 */
export const isVirtualProductSensor = (sensor: IInstallationPointSensorModel): boolean =>
    isVirtualProduct(sensor.parentPiaDevice);

/**
 * Check if the installation point has a virtual product
 */
export const hasVirtualProductCategory = (installationPoint: IInstallationPointModel): boolean =>
    installationPoint.sensors.some(isVirtualProductSensor);
