import * as React from 'react';
import { trigonometry } from 'axis-webtools-util';
import { t } from 'app/translate';
import { useService } from 'app/ioc';
import {
    getCurrentProjectUnitSystem,
    calculateSensorResolutionLimit,
    isFullPanoramic,
    Scene3d,
    SceneRenderView,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { isDoorStation } from 'app/core/persistence';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import { MapsActionService } from '../../services/MapsAction.service';
import type { Property } from 'csstype';
import { Box, Closeable, IconButton, NoPrint, Positioned } from 'app/components';
import {
    getVerticalFovOfSensor,
    getShow3dView,
    getDerotatedBlockerPolylines,
    getDerotatedSelectedInstallationPoint,
    getSelectedDerotatedSensor,
    getDerotatedMapsArrayWithPendingGeolocation,
} from '../../selectors';

const timeout = {
    appear: 0,
    enter: 0,
    exit: 400,
};

const transitionDuration = 400;

const stateToTranslation: Record<TransitionStatus, Property.Translate<string>> = {
    unmounted: '100%',
    entering: '100%',
    entered: '-20px',
    exiting: '100%',
    exited: '100%',
};

export const InstallationPointSensor3dView: React.FC = () => {
    const actionService = useService(MapsActionService);
    const floorPlans = useSelector(getDerotatedMapsArrayWithPendingGeolocation);
    const installationPoint = useSelector(getDerotatedSelectedInstallationPoint);

    const unitSystem = useSelector(getCurrentProjectUnitSystem);
    const sensor = useSelector(getSelectedDerotatedSensor);
    const blockers = useSelector(getDerotatedBlockerPolylines);

    const isSensorSelected = installationPoint && sensor !== undefined;
    const sensorCount = installationPoint?.sensors.length ?? 0;

    const cameraHeight = installationPoint?.height ?? 0;
    const targetDistance = sensor?.target.distance ?? 0;
    const targetHeight = sensor?.target.height ?? 0;
    const tiltOffset = sensor?.settings.tiltOffset ?? 0;
    const horizontalAngle = sensor?.target.horizontalAngle;
    const horizontalFov = sensor ? trigonometry.toRadians(sensor?.settings.horizontalFov) : 0;
    const corridorFormat = sensor?.settings.corridorFormat ?? false;

    // get the vertical FOV of the sensor
    const sensorVerticalFov = trigonometry.toRadians(
        useSelector<IStoreState, number | undefined>((store) =>
            getVerticalFovOfSensor(store, installationPoint?._id),
        ) ?? 0,
    );
    const verticalFov = isFullPanoramic(horizontalFov, sensorVerticalFov)
        ? Math.PI
        : sensorVerticalFov;

    const show3dView = useSelector(getShow3dView) && isSensorSelected;

    const sensorInfo =
        sensorCount > 1 ? t.cameraSelectorMultiChannelMessage(sensorCount) : undefined;

    const panoramaMode =
        installationPoint?.parentDevice.properties.camera?.filter.panoramaMode ?? false;

    const resolutionLimit = sensor && calculateSensorResolutionLimit(sensor, horizontalFov);

    // flip fovs if corridor format
    const actualHorizontalFov = corridorFormat ? verticalFov : horizontalFov;
    const actualVerticalFov = corridorFormat ? horizontalFov : verticalFov;

    // determine which views we want to show depending on the FOV
    const availableViews =
        actualHorizontalFov < trigonometry.toRadians(145)
            ? [SceneRenderView.LENS, SceneRenderView.ORBIT]
            : [SceneRenderView.ORBIT];

    // door stations can't be tilted
    const isTiltable = !isDoorStation(installationPoint?.parentPiaDevice);

    return (
        <Transition in={show3dView} timeout={timeout} mountOnEnter unmountOnExit>
            {(state) => (
                <NoPrint>
                    <Positioned
                        position="absolute"
                        bottom="0"
                        translateY={stateToTranslation[state]}
                        transition
                        duration={transitionDuration}
                        aboveMap
                    >
                        <Box paddingBottom="half" paddingX="panel" width="100%">
                            <Closeable
                                close={() => {}}
                                customShadow="rgb(0 0 0 / 20%) 0px 4px 10px"
                            >
                                <Box
                                    color="grey1"
                                    borderRadius="rounded"
                                    width="100%"
                                    height="700px"
                                    maxHeight="50vh"
                                    overflow="hidden"
                                    position="relative"
                                >
                                    {show3dView && (
                                        <Scene3d
                                            cameraHeight={cameraHeight}
                                            targetHeight={targetHeight}
                                            tiltOffset={tiltOffset}
                                            targetDistance={targetDistance}
                                            resolutionLimit={resolutionLimit}
                                            selectedHorizontalFov={actualHorizontalFov}
                                            selectedVerticalFov={actualVerticalFov}
                                            horizontalAngle={horizontalAngle}
                                            unitSystem={unitSystem}
                                            panoramaMode={panoramaMode}
                                            tiltable={isTiltable}
                                            sensorInfo={sensorInfo}
                                            availableViews={availableViews}
                                            location={installationPoint?.location}
                                            floorPlans={floorPlans}
                                            blockers={blockers}
                                            enableDragEvents={false}
                                        />
                                    )}
                                    <Positioned position="absolute" top="8px" right="8px">
                                        <IconButton
                                            icon="close_circle_outline"
                                            color="grey7"
                                            onClick={actionService.toggle3dView}
                                        />
                                    </Positioned>
                                </Box>
                            </Closeable>
                        </Box>
                    </Positioned>
                </NoPrint>
            )}
        </Transition>
    );
};

InstallationPointSensor3dView.displayName = 'InstallationPointSensor3dView';
