import { eventTracking } from 'app/core/tracking';
import { AppSettings } from 'app/AppSettings';
import { ServiceLocator } from 'app/ioc';
import type { IAppState } from './models/IAppState';
import { reducerBuilder } from 'app/store';
import type { IUserPreferences } from 'app/core/persistence';
import { defaultUserPreferences, getItemLocalStorage } from 'app/core/persistence';
import { AppActions } from './AppActions';
import type { IMigrationState, IPiaState, IRouterLocationState } from './appLoader/models';
import { CONSTANTS } from './constants';
import { withTrailingSlash } from 'app/utils';

const initialPiaState: IPiaState = {
    ready: false,
    loading: false,
    error: null,
    progress: 0,
};

const initialMigrationState: IMigrationState = {
    finished: false,
    migrating: false,
    error: null,
    progress: 0,
};

const initialRouterLocationState = {
    hash: '',
    key: '',
    pathname: '',
    search: '',
    state: null,
};

export const appReducer = reducerBuilder<IAppState>()
    .setInitialState(() => ({
        appSettings: ServiceLocator.get(AppSettings),
        userPreferences: defaultUserPreferences,
        piaState: initialPiaState,
        migrationState: initialMigrationState,
        eulaAcceptedVersion: Number(getItemLocalStorage('EulaAcceptedVersion')),
        welcomeFinished: false,
        routerLocation: initialRouterLocationState,
        userInfoFetched: false,
        userOnline: true,
        updateAvailable: false,
    }))
    .onAction<boolean>(AppActions.UserAcceptedEulaVersion, (state) => ({
        ...state,
        eulaAcceptedVersion: CONSTANTS.eulaVersion,
    }))
    .onAction<IRouterLocationState>(AppActions.UpdateRouterLocation, (state, action) => {
        action.payload.pathname = withTrailingSlash(action.payload.pathname);
        if (state.routerLocation.pathname !== action.payload.pathname) {
            eventTracking.logPageView(action.payload.pathname);
        }

        return {
            ...state,
            routerLocation: {
                ...action.payload,
            },
        };
    })
    .onAction<boolean>(AppActions.GetUserFinishedWelcome, (state, action) => ({
        ...state,
        welcomeFinished: action.payload,
    }))
    .onAction<boolean>(AppActions.UserFinishedWelcome, (state) => ({
        ...state,
        welcomeFinished: true,
    }))
    .onAction<IUserPreferences>(AppActions.SetUserPreferences, (state, action) => ({
        ...state,
        userPreferences: action.payload,
    }))
    .onAction<boolean>(AppActions.UserNetworkChange, (state, action) => ({
        ...state,
        userOnline: action.payload,
    }))
    .onAction<number>(AppActions.PiaProgress, (state, action) => ({
        ...state,
        piaState: {
            ...state.piaState,
            progress: action.payload,
        },
    }))
    .onAction<number>(AppActions.UserDataProgress, (state, action) => ({
        ...state,
        migrationState: {
            ...state.migrationState,
            progress: action.payload,
        },
    }))
    .onAction<boolean>(AppActions.UpdateAvailable, (state, action) => ({
        ...state,
        updateAvailable: action.payload,
    }))
    .onAsyncAction(AppActions.PiaReady, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                piaState: {
                    ready: false,
                    loading: true,
                    error: null,
                    progress: 0,
                },
            }))
            .onFulfilled((state) => ({
                ...state,
                piaState: {
                    ready: true,
                    loading: false,
                    error: null,
                    progress: 1,
                },
            }))
            .onRejected<Error>((state, action) => ({
                ...state,
                piaState: {
                    ready: false,
                    loading: false,
                    error: action.payload,
                    progress: 0,
                },
            }));
    })
    .onAsyncAction(AppActions.InitializingStorage, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                migrationState: {
                    finished: false,
                    migrating: true,
                    error: null,
                    progress: 0,
                },
            }))
            .onFulfilled((state) => ({
                ...state,
                migrationState: {
                    finished: true,
                    migrating: false,
                    error: null,
                    progress: 1,
                },
            }))
            .onRejected<Error>((state, action) => ({
                ...state,
                migrationState: {
                    finished: false,
                    migrating: false,
                    error: action.payload,
                    progress: 0,
                },
            }));
    })
    .onAsyncAction(AppActions.FetchUserInfo, (handler) => {
        handler.onFulfilled((state) => ({
            ...state,
            userInfoFetched: true,
        }));
    })
    .create();
