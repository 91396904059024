import type { DeviceType } from '../DeviceType';

type CameraDeviceTypes = Extract<
    DeviceType,
    'camera' | 'encoder' | 'mainUnit' | 'doorstation' | 'sensorUnit' | 'analogCamera'
>;
const camerasGroup: CameraDeviceTypes[] = [
    'camera',
    'encoder',
    'mainUnit',
    'doorstation',
    'sensorUnit',
    'analogCamera',
] as const;

type SpeakerDeviceTypes = Extract<DeviceType, 'speaker'>;
const speakerDeviceTypes: SpeakerDeviceTypes[] = ['speaker'] as const;

type RadarDeviceTypes = Extract<DeviceType, 'radardetector'>;
const radarDeviceTypes: RadarDeviceTypes[] = ['radardetector'] as const;

type DoorControllerDeviceTypes = Extract<DeviceType, 'doorcontroller' | 'door'>;
const doorControllerDeviceTypes: DoorControllerDeviceTypes[] = ['doorcontroller', 'door'] as const;

type OtherDeviceTypes = Extract<
    DeviceType,
    | 'alerter'
    | 'peopleCounter'
    | 'systemController'
    | 'dockingStation'
    | 'decoder'
    | 'pac'
    | 'connectivitydevice'
    | 'pagingconsole'
>;
const otherDeviceTypes: OtherDeviceTypes[] = [
    'alerter',
    'peopleCounter',
    'systemController',
    'dockingStation',
    'decoder',
    'pac',
    'connectivitydevice',
    'pagingconsole',
] as const;

export const deviceTypeLayers = {
    cameras: camerasGroup,
    speakers: speakerDeviceTypes,
    radarDetectors: radarDeviceTypes,
    doorControllers: doorControllerDeviceTypes,
    other: otherDeviceTypes,
} as const;

export const coverageAreaLayers = {
    cameras: camerasGroup,
    speakers: speakerDeviceTypes,
    radarDetectors: radarDeviceTypes,
} as const;

export type DeviceTypeLayerGroup = keyof typeof deviceTypeLayers;
export type CoverageAreaLayerGroup = keyof typeof coverageAreaLayers;
