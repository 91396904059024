import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { Id, IInstallationPointEntity, IPersistence } from 'app/core/persistence';
import { DataWidget, Box } from 'app/components';
import { getGeoLocatedInstallationPoint } from '../../selectors';

interface IInstallationDetailsLocationProps {
    installationPointId?: Id;
    modelName: string;
}

export const InstallationDetailsLocation: React.FC<IInstallationDetailsLocationProps> = ({
    installationPointId,
    modelName,
}) => {
    const installationPoint = useSelector<
        IStoreState,
        IPersistence<IInstallationPointEntity> | undefined
    >((store) => getGeoLocatedInstallationPoint(store, installationPointId));

    return (
        <Box spacing="panel" justifyContent={'start'} alignItems="center" wrap paddingTop="base">
            {installationPoint && (
                <DataWidget
                    testId={`installation_report_location_${modelName}`}
                    text={`${installationPoint.location.lat.toFixed(
                        6,
                    )}, ${installationPoint.location.lng.toFixed(6)}`}
                    description={t.location}
                    textStyle="heading"
                />
            )}
        </Box>
    );
};

InstallationDetailsLocation.displayName = 'InstallationDetailsLocation';
