import { injectable } from 'inversify';
import type { IPiaAlerter } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';
import type {
    IItemEntity,
    IPersistence,
    IConnectivityDeviceItemEntity,
    Id,
} from 'app/core/persistence';
import {
    deviceTypeCheckers,
    ProjectModelService,
    ScheduleModelService,
} from 'app/core/persistence';
import { isDefined } from 'axis-webtools-util';
import type { IExportablePersistedEntity, IExportedConnectivityDevice } from '../../../models';
import { BaseItemExporterService } from './BaseItemExporter.service';
import {
    BandwidthCalculatorService,
    ProfileOverrideService,
    ProfileSupportService,
    ScenarioService,
    StorageCalculationService,
} from 'app/modules/common';
import { toShareModelNames } from '../../mapToShareItemNames';

@injectable()
export class ConnectivityDevicesExporterService extends BaseItemExporterService {
    constructor(
        profileOverrideService: ProfileOverrideService,
        profileSupportService: ProfileSupportService,
        piaItemService: PiaItemService<IPiaAlerter>,
        projectModelService: ProjectModelService,
        bandwidthCalculatorService: BandwidthCalculatorService,
        scenarioService: ScenarioService,
        storageCalculationService: StorageCalculationService,
        scheduleModelService: ScheduleModelService,
    ) {
        super(
            profileOverrideService,
            profileSupportService,
            piaItemService,
            projectModelService,
            bandwidthCalculatorService,
            scenarioService,
            storageCalculationService,
            scheduleModelService,
        );
    }

    public mapItemsToExportedConnectivityDevices = async (
        items: IExportablePersistedEntity[],
        projectId: Id,
    ): Promise<IExportedConnectivityDevice[]> => {
        const allConnectivityDevices = items.filter(this.isConnectivityDevicesItemEntity);

        const mappedConnectivityDevices = await Promise.all(
            allConnectivityDevices.map((connectivityDevice) =>
                this.mapConnectivityDevicesToExportedConnectivityDevices(
                    connectivityDevice,
                    projectId,
                ),
            ),
        );
        return mappedConnectivityDevices.filter(isDefined);
    };

    public mapConnectivityDevicesToExportedConnectivityDevices = async (
        connectivityDeviceItem: IExportablePersistedEntity,
        projectId: Id,
    ): Promise<IExportedConnectivityDevice | null> => {
        if (!connectivityDeviceItem.productId) {
            return null;
        }

        const connectivityDevicePiaDevice = this.getPiaDevice(connectivityDeviceItem.productId);
        const mappedBaseItem = await this.mapItemToExportedItemBase(
            connectivityDeviceItem,
            projectId,
        );

        return {
            ...mappedBaseItem,
            ...toShareModelNames(connectivityDevicePiaDevice.name),
            piaId: connectivityDeviceItem.productId,
        };
    };

    private isConnectivityDevicesItemEntity(
        item: IPersistence<IItemEntity>,
    ): item is IPersistence<IConnectivityDeviceItemEntity> {
        return deviceTypeCheckers.isConnectivityDevice(item);
    }
}
