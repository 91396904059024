import type { DeviceType, IFilteredMapLayers } from 'app/core/persistence';
import { deviceTypeLayers, defaultFilteredMapLayers } from 'app/core/persistence';
import { getUserPreferences } from 'app/modules/application';
import { getCurrentProjectId } from 'app/modules/common';
import { createSelector } from 'reselect';

export const getFilteredMapLayers = createSelector(
    [getUserPreferences, getCurrentProjectId],
    (userPreferences, projectId): IFilteredMapLayers => {
        // Filtered map layers can be undefined if user preferences has not been set yet
        if (!userPreferences.projectSettings[projectId]) {
            return defaultFilteredMapLayers;
        }
        return (
            userPreferences.projectSettings[projectId].mapSettings?.filteredMapLayers ??
            defaultFilteredMapLayers
        );
    },
);

export const getLabelsLayerVisible = createSelector(
    [getFilteredMapLayers],
    (filter) => !filter.labelsLayer,
);

export const getTextBoxesVisible = createSelector(
    [getFilteredMapLayers],
    (filter) => !filter.textBoxesLayer,
);

export const getFilteredDeviceTypes = createSelector(
    [getFilteredMapLayers],
    (filteredMapLayers) => {
        const deviceTypeGroups = filteredMapLayers.deviceLayers;
        const deviceTypes = deviceTypeGroups.flatMap(
            (group) => deviceTypeLayers[group] as DeviceType[],
        );
        return deviceTypes;
    },
);

export const getDeviceTypesWithFilteredCoverageArea = createSelector(
    [getFilteredMapLayers],
    (filteredMapLayers) => {
        const deviceTypeGroups = filteredMapLayers.coverageAreaLayers;
        const deviceTypes = deviceTypeGroups.flatMap(
            (group) => deviceTypeLayers[group] as DeviceType[],
        );
        return deviceTypes;
    },
);

export const getFilteredDeviceColors = createSelector(
    [getFilteredMapLayers],
    (filteredMapLayers) => {
        return filteredMapLayers.colors;
    },
);
