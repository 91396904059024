import * as React from 'react';
import type { Icons } from '../ui/icon';
import { Icon } from '../ui/icon';
import type { IAutoTestable } from '../ui-test';
import type { ITextProps } from '../text';
import { Text } from '../text';
import type { IWithChildren } from '../models';
import { Box } from '../containers';

interface IDataWidgetMultiProps extends IAutoTestable, IWithChildren {
    textValues: string[] /** an array with the values to represent*/;
    secondaryTextValues?: string[] /**an optional array representing a possible secondary value to display beside the textValue*/;
    descriptions: string[] /**  an array of text that describe the text values*/;
    textValueDescription?: string /** if two text values this describe the first*/;
    secondaryTextValueDescription?: string /** if two text values this describe the second*/;
    icon?: Icons /**an optional icon representing the data to display */;
    textStyle?: ITextProps['style'] /**the style of the textValues and defaults to 'headline' */;
    textValueDescriptionStyle?: ITextProps['style'] /**the style of the descriptions and defaults to 'headline' */;
    secondaryIcons?: (
        | Icons
        | undefined
    )[] /**an optional icon shown to the right of the textValue */;
}

/**
 * Component representing data for multiple text and description pairs (below each other) compared to DataWidget that only represent one textValue
 * Representation of data consists of an optional icon to the left, then text value and description for the value.
 * It is also possible with a common description and then two text values beside each other with a sub description for the two possible values

 */
export const DataWidgetMulti: React.FunctionComponent<IDataWidgetMultiProps> = ({
    textValues,
    secondaryTextValues,
    descriptions,
    textValueDescription,
    secondaryTextValueDescription,
    icon,
    testId,
    textStyle = 'headline',
    textValueDescriptionStyle = 'headline',
    secondaryIcons,
}) => {
    const getMainText = (mainText: string, extraIcon: Icons | undefined) => {
        if (extraIcon) {
            return (
                <Box spacing="half">
                    <Text style={textStyle} whiteSpace="nowrap" lineHeight={1} testId={testId}>
                        {mainText}
                    </Text>
                    <Icon icon={extraIcon} size="sm" />
                </Box>
            );
        }
        return (
            <Text testId={testId} style={textStyle} whiteSpace="nowrap" lineHeight={1}>
                {mainText}
            </Text>
        );
    };
    return (
        <Box spacing="base" alignItems="center">
            {icon && <Icon opaque icon={icon} color="grey4" />}
            <Box direction="column" spacing="half">
                {textValues.map((text, index) => (
                    <Box key={index} direction="column" spacing="halfQuart">
                        {secondaryTextValues ? (
                            <Box direction="row" spacing="half" alignItems="center">
                                <Box alignItems="center" spacing="quart">
                                    {textValueDescription && (
                                        <Text
                                            style={textValueDescriptionStyle}
                                            whiteSpace="nowrap"
                                            lineHeight={1}
                                        >
                                            {textValueDescription}
                                        </Text>
                                    )}
                                    <Text style={textStyle} whiteSpace="nowrap" lineHeight={1}>
                                        {text}
                                    </Text>
                                </Box>
                                <Box alignItems="center" spacing="quart">
                                    {secondaryTextValueDescription && (
                                        <Text
                                            style={textValueDescriptionStyle}
                                            whiteSpace="nowrap"
                                            lineHeight={1}
                                        >
                                            {secondaryTextValueDescription}
                                        </Text>
                                    )}
                                    <Text
                                        testId={testId}
                                        style={textStyle}
                                        whiteSpace="nowrap"
                                        lineHeight={1}
                                    >
                                        {secondaryTextValues[index]}
                                    </Text>
                                </Box>
                            </Box>
                        ) : (
                            getMainText(text, secondaryIcons ? secondaryIcons[index] : undefined)
                        )}
                        <Text whiteSpace="nowrap" color="grey5" small>
                            {descriptions[index]}
                        </Text>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

DataWidgetMulti.displayName = 'DataWidgetMulti';
