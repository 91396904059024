import type { Ray } from 'three';
import { Vector3, Plane } from 'three';

const RENDER_HORIZON = 10e3;

const farAwayPlane = new Plane(new Vector3(-1, 0, 0), RENDER_HORIZON);
const leftFarAwayPlane = new Plane(new Vector3(0, 0, -1), RENDER_HORIZON);
const rightFarAwayPlane = new Plane(new Vector3(0, 0, 1), RENDER_HORIZON);
const backFarAwayPlane = new Plane(new Vector3(1, 0, 0), RENDER_HORIZON);

/**
 * Calculate the intersection of a ray and a plane at the specified height
 * If there is no intersection, return a point on the ground plane far away
 * in the direction of the ray
 */
export function getIntersection(ray: Ray, height = 0) {
    // if the ray origin is equal to height use a somewhat smaller height
    const calculationHeight = ray.origin.y === height ? height - 0.001 : height;

    const groundPlane = new Plane(new Vector3(0, -1, 0), calculationHeight);

    let intersection = ray.intersectPlane(groundPlane, new Vector3());

    // if ray doesn't intersect ground plane, find a point at RENDER_HORIZON and then
    // project this point on ground plane
    if (!intersection || intersection.length() > RENDER_HORIZON) {
        const intersections = [
            ray.intersectPlane(farAwayPlane, new Vector3()),
            ray.intersectPlane(leftFarAwayPlane, new Vector3()),
            ray.intersectPlane(rightFarAwayPlane, new Vector3()),
            ray.intersectPlane(backFarAwayPlane, new Vector3()),
        ].sort((a, b) => (a?.length() ?? Number.MAX_VALUE) - (b?.length() ?? Number.MAX_VALUE));

        intersection = intersections[0]
            ? groundPlane.projectPoint(intersections[0], new Vector3())
            : null;
    }
    return intersection;
}
