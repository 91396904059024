import type { Colors } from 'app/styles';
import { createCachedSelector } from 're-reselect';
import { getIdFromProps, toCacheKey } from 'app/modules/common';
import { getDerotatedInstallationPointsArray } from './getProjectInstallationPoints';
import { getDefaultColor } from '../utils/getDefaultColor';

export const getInstallationPointColor = createCachedSelector(
    [getDerotatedInstallationPointsArray, getIdFromProps],
    (installationPoints, installationPointId): Colors => {
        const installationPoint = installationPoints.find((ip) => ip._id === installationPointId);
        let deviceColor = installationPoint?.parentDevice.color;
        if (installationPoint?.parentId) {
            const parentInstallationPoint = installationPoints.find(
                (ip) => ip._id === installationPoint.parentId,
            );
            deviceColor = parentInstallationPoint?.parentDevice.color;
        }
        return deviceColor ?? getDefaultColor(installationPoint?.parentDevice);
    },
)(toCacheKey);
