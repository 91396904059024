import { trigonometry, isDefined } from 'axis-webtools-util';
import { calculateCornerRays, calculateEdgeRays } from './calculateBoundingRays';
import * as PolyBool from 'polybooljs';
import type { IPolygon } from 'app/modules/common';
import type { PanoramaModes } from 'app/core/persistence';
import { getCircle, projectOnGround, toPolygon } from './cameraCalc';
import { getIntersection } from './getIntersection';

// The distance to use when constructing a polygon that masks out the parts of
// the visible area that are beyond the target
const FAR_AWAY = 10000;

/**
 * return a polygon representing the visible area of a camera. The visible area is
 * defined as the area where both the ground plane and a plane at the target height
 * is visible through the lens
 */
function calculateNonPanoramaVisibleArea(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    tiltOffset: number,
): IPolygon {
    const cornerRays = calculateCornerRays(
        trigonometry.toRadians(horizontalFov),
        verticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
        tiltOffset,
    );

    // find the intersections with a horizontal plane at target height. If target is above camera, there is no intersection
    const intersectionsAtTargetHeight =
        cameraHeight > targetHeight
            ? [
                  getIntersection(cornerRays.topLeft, targetHeight),
                  getIntersection(cornerRays.topRight, targetHeight),
                  getIntersection(cornerRays.bottomRight, targetHeight),
                  getIntersection(cornerRays.bottomLeft, targetHeight),
              ]
            : [
                  getIntersection(cornerRays.topLeft, targetHeight),
                  getIntersection(cornerRays.topRight, targetHeight),
                  getIntersection(cornerRays.topLeft, targetHeight),
                  getIntersection(cornerRays.topRight, targetHeight),
              ];

    // find the intersections with a horizontal plane at ground level
    const intersectionsAtGroundLevel = [
        getIntersection(cornerRays.topLeft),
        getIntersection(cornerRays.topRight),
        getIntersection(cornerRays.bottomRight),
        getIntersection(cornerRays.bottomLeft),
    ];

    // The visible area is the intersection between the two polygons
    const intersections = PolyBool.intersect(
        toPolygon(intersectionsAtTargetHeight.filter(isDefined).map(projectOnGround)),
        toPolygon(intersectionsAtGroundLevel.filter(isDefined).map(projectOnGround)),
    );

    if (tiltOffset === 0) {
        // The camera is not tilted, so we can return the intersection directly
        return intersections;
    }

    // The camera is tilted, so we need to cut away the parts of the visible area that are
    // beyond the tilt angle

    // create a polygon for masking out the parts of the visible area that are beyond the tilt angle
    const tiltAngleMask = toPolygon([
        [targetDistance, -FAR_AWAY],
        [targetDistance, FAR_AWAY],
        [-FAR_AWAY, FAR_AWAY],
        [-FAR_AWAY, -FAR_AWAY],
    ]);

    // intersect the visible area with the tilt angle mask
    return PolyBool.intersect(intersections, tiltAngleMask);
}

/**
 * Calculate the visible area for cameras that have a horizontal FOV that is larger than 179 degrees
 */
function calculatePanoramicVisibleArea(
    targetDistance: number,
    panoramaMode: PanoramaModes,
): IPolygon {
    return toPolygon(getCircle(targetDistance, panoramaMode === 'vertical' ? 180 : 360));
}

function calculateIntercomVisibleArea(targetDistance: number, horizontalFov: number): IPolygon {
    return toPolygon(getCircle(targetDistance, horizontalFov));
}

/**
 * Calculate the visible area for wide angle cameras
 */
function calculateWideAngleVisibleArea(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    panoramaMode: PanoramaModes,
    tiltOffset: number,
): IPolygon {
    const edgeRays = calculateEdgeRays(
        trigonometry.toRadians(horizontalFov),
        verticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
        panoramaMode,
        tiltOffset,
    );

    // determine at which hight we should check for intersections
    const calculationHeight = panoramaMode === 'horizontal' ? targetHeight : 0;

    if (panoramaMode === 'horizontal' && targetHeight > cameraHeight) {
        // special case: ceiling mounted camera where target is even higher
        return {
            regions: [],
            inverted: false,
        };
    }

    // calculate the visible area
    const visibleArea = edgeRays
        .map((ray) => getIntersection(ray, calculationHeight))
        .filter(isDefined);

    const intersections = PolyBool.intersect(
        toPolygon(visibleArea.map(projectOnGround)),
        toPolygon(getCircle(targetDistance, 360)),
    );

    return intersections;
}

export function calculateVisibleArea(
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    panoramaMode: PanoramaModes,
    isTiltable: boolean,
    tiltOffset = 0,
): IPolygon {
    if (horizontalFov >= 180 && isTiltable) {
        if (verticalFov < Math.PI) {
            return calculateWideAngleVisibleArea(
                horizontalFov,
                verticalFov,
                cameraHeight,
                targetHeight,
                targetDistance,
                panoramaMode,
                tiltOffset,
            );
        } else {
            return calculatePanoramicVisibleArea(targetDistance, panoramaMode);
        }
    } else if (!isTiltable) {
        return calculateIntercomVisibleArea(targetDistance, horizontalFov);
    } else {
        return calculateNonPanoramaVisibleArea(
            horizontalFov,
            verticalFov,
            cameraHeight,
            targetHeight,
            targetDistance,
            tiltOffset,
        );
    }
}
