import type { IEntity, Id } from '../userDataPersistence';

/*
 *  Get the parent id of an entity. The last element in the path array is the id
 *  of the entity itself, the second last is the id of the parent.
 */
export const getParentId = (entity: Pick<IEntity, 'path'>): Id | undefined =>
    entity.path[entity.path.length - 2];

/**
 * Get the projectId of an entity. The first element in the path array is the id
 * of the project.
 **/
export const getProjectIdFromEntity = (entity: Pick<IEntity, 'path'>): Id => {
    return entity.path[0];
};
