import { isDefined } from 'axis-webtools-util';
import { CurrentProjectService, deviceTypeCheckers } from 'app/core/persistence';
import type {
    IInstallationPointModel,
    ILatLng,
    FontSize,
    ISpeakerItemEntity,
    IInstallationPointSensorModel,
} from 'app/core/persistence';
import * as leaflet from 'leaflet';
import { injectable } from 'inversify';
import type { LeafletMap } from '../components/map/LeafletMap';
import type { BaseCone } from '../components/map/cone/BaseCone';
import { CameraCone, SpeakerCone } from '../components/map/cone';
import { RadarCone } from '../components/map/cone/RadarCone';
import { PanRangeCone } from '../components/map/cone/PanRangeCone';
import {
    LeafletMenuButtonControl,
    type ILeafletMenuButtonControl,
} from '../components/map/LeafletMenuButtonControl';
import { ColorsEnum, SpacingsEnum, colorToRgba, getFontColor } from 'app/styles';
import type { Colors } from 'app/styles';
import classNames from 'classnames';
import { css, cx } from '@emotion/css';
import { HideOverflowStyle, SmallStyle, TextStyle } from 'app/components';
import {
    calculateVerticalFov,
    cropTextMiddle,
    estimateVerticalFOV,
    getTextWidth,
} from 'app/modules/common';
import {
    FOCUS_OPACITY,
    HUMAN_ICON_HEIGHT,
    HUMAN_ICON_WIDTH,
    popupStyle,
    svgIconStyleCenter,
    VEHICLE_ICON_HEIGHT,
    VEHICLE_ICON_WIDTH,
} from '../components/map/cone/utils/iconConstants';
import type { IPiaCamera, IPiaRadarDetector, IPiaSpeaker } from 'app/core/pia';

const PIXEL_HEIGHT_LABEL = 15;
const PIXEL_MARGIN_SIDES = 5;
const LABEL_MAX_LENGTH = 36;

const deviceIconStyle = (color: ColorsEnum) => {
    return css`
        box-sizing: border-box;
        border-radius: 50%;
        outline: none;
        -webkit-print-color-adjust: exact; /** Chrome, Safari, Edge */
        color-adjust: exact; /** FF */
        display: flex !important;
        align-items: center;
        justify-content: center;

        &[data-blurred] img {
            opacity: ${0.3};
        }

        &[data-blurred] {
            border-color: ${colorToRgba(color, 0.2)};
        }
    `;
};
const deviceIconBorderStyle = (color: ColorsEnum) => {
    return css`
        ${deviceIconStyle(color)}
        background-color: ${ColorsEnum.white};
        border: 3px solid ${color};
        border-color: ${color};
    `;
};

const tinyDeviceStyle = (color: ColorsEnum) => {
    return css`
        ${deviceIconStyle(color)}
    `;
};

const bigDeviceStyle = (color: ColorsEnum) => {
    return css`
        ${deviceIconBorderStyle(color)}
        border: 3px solid ${color};
    `;
};

const smallDeviceStyle = (color: ColorsEnum) => {
    return css`
        ${deviceIconBorderStyle(color)}
        border: 2px solid ${color};
    `;
};

const numberedDeviceStyle = (color: ColorsEnum, isChildDevice: boolean) => {
    return css`
        background-color: ${isChildDevice ? color : ColorsEnum.white};
        border: 3px solid ${color};
        border-radius: 50%;
        text-align: center;

        p {
            margin: 0;
            padding: ${SpacingsEnum.halfQuart};
        }
    `;
};

const labelColorStyle = (backGroundColor: ColorsEnum, isChildDevice: boolean) => {
    return css`
        color: ${isChildDevice ? getFontColor(backGroundColor) : ColorsEnum.grey9};
    `;
};
@injectable()
export class LeafletItemFactory {
    constructor(private currentProjectService: CurrentProjectService) {}

    public createStaticItem(location: ILatLng | undefined, icon?: leaflet.DivIcon) {
        const marker = leaflet.marker(leaflet.latLng(location?.lat ?? 0, location?.lng ?? 0), {
            icon,
        });
        return marker;
    }

    public createInteractiveItem(
        location: ILatLng | undefined,
        icon?: leaflet.Icon | leaflet.DivIcon,
        isDraggable = true,
    ) {
        const draggableMarker = leaflet.marker(
            leaflet.latLng(location?.lat ?? 0, location?.lng ?? 0),
            {
                draggable: isDraggable,
                interactive: isDraggable,
                rotationOrigin: 'center',
                icon,
            },
        );

        draggableMarker.on('click', (e: leaflet.LeafletMouseEvent) => {
            // Prevent click from reaching map
            leaflet.DomEvent.stopPropagation(e);
        });
        draggableMarker.on('mousedown', (e: leaflet.LeafletMouseEvent) => {
            // Prevent click from reaching map
            leaflet.DomEvent.stopPropagation(e);
        });

        return draggableMarker;
    }

    public createDashedPolyline(
        points: (ILatLng | undefined)[],
        color: Colors,
        dashArray: string = '5, 5',
        weight: number = 1,
    ) {
        return leaflet.polyline(points.filter(isDefined), {
            color: ColorsEnum[color],
            dashArray,
            weight,
        });
    }

    public createDashedPolygon(
        points: (ILatLng | undefined)[],
        color: Colors,
        dashArray: string = '5, 5',
        weight: number = 1,
        fillOpacity: number = 0,
    ) {
        return leaflet.polygon(points.filter(isDefined), {
            color: ColorsEnum[color],
            dashArray,
            weight,
            fillOpacity,
            fill: fillOpacity !== 0,
        });
    }

    private isPanorama(sensor: IInstallationPointSensorModel) {
        const verticalFov = sensor.parentDevice
            ? calculateVerticalFov(
                  sensor.settings.horizontalFov,
                  sensor.fovLimits.horizontal.max,
                  sensor.fovLimits.horizontal.min,
                  sensor.fovLimits.vertical.max,
                  sensor.fovLimits.vertical.min,
              )
            : estimateVerticalFOV(sensor.settings.horizontalFov);

        const isTiltable = !deviceTypeCheckers.isDoorStation(sensor.parentDevice);
        sensor.settings.horizontalFov;
        if (sensor.settings.horizontalFov >= 180 && isTiltable && verticalFov >= Math.PI) {
            return true;
        }
        return false;
    }

    public createCones(
        installationPoint: IInstallationPointModel | undefined,
        map: LeafletMap,
        color: Colors,
        hasExternalIlluminator: boolean,
    ): BaseCone[] {
        if (!installationPoint) return [];
        const distanceUnit = this.currentProjectService.getProjectDistanceUnit();
        const sensors = installationPoint.sensors.map(
            (sensor) =>
                new CameraCone(
                    sensor,
                    map,
                    color,
                    hasExternalIlluminator,
                    distanceUnit,
                    false,
                    installationPoint,
                ),
        );
        // we do not want to show the ptz-panRange if the camera itself has a sensor of panoramic type
        const isAnySensorPanorma = installationPoint.sensors.some((sensor) =>
            this.isPanorama(sensor),
        );

        const panRange =
            installationPoint.panRange && !isAnySensorPanorma
                ? new PanRangeCone(
                      installationPoint.panRange,
                      installationPoint.location,
                      installationPoint.parentPiaDevice as IPiaCamera,
                      map,
                      color,
                  )
                : undefined;

        const speaker = installationPoint.speaker
            ? new SpeakerCone(
                  installationPoint.speaker,
                  installationPoint.location,
                  installationPoint.parentPiaDevice as IPiaSpeaker,
                  installationPoint.parentDevice as ISpeakerItemEntity,
                  installationPoint.height,
                  map,
                  color,
                  distanceUnit,
              )
            : undefined;

        const radar = installationPoint.radar
            ? new RadarCone(
                  installationPoint.radar,
                  installationPoint.location,
                  installationPoint.parentPiaDevice as IPiaRadarDetector,
                  installationPoint.height,
                  map,
                  color,
                  distanceUnit,
              )
            : undefined;

        return [...sensors, panRange, speaker, radar].filter(isDefined);
    }

    public createHumanMarker(location: ILatLng, color: Colors, hasPopup?: boolean) {
        const marker = leaflet.marker(location, {
            icon: this.createHumanIcon(
                svgIconStyleCenter(HUMAN_ICON_HEIGHT, HUMAN_ICON_WIDTH),
                '',
                ColorsEnum[color],
            ),
            rotationAngle: 0,
            rotationOrigin: 'top center',
            opacity: FOCUS_OPACITY,
            interactive: true,
        });

        if (hasPopup) {
            marker.bindPopup('', {
                closeOnClick: false,
                className: popupStyle,
                closeButton: false,
            });
        }

        return marker;
    }

    public createHumanIcon(svgIconStyle: string, hiddenStyle: string, colorStyle: string) {
        return new leaflet.DivIcon({
            className: classNames(svgIconStyle, hiddenStyle),
            html: `<svg data-test-id="human" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" stroke-width="10" fill="none" fill-rule="evenodd">
                            <path class="${colorStyle}" d="M13.5,5.5 C14.6,5.5 15.5,4.6 15.5,3.5 C15.5,2.4 14.6,1.5 13.5,1.5 C12.4,1.5 11.5,2.4 11.5,3.5 C11.5,4.6 12.4,5.5 13.5,5.5 Z M9.8,8.9 L7,23 L9.1,23 L10.9,15 L13,17 L13,23 L15,23 L15,15.5 L12.9,13.5 L13.5,10.5 C14.8,12 16.8,13 19,13 L19,11 C17.1,11 15.5,10 14.7,8.6 L13.7,7 C13.3,6.4 12.7,6 12,6 C11.7,6 11.5,6.1 11.2,6.1 L6,8.3 L6,13 L8,13 L8,9.6 L9.8,8.9 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                        </g>
                     </svg>`,
            iconSize: undefined, // bug! Leaflet defaults to 12x12 otherwise
        });
    }

    public createVehicleMarker(location: ILatLng, color: Colors, hasPopup?: boolean) {
        const marker = leaflet.marker(location, {
            icon: this.createVehicleIcon(
                svgIconStyleCenter(VEHICLE_ICON_HEIGHT, VEHICLE_ICON_WIDTH),
                '',
                ColorsEnum[color],
            ),
            rotationAngle: 0,
            rotationOrigin: 'top center',
            opacity: FOCUS_OPACITY,
            interactive: true,
        });

        if (hasPopup) {
            marker.bindPopup('', {
                closeOnClick: false,
                className: popupStyle,
                closeButton: false,
            });
        }

        return marker;
    }

    public createVehicleIcon(svgIconStyle: string, hiddenStyle: string, colorStyle: string) {
        return new leaflet.DivIcon({
            className: classNames(svgIconStyle, hiddenStyle),
            html: `<svg data-test-id="vehicle" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                     <g id="directions_car-U0xE9DE" stroke="none" stroke-width="10" fill="none" fill-rule="evenodd">
                         <path class="${colorStyle}" d="M18.92,6.01 C18.72,5.42 18.16,5 17.5,5 L6.5,5 C5.84,5 5.29,5.42 5.08,6.01 L3,12 L3,20 C3,20.55 3.45,21 4,21 L5,21 C5.55,21 6,20.55 6,20 L6,19 L18,19 L18,20 C18,20.55 18.45,21 19,21 L20,21 C20.55,21 21,20.55 21,20 L21,12 L18.92,6.01 Z M6.5,16 C5.67,16 5,15.33 5,14.5 C5,13.67 5.67,13 6.5,13 C7.33,13 8,13.67 8,14.5 C8,15.33 7.33,16 6.5,16 Z M17.5,16 C16.67,16 16,15.33 16,14.5 C16,13.67 16.67,13 17.5,13 C18.33,13 19,13.67 19,14.5 C19,15.33 18.33,16 17.5,16 Z M5,11 L6.5,6.5 L17.5,6.5 L19,11 L5,11 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                     </g>
                 </svg>`,
            iconSize: undefined, // bug! Leaflet defaults to 12x12 otherwise
        });
    }

    public createLabel(
        name: string,
        installationPointName: string,
        color: Colors,
        index: number,
        quantity: number,
    ) {
        const indexedDeviceName =
            index && quantity && index > 0 ? `(${index}/${quantity}) ${name}` : name;
        const ipName = installationPointName
            ? `${indexedDeviceName} - ${installationPointName}`
            : indexedDeviceName;
        const labelText = cropTextMiddle(ipName, LABEL_MAX_LENGTH);
        const width = getTextWidth(labelText);
        return new leaflet.DivIcon({
            className: cx(this.getLabelStyle(color), TextStyle, SmallStyle, HideOverflowStyle),
            iconSize: [width + PIXEL_MARGIN_SIDES, PIXEL_HEIGHT_LABEL],
            html: labelText,
        });
    }

    public createFreeText(
        text: string,
        size?: FontSize,
        color?: string,
        backgroundColor?: string,
        isEditing?: boolean,
    ) {
        const style = css`
            background-color: ${backgroundColor ?? 'transparent'};
            border: 1px solid transparent;
            border-radius: 4px;
            white-space: nowrap;
            text-align: center;
            color: ${color ?? 'black'}!important;
            padding: 4px;
            opacity: 0.7;
            font-size: ${size ?? '10'}px;
            width: auto !important;
            height: auto !important;
        `;
        const selectedStyle = isEditing
            ? css`
                  border: 1px dashed ${ColorsEnum.blue};
              `
            : '';
        return new leaflet.DivIcon({
            className: cx(style, selectedStyle),
            html: text,
        });
    }

    public createTinyDeviceIcon(image: string, rotationAngle: number = 0) {
        return new leaflet.DivIcon({
            html: `<img src=${image} style="pointer-events: none;transform: rotate(${rotationAngle}deg); width:13px;" data-test-id="${image}_icon"/>`,
            iconSize: [24, 24],
            className: tinyDeviceStyle(ColorsEnum.grey1),
        });
    }

    public createSmallDeviceIcon(image: string, color: Colors) {
        return new leaflet.DivIcon({
            html: `<img src=${image} style="pointer-events: none; width:13px;" data-test-id="${image}_icon"/>`,
            className: smallDeviceStyle(ColorsEnum[color]),
            iconSize: [24, 24],
        });
    }

    public createBigDeviceIcon(image: string, color: Colors) {
        return new leaflet.DivIcon({
            html: `<img src=${image} style="pointer-events: none; width: 24px;" data-test-id="${image}_icon"/>`,
            className: bigDeviceStyle(ColorsEnum[color]),
            iconSize: [47, 47],
        });
    }

    public createNumberedIcon(label: string, color: Colors, isChildDevice: boolean) {
        return new leaflet.DivIcon({
            html: `<div><p data-test-id=numbered_icon_${label} class=${labelColorStyle(
                ColorsEnum[color],
                isChildDevice,
            )}>${label}</p></div>`,
            className: numberedDeviceStyle(ColorsEnum[color], isChildDevice),
            iconSize: [22, 22],
        });
    }

    public createLeafletMenuButton(options: ILeafletMenuButtonControl) {
        return new LeafletMenuButtonControl(options);
    }

    private getLabelStyle(color: Colors) {
        const rgbColor = ColorsEnum[color];
        const labelStyle = css`
            background-color: ${rgbColor};
            border-radius: 4px;
            line-height: 15px;
            white-space: nowrap;
            text-align: center;
            color: ${getFontColor(rgbColor)}!important;
            padding: 4px;
            opacity: 0.7;

            @media print {
                overflow: hidden !important;
            }

            &[data-blurred] {
                background-color: ${colorToRgba(rgbColor, 0.4)};
            }
        `;
        return labelStyle;
    }
}
