import { AppConstants } from 'app/AppConstants';
import type { UnitSystem } from 'app/core/persistence';
import type { IPiaSpeaker } from 'app/core/pia';
import { t } from 'app/translate';
import { convert, roundValue } from 'axis-webtools-util';
import type { ISpeakerFilter } from '../models';

const HORN_LENGTH_COVERAGE_OUTDOOR = 140;
const SPEAKER_BASIC_LENGTH_COVERAGE = 6;
const SPEAKER_MIN_INSTALLATION_HEIGHT = 2;
const LISTENING_HEIGHT = 1;

const HORN_SPEAKER_FORM_FACTOR = 'horn';

export const getCeilingSpeakerQuantity = (
    installationHeight: number,
    listeningArea: number,
    basicSolution: boolean,
    coverage: number,
): number => {
    const coverageFactor = coverage >= 140 ? 2.0 : 1.5;
    const basicSpeakerCoverageArea = Math.pow(installationHeight * coverageFactor, 2) * Math.PI;
    const premiumSpeakerCoverageArea = basicSpeakerCoverageArea / 2;
    const speakerCoverageArea = basicSolution
        ? basicSpeakerCoverageArea
        : premiumSpeakerCoverageArea;
    const speakerCount = Math.round(listeningArea / speakerCoverageArea);
    return Math.max(Math.round(speakerCount), 1);
};

export const getWallSpeakerQuantity = (
    installationHeight: number,
    wallLength: number,
    basicSolution: boolean,
    formFactor: string,
    outdoor: boolean,
): number => {
    let basicSpeakerCount;

    const isHornSpeaker = formFactor === HORN_SPEAKER_FORM_FACTOR;

    if (isHornSpeaker && outdoor) {
        // Horn speakers should be calculated differently
        basicSpeakerCount = wallLength / HORN_LENGTH_COVERAGE_OUTDOOR;
    } else {
        basicSpeakerCount = wallLength / getSpeakerCoverageLength(installationHeight);
    }
    const speakerCount = basicSolution ? basicSpeakerCount : basicSpeakerCount * 2;

    // getSpeakerCoverageLength could return 0
    return isFinite(speakerCount) ? Math.max(Math.ceil(speakerCount), 1) : 1;
};

export const getSpeakerCoverageLength = (mountingHeight: number): number => {
    // According to rule of thumb developed by audio team.
    return (
        SPEAKER_BASIC_LENGTH_COVERAGE *
        (Math.max(mountingHeight, SPEAKER_MIN_INSTALLATION_HEIGHT) - LISTENING_HEIGHT)
    );
};

export const getFilterInfoMessage = (
    selectedSpeaker: IPiaSpeaker | undefined,
    filter: ISpeakerFilter,
    selectedSpeakerValid: boolean,
    quantity: number,
): string => {
    const hasSpeaker = selectedSpeaker !== undefined;
    const hasPlacement = !!filter.placement;
    // Check for area or wall value since we don't know in what order the params will be entered
    // If placement has been defined - corresponding value must exist though.
    const hasValue =
        (!hasPlacement &&
            (filter.listeningArea !== undefined || filter.wallLength !== undefined)) ||
        (hasPlacement && filter.placement === 'ceiling' && filter.listeningArea !== undefined) ||
        (hasPlacement && filter.placement === 'wall' && filter.wallLength !== undefined);
    let infoMessage: string;

    if (hasSpeaker && hasPlacement && hasValue) {
        // Check that selected model still matches the filtered speakers.
        if (selectedSpeakerValid) {
            infoMessage =
                quantity === AppConstants.componentQuantityMax
                    ? t.speakerSelectorInfoMessagesMaxQuantityReached(quantity)
                    : '';
        } else {
            infoMessage = t.speakerSelectorInfoMessagesInvalidModel;
        }
    } else if (hasSpeaker && hasPlacement) {
        infoMessage = t.speakerSelectorInfoMessagesValuesMissing;
    } else if (hasSpeaker && hasValue) {
        infoMessage = t.speakerSelectorInfoMessagesPlacementMissing;
    } else if (hasPlacement && hasValue) {
        infoMessage = t.speakerSelectorInfoMessagesModelMissing;
    } else if (hasSpeaker) {
        infoMessage = t.speakerSelectorInfoMessagesPlacementValuesMissing;
    } else if (hasPlacement) {
        infoMessage = t.speakerSelectorInfoMessagesModelValuesMissing;
    } else if (hasValue) {
        infoMessage = t.speakerSelectorInfoMessagesModelPlacementMissing;
    } else {
        infoMessage = t.speakerSelectorInfoMessagesModelPlacementValuesMissing;
    }
    return infoMessage;
};

export const convertToDisplayValue = (
    unitSystem: UnitSystem,
    isAreaUnit: boolean,
    value?: number,
): number | undefined => {
    if (value === undefined) {
        return value;
    }
    if (unitSystem === 'imperial') {
        value = isAreaUnit ? convert.squareMetersToSquareFeet(value) : convert.metersToFeet(value);
    }

    return roundValue(value, 1);
};

export const convertFromDisplayValue = (
    unitSystem: UnitSystem,
    isAreaUnit: boolean,
    value: number,
) => {
    if (unitSystem === 'imperial') {
        value = isAreaUnit ? convert.squareFeetToSquareMeters(value) : convert.feetToMeters(value);
    }

    return value;
};
