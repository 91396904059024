import { UnreachableCaseError } from 'axis-webtools-util';
import { t } from 'app/translate';
import { CategoryEnum } from './Category.enum';

export function translateCategory(category: CategoryEnum): string {
    switch (category) {
        case CategoryEnum.Camera:
            return t.quotationCameras;
        case CategoryEnum.Encoder:
            return t.quotationEncoders;
        case CategoryEnum.AnalogCamera:
            return t.quotationAnalogCamera;
        case CategoryEnum.FSeries:
            return t.quotationFSeries;
        case CategoryEnum.Speaker:
            return t.quotationSpeakers;
        case CategoryEnum.RecordingAndNetwork:
            return t.quotationRecordingAndNetwork;
        case CategoryEnum.Software:
            return t.quotationSoftware;
        case CategoryEnum.Decoder:
            return t.quotationDecoders;
        case CategoryEnum.Pac:
            return t.quotationPacs;
        case CategoryEnum.RadarDetector:
            return t.quotationRadarDetectors;
        case CategoryEnum.ConnectivityDevice:
            return t.otherProjectDeviceSelectorCategoriesGROUP['connectivityDevices'];
        case CategoryEnum.Miscellaneous:
            return t.quotationMiscellaneous;
        case CategoryEnum.DoorStation:
            return t.quotationDoorStations;
        case CategoryEnum.Accessory:
            return t.accessories;
        case CategoryEnum.InstallationService:
            return t.quotationInstallationService;
        case CategoryEnum.Service:
            return t.quotationService;
        case CategoryEnum.PeopleCounters:
            return t.peopleCounters;
        case CategoryEnum.Wearables:
            return t.wearables;
        case CategoryEnum.DoorControllers:
            return t.otherProjectDeviceSelectorCategoriesGROUP['doorControllers'];
        case CategoryEnum.Doors:
            return t.doors;
        case CategoryEnum.Alerters:
            return t.otherProjectDeviceSelectorCategoriesGROUP['audioVisualAlerters'];
        case CategoryEnum.PagingConsole:
            return t.otherProjectDeviceSelectorCategoriesGROUP['pagingConsoles'];
        default:
            throw new UnreachableCaseError(category);
    }
}
