import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { IAuth } from '../../models';
import { AuthState } from '../../models';
import { CommonActionService, TokenService } from '../../services';
import { getAuth } from '../selectors/getAuth';
import { AuthContext } from './AuthContext';
import {
    extendableProps,
    renderReactChildren,
    type IExtendableComponentWithChildren,
} from 'app/components';
import { removeItemLocalStorage } from 'app/core/persistence';

const getSearchParam = (param: string): string | undefined =>
    new URL(window.location.href).searchParams.get(param) || undefined;

const removeCookie = (name: string, domain: string, path: string) => {
    document.cookie = `${encodeURIComponent(name)}=; \
        expires=Thu, 01 Jan 1970 00:00:00 GMT; \
        domain=${domain}; \
        path=${path}`;
};

export const AuthProvider: React.FC<IExtendableComponentWithChildren> = ({
    children,
    ...extendedProps
}) => {
    const [commonActionService] = React.useState(ServiceLocator.get(CommonActionService));
    const [tokenService] = React.useState(ServiceLocator.get(TokenService));
    const auth = useSelector<IStoreState, IAuth>(getAuth);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isAuthorizeCallback = pathname === window.appConfig.auth.authorizationCallback;
    const isLogoutCallback = pathname === window.appConfig.auth.logoutCallback;
    const isCallback = isAuthorizeCallback || isLogoutCallback;

    React.useEffect(() => {
        if (AuthState.NONE === auth.state) {
            if (isAuthorizeCallback) {
                commonActionService.exchangeTokens(getSearchParam('code'));
            } else if (isLogoutCallback) {
                removeItemLocalStorage('LatestSyncInfo');
                removeCookie('axis_auth', '.axis.com', '/');
                navigate('/', { replace: true });
            } else {
                commonActionService.authorize();
            }
        } else if (AuthState.AUTHORIZED === auth.state && isCallback) {
            navigate('/', { replace: true });
        }
    });

    const attributes = extendableProps(extendedProps, {});

    return (
        <AuthContext.Provider
            value={{
                name: auth.name,
                idToken: auth.idToken,
                getAccessToken: tokenService.getAccessToken,
                reauthorize: commonActionService.reauthorize,
            }}
        >
            {renderReactChildren(
                children,
                (child) => React.cloneElement(child, attributes),
                (child) => React.cloneElement(child, attributes.__htmlAttributes),
            )}
        </AuthContext.Provider>
    );
};

AuthProvider.displayName = 'AuthProvider';
