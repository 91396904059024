import { DistanceUnit, distanceUnitShortText } from 'app/core/persistence';
import type {
    Id,
    IInstallationPointSensorModel,
    IItemEntity,
    IPersistence,
} from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { convert, roundValue } from 'axis-webtools-util';
import { createCachedSelector } from 're-reselect';
import { t } from 'app/translate';
import type { IFOVLimits } from 'app/modules/common';
import {
    calculateVerticalFov,
    estimateVerticalFOV,
    getCurrentProjectDisplayUnit,
    getCurrentProjectInstallationPoints,
    getCurrentProjectItems,
    getCurrentProjectUnitSystem,
    getIdFromProps,
    getPiaCamera,
    getPixelDensityTextForSensor,
    getPixelsPerUnitSystemAbbreviation,
    toCacheKey,
} from 'app/modules/common';
import {
    getProjectInstallationPoints,
    getProjectSensorDeviceInstallationPoints,
} from 'app/modules/maps';
import { convertDensityToMeters } from 'app/core/common';

// itemId must be provided even thought it is not used because re-select expects arguments to be in the correct order
const getInstallationPointIdFromProps = (
    _state: IStoreState,
    _itemId: Id,
    installationPointId: Id,
) => installationPointId;

const getInstallationHeightForItem = (item: IPersistence<IItemEntity> | undefined) => {
    return (
        item?.properties.camera?.filter.installationHeight ??
        item?.properties.sensorUnit?.filter.installationHeight ??
        item?.properties.speaker?.filter.installationHeight ??
        undefined
    );
};

/**
 * Get installation height for installation point or item.
 * If installation point id is missing, the installation height on the item filter will be returned instead.
 */
export const getInstallationHeightText = createCachedSelector(
    [
        getCurrentProjectInstallationPoints,
        getCurrentProjectUnitSystem,
        getCurrentProjectItems,
        getIdFromProps,
        getInstallationPointIdFromProps,
    ],
    (installationPoints, unitSystem, currentProjectItems, itemId, installationPointId) => {
        const height =
            installationPointId && installationPointId !== 'unplaced'
                ? installationPoints[installationPointId]?.height
                : itemId
                  ? getInstallationHeightForItem(currentProjectItems[itemId])
                  : undefined;

        if (!height) return undefined;

        if (unitSystem === 'imperial') {
            return `${roundValue(convert.metersToFeet(height), 2)} ${distanceUnitShortText(
                DistanceUnit.Feet,
            )}`;
        }

        return `${roundValue(height, 2)} ${distanceUnitShortText(DistanceUnit.Meter)}`;
    },
)(toCacheKey);

/**
 * @description Returns translated string for focal length
 * in mm if that information is provided in pia.
 * @param state storeState
 * @param itemId Id for the camera which focal length you want to retrieve
 */
export const getCameraFocalLengthString = createCachedSelector([getPiaCamera], (piaCamera) => {
    //CharCode 160 represents non breaking space
    const nonBreakingSpace = String.fromCharCode(160);
    if (!piaCamera) {
        return '';
    }
    const focalLength = piaCamera.properties.focalLength;
    if (
        piaCamera.properties.maxHorizontalFOV === 0 &&
        piaCamera.properties.minHorizontalFOV === 0
    ) {
        return t.noLens;
    }

    if (!piaCamera.properties.focalLength) {
        return '';
    }

    if (focalLength?.min === focalLength?.max) {
        return `${focalLength?.min}${nonBreakingSpace}mm`;
    }

    return `${focalLength?.min} - ${focalLength?.max}${nonBreakingSpace}mm`;
})(toCacheKey);

/**
 * Get field of view for the sensors of the installation point, return array of IFieldOfView (horizontal and vertical fov strings)
 * If installation point id is missing or ip is unplaced empty array is returned
 */
export const getFovStrings = createCachedSelector(
    [getProjectInstallationPoints, getPiaCamera, getIdFromProps, getInstallationPointIdFromProps],
    (installationPoints, piaCamera, itemId, installationPointId) => {
        if (!piaCamera) return [];
        if (!itemId) return [];
        if (!(installationPointId && installationPointId !== 'unplaced')) return [];

        const installationPoint = installationPoints.find((ip) => ip._id === installationPointId);
        if (!installationPoint) return [];

        const fov = installationPoint.sensors.map((sensor) => {
            return getStringAngles(
                sensor.settings.horizontalFov,
                sensor.fovLimits,
                sensor.settings.corridorFormat,
                installationPoint.parentId,
            );
        });

        return fov;
    },
)(toCacheKey);

/**
 * Returns array of boolean for corridor format of the sensors for the installation point (true for corridor format).
 * If installation point id is missing or ip is unplaced empty array is returned
 */
export const getCorridor = createCachedSelector(
    [
        getCurrentProjectInstallationPoints,
        getPiaCamera,
        getIdFromProps,
        getInstallationPointIdFromProps,
    ],
    (installationPoints, piaCamera, itemId, installationPointId) => {
        if (!piaCamera) return [];
        if (!itemId) return [];
        if (!(installationPointId && installationPointId !== 'unplaced')) return [];

        const corridor = installationPoints[installationPointId]?.sensors.map(
            (sensor) => sensor.settings.corridorFormat,
        );

        return corridor ?? [];
    },
)(toCacheKey);

/**
 * Returns pixel density value strings for each sensor at target distance for sensors
 */
export const getPixelDensityTarget = createCachedSelector(
    [
        getProjectSensorDeviceInstallationPoints,
        getPixelsPerUnitSystemAbbreviation,
        getCurrentProjectDisplayUnit,
        getIdFromProps,
        getInstallationPointIdFromProps,
    ],
    (installationPoints, pixelPerUnit, distanceUnit, _itemId, installationPointId) => {
        const installationPoint = installationPoints.find((ip) => ip?._id === installationPointId);
        if (!(installationPointId && installationPointId !== 'unplaced')) return [];
        if (!installationPoint) return [];

        const pxDensityTarget = installationPoint.sensors.map((sensor, index) =>
            getPixelDensityTextForSensor(
                sensor,
                index + 1,
                installationPoint,
                pixelPerUnit,
                distanceUnit,
            ),
        );
        return pxDensityTarget;
    },
)(toCacheKey);

/** return string array of target distance for sensors */
export const getTargetDistance = createCachedSelector(
    [getProjectInstallationPoints, getCurrentProjectDisplayUnit, getIdFromProps],
    (installationPoints, displayUnit, installationPointId) => {
        if (!(installationPointId && installationPointId !== 'unplaced')) return [];
        const installationPoint = installationPoints.find((ip) => ip._id === installationPointId);

        if (!installationPoint) return [];
        const distanceUnitText = distanceUnitShortText(displayUnit);

        const targetDistanceString = installationPoint.sensors.map((sensor) =>
            getTargetDistanceForSensor(sensor, distanceUnitText, displayUnit),
        );
        return targetDistanceString;
    },
)(toCacheKey);

/**
 * Get vertical fov
 * @param horizontalFov
 * @param fovLimits min and max values of both horizontal and vertical
 * @param parentId The installation point parent id if any.(e.g. main units, encoders that can have other installation points as children)
 * @returns vertical fov in degrees
 */
const getVerticalFov = (horizontalFov: number, fovLimits?: IFOVLimits, parentId?: Id) => {
    return parentId && fovLimits
        ? calculateVerticalFov(
              horizontalFov,
              fovLimits.horizontal.max,
              fovLimits.horizontal.min,
              fovLimits.vertical.max,
              fovLimits.vertical.min,
          )
        : estimateVerticalFOV(horizontalFov);
};

export interface IFieldOfView {
    vertical: string;
    horizontal: string;
}

/**
 * Return field of view for the device (i.e vertical fov if corridor format horizontal fov otherwise)
 * @param horizontalFov horizontal fov for the camera
 * @param fovLimits min and max values of both horizontal and vertical
 * @param corridorFormat true if corridor format is used
 * @param parentId The installation point parent id if any.(e.g. main units, encoders that can have other installation points as children)
 * @returns field of view value in degrees as string
 */
const getStringAngles = (
    horizontalFov: number,
    fovLimits: IFOVLimits,
    corridorFormat: boolean,
    parentId?: Id,
): IFieldOfView => {
    const horizontal = Math.round(horizontalFov * 10) / 10;
    const vertical =
        Math.abs(Math.round(getVerticalFov(horizontalFov, fovLimits, parentId) * 10)) / 10; // matu absolutbeloppet
    if (!corridorFormat) {
        return { horizontal: `${horizontal}°`, vertical: `${vertical}°` };
    }

    return { horizontal: `${vertical}°`, vertical: `${horizontal}°` };
};

/**
 * Returns target distance for one sensor
 * @param currentSensor the sensor to get pixel density for
 * @param unit used for project
 * @param distanceUnit unit used for distance
 * @returns string including value and unit
 */
const getTargetDistanceForSensor = (
    currentSensor: IInstallationPointSensorModel,
    unit: string,
    distanceUnit: DistanceUnit,
) => {
    const distanceInCorrectUnit = convertDensityToMeters(
        currentSensor.target.distance,
        distanceUnit,
    ).toFixed(1);

    return `${distanceInCorrectUnit} ${unit}`;
};
