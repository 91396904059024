import { Module } from 'app/ioc';
import {
    ExportProjectActionService,
    ProjectSettingsExporterService,
    CamerasExporterService,
    EncodersExporterService,
    MainUnitsExporterService,
    ScheduleExporterService,
    ProjectSettingsCommunicator,
    SpeakersExporterService,
    ZonesExporterService,
    RadarsExporterService,
    PacsExporterService,
    DoorStationsExporterService,
    DecoderExporterService,
    MapsExporterService,
    PeopleCountersExporterService,
    AlertersExporterService,
    ConnectivityDevicesExporterService,
    PagingConsolesExporterService,
} from './services';
import { exportProjectSettingsReducer } from './ExportProjectSettingsReducer';

@Module({
    binder: (binder) => {
        binder.bindService(ExportProjectActionService);
        binder.bindService(ProjectSettingsExporterService);
        binder.bindService(CamerasExporterService);
        binder.bindService(EncodersExporterService);
        binder.bindService(MainUnitsExporterService);
        binder.bindService(ScheduleExporterService);
        binder.bindService(ProjectSettingsCommunicator);
        binder.bindService(SpeakersExporterService);
        binder.bindService(ZonesExporterService);
        binder.bindService(RadarsExporterService);
        binder.bindService(PacsExporterService);
        binder.bindService(DoorStationsExporterService);
        binder.bindService(DecoderExporterService);
        binder.bindService(MapsExporterService);
        binder.bindService(PeopleCountersExporterService);
        binder.bindService(AlertersExporterService);
        binder.bindService(ConnectivityDevicesExporterService);
        binder.bindService(PagingConsolesExporterService);
    },
    reducers: {
        exportProjectSettings: exportProjectSettingsReducer,
    },
})
export class ProjectSettingsShareModule {}
