import type { PiaId } from 'app/core/pia';
import { DEFAULT_ZOOM_LEVEL } from './mapConstants';
import type {
    AddDeviceTabName,
    BlockerEditState,
    IAddDeviceTab,
    ISelectedCoverageAreaInfo,
    ISelectedMapItem,
} from './models/IMapsState';
import { reducerBuilder } from 'app/store';
import { MapsActions } from './actions';
import type {
    IMapsState,
    IPressedModifierKeys,
    IDuplicationInfo,
    IFileTransferState,
    IFloorPlanGeolocationChanges,
} from './models';
import type {
    IBounds,
    Id,
    IFloorPlanEntity,
    ILatLng,
    IInstallationPointModel,
} from 'app/core/persistence';
import { PersistenceActions } from 'app/core/persistence';
import { isEmpty } from 'lodash-es';

const initialFileTransfers: IFileTransferState[] = [];

const initialState: IMapsState = {
    selectedAddTab: { name: 'cameras', deviceTypes: ['camera'] },
    selectedDragProduct: {} as Record<AddDeviceTabName, PiaId | null>,
    addCameraSearchFilter: '',
    selectedMap: undefined,
    uploading: false,
    showAddMapModal: false,
    showAddLocationModal: false,
    showCopyMapModal: false,
    showDevicesOnMapToggle: true,
    selected: undefined,
    enableScalingTool: false,
    doriOn: false,
    fileTransfers: initialFileTransfers,
    enable3dView: false,
    zoomLevel: DEFAULT_ZOOM_LEVEL,
    pressedModifierKeys: { isAltDown: false, isControlDown: false, isShiftDown: false },
    duplicationInfo: null,
    showRadarCoexistenceWarning: false,
    errorMessage: false,
    mapViewBounds: {} as Record<Id, IBounds | undefined>,
    showFreeTextTool: false,
    showMeasureTool: false,
    showKeyboardShortcuts: false,
    showGetToKnowMaps: false,
    blockerEditState: 'none',
    floorPlanToGeolocate: null,
    floorPlanGeoLocationChanges: {},
    defaultGeoLocationChanged: false,
    focusedFloorPlanId: undefined,
    desiredBounds: {} as Record<Id, IBounds>,
    floorPlanConfigOverlayPanel: false,
    originFilter: undefined,
    draftInstallationPoints: {},
};

export const mapsReducer = reducerBuilder<IMapsState>()
    .setInitialState(initialState)
    .onAction<Partial<IMapsState['selected']> | undefined>(
        MapsActions.SelectItem,
        (state, action) => {
            return {
                ...state,
                selected: action.payload
                    ? {
                          ...state.selected,
                          ...action.payload,
                          type: action.payload?.type ?? state.selected?.type ?? 'device',
                          multiSelected:
                              action.payload?.multiSelected ?? state.selected?.multiSelected ?? {},
                      }
                    : undefined,
            };
        },
    )
    .onAction<Partial<ISelectedMapItem>>(MapsActions.UpdateSelectedMapItem, (state, action) => {
        if (!state.selected?.mapItem) return state;

        return {
            ...state,
            selected: {
                ...state.selected,
                mapItem: {
                    ...state.selected.mapItem,
                    ...action.payload,
                },
                type: isEmpty(state.selected.multiSelected)
                    ? 'installationPoint'
                    : 'multiInstallationPoint',
            },
        };
    })
    .onAction<ISelectedCoverageAreaInfo | undefined>(
        MapsActions.UpdateSelectedCoverageArea,
        (state, action) => ({
            ...state,
            selected: {
                ...state.selected,
                type: isEmpty(state?.selected?.multiSelected)
                    ? 'installationPoint'
                    : 'multiInstallationPoint',
                coverageAreaInfo: action.payload,
                multiSelected: { ...state.selected?.multiSelected },
            },
        }),
    )
    .onAction<IInstallationPointModel>(MapsActions.SetDraftInstallationPoint, (state, action) => ({
        ...state,
        draftInstallationPoints: {
            ...state.draftInstallationPoints,
            [action.payload._id]: action.payload,
        },
    }))
    .onAction<Id>(MapsActions.RemoveDraftInstallationPoint, (state, action) => {
        const draftInstallationPoints = { ...state.draftInstallationPoints };
        delete draftInstallationPoints[action.payload];
        return {
            ...state,
            draftInstallationPoints,
        };
    })
    .onAction<Id>(MapsActions.SetFocusedFloorPlanId, (state, action) => {
        return {
            ...state,
            showMeasureTool: false,
            showRadarCoexistenceWarning: false,
            enableScalingTool: false,
            focusedFloorPlanId: action.payload,
        };
    })
    .onAction<void>(MapsActions.UnsetFocusedFloorPlanId, (state) => {
        return {
            ...state,
            focusedFloorPlanId: undefined,
        };
    })
    .onAction<void>(MapsActions.ClearMultiSelected, (state) => {
        return {
            ...state,
            selected: {
                ...state.selected,
                multiSelected: {},
                type: 'installationPoint',
            },
        };
    })
    .onAction<boolean>(MapsActions.ToggleAddMapModal, (state, action) => ({
        ...state,
        showAddMapModal: action.payload,
        uploadErrorMessage: undefined,
    }))
    .onAction<boolean>(MapsActions.ToggleAddLocationModal, (state, action) => ({
        ...state,
        showAddLocationModal: action.payload,
    }))
    .onAction<boolean>(MapsActions.ToggleCopyMapModal, (state, action) => ({
        ...state,
        showCopyMapModal: action.payload,
        uploadErrorMessage: undefined,
    }))
    .onAction<IAddDeviceTab>(MapsActions.SetSelectedAddTab, (state, action) => ({
        ...state,
        selectedAddTab: action.payload,
    }))
    .onAction<string>(MapsActions.SetAddCameraSearchFilter, (state, action) => ({
        ...state,
        addCameraSearchFilter: action.payload,
    }))
    .onAction<{ tabName: AddDeviceTabName; piaId: PiaId | null }>(
        MapsActions.SetSelectedDragProduct,
        (state, action) => {
            const selectedDragProduct = {
                ...state.selectedDragProduct,
                [action.payload.tabName]: action.payload.piaId,
            };
            return {
                ...state,
                selectedDragProduct,
            };
        },
    )
    .onAction<number>(MapsActions.SetZoomLevel, (state, action) => ({
        ...state,
        zoomLevel: action.payload,
    }))
    .onAction<boolean>(MapsActions.ToggleScalingTool, (state, action) => ({
        ...state,
        enableScalingTool: action.payload === false ? false : !state.enableScalingTool,
        showMeasureTool: false,
    }))
    .onAction<undefined>(MapsActions.Close3dView, (state) => ({
        ...state,
        enable3dView: false,
    }))
    .onAction<undefined>(MapsActions.Toggle3dView, (state) => ({
        ...state,
        enable3dView: !state.enable3dView,
    }))
    .onAction<boolean | undefined>(MapsActions.ToggleDoriPixels, (state, action) => ({
        ...state,
        doriOn: action.payload ? action.payload : !state.doriOn,
    }))
    .onAction<boolean>(MapsActions.ToggleUploading, (state, action) => ({
        ...state,
        uploading: action.payload,
    }))
    .onAction<boolean>(MapsActions.ToggleShowDevicesOnMap, (state, action) => ({
        ...state,
        showDevicesOnMapToggle: action.payload,
    }))
    .onAction<boolean>(MapsActions.ToggleGetToKnowMaps, (state, action) => ({
        ...state,
        showGetToKnowMaps: action.payload ?? !state.showGetToKnowMaps,
    }))
    .onAsyncAction(MapsActions.AddFloorPlan, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                uploading: true,
                uploadErrorMessage: undefined,
                showMeasureTool: false,
            }))
            .onFulfilled<IFloorPlanEntity>((state, action) => ({
                ...state,
                selectedMap: action.payload._id,
                uploading: false,
                showAddMapModal: false,
                showAddLocationModal: false,
                showCopyMapModal: false,
                floorPlanConfigOverlayPanel: false,
                showRadarCoexistenceWarning: false,
            }))
            .onRejected<Error>((state, action) => ({
                ...state,
                showAddMapModal: true,
                uploading: false,
                uploadErrorMessage: action.payload.message,
            }));
    })
    .onAsyncAction(MapsActions.ChangeFloorPlan, (handler) => {
        handler
            .onPending((state) => ({
                ...state,
                uploading: true,
                uploadErrorMessage: undefined,
                showMeasureTool: false,
            }))
            .onFulfilled<IFloorPlanEntity>((state, action) => ({
                ...state,
                selectedMap: action.payload._id,
                uploading: false,
                showAddMapModal: false,
                showRadarCoexistenceWarning: false,
                showMeasureTool: false,
                floorPlanConfigOverlayPanel: false,
            }))
            .onRejected<Error>((state, action) => ({
                ...state,
                showAddMapModal: true,
                uploading: false,
                uploadErrorMessage: action.payload.message,
            }));
    })
    .onAsyncAction(MapsActions.RemoveInstallationPoint, (handler) => {
        handler.onFulfilled<string>((state, action) => ({
            ...state,
            selected: {
                type: 'device',
                deviceId: action.payload,
                multiSelected: {},
            },
        }));
    })
    .onAsyncAction(MapsActions.RemoveFloorPlan, (handler) => {
        handler.onFulfilled<string | undefined>((state, action) =>
            action.payload
                ? {
                      ...state,
                      selected: undefined,
                      selectedMap: undefined,
                  }
                : state,
        );
    })
    .onAction<Id>(MapsActions.SetSelectedMap, (state, action) => ({
        ...state,
        selected: undefined,
        selectedMap: action.payload,
        floorPlanToGeolocate: null,
        showMeasureTool: false,
        showRadarCoexistenceWarning: false,
        enableScalingTool: false,
        floorPlanConfigOverlayPanel: false,
        floorPlanGeoLocationChanges: {},
    }))
    .onAction<undefined>(MapsActions.ResetToInitialState, () => ({
        ...initialState,
    }))
    .onAction<undefined>(PersistenceActions.PopRedoAction, (state) => ({
        ...state,
        selected: undefined,
    }))
    .onAction<undefined>(PersistenceActions.PopUndoAction, (state) => ({
        ...state,
        selected: undefined,
    }))
    .onAction<number>(MapsActions.AddFileTransfer, (state, action) => ({
        ...state,
        fileTransfers: state.fileTransfers.concat({ id: action.payload, loaded: 0, total: 1 }),
    }))
    .onAction<IFileTransferState>(MapsActions.UpdateFileTransfer, (state, action) => ({
        ...state,
        fileTransfers: state.fileTransfers.map((transfer: IFileTransferState) => {
            if (transfer.id === action.payload.id) {
                return { ...action.payload };
            } else {
                return transfer;
            }
        }),
    }))
    .onAction<number>(MapsActions.EndFileTransfer, (state, action) => ({
        ...state,
        fileTransfers: state.fileTransfers.map((transfer: IFileTransferState) => {
            if (transfer.id === action.payload) {
                return { ...transfer, loaded: transfer.total };
            } else {
                return transfer;
            }
        }),
    }))
    .onAction<number>(MapsActions.ClearFileTransfers, (state) => ({
        ...state,
        fileTransfers: [],
    }))
    .onAction<IPressedModifierKeys>(MapsActions.SetPressedModifierKeys, (state, action) => ({
        ...state,
        pressedModifierKeys: action.payload,
    }))
    .onAction<IDuplicationInfo | null>(MapsActions.SetDuplicationInfo, (state, action) => ({
        ...state,
        duplicationInfo: action.payload,
    }))
    .onAction<boolean>(MapsActions.SetRadarCoexistingShowWarning, (state, action) => ({
        ...state,
        showRadarCoexistenceWarning: action.payload,
    }))
    .onAction<boolean>(MapsActions.SetErrorMessage, (state, action) => ({
        ...state,
        errorMessage: action.payload,
    }))
    .onAction<boolean>(MapsActions.DefaultGeoLocationChanged, (state, action) => ({
        ...state,
        defaultGeoLocationChanged: action.payload,
    }))
    .onAction<{ floorPlanId: Id; bounds: IBounds | undefined }>(
        MapsActions.SetMapViewBounds,
        (state, action) => {
            return {
                ...state,
                mapViewBounds: {
                    ...state.mapViewBounds,
                    [action.payload.floorPlanId]: action.payload.bounds,
                },
            };
        },
    )
    .onAction<boolean>(MapsActions.ToggleFreeTextTool, (state, action) => ({
        ...state,
        showFreeTextTool: action.payload,
    }))
    .onAction<boolean>(MapsActions.ToggleMeasureTool, (state, action) => ({
        ...state,
        showMeasureTool: action.payload,
    }))
    .onAction<BlockerEditState>(MapsActions.UpdateBlockerEditState, (state, action) => ({
        ...state,
        blockerEditState: action.payload,
    }))
    .onAction<{ isOpen: boolean; initialTab: 'accessories' | 'applications' } | undefined>(
        MapsActions.ToggleOverlayPanel,
        (state, action) => {
            if (state.deviceOverlayPanel?.isOpen) {
                return { ...state, deviceOverlayPanel: undefined };
            }

            return {
                ...state,
                deviceOverlayPanel: action.payload,
            };
        },
    )
    .onAction<boolean | undefined>(
        MapsActions.ToggleFloorPlanConfigOverlayPanel,
        (state, action) => {
            return {
                ...state,
                floorPlanConfigOverlayPanel:
                    action.payload === undefined
                        ? !state.floorPlanConfigOverlayPanel
                        : action.payload,
            };
        },
    )
    .onAction<Id | null>(MapsActions.ToggleGeoLocationTool, (state, action) => ({
        ...state,
        floorPlanToGeolocate: action.payload,
        focusedFloorPlanId: undefined,
        // unset any changes when the tool is disabled
        floorPlanGeoLocationChanges: action.payload ? state.floorPlanGeoLocationChanges : {},
    }))
    .onAction<IFloorPlanGeolocationChanges>(
        MapsActions.UpdateFloorPlanLocationChangeState,
        (state, action) => ({
            ...state,
            floorPlanGeoLocationChanges: {
                ...state.floorPlanGeoLocationChanges,
                ...action.payload,
            },
        }),
    )
    .onAction<ILatLng>(MapsActions.SetCurrentMapLocation, (state, action) => ({
        ...state,
        currentMapLocation: action.payload,
    }))
    .onAction<{ id: Id; bounds: IBounds | undefined }>(
        MapsActions.SetDesiredBounds,
        (state, action) => ({
            ...state,
            desiredBounds: {
                ...state.desiredBounds,
                [action.payload.id]: action.payload.bounds,
            },
        }),
    )
    .onAction<Id | undefined>(MapsActions.SetOriginFilter, (state, action) => ({
        ...state,
        originFilter: action.payload === state.originFilter ? undefined : action.payload,
    }))
    .create();
