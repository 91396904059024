import { injectable } from 'inversify';
import { clamp, debounce } from 'lodash-es';
import type {
    IItemEntity,
    IInstallationPoint,
    IInstallationPointEntity,
    IPersistence,
    Id,
    IIdRev,
    IInstallationPointSensor,
    IInstallationPointPanRange,
    IInstallationPointSpeaker,
    IInstallationPointRadar,
} from '../userDataPersistence';
import { InstallationPointRepository } from '../userDataPersistence';
import type { ILatLng, DeviceAndSubType } from '../models';
import type {
    IPiaSpeaker,
    IPiaCamera,
    PiaId,
    IPiaRelationPropertyItem,
    IPiaCameraProperties,
    IPiaDevice,
    IPiaPac,
} from 'app/core/pia';
import { PiaItemPacCategory, PiaItemService, isCameraCategory } from 'app/core/pia';
import { InstallationPointDeviceService } from './InstallationPointDevice.service';
import { ParentChildInstallationPointService } from './ParentChildInstallationPoint.service';
import { DuplicationService } from './Duplication.service';
import { QuantityUpdateVerifier } from './QuantityUpdateVerifier.service';
import { t } from 'app/translate';
import { getParentId } from '../utils/getParentId';
import { getDefaultSensors, isCamera, isDevice, isDoorStation } from '../utils';
import { ModalService } from 'app/modal';
import { ItemService } from './item/Item.service';
import { CurrentProjectService } from './CurrentProject.service';

@injectable()
export class InstallationPointService {
    public updateInstallationPointDebounced = debounce(this.updateInstallationPoint, 400);
    public removeInstallationPointDebounced = debounce(this.removeInstallationPoint, 400);

    constructor(
        private installationPointRepository: InstallationPointRepository,
        private piaCameraService: PiaItemService<IPiaCamera>,
        private piaSpeakerService: PiaItemService<IPiaSpeaker>,
        private piaPacService: PiaItemService<IPiaPac>,
        private installationPointDeviceService: InstallationPointDeviceService,
        private parentChildInstallationPointService: ParentChildInstallationPointService,
        private duplicationService: DuplicationService,
        private modalService: ModalService,
        private itemService: ItemService,
        private currentProjectService: CurrentProjectService,
        quantityUpdateVerifier: QuantityUpdateVerifier,
    ) {
        quantityUpdateVerifier.observeQuantityUpdates((entity, newQuantity) =>
            this.isQuantityUpdateOk(entity, newQuantity),
        );
    }

    public getInstallationPoint(id: Id) {
        return this.installationPointRepository.get(id);
    }
    public getAllInstallationPoints() {
        return this.installationPointRepository.getAll();
    }

    public async getInstallationPointDescendants(parentId: Id) {
        return this.installationPointRepository.getDescendants(parentId);
    }

    public async addInstallationPointFromType(
        item: IPersistence<IItemEntity>,
        mapId: Id,
        location: ILatLng,
        type: DeviceAndSubType,
        options: {
            lenses?: IPersistence<IItemEntity>[];
            global?: boolean;
        } = {},
    ): Promise<IInstallationPointEntity | IInstallationPointEntity[]> {
        if (item.properties.camera) {
            return this.installationPointDeviceService.addCameraInstallationPoint(
                item,
                mapId,
                location,
                options,
            );
        } else if (item.properties.speaker) {
            return this.installationPointDeviceService.addSpeakerInstallationPoint(
                item,
                mapId,
                location,
                options,
            );
        } else if (item.properties.radarDetector) {
            return this.installationPointDeviceService.addRadarDetectorInstallationPoint(
                item,
                mapId,
                location,
                options,
            );
        } else if (
            item.properties.peopleCounter ||
            item.properties.alerter ||
            item.properties.decoder ||
            item.properties.systemController ||
            item.properties.dockingStation ||
            (item.properties.pac &&
                !this.isExpandableIoRelay(item) &&
                !this.isExpansionModule(item)) ||
            item.properties.connectivityDevice ||
            item.properties.pagingConsole
        ) {
            return this.installationPointDeviceService.addDeviceInstallationPoint(
                item,
                mapId,
                location,
                options,
            );
        } else if (
            item.properties.mainUnit ||
            item.properties.encoder ||
            item.properties.doorController ||
            (item.properties.pac && this.isExpandableIoRelay(item))
        ) {
            return this.parentChildInstallationPointService.addParentUnitWithChildren(
                item,
                mapId,
                location,
                item.properties.pac && this.isExpandableIoRelay(item) ? 'iorelay' : type,
                options,
            );
        } else if (
            item.properties.sensorUnit ||
            item.properties.analogCamera ||
            item.properties.door ||
            (item.properties.pac && this.isExpansionModule(item))
        ) {
            return this.parentChildInstallationPointService.addChildUnitToAllInstallationPointParents(
                item,
                location,
                type,
            );
        } else {
            throw new Error('Maps installationPoint not supported');
        }
    }

    public async removeInstallationPoint(
        installationPointId: Id,
        installationPointRev: string,
    ): Promise<Id> {
        const allIps = await this.getAllInstallationPoints();
        const descendants = allIps.filter((ip) => ip.parentId === installationPointId);
        if (descendants) {
            await this.removeInstallationPoints(descendants);
        }
        return this.installationPointRepository.delete(installationPointId, installationPointRev);
    }

    public removeInstallationPoints(installationPoints: IIdRev[]): Promise<IIdRev[]> {
        return this.installationPointRepository.bulkDelete(installationPoints);
    }

    /**
     * @deprecated
     * Use the more general updateSensor instead
     *
     * Updates horizontalFov for an individual sensor on an installation point
     *
     * @param installationPointId Id of the installation point whose sensor will be updated
     * @param sensorIndex Index of the sensor that will be updated
     * @param newHorizontalFov The horizontalFov value that will override the current value
     * @returns Updated installation point
     */
    public async updateSensorHorizontalFov(
        installationPointId: Id,
        sensorIndex: number,
        newHorizontalFov: number,
    ) {
        const installationPoint = await this.installationPointRepository.get(installationPointId);
        installationPoint.sensors[sensorIndex].settings.horizontalFov = newHorizontalFov;

        return this.installationPointRepository.update(installationPoint);
    }

    public async updateSensor(installationPointId: Id, sensor: IInstallationPointSensor) {
        const installationPoint = await this.installationPointRepository.get(installationPointId);
        const ipSensor: IInstallationPointSensor = {
            parentPiaDeviceId: sensor.parentPiaDeviceId,
            isVirtual: sensor.isVirtual,
            sensorId: sensor.sensorId,
            target: sensor.target,
            settings: sensor.settings,
        };

        installationPoint.sensors[sensor.sensorId - 1] = ipSensor;
        return this.installationPointRepository.update(installationPoint);
    }

    public async updateSensors(installationPointId: Id, sensors: IInstallationPointSensor[]) {
        const installationPoint = await this.installationPointRepository.get(installationPointId);
        const ipSensors: IInstallationPointSensor[] = sensors.map((sensor) => ({
            parentPiaDeviceId: sensor.parentPiaDeviceId,
            isVirtual: sensor.isVirtual,
            sensorId: sensor.sensorId,
            target: sensor.target,
            settings: sensor.settings,
        }));

        installationPoint.sensors = ipSensors;

        return this.installationPointRepository.update(installationPoint);
    }

    public async updateCoverageArea(
        installationPointId: Id,
        area: {
            panRange?: IInstallationPointPanRange;
            speaker?: IInstallationPointSpeaker;
            radar?: IInstallationPointRadar;
        },
    ): Promise<IPersistence<IInstallationPointEntity>> {
        const installationPoint = await this.installationPointRepository.get(installationPointId);
        installationPoint.panRange = area.panRange ?? installationPoint.panRange;
        installationPoint.speaker = area.speaker ?? installationPoint.speaker;
        installationPoint.radar = area.radar ?? installationPoint.radar;
        return this.installationPointRepository.update(installationPoint);
    }

    public async updateInstallationPoint(
        installationPointId: Id,
        installationPoint: IInstallationPoint,
    ): Promise<IPersistence<IInstallationPointEntity>> {
        const oldInstallationPoint =
            await this.installationPointRepository.get(installationPointId);

        const sensors: IInstallationPointEntity['sensors'] = installationPoint.sensors.map(
            (sensor) => ({
                parentPiaDeviceId: sensor.parentPiaDeviceId,
                isVirtual: sensor.isVirtual,
                sensorId: sensor.sensorId,
                settings: sensor.settings,
                target: sensor.target,
            }),
        );

        return this.installationPointRepository.update({
            _id: oldInstallationPoint._id,
            _rev: oldInstallationPoint._rev,
            path: oldInstallationPoint.path,
            archived: oldInstallationPoint.archived,
            creationDate: oldInstallationPoint.creationDate,
            entityVersion: oldInstallationPoint.entityVersion,
            locked: oldInstallationPoint.locked,
            updatedDate: oldInstallationPoint.updatedDate,
            type: 'installationPoint',
            floorPlanId: oldInstallationPoint.floorPlanId,
            mapOrigin: oldInstallationPoint.mapOrigin,
            height: installationPoint.height,
            location: installationPoint.location,
            labelOffset: installationPoint.labelOffset,
            sensors: sensors,
            panRange: installationPoint.panRange,
            speaker: installationPoint.speaker,
            radar: installationPoint.radar,
            parentId: installationPoint.parentId,
            name: installationPoint.name,
            serialNumber: installationPoint.serialNumber,
        });
    }

    public async updateInstallationPoints(
        installationPoints: IInstallationPointEntity[],
    ): Promise<IInstallationPointEntity[]> {
        return this.installationPointRepository.bulkUpdate(installationPoints);
    }

    public async updateLabelOffset(
        installationPointId: Id,
        labelOffset: IInstallationPoint['labelOffset'],
    ): Promise<IPersistence<IInstallationPointEntity>> {
        const installationPoint = await this.installationPointRepository.get(installationPointId);

        return this.installationPointRepository.update({
            ...installationPoint,
            labelOffset,
        });
    }

    public async updateInstallationPointsToNewDevice(
        itemId: Id,
        editCameraPiaId: PiaId | null,
        installationPoints: IInstallationPointEntity[],
        lensRelationProperties?: IPiaRelationPropertyItem,
    ) {
        const item = await this.itemService.getItem(itemId);

        const newPiaDevice = item.productId
            ? this.piaCameraService.get(item.productId).first()
            : undefined;

        const isNewDeviceCamera = newPiaDevice && isCameraCategory(newPiaDevice.category);

        const isNewDeviceMultiDirectional =
            isNewDeviceCamera && newPiaDevice?.properties.panCameraType === 'Multidirectional';

        const oldPiaDevice = editCameraPiaId
            ? this.piaCameraService.get(editCameraPiaId).first()
            : undefined;

        const isOldDeviceCamera = oldPiaDevice && isCameraCategory(oldPiaDevice.category);

        const isOldDeviceMultiDirectional =
            isOldDeviceCamera && oldPiaDevice?.properties.panCameraType === 'Multidirectional';

        if (isDoorStation(newPiaDevice)) {
            // Add sensors if updating from a door station without a camera to a door station with a camera
            if (isCamera(newPiaDevice) && installationPoints.some((ip) => ip.sensors.length < 1)) {
                const filter = item.properties.camera?.filter;
                const newSensors = filter
                    ? getDefaultSensors(
                          filter.horizontalFov,
                          filter.panoramaMode,
                          filter.distanceToTarget,
                          filter.targetHeight,
                          filter.corridorFormat,
                          newPiaDevice,
                          1,
                      )
                    : [];

                installationPoints.map((installationPoint) => {
                    installationPoint.sensors = newSensors;
                });
            }

            // Remove sensors when updating from a door station with a camera to a door station without a camera
            if (!isCamera(newPiaDevice)) {
                installationPoints.map((installationPoint) => (installationPoint.sensors = []));
            }
        }

        // change ip values if needed:
        const newPiaDeviceMinHorizontalFoV = !newPiaDevice
            ? item.properties.camera?.filter.panoramaMode
                ? 180
                : 1
            : isNewDeviceMultiDirectional && newPiaDevice.properties.minLensCalcFOV
              ? newPiaDevice.properties.minLensCalcFOV
              : newPiaDevice.properties.minHorizontalFOV;

        const newPiaDeviceMaxHorizontalFoV = !newPiaDevice
            ? item.properties.camera?.filter.panoramaMode
                ? 180
                : 145
            : isNewDeviceMultiDirectional && newPiaDevice.properties.maxLensCalcFOV
              ? newPiaDevice.properties.maxLensCalcFOV
              : newPiaDevice.properties.maxHorizontalFOV;

        const canNewDeviceHaveCorridorFormat = newPiaDevice
            ? newPiaDevice.properties.corridorFormat
            : true;

        const lensMaxHorizontalFov = lensRelationProperties?.horizontalFOV?.max;
        const lensMinHorizontalFov = lensRelationProperties?.horizontalFOV?.min;

        const updateInstallationPoints = installationPoints.map(async (installationPoint) => {
            // Filter out virtual sensors
            const installationPointSensors = installationPoint.sensors.filter(
                (sensor) => !sensor.isVirtual,
            );
            installationPointSensors.forEach((sensor) => {
                if (!canNewDeviceHaveCorridorFormat) {
                    sensor.settings.corridorFormat = false;
                }
                if (
                    sensor.settings.horizontalFov >
                    (lensMaxHorizontalFov || newPiaDeviceMaxHorizontalFoV)
                ) {
                    sensor.settings.horizontalFov = newPiaDeviceMaxHorizontalFoV;
                }

                if (
                    sensor.settings.horizontalFov <
                    (lensMinHorizontalFov || newPiaDeviceMinHorizontalFoV)
                ) {
                    sensor.settings.horizontalFov = newPiaDeviceMinHorizontalFoV;
                }
                sensor.parentPiaDeviceId = newPiaDevice?.id ?? null;
            });

            //if we update an ip to a device including a virtual item, we need ta add the virtual sensors
            const virtualRelatedItems = newPiaDevice
                ? this.itemService.getVirtualRelatedItems(newPiaDevice)
                : [];

            if (virtualRelatedItems.length > 0) {
                await this.addVirtualSensors(virtualRelatedItems, item, installationPointSensors);
            }
            installationPoint.sensors = installationPointSensors;

            return this.updateInstallationPoint(installationPoint._id, installationPoint);
        });
        await Promise.all(updateInstallationPoints);

        const oldDeviceSensorCount =
            (isOldDeviceMultiDirectional && oldPiaDevice?.properties.imageSensors) || 1;
        const newDeviceSensorCount =
            (isNewDeviceMultiDirectional && newPiaDevice?.properties.imageSensors) || 1;

        if (oldDeviceSensorCount !== newDeviceSensorCount) {
            this.updateSensorCount(installationPoints, newDeviceSensorCount);
        }
    }

    /**
     * updateInstallationPointsForDevice with itemId if needed according to max and min FOV
     * @param  {Id} itemId - Id for device to update installation points for
     * @param  {number} minHorizontalFOV - min horizontal field of view to compare with.
     * @param  {number} maxHorizontalFOV - max horizontal field of view to compare with.
     */
    public updateInstallationPointsForDevice = async (
        itemId: Id,
        sensorIndex: number,
        minHorizontalFOV: number,
        maxHorizontalFOV: number,
    ) => {
        const { descendants: installationPoints } =
            await this.getInstallationPointDescendants(itemId);
        if (installationPoints.length > 0) {
            installationPoints.forEach((installationPoint) => {
                const oldFov = installationPoint.sensors[sensorIndex].settings.horizontalFov;
                installationPoint.sensors[sensorIndex].settings.horizontalFov = clamp(
                    installationPoint.sensors[sensorIndex].settings.horizontalFov,
                    minHorizontalFOV,
                    maxHorizontalFOV,
                );
                if (oldFov !== installationPoint.sensors[sensorIndex].settings.horizontalFov) {
                    this.updateInstallationPoint(installationPoint._id, installationPoint);
                }
            });
        }
    };

    public async updateInstallationPointsToNewSpeakerEntity(
        productId: PiaId | undefined,
        installationPoints: IInstallationPointEntity[],
    ) {
        if (!productId) {
            return;
        }

        const newPiaDevice = this.piaSpeakerService.get(productId).first();

        // change ip values if needed:

        for (const installationPoint of installationPoints) {
            const newHeight = clamp(
                installationPoint.height,
                newPiaDevice?.properties.minRecommendedMountingHeight ?? 1,
                newPiaDevice?.properties.maxRecommendedMountingHeight ?? 20,
            );
            if (newHeight !== installationPoint.height) {
                await this.updateInstallationPoint(installationPoint._id, {
                    ...installationPoint,
                    height: newHeight,
                });
            }
        }
    }

    public duplicateInstallationPoint(
        installationPointId: Id,
        parentId?: Id,
        location?: ILatLng,
    ): Promise<Id> {
        return this.duplicationService.duplicateInstallationPoint(
            installationPointId,
            parentId,
            location,
        );
    }

    public duplicateInstallationPointToDevice(
        installationPointId: Id,
        deviceId: Id,
        installationPointName: string,
        location?: ILatLng,
    ): Promise<Id> {
        return this.duplicationService.duplicateInstallationPointToDevice(
            installationPointId,
            deviceId,
            installationPointName,
            location,
        );
    }

    public async swapInstallationPoints(
        installationPoint_A_Id: Id,
        installationPoint_B_Id: Id,
    ): Promise<IInstallationPointEntity[]> {
        // sub-millimeter change applied to swapped installation points for markers to register the change
        const negligibleChange = 0.000000000000001;
        const installationPoint_A_Entity =
            await this.installationPointRepository.get(installationPoint_A_Id);
        const installationPoint_B_Entity =
            await this.installationPointRepository.get(installationPoint_B_Id);

        const installationPoint_A_location = {
            lat: installationPoint_A_Entity.location.lat + negligibleChange,
            lng: installationPoint_A_Entity.location.lng + negligibleChange,
        };
        const installationPoint_B_location = {
            lat: installationPoint_B_Entity.location.lat + negligibleChange,
            lng: installationPoint_B_Entity.location.lng + negligibleChange,
        };

        installationPoint_A_Entity.location = installationPoint_B_location;
        installationPoint_B_Entity.location = installationPoint_A_location;

        return Promise.all([
            this.updateInstallationPoint(installationPoint_A_Id, installationPoint_A_Entity),
            this.updateInstallationPoint(installationPoint_B_Id, installationPoint_B_Entity),
        ]);
    }

    // if product has virtual related items add virtual sensors to the ip
    // if the virtual product is not a camera how should we handle that?
    private async addVirtualSensors(
        virtualRelatedItems: IPiaDevice[],
        item: IPersistence<IItemEntity>,
        sensors: IInstallationPointSensor[],
    ): Promise<IInstallationPointSensor[]> {
        if (!item.properties.camera) {
            throw new Error('Virtual product is not a camera');
        } else {
            await Promise.all(
                virtualRelatedItems.map((virtualPiaItem) => {
                    const virtualItemProperties = virtualPiaItem.properties as IPiaCameraProperties;
                    const virtualHasMultipleSensors =
                        virtualItemProperties.panCameraType === 'Multidirectional';
                    const virtualImageSensors = virtualItemProperties.imageSensors ?? 1;
                    const virtualNbrImageSensor = virtualHasMultipleSensors
                        ? virtualImageSensors
                        : 1;
                    const filter =
                        item.properties.camera?.filter ??
                        this.currentProjectService.getProjectDefaultCameraFilter();
                    const virtualInstallationPointSensors = virtualItemProperties
                        ? getDefaultSensors(
                              filter.horizontalFov,
                              filter.panoramaMode,
                              filter.distanceToTarget,
                              filter.targetHeight,
                              filter.corridorFormat,
                              virtualPiaItem as IPiaCamera,
                              virtualNbrImageSensor,
                          )
                        : null;

                    if (virtualInstallationPointSensors) {
                        virtualInstallationPointSensors.forEach((virtualSensor) => {
                            virtualSensor.sensorId = virtualSensor.sensorId + sensors.length;
                            virtualSensor.isVirtual = true;
                            sensors.push(virtualSensor);
                        });
                    }
                }),
            );
            return sensors;
        }
    }

    private updateSensorCount(
        installationPoints: IInstallationPointEntity[],
        nbrOfSensors: number,
    ) {
        installationPoints.map((installationPoint) => {
            // exclude virtual sensors from update
            const installationPointSensors = installationPoint.sensors.filter(
                (sensor) => !sensor.isVirtual,
            );
            const virtualInstallationPointSensors = installationPoint.sensors.filter(
                (sensor) => sensor.isVirtual,
            );
            const oldCount = installationPointSensors.length;

            const sensors = [
                ...installationPointSensors, // current sensors
                ...[...Array(nbrOfSensors)].map((_sensor, index) => ({
                    // more sensors
                    parentPiaDeviceId: installationPoint.sensors[0].parentPiaDeviceId,
                    isVirtual: installationPoint.sensors[0].isVirtual,
                    sensorId: oldCount + index + 1,
                    target: {
                        distance: installationPoint.sensors[0].target.distance,
                        height: installationPoint.sensors[0].target.height,
                        horizontalAngle: (oldCount + index) * 90,
                    },
                    settings: {
                        horizontalFov: installationPoint.sensors[0].settings.horizontalFov,
                        corridorFormat: installationPoint.sensors[0].settings.corridorFormat,
                    },
                })),
            ].slice(0, nbrOfSensors); // take the first nbrOfSensors

            //add virtual sensors again, they are additional to "normal" handling
            virtualInstallationPointSensors.forEach((virtualSensor, index) => {
                virtualSensor.sensorId = sensors.length + index + 1;
                sensors.push(virtualSensor);
            });

            this.updateInstallationPoint(installationPoint._id, {
                ...installationPoint,
                sensors,
            });
        });
    }
    /**
     * Checks that a quantity update is OK. For example it checks that quantity is not
     * lower then the existing installation points.
     */
    private async isQuantityUpdateOk(
        entity: IPersistence<IItemEntity>,
        newQuantity: number,
    ): Promise<boolean> {
        // Quantity can not be negative or zero
        newQuantity = Math.max(1, newQuantity);

        const { descendants } = await this.getInstallationPointDescendants(entity._id);
        const parentId = getParentId(entity);

        // If the parent id is an item and the entity to change is a device we need to sanity check
        // the installation points.
        if (parentId?.startsWith('item:') && isDevice(entity)) {
            // Return the parent id since we would like to remove the parent device's installation points,
            // since there is a risk of ending up with parent devices in maps with different amount of children.
            if (descendants.length > 0 && newQuantity < entity.quantity) {
                const { descendants: parentDescendants } =
                    await this.getInstallationPointDescendants(parentId);

                const doNotAllowQuantityChange = await this.modalService.createConfirmDialog({
                    header: t.deleteInstallationPointsHeader,
                    body: t.deleteInstallationPointsText,
                    cancelButtonText: t.remove,
                    confirmButtonText: t.cancel,
                })();

                if (doNotAllowQuantityChange) {
                    return false;
                }
                await this.removeInstallationPoints(parentDescendants);
            }
        } else {
            const parentUnits = Object.values(descendants).filter(
                (ip) => ip.parentId === undefined,
            );

            if (newQuantity < parentUnits.length) {
                const doNotAllowQuantityChange = await this.modalService.createConfirmDialog({
                    header: t.deleteInstallationPointsHeader,
                    body: t.deleteInstallationPointsText,
                    cancelButtonText: t.remove,
                    confirmButtonText: t.cancel,
                })();

                if (doNotAllowQuantityChange) {
                    return false;
                }
                await this.removeInstallationPoints(descendants);
            }
        }

        return true;
    }

    private isExpandableIoRelay(item: IPersistence<IItemEntity>) {
        if (item.productId) {
            const piaItem = this.piaPacService.get(item.productId).first();
            return (
                piaItem?.category === PiaItemPacCategory.IORELAYS &&
                piaItem.properties.nbrSupportedExpansionModules
            );
        }
        return false;
    }

    private isExpansionModule(item: IPersistence<IItemEntity>) {
        if (item.productId) {
            const piaItem = this.piaPacService.get(item.productId).first();
            return piaItem?.category === PiaItemPacCategory.RELAYEXPMODULES;
        }
        return false;
    }
}
