import type { IInstallationPointEntity, Id } from 'app/core/persistence';
import { getIdFromProps, getInstallationPointsArray, toCacheKey } from 'app/modules/common';
import { createCachedSelector } from 're-reselect';
import { getDeviceIpMarkerLabels } from './getDevicesOnSelectedFloorplan';
import { createSelector } from 'reselect';

const digits = new RegExp(/\d+/);
const letters = new RegExp(/\D+/);

const getInstallationPointsByParentDeviceRecord = createSelector(
    [getInstallationPointsArray],
    (installationPoints) =>
        installationPoints.reduce(
            (installationPointRecord, installationPoint) => {
                // Parent and child devices have the same path[1]
                const parentDeviceId = installationPoint.path[1];
                if (!installationPointRecord[parentDeviceId]) {
                    installationPointRecord[parentDeviceId] = [];
                }
                installationPointRecord[parentDeviceId].push(installationPoint);
                return installationPointRecord;
            },
            {} as Record<Id, IInstallationPointEntity[]>,
        ),
);

const getInstallationPointsByParentDevice = createCachedSelector(
    [getInstallationPointsByParentDeviceRecord, getIdFromProps],
    (installationPointsByParentDeviceRecord, parentDeviceId): IInstallationPointEntity[] =>
        parentDeviceId ? (installationPointsByParentDeviceRecord[parentDeviceId] ?? []) : [],
)(toCacheKey);

/** Gets installation points that are associated with a specific device and its eventual child devices,
 *  i.e. installation points for a main unit and its sensor units. */
export const getSortedInstallationPointsForDevice = createCachedSelector(
    [getInstallationPointsByParentDevice, getDeviceIpMarkerLabels],
    (installationPoints, ipMarkerLabels): IInstallationPointEntity[] => {
        // Sort first by the number in the label, then by the letters in the label, i.e. 1, 1a, 1b, 2, 2a, 2b, etc.
        return installationPoints
            .filter((ip) => ipMarkerLabels[ip._id] !== undefined)
            .sort((ipA, ipB) => {
                const labelA = ipMarkerLabels[ipA._id];
                const labelB = ipMarkerLabels[ipB._id];

                const numA = Number(labelA.match(digits));
                const numB = Number(labelB.match(digits));
                if (numA !== numB) {
                    return numA - numB;
                }
                return (labelA.match(letters) ?? '')
                    .toString()
                    .localeCompare((labelB.match(letters) ?? '').toString(), undefined, {
                        numeric: true,
                    });
            });
    },
)(toCacheKey);
