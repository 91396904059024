import { injectable } from 'inversify';
import { xor } from 'lodash-es';
import { ApplicationActionService } from 'app/modules/application';
import { Colors } from 'app/styles';
import {
    CoverageAreaLayerGroup,
    CurrentProjectService,
    DeviceTypeLayerGroup,
    IFilteredMapLayers,
    UserPreferencesService,
    defaultFilteredMapLayers,
    defaultProjectSettings,
} from 'app/core/persistence';
import { eventTracking } from 'app/core/tracking';

@injectable()
export class MapLayerFilterService {
    constructor(
        private applicationActions: ApplicationActionService,
        private userPreferences: UserPreferencesService,
        private currentProject: CurrentProjectService,
    ) {}

    public toggleDeviceTypeLayerFilter(deviceTypeLayerGroup: DeviceTypeLayerGroup): void {
        eventTracking.logApplicationEvent(
            'Maps',
            'Layer Options',
            `Device Type: ${deviceTypeLayerGroup}`,
        );
        const currentFilter = this.getCurrentFilter();
        const deviceLayers = xor(currentFilter.deviceLayers, [deviceTypeLayerGroup]);
        const newFilter = { ...currentFilter, deviceLayers };
        this.updateFilter(newFilter);
    }

    public toggleCoverageAreaLayerFilter(coverageAreaLayerGroup: CoverageAreaLayerGroup): void {
        eventTracking.logApplicationEvent(
            'Maps',
            'Layer Options',
            `Coverage Area: ${coverageAreaLayerGroup}`,
        );
        const currentFilter = this.getCurrentFilter();
        const coverageAreaLayers = xor(currentFilter.coverageAreaLayers, [coverageAreaLayerGroup]);
        const newFilter = { ...currentFilter, coverageAreaLayers };
        this.updateFilter(newFilter);
    }

    public toggleColorLayerFilter(color: Colors): void {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', `Color: ${color}`);
        const currentFilter = this.getCurrentFilter();
        const colors = xor(currentFilter.colors, [color]);
        const newFilter = { ...currentFilter, colors };
        this.updateFilter(newFilter);
    }

    public toggleTextBoxesLayerFilter(): void {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', `Text boxes`);
        const currentFilter = this.getCurrentFilter();
        const newFilter = { ...currentFilter, textBoxesLayer: !currentFilter.textBoxesLayer };
        this.updateFilter(newFilter);
    }

    public toggleLabelsLayerFilter(): void {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', `Labels`);
        const currentFilter = this.getCurrentFilter();
        const newFilter = { ...currentFilter, labelsLayer: !currentFilter.labelsLayer };
        this.updateFilter(newFilter);
    }

    public resetFilter(): void {
        this.updateFilter(defaultFilteredMapLayers);
    }

    private getCurrentFilter(): IFilteredMapLayers {
        const projectId = this.currentProject.getProjectId();
        const projectSettings = this.userPreferences.get().projectSettings;

        if (!projectSettings[projectId]) {
            projectSettings[projectId] = defaultProjectSettings;
        }

        return projectSettings[projectId].mapSettings.filteredMapLayers;
    }

    private updateFilter(newFilter: IFilteredMapLayers): void {
        const projectId = this.currentProject.getProjectId();
        const currentProjectSettings = this.userPreferences.get().projectSettings;

        if (!currentProjectSettings[projectId]) {
            currentProjectSettings[projectId] = defaultProjectSettings;
        }

        currentProjectSettings[projectId].mapSettings.filteredMapLayers = newFilter;

        this.applicationActions.setUserPreferences({
            projectSettings: currentProjectSettings,
        });
    }
}
