import { eventTracking } from 'app/core/tracking';
import * as Tween from '@tweenjs/tween.js';
import * as THREE from 'three';
import * as Materials from '../materials';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EventEmitter } from 'events';
import { DragControls } from '../DragControls';
import { VectorPool } from '../VectorPool';
import { calculateCameraLookAtLocation, increaseLength } from '../math';
import { t } from 'app/translate';
import {
    calculateCornerVectors,
    calculateActualCornerVectors,
    isWideAnglePanoramic,
    isFullPanoramic,
} from '../../utils';
import { getCameraCone, getPanoramicCameraCone, getWideFovCone } from './cone';
import { toErrorMessage } from 'app/core/persistence';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import targetFigure from '../meshes/walkingman.obj';
import axisCamera from '../meshes/axis_dummy_cam.obj';
import { getCameraCornerVectors } from './getCameraCornerVectors';
import type { ICameraRays } from './getCornerRays';
import { getCornerRays } from './getCornerRays';
import { createVerticalAxisGeometry } from './createVerticalAxisGeometry';
import { createSphere } from './createSphere';
import { getDisplayVerticalFOV } from './getDisplayVerticalFOV';
import { getFovGeometry } from './getFovGeometry';
import { DebugArrows } from './DebugArrows';
import { Blockers } from './Blockers';
import { FloorPlans, type IFloorPlan3d } from './FloorPlans';
import { ResolutionGuide } from './ResolutionGuide';
import { Ground } from './Ground';

const Colors = Materials.Colors;

/**
 * FAR_AWAY used as a max distance. For example when drawing orthogonal
 * planes for selected cone when using full panoramic or max distance
 * when intersecting blocker.
 */
const FAR_AWAY = 10000;

/**
 * BLOCKER_INTERSECTION_MARGIN add margin to blockers when intersecting.
 * Can be used to control thickness of blockers.
 */
const BLOCKER_INTERSECTION_MARGIN = 0.07;

export enum SceneRenderView {
    TOP = 'TOP',
    SIDE = 'SIDE',
    LENS = 'LENS',
    ORBIT = 'ORBIT',
}

interface ICameraAnimationProps {
    x: number;
    y: number;
    z: number;
    fov: number;
    lookAtX: number;
    lookAtY: number;
    lookAtZ: number;
}

interface ISceneRenderSettings {
    unitLength: number;
    cameraHeight: number;
    targetDistance: number;
    targetHeight: number;
    desiredHorizontalFov?: number;
    desiredVerticalFov?: number;
    desiredTiltAngle?: number;
    selectedHorizontalFov: number;
    selectedVerticalFov: number;
    selectedTiltAngle: number;
    resolutionLimit: number;
    showResolutionGuideOnError: boolean;
}

const clippingPlanes = [
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
    new THREE.Plane(new THREE.Vector3(0, 1, 0), 0),
];

const inverseClippingPlanes = [
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
    new THREE.Plane(new THREE.Vector3(0, 0, 0), 0),
];

Materials.selectedNearMaterial.clippingPlanes = clippingPlanes;
Materials.selectedNearMaterialBack.clippingPlanes = clippingPlanes;
Materials.selectedAspectMaterial.clippingPlanes = inverseClippingPlanes;

export class SceneRenderer extends EventEmitter {
    private readonly isDebug = false; // set to true to see debug helpers in scene
    private debugArrows!: DebugArrows;

    private settings: ISceneRenderSettings;
    private renderer: THREE.WebGLRenderer | undefined;
    private currentView: SceneRenderView;
    private scene!: THREE.Scene;
    private camera!: THREE.PerspectiveCamera;
    private desiredConeMesh!: THREE.Mesh;
    private selectedConeAspectMesh!: THREE.Mesh;
    private selectedConeFarMesh!: THREE.Mesh;
    private selectedConeFarMeshBack!: THREE.Mesh;
    private selectedConeWireframe!: THREE.LineSegments;
    private axisCamera!: THREE.Object3D;
    private axisCameraMesh!: THREE.Mesh;
    private target!: THREE.Mesh;
    private blockers!: Blockers;
    private floorPlans!: FloorPlans;
    private intersectLines!: THREE.LineSegments;
    private verticalAxis!: THREE.Line;
    private resolutionGuide!: ResolutionGuide;
    private ground!: Ground;

    private directionalLight!: THREE.DirectionalLight;
    private ambientLight!: THREE.AmbientLight;

    private previousView: SceneRenderView;
    private previousLookAt!: THREE.Vector3;
    private animationRef!: number;
    private orbitAnimation!: number;
    private controls!: OrbitControls;
    private tweenAnimation!: Tween.Tween<any>;

    private selectedCameraRays!: ICameraRays;
    private desiredCameraRays!: ICameraRays;
    private groundPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
    private aboveGround = new THREE.Plane(new THREE.Vector3(0, 1, 0), -0.01);
    private farAwayPlane = new THREE.Plane(new THREE.Vector3(1, 0, 0), -1000);
    private cameraDragControls!: DragControls;
    private targetDragControls!: DragControls;
    private vectorPool = new VectorPool(10);

    constructor() {
        super();
        this.settings = {
            unitLength: 1,
            cameraHeight: 0,
            targetDistance: 0,
            targetHeight: 0,
            desiredHorizontalFov: 0,
            desiredVerticalFov: 0,
            desiredTiltAngle: 0,
            selectedHorizontalFov: 0,
            selectedVerticalFov: 0,
            selectedTiltAngle: 0,
            resolutionLimit: 0,
            showResolutionGuideOnError: false,
        };
        this.currentView = SceneRenderView.SIDE;
        this.previousView = SceneRenderView.SIDE;
        if (this.isDebug) {
            this.debugArrows = new DebugArrows();
        }
    }

    public set(props: Partial<ISceneRenderSettings>) {
        this.settings = {
            ...this.settings,
            ...props,
        };
    }

    public setView(view: SceneRenderView) {
        if (view === SceneRenderView.ORBIT) {
            if (this.currentView !== view) {
                this.startOrbit();
            }
        } else {
            this.stopOrbit();
        }
        this.currentView = view;
    }

    public resize(container: HTMLDivElement) {
        if (!this.renderer) {
            return;
        }

        // Set the render to size 0 to be able to shrink the container
        this.renderer.setSize(0, 0);

        const { width, height } = container.getBoundingClientRect();

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
        this.update();
    }

    public setBlockers(blockers: number[][][], height: number) {
        this.blockers.update(blockers, height);
        this.render();
    }

    public setFloorPlans(floorPlans: IFloorPlan3d[]) {
        this.floorPlans.setFloorPlanImages(floorPlans);
    }

    /**
     * Re-renders canvas with current set properties.
     */
    public update() {
        this.updateRays();

        this.updateCameraAndReRender(
            this.settings.selectedHorizontalFov,
            this.settings.selectedVerticalFov,
            this.settings.cameraHeight,
            this.settings.targetDistance,
            this.settings.desiredHorizontalFov,
            this.settings.desiredVerticalFov,
        );
    }

    public onMouseDown() {
        this.controls.autoRotate = false;
    }

    public destroy() {
        cancelAnimationFrame(this.orbitAnimation);
        this.renderer?.forceContextLoss();
        this.renderer?.dispose();
        this.renderer = undefined;
        this.cameraDragControls?.destroy();
        this.targetDragControls?.destroy();
    }

    public setUnitLength(unitLength: number) {
        this.settings.unitLength = unitLength;
        this.updateAxis();
        this.ground.update(unitLength);
    }

    public getBlindSpot(): number | undefined {
        const groundIntersection = this.selectedCameraRays.bottomCenter.intersectPlane(
            this.groundPlane,
            this.vectorPool.get(),
        );

        this.vectorPool.reset();
        return groundIntersection ? Math.max(groundIntersection.x, 0) : undefined;
    }

    public getWidthAtTarget(): number {
        /** A plane that's parallel to the ground but at the target's height. */
        const targetHeightPlane = new THREE.Plane(
            new THREE.Vector3(0, 1, 0),
            -this.settings.targetHeight,
        );

        /** The vector between the camera and the target at its highest point. */
        const targetHeightIntersection = this.selectedCameraRays.topLeft.intersectPlane(
            targetHeightPlane,
            this.vectorPool.get(),
        );

        /** A plane for the x distance between the camera and the target.
         *  When target is beneath the camera with a low x distance, targetHeightIntersection will be larger than targetDistance.
         *  Otherwise these values are almost identical. Check https://github.com/axteams-software/ap-site-designer/pull/925 for additional information. */
        const targetPlane = new THREE.Plane(
            new THREE.Vector3(1, 0, 0),
            -Math.max(targetHeightIntersection?.x ?? 0, this.settings.targetDistance),
        );

        const topLeftPos = this.selectedCameraRays.topLeft.intersectPlane(
            targetPlane,
            this.vectorPool.get(),
        );
        const topRightPos = this.selectedCameraRays.topRight.intersectPlane(
            targetPlane,
            this.vectorPool.get(),
        );
        const bottomLeftPos = this.selectedCameraRays.bottomLeft.intersectPlane(
            targetPlane,
            this.vectorPool.get(),
        );
        const bottomRightPos = this.selectedCameraRays.bottomRight.intersectPlane(
            targetPlane,
            this.vectorPool.get(),
        );

        const topWidth =
            topLeftPos && topRightPos ? topLeftPos.distanceTo(topRightPos) : Number.MAX_VALUE;
        const bottomWidth =
            bottomLeftPos && bottomRightPos
                ? bottomLeftPos.distanceTo(bottomRightPos)
                : Number.MAX_VALUE;

        this.vectorPool.reset();
        return Math.min(topWidth, bottomWidth);
    }

    private async setupScene() {
        // scene
        const fog = new THREE.Fog(Colors.defaultSky, 0.1, 200);
        this.scene = new THREE.Scene();
        this.scene.fog = fog;

        // axis camera
        this.axisCamera = await this.createAxisCamera();
        this.axisCamera.position.x = 0;
        this.axisCamera.position.y = this.settings.cameraHeight;
        this.axisCamera.add(this.createSelectedCone());
        this.axisCamera.add(this.createDesiredCone());

        // Create target
        this.target = await this.getTargetFigure();

        // Create lights
        this.directionalLight = new THREE.DirectionalLight(Colors.defaultDirectionalLight, 1);
        this.directionalLight.position.set(-5, 1, 4);
        this.ambientLight = new THREE.AmbientLight(Colors.defaultAmbientLight);

        // Create vertical axis
        this.verticalAxis = this.createVerticalAxis(this.settings.cameraHeight);

        // blockers
        this.blockers = new Blockers();

        this.floorPlans = new FloorPlans();

        this.ground = new Ground(this.settings.unitLength);
        this.scene.add(this.ground);
        this.scene.add(this.createIntersectionLines());
        this.scene.add(this.target);
        this.scene.add(this.axisCamera);
        this.scene.add(this.verticalAxis);
        this.scene.add(this.floorPlans);
        this.scene.add(this.blockers);
        this.scene.add(this.directionalLight);
        this.scene.add(this.ambientLight);

        if (this.isDebug) {
            this.scene.add(this.debugArrows);
        }
    }

    private setupCamera() {
        this.camera = new THREE.PerspectiveCamera(45, 1, 0.01, 1000);
        this.updateCamera(
            Math.PI / 4,
            new THREE.Vector3(0, this.settings.targetHeight, -4),
            new THREE.Vector3(0, 0, 0),
        );
    }

    private setupRenderer(canvas: HTMLCanvasElement) {
        try {
            this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas });
            this.renderer.setClearColor(Colors.defaultSky);
            this.renderer.autoClear = false;
            this.renderer.setSize(canvas.width, canvas.height);
            this.renderer.localClippingEnabled = true;
        } catch (e) {
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            if (ctx) {
                ctx.textAlign = 'center';
                ctx.fillText(
                    t.cameraSelectorSetupRendererError(toErrorMessage(e)),
                    canvas.width / 2,
                    canvas.height / 2,
                );
            }
            this.emit('setupRenderError', toErrorMessage(e));
        }
    }

    public async init(canvas: HTMLCanvasElement) {
        await this.setupScene();
        this.setupCamera();
        this.setupRenderer(canvas);

        this.controls = new OrbitControls(this.camera, canvas);
        this.controls.update();
        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.autoRotateSpeed = 1;
    }

    public enableDragEvents(enabled: boolean) {
        if (enabled && this.renderer?.domElement) {
            // Camera
            this.cameraDragControls = new DragControls(
                this.axisCameraMesh,
                this.camera,
                this.renderer.domElement,
                'y',
            );

            this.cameraDragControls.on('hoveron', () => {
                this.axisCameraMesh.material = Materials.hoverMaterial;
                this.render();
            });

            this.cameraDragControls.on('hoveroff', () => {
                this.axisCameraMesh.material = Materials.cameraMaterial;
                this.render();
            });

            this.cameraDragControls.on('dragstart', () => {
                this.controls.enabled = false;
            });

            this.cameraDragControls.on('drag', (newPos) => {
                const newYPos = Math.max(newPos.y, this.settings.unitLength);
                this.emit('cameraHeightChange', newYPos);
            });

            this.cameraDragControls.on('dragend', () => {
                this.controls.enabled = true;
            });

            // Target
            this.targetDragControls = new DragControls(
                this.target,
                this.camera,
                this.renderer.domElement,
                'x',
            );

            this.targetDragControls.on('hoveron', () => {
                this.target.material = Materials.hoverMaterial;
                this.render();
            });

            this.targetDragControls.on('hoveroff', () => {
                this.target.material = Materials.targetMaterial;
                this.render();
            });
            this.targetDragControls.on('dragstart', () => {
                this.controls.enabled = false;
            });

            this.targetDragControls.on('drag', (newPos) => {
                newPos.x = Math.max(newPos.x, this.settings.unitLength);
                this.target.position.copy(newPos);
                this.render();
            });

            this.targetDragControls.on('dragend', () => {
                this.controls.enabled = true;
                this.emit('distanceChange', this.target.position.x);
            });
        } else {
            this.cameraDragControls?.removeAllListeners();
            this.targetDragControls?.removeAllListeners();
            this.cameraDragControls?.destroy();
            this.targetDragControls?.destroy();
        }
    }

    /** Only used to update camera rays in jest test */
    public updateCameraRaysForTest(rays: ICameraRays) {
        this.selectedCameraRays = rays;
    }
    /** Only used to update target distance in jest test */
    public updateTargetDistanceForTest(distance: number) {
        this.settings.targetDistance = distance;
    }
    /** Only used to update target height in jest test */
    public updateTargetHeightForTest(height: number) {
        this.settings.targetHeight = height;
    }

    private animateViewTransition = (time: number) => {
        this.animationRef = requestAnimationFrame(this.animateViewTransition);
        this.tweenAnimation.update(time);
    };

    private animateOrbit = () => {
        this.orbitAnimation = requestAnimationFrame(this.animateOrbit);
        this.controls.update();
        this.render();
    };

    private stopOrbit = () => {
        this.controls.autoRotate = false;
        cancelAnimationFrame(this.orbitAnimation);
    };

    private startOrbit = () => {
        if (!this.controls.autoRotate) {
            this.controls.autoRotate = true;
            this.animateOrbit();
        }
    };

    private updateView(
        targetPosX: number,
        cameraPosY: number,
        horizontalFov: number,
        verticalFov: number,
    ) {
        const shouldAnimate = this.currentView !== this.previousView;
        const sceneCenter = new THREE.Vector3(targetPosX / 2, cameraPosY / 2, -0.01);

        this.controls.target = sceneCenter;

        (this.selectedConeAspectMesh.material as THREE.MeshLambertMaterial).visible = false;
        switch (this.currentView) {
            case SceneRenderView.TOP:
                this.updateCamera(
                    Math.PI / 4,
                    new THREE.Vector3(targetPosX / 2, 3 * (cameraPosY + targetPosX / 2), 0),
                    sceneCenter,
                    shouldAnimate,
                );
                this.controls.enabled = false;
                break;
            case SceneRenderView.SIDE:
                this.updateCamera(
                    Math.PI / 4,
                    new THREE.Vector3(
                        targetPosX / 2,
                        cameraPosY,
                        Math.max(cameraPosY * 1.4, targetPosX * 1.1),
                    ),
                    sceneCenter,
                    shouldAnimate,
                );
                this.controls.enabled = false;
                break;
            case SceneRenderView.LENS:
                const displayVerticalFOV = getDisplayVerticalFOV(
                    horizontalFov,
                    verticalFov,
                    this.camera.aspect,
                );
                const cameraPos = new THREE.Vector3(0, cameraPosY, 0);
                const direction = new THREE.Vector3(0, 0, -1);
                this.axisCamera.getWorldDirection(direction);
                const target = cameraPos.add(direction);

                this.updateCamera(
                    displayVerticalFOV,
                    new THREE.Vector3(0, cameraPosY, 0),
                    target,
                    shouldAnimate,
                    true,
                );

                this.controls.enabled = false;
                this.controls.target = target;
                break;
            case SceneRenderView.ORBIT:
                if (this.previousView !== SceneRenderView.ORBIT) {
                    this.updateCamera(
                        Math.PI / 4,
                        new THREE.Vector3(-cameraPosY, cameraPosY * 3, cameraPosY + targetPosX),
                        sceneCenter,
                        shouldAnimate,
                    );
                    this.controls.enabled = true;
                }

                break;
        }
        this.previousView = this.currentView;
    }

    private updateAxisCamera(
        selectedHorizontalFov: number,
        selectedVerticalFov: number,
        cameraHeight: number,
        showDesiredCone: boolean,
        desiredHorizontalFov?: number,
        desiredVerticalFov?: number,
    ) {
        this.updateSelectedFov(selectedHorizontalFov, selectedVerticalFov, !showDesiredCone);
        this.updateDesiredFov(desiredHorizontalFov, desiredVerticalFov);

        // Determine lookAt position for camera and cone (if desired is out of
        // range for selected camera, selected camera should rule)
        const cameraPos = new THREE.Vector3(0, cameraHeight, 0);

        // selected camera
        const selectedTiltAngle = this.settings.selectedTiltAngle;
        const selectedLookAtLocation = calculateCameraLookAtLocation(selectedTiltAngle, cameraPos);

        this.axisCamera.position.y = cameraHeight;
        this.axisCamera.lookAt(selectedLookAtLocation);

        // desired camera
        const desiredTiltAngle = this.settings.desiredTiltAngle;
        if (desiredTiltAngle) {
            const desiredLookAtLocation = calculateCameraLookAtLocation(
                desiredTiltAngle,
                cameraPos,
            );

            this.desiredConeMesh.lookAt(desiredLookAtLocation);
        }
    }

    private updateRays() {
        this.desiredCameraRays = this.getDesiredCameraCornerRays();
        this.selectedCameraRays = this.getSelectedCameraCornerRays();

        if (this.isDebug) {
            this.debugArrows.update(this.selectedCameraRays, this.desiredCameraRays);
        }
    }

    private getSelectedCameraCornerVectors() {
        const horizontalFov = this.settings.selectedHorizontalFov;
        const verticalFov = this.settings.selectedVerticalFov;
        const tiltAngle = this.settings.selectedTiltAngle;
        return getCameraCornerVectors(horizontalFov, verticalFov, tiltAngle);
    }

    private getDesiredCameraCornerVectors() {
        const tiltAngle = this.settings.desiredTiltAngle;
        return getCameraCornerVectors(
            this.settings.desiredHorizontalFov ?? 0,
            this.settings.desiredVerticalFov ?? 0,
            tiltAngle ?? 0,
        );
    }

    private getSelectedCameraCornerRays() {
        const vectors = this.getSelectedCameraCornerVectors();
        return getCornerRays(vectors, this.settings.cameraHeight);
    }

    private getDesiredCameraCornerRays() {
        const vectors = this.getDesiredCameraCornerVectors();
        return getCornerRays(vectors, this.settings.cameraHeight);
    }

    private updateCameraAndReRender(
        selectedHorizontalFov: number,
        selectedVerticalFov: number,
        cameraHeight: number,
        targetDistance: number,
        desiredHorizontalFov?: number,
        desiredVerticalFov?: number,
    ) {
        const showDesiredCone = !!(desiredHorizontalFov && desiredVerticalFov);

        this.updateAxisCamera(
            selectedHorizontalFov,
            selectedVerticalFov,
            cameraHeight,
            showDesiredCone,
            desiredHorizontalFov,
            desiredVerticalFov,
        );

        this.target.position.x = targetDistance;

        this.updateView(targetDistance, cameraHeight, selectedHorizontalFov, selectedVerticalFov);
        this.updateAxis();

        if (showDesiredCone) {
            try {
                this.updateIntersections();
            } catch {
                // Firefox sometimes throws an error when using panorama
                // Catch error to avoid crash
                // TODO: Fix calculations so firefox doesn't crash
                eventTracking.logError(
                    'Firefox could not update intersections in scene renderer',
                    'cameraSelector',
                );
            }
        }
        this.updateClippingPlanes();
        this.render(); // Re-render with intersections
    }

    private render() {
        if (!this.renderer) {
            return;
        }
        this.renderer.clear();
        this.renderer.render(this.scene, this.camera);
    }

    private updateCamera(
        fov: number,
        pos: THREE.Vector3,
        lookAt?: THREE.Vector3,
        animate?: boolean,
        showAspect = false,
    ) {
        const fovDegrees = THREE.MathUtils.radToDeg(fov);
        if (animate) {
            const animationValues: ICameraAnimationProps = {
                x: this.camera.position.x,
                y: this.camera.position.y,
                z: this.camera.position.z,
                fov: this.camera.fov,
                lookAtX: this.previousLookAt.x,
                lookAtY: this.previousLookAt.y,
                lookAtZ: this.previousLookAt.z,
            };

            const newLookAt = lookAt
                ? { lookAtX: lookAt.x, lookAtY: lookAt.y, lookAtZ: lookAt.z }
                : {
                      lookAtX: 0,
                      lookAtY: this.settings.targetHeight,
                      lookAtZ: 0,
                  };

            if (this.tweenAnimation) {
                this.tweenAnimation.stop();
            }
            this.tweenAnimation = new Tween.Tween(animationValues)
                .to(
                    {
                        x: pos.x,
                        y: pos.y,
                        z: pos.z,
                        fov: fovDegrees,
                        ...newLookAt,
                    },
                    1000,
                )
                .easing(Tween.Easing.Cubic.Out)
                .onUpdate((props: ICameraAnimationProps) => {
                    this.camera.fov = props.fov;
                    this.camera.position.x = props.x;
                    this.camera.position.y = props.y;
                    this.camera.position.z = props.z;
                    this.camera.lookAt(
                        new THREE.Vector3(props.lookAtX, props.lookAtY, props.lookAtZ),
                    );
                    this.camera.updateProjectionMatrix();
                    this.render();
                })
                .onComplete(() => {
                    cancelAnimationFrame(this.animationRef);
                    this.previousLookAt = lookAt
                        ? lookAt
                        : new THREE.Vector3(0, this.settings.targetHeight, 0);

                    this.setAspectBoxVisibility(showAspect);
                    this.render();
                })
                .start();
            requestAnimationFrame(this.animateViewTransition);
        } else {
            this.setAspectBoxVisibility(showAspect);
            this.previousLookAt = lookAt
                ? lookAt
                : new THREE.Vector3(0, this.settings.targetHeight, 0);
            this.camera.fov = fovDegrees;
            this.camera.position.x = pos.x;
            this.camera.position.y = pos.y;
            this.camera.position.z = pos.z;
            this.camera.lookAt(this.previousLookAt);
            this.camera.updateProjectionMatrix();
        }
    }

    private setAspectBoxVisibility = (visible: boolean) => {
        (this.selectedConeAspectMesh.material as THREE.MeshLambertMaterial).visible = visible;
        (this.axisCameraMesh.material as THREE.MeshLambertMaterial).visible = !visible;
    };

    private async getTargetFigure() {
        const loader = new OBJLoader();
        const model = await new Promise<THREE.Group>((resolve, reject) =>
            loader.load(targetFigure, resolve, undefined, reject),
        );
        const mesh = <THREE.Mesh>model.children[0];
        mesh.material = Materials.targetMaterial;

        return mesh;
    }

    private async createAxisCamera() {
        const loader = new OBJLoader();
        const model = await new Promise<THREE.Group>((resolve, reject) =>
            loader.load(axisCamera, resolve, undefined, reject),
        );
        this.axisCameraMesh = <THREE.Mesh>model.children[0];
        this.axisCameraMesh.rotation.y = Math.PI / 2;
        const pivot = new THREE.Object3D();
        pivot.add(this.axisCameraMesh);
        return pivot;
    }

    private updateAxis() {
        this.verticalAxis.geometry = createVerticalAxisGeometry(
            new THREE.Vector3(0, 0, 0),
            this.settings.cameraHeight,
            true,
            this.settings.unitLength,
        );
    }

    private updateSelectedFov(horizontalFov: number, verticalFov: number, noDesiredCone: boolean) {
        this.resolutionGuide.update(
            this.settings.cameraHeight,
            this.settings.targetDistance,
            this.settings.targetHeight,
            this.settings.resolutionLimit,
            this.settings.showResolutionGuideOnError,
        );

        // if we have no desired cone, we add edges to our selected cone
        (this.selectedConeWireframe.material as THREE.MeshLambertMaterial).visible = noDesiredCone;

        // set the edge color
        const edgeColor = noDesiredCone ? Colors.desiredMaterial : Colors.defaultMaterialEdge;
        (this.selectedConeWireframe.material as THREE.MeshLambertMaterial).color.set(edgeColor);
        if (isFullPanoramic(horizontalFov, verticalFov)) {
            this.updateSelectedConeGeometryFullPanoramic(noDesiredCone);
        } else if (isWideAnglePanoramic(horizontalFov, verticalFov)) {
            this.updateSelectedConeGeometryWideAnglePanoramic(horizontalFov, verticalFov);
        } else {
            this.updateSelectedConeGeometry(horizontalFov, verticalFov);
        }
        this.selectedConeFarMeshBack.geometry = this.selectedConeFarMesh.geometry;
    }

    private updateSelectedConeGeometryFullPanoramic(noDesiredCone: boolean) {
        if (noDesiredCone) {
            // show a semisphere
            ({
                geometry: this.selectedConeFarMesh.geometry,
                edges: this.selectedConeWireframe.geometry,
            } = getPanoramicCameraCone(
                this.settings.targetDistance,
                this.settings.selectedTiltAngle,
                this.getVectorToBlockerIntersection.bind(this),
            ));
        } else {
            // show a plane orthogonal to the camera direction. This is the behavior
            // we see in the device selector
            this.selectedConeFarMesh.geometry = new THREE.PlaneGeometry(FAR_AWAY, FAR_AWAY);
            this.selectedConeFarMesh.geometry.rotateY(Math.PI / 2);

            // clear wireframe geometry
            this.selectedConeWireframe.geometry = new THREE.BufferGeometry();
        }
    }

    private updateSelectedConeGeometryWideAnglePanoramic(
        horizontalFov: number,
        verticalFov: number,
    ) {
        ({
            geometry: this.selectedConeFarMesh.geometry,
            edges: this.selectedConeWireframe.geometry,
        } = getWideFovCone(
            horizontalFov,
            verticalFov,
            this.settings.cameraHeight,
            this.settings.selectedTiltAngle,
            this.getVectorToBlockerIntersection.bind(this),
        ));

        // always show edges for wide angle panoramics
        (this.selectedConeWireframe.material as THREE.MeshLambertMaterial).visible = true;
    }

    private updateSelectedConeGeometry(horizontalFov: number, verticalFov: number) {
        ({
            geometry: this.selectedConeFarMesh.geometry,
            edges: this.selectedConeWireframe.geometry,
        } = getCameraCone(
            horizontalFov,
            verticalFov,
            this.settings.cameraHeight,
            this.settings.selectedTiltAngle,
            this.getVectorToBlockerIntersection.bind(this),
        ));
    }

    private updateDesiredFov(horizontalFov?: number, verticalFov?: number) {
        if (horizontalFov && verticalFov) {
            const vectors = isWideAnglePanoramic(horizontalFov, verticalFov)
                ? calculateActualCornerVectors(horizontalFov, verticalFov)
                : calculateCornerVectors(horizontalFov, verticalFov);

            const geometry = getFovGeometry(
                vectors.topLeft,
                vectors.topRight,
                vectors.bottomRight,
                vectors.bottomLeft,
            );
            geometry.rotateY(-Math.PI / 2);
            this.desiredConeMesh.geometry = geometry;
            this.desiredConeMesh.material = Materials.desiredMaterial;
        } else {
            this.desiredConeMesh.geometry = new THREE.BufferGeometry();
        }
    }

    private getFirstIntersection(ray: THREE.Ray) {
        const raycaster = new THREE.Raycaster(ray.origin, ray.direction, 0, 100);
        this.scene.updateMatrixWorld();
        const intersections = raycaster.intersectObjects([
            ...this.blockers.getIntersectObjects(),
            ...this.ground.getIntersectObjects(),
        ]);

        return intersections[0];
    }

    private getVectorToBlockerIntersection(
        vector: THREE.Vector3,
        maxDistance = FAR_AWAY,
    ): THREE.Vector3 {
        const ray = new THREE.Ray(new THREE.Vector3(0, this.settings.cameraHeight, 0), vector);

        const distance = Math.min(
            this.getFirstIntersection(ray)?.distance ?? Infinity,
            maxDistance,
        );

        const intersection = vector.clone().multiplyScalar(distance);

        return increaseLength(intersection, -BLOCKER_INTERSECTION_MARGIN);
    }

    private createDesiredCone(): THREE.Object3D {
        const pivot = new THREE.Object3D();
        this.desiredConeMesh = new THREE.Mesh();

        pivot.add(this.desiredConeMesh);

        return pivot;
    }

    private createSelectedCone(): THREE.Object3D {
        this.resolutionGuide = new ResolutionGuide();
        const pivot = new THREE.Object3D();
        const sphere = createSphere(1);
        this.selectedConeAspectMesh = new THREE.Mesh(undefined, Materials.selectedAspectMaterial);
        this.selectedConeAspectMesh.geometry = sphere; // clipped by clipping planes

        this.selectedConeFarMesh = new THREE.Mesh(undefined, Materials.selectedDistantMaterial);
        this.selectedConeFarMesh.renderOrder = 3;
        this.selectedConeWireframe = new THREE.LineSegments(undefined, Materials.coneEdgeMaterial);

        this.selectedConeFarMeshBack = new THREE.Mesh(
            undefined,
            Materials.selectedDistantMaterialBack,
        );
        this.selectedConeFarMeshBack.renderOrder = 4;
        pivot.add(this.selectedConeFarMesh);
        pivot.add(this.selectedConeFarMeshBack);
        pivot.add(this.selectedConeWireframe);
        pivot.add(this.resolutionGuide);
        pivot.add(this.selectedConeAspectMesh);

        pivot.rotation.y = -Math.PI / 2;
        return pivot;
    }

    private createVerticalAxis(length: number) {
        const origin = new THREE.Vector3(0, 0, 0);

        return new THREE.Line(
            createVerticalAxisGeometry(origin, length, true, this.settings.unitLength),
            Materials.axisMaterial,
        );
    }

    private createIntersectionLines(): THREE.LineSegments {
        return (this.intersectLines = new THREE.LineSegments(
            new THREE.BufferGeometry(),
            new THREE.LineBasicMaterial({
                color: Colors.intersectionLines,
            }),
        ));
    }

    // update intersections of desired camera and ground plane
    private updateIntersections(): void {
        const pointsOfIntersection = new THREE.BufferGeometry();

        const getIntersection = (ray: THREE.Ray) => {
            let intersection = ray.intersectPlane(this.aboveGround, new THREE.Vector3());

            if (!intersection) {
                intersection = this.aboveGround.projectPoint(
                    ray.intersectPlane(this.farAwayPlane, new THREE.Vector3()) ??
                        new THREE.Vector3(),
                    new THREE.Vector3(),
                );
            }
            return intersection;
        };

        const topLeftVertex = getIntersection(this.desiredCameraRays.topLeft).toArray();
        const topRightVertex = getIntersection(this.desiredCameraRays.topRight).toArray();
        const bottomLeftVertex = getIntersection(this.desiredCameraRays.bottomLeft).toArray();
        const bottomRightVertex = getIntersection(this.desiredCameraRays.bottomRight).toArray();

        const vertices = new Float32Array([
            ...topLeftVertex,
            ...topRightVertex,

            ...topRightVertex,
            ...bottomRightVertex,

            ...bottomRightVertex,
            ...bottomLeftVertex,

            ...bottomLeftVertex,
            ...topLeftVertex,
        ]);

        pointsOfIntersection.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        this.intersectLines.geometry = pointsOfIntersection;
    }

    // update clipping planes according to selected camera
    private updateClippingPlanes(): void {
        isWideAnglePanoramic(this.settings.selectedHorizontalFov, this.settings.selectedVerticalFov)
            ? this.updateWideAngleClippingPlanes()
            : this.updateNormalClippingPlanes();

        inverseClippingPlanes.forEach((plane, i) => {
            plane.copy(clippingPlanes[i]);
            plane.negate();
        });

        this.vectorPool.reset();
    }

    private updateWideAngleClippingPlanes(): void {
        const resolutionLimit = this.settings.resolutionLimit;

        const topCenter = this.selectedCameraRays.topCenter.at(
            resolutionLimit,
            this.vectorPool.get(),
        );
        const bottomCenter = this.selectedCameraRays.bottomCenter.at(
            resolutionLimit,
            this.vectorPool.get(),
        );

        clippingPlanes[0].setFromNormalAndCoplanarPoint(
            this.selectedCameraRays.down.direction,
            topCenter,
        );
        clippingPlanes[1].setFromCoplanarPoints(
            this.selectedCameraRays.rightCenter.origin,
            this.selectedCameraRays.rightCenter.at(1, this.vectorPool.get()),
            this.selectedCameraRays.down.at(1, this.vectorPool.get()),
        );
        clippingPlanes[2].setFromNormalAndCoplanarPoint(
            this.selectedCameraRays.up.direction,
            bottomCenter,
        );
        clippingPlanes[3].setFromCoplanarPoints(
            this.selectedCameraRays.leftCenter.origin,
            this.selectedCameraRays.leftCenter.at(1, this.vectorPool.get()),
            this.selectedCameraRays.up.at(1, this.vectorPool.get()),
        );
    }

    private updateNormalClippingPlanes(): void {
        clippingPlanes[0].setFromCoplanarPoints(
            this.selectedCameraRays.topLeft.origin,
            this.selectedCameraRays.topLeft.at(1, this.vectorPool.get()),
            this.selectedCameraRays.topRight.at(1, this.vectorPool.get()),
        );
        clippingPlanes[1].setFromCoplanarPoints(
            this.selectedCameraRays.topRight.origin,
            this.selectedCameraRays.topRight.at(1, this.vectorPool.get()),
            this.selectedCameraRays.bottomRight.at(1, this.vectorPool.get()),
        );
        clippingPlanes[2].setFromCoplanarPoints(
            this.selectedCameraRays.bottomRight.origin,
            this.selectedCameraRays.bottomRight.at(1, this.vectorPool.get()),
            this.selectedCameraRays.bottomLeft.at(1, this.vectorPool.get()),
        );
        clippingPlanes[3].setFromCoplanarPoints(
            this.selectedCameraRays.bottomLeft.origin,
            this.selectedCameraRays.bottomLeft.at(1, this.vectorPool.get()),
            this.selectedCameraRays.topLeft.at(1, this.vectorPool.get()),
        );
    }
}
