import {
    type IInstallationPointSensorModel,
    isCamera,
    isVirtualCamera,
} from 'app/core/persistence';

/**
 * Get the aspect ratio of a sensor
 */
export const getAspectRatio = (sensor: IInstallationPointSensorModel) => {
    return isCamera(sensor.parentPiaDevice) || isVirtualCamera(sensor.parentPiaDevice)
        ? sensor.parentPiaDevice.properties.maxVerticalFOV /
              sensor.parentPiaDevice.properties.maxHorizontalFOV
        : 1;
};
