import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { css } from '@emotion/css';
import { debounce, isElement } from 'lodash-es';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { IBoxProps } from '../../containers';
import { Box } from '../../containers';
import type { ICloseableProps } from '../../functional';
import { Clickable, Closeable } from '../../functional';
import { getNodeLabels } from '../../ui/dropDown/DropDown.helpers';
import {
    getShouldOpenDownwards,
    getShouldOpenRightwards,
    getShouldOpenLeftwards,
} from '../../services';
import { AppConstants } from 'app/AppConstants';
import type { IAutoTestable } from '../../ui-test';
import { Stack } from '../../layout/stack/Stack.component';
import { TextInput } from '../../input/textInput/TextInput.component';
import { Text } from '../../text/text/Text.component';
import { Icon } from '../icon/Icon.component';
import { Border } from 'app/components/style/Border';
import { t, isPageRTL } from 'app/translate';

const MAX_ITEMS_SHOWN = 6;
const AVG_ITEM_HEIGHT = 49;
/** Max height for dropdown content. Cuts bottom option in half to make it clear that dropdown is scrollable in all browsers. */
const MAX_CONTENT_HEIGHT = MAX_ITEMS_SHOWN * AVG_ITEM_HEIGHT - AVG_ITEM_HEIGHT / 2;
const DEFAULT_MIN_WIDTH = 140;

const noSearchResultsIcon = require('src/assets/images/no-search-result.svg');

const BorderStyle = css`
    border-style: solid;
    border-width: 1px;
    border-top-width: 2px;
    border-bottom-width: 2px;
`;

const DropDownStyle = (maxHeight?: number) => css`
    max-height: ${maxHeight ? `${maxHeight}px` : `${MAX_CONTENT_HEIGHT}px`};
    overflow-y: auto;
    overflow-x: hidden;

    & > * {
        border-bottom: 1px solid;
    }
    & > *:focus {
        outline: none;
        background-color: ${ColorsEnum.grey1};
    }
    & > *:last-child {
        border-bottom: none;
    }
    & > *:hover {
        background-color: ${ColorsEnum.grey2};
    }
    [aria-selected='true'] {
        border-left: 4px solid;
        padding-left: 0;
        background-color: ${ColorsEnum.yellow1};
    }
    [aria-disabled='true'] {
        opacity: 0.5;
    }
    [role='presentation'] {
        opacity: 1 !important;
        background: ${ColorsEnum.transparent};
        color: ${ColorsEnum.black};
    }
    [role='presentation']:hover {
        background: ${ColorsEnum.transparent};
    }
`;

const DropDownTriggerStyle = css`
    border-radius: 2px;
    &*:focus {
        outline: none;
    }
`;

type CenterAlignProps =
    | {
          /**
           * Set the width (in px) of the drop down.
           */
          width: number;
          /**
           * Align contents to open at the center of trigger element.
           * A width-property must be provided if centerAlignContent is true
           */
          centerAlignContent: true;
      }
    | {
          /**
           * Set the width (in px) of the drop down.
           */
          width?: number;
          /**
           * Align contents to open at the center of trigger element.
           * A width-property must be provided if centerAlignContent is true
           */
          centerAlignContent?: undefined;
      };

export interface IDropDownProps extends IAutoTestable {
    /**
     * The contents of the drop down.
     * Works with React.Children.
     * Usually an array of DropDownMenuItem:s
     */
    contents: React.ReactNode;
    /**
     * This will force the drop down to
     * stay open when selecting an option.
     */
    stayOpen?: boolean;
    /**
     * An element to act as the trigger for
     * the drop down. If this is an input
     * you should set the hasInput prop to true.
     */
    trigger: JSX.Element;
    /**
     * If the trigger han an input element (like
     * AutoComplete) this will change the behavior
     * of the dropDown to better fit what's expected.
     */
    hasInput?: boolean;
    /**
     * Disable the drop down.
     */
    disabled?: boolean;
    /**
     * Show a border around the trigger
     */
    border?: boolean;
    /**
     * Background color of the trigger
     */
    background?: Colors;
    /**
     * Override the default flex align of the drop
     * down trigger. Defaults to "center".
     */
    alignItems?: IBoxProps['alignItems'];
    /**
     * Override the minimum width (in px) of the drop down.
     * Defaults to 140px.
     */
    minWidth?: number;
    /**
     * Set the maximum width (in px) of the drop down.
     */
    maxWidth?: number;
    /**
     * Override the maximum height (in px) of the drop down.
     * Defaults to {MAX_CONTENT_HEIGHT}px.
     */
    maxHeight?: number;
    /**
     * sets the height to 100%.
     */
    fullHeight?: boolean;
    /**
     * Fill 100% of the available width.
     */
    fillWidth?: boolean;
    /**
     * Fill 100% of the available width also for the options.
     */
    fillDropdownWidth?: boolean;
    /**
     * Set this to open the drop down in a portal.
     * This allows it to extend beyond overflow borders,
     * however it will not scroll or move with the trigger.
     */
    openInPortal?: boolean;
    /**
     * Sets index of the drop down item to be focused in drop down list
     */
    focusedItemIndex?: number;
    /**
     * Sets initial isOpen dropdown state to true
     */
    isOpenByDefault?: boolean;
    /**
     * Allows you to manually describe how many items the dropdown contents contain.
     * Useful when content has nested items such as categories each containing different items.
     */
    contentLengthOverride?: number;

    /**Search properties */

    /**
     * true if a searchField should be added for the dropDown
     * */
    includeSearchField?: boolean;
    /**
     * Callback called when text is changed
     * */
    onSearchTextChanged?(value: string): void;
    /**
     * text to display in search field
     * */
    searchFieldText?: string;
    /**
     * placeholder for the search field
     * */
    placeHolder?: string;
    /**
     * Hides borders when dropdown has no content
     */
    hideEmptyContentBorder?: boolean;
    /**
     * set to true if content should be flex line instead of column. Content will be wrapped at the set width
     */
    wrapLine?: boolean;
}

export interface IDropDownState {
    isOpen: boolean;
    shouldOpenDownwards: boolean;
    shouldOpenRightwards: boolean;
    shouldOpenLeftwards: boolean;
}

/**
 * This component encompasses the drop down functionality.
 * By providing a trigger element (which can be any element)
 * and an array of content elements (which also can be anything,
 * but most likely `<DropDownMenuItem>`s wrapped in `<Clickable>`s)
 * you get a trigger which upon click will open the drop down
 * menu featuring the content elements.
 *
 * This also supports keyboard navigation such as opening on
 * space/enter/arrowUp/arrowDown when focusing the trigger element
 * and arrow navigation in the drop down menu list.
 */
type IProps = IDropDownProps & CenterAlignProps;

export class DropDown extends React.Component<IProps, IDropDownState> {
    private openOnFocus = true;

    public static defaultProps: Partial<IDropDownProps> = {
        minWidth: DEFAULT_MIN_WIDTH,
    };

    private colors = {
        borderColor: ColorsEnum.grey3,
        borderTopColor: ColorsEnum.black,
        borderBottomColor: ColorsEnum.black,
        backgroundColor: ColorsEnum.white,
    };

    private searchText: string = '';

    /**
     * A React ref to the trigger element
     */
    private triggerElement: React.RefObject<HTMLDivElement> = React.createRef();

    /**
     * A React ref to the search element
     */
    private searchElement: React.RefObject<HTMLDivElement> = React.createRef();

    /**
     * A React ref to the drop down list container element
     */
    private contentElement: React.RefObject<HTMLDivElement> = React.createRef();

    /**
     * The currently focused drop down list element
     */
    private activeDescendant = this.props.focusedItemIndex ? this.props.focusedItemIndex : 0;

    /**
     * Clear the search text if no input is recorded for 400 ms
     */
    private clearSearchText = debounce(() => (this.searchText = ''), 400);

    constructor(props: IProps) {
        super(props);
        this.state = {
            isOpen: props.isOpenByDefault ?? false,
            shouldOpenDownwards: false,
            shouldOpenRightwards: false,
            shouldOpenLeftwards: false,
        };
    }

    public componentDidMount() {
        this.updateOpeningDirection();
        this.addTriggerEventHandlers();
    }

    public componentDidUpdate(prevProps: IProps) {
        this.updateOpeningDirection();

        if (
            this.props.isOpenByDefault !== prevProps.isOpenByDefault &&
            this.props.isOpenByDefault
        ) {
            this.setState({ isOpen: true });
        }
        if (this.props.stayOpen !== prevProps.stayOpen) {
            this.updateContentEventHandlers();
        }
    }

    public componentWillUnmount() {
        this.removeContentEventHandlers();
        this.removeTriggerEventHandlers();
        this.close();
    }

    public render() {
        const style = {
            border: this.props.border ? `1px solid ${ColorsEnum.grey3}` : undefined,
            background: this.props.background,
            padding: this.props.border ? '6px' : undefined,
        };

        const attributes = {
            className: DropDownTriggerStyle,
            style,
        };

        return (
            <Box
                position="relative"
                display={this.props.fillWidth ? 'block' : 'inlineBlock'}
                flex={this.props.fillWidth ? 'fullWidth' : undefined}
                height={this.props.fullHeight ? '100%' : undefined}
            >
                <Clickable
                    noInteraction={this.props.hasInput}
                    disabled={this.props.disabled}
                    onClick={(event) => {
                        event.stopPropagation();
                        this.toggleDropDown();
                    }}
                    __htmlAttributes={attributes}
                >
                    <Box
                        testId={this.props.testId}
                        innerRef={this.triggerElement}
                        alignItems={this.props.alignItems || 'center'}
                        flex="fullWidth"
                        height="100%"
                    >
                        {this.props.trigger}
                    </Box>
                </Clickable>
                {this.state.isOpen && this.renderContent()}
            </Box>
        );
    }

    private renderContent = () => {
        const position: React.CSSProperties = this.props.openInPortal
            ? this.getPortalPosition()
            : this.getPositions();

        const closeableProps: ICloseableProps = {
            close: this.close,
            parentRef: this.triggerElement,
            closeOnScroll: this.props.openInPortal,
        };

        const minWidth = this.props.fillDropdownWidth
            ? this.props.openInPortal
                ? `${this.triggerElement.current?.offsetWidth}px`
                : '100%'
            : `${this.props.minWidth}px`;

        const content = (
            <Closeable {...closeableProps}>
                <Box
                    testId={this.props.testIdChild}
                    minWidth={minWidth}
                    maxHeight={this.props.maxHeight ?? MAX_CONTENT_HEIGHT}
                    direction="column"
                    __htmlAttributes={{
                        className:
                            (this.props.hideEmptyContentBorder &&
                                Array.isArray(this.props.contents) &&
                                this.props.contents.length === 0) ||
                            !this.props.contents
                                ? undefined
                                : BorderStyle,
                        style: {
                            ...this.colors,
                            position: 'absolute',
                            ...position,
                        },
                    }}
                >
                    {this.props.includeSearchField && this.renderSearch()}
                    <Box
                        testId="dropdown_menu"
                        direction={this.props.wrapLine ? 'row' : 'column'}
                        innerRef={this.contentElement}
                        width={this.props.width}
                        wrap={this.props.wrapLine}
                        __htmlAttributes={
                            this.props.wrapLine
                                ? undefined
                                : {
                                      className: DropDownStyle(this.props.maxHeight),
                                  }
                        }
                    >
                        {this.props.contents
                            ? React.Children.map(this.props.contents, (child) => child)
                            : this.props.includeSearchField && this.renderSearchNoContent()}
                    </Box>
                </Box>
            </Closeable>
        );

        return this.props.openInPortal
            ? ReactDOM.createPortal(content, document.getElementById(AppConstants.modalRootId)!)
            : content;
    };

    private renderSearch = () => {
        return (
            <Box innerRef={this.searchElement} width="100%">
                <Border bottomWidth={1} color="black">
                    <Stack flex="fullWidth" spacing="half">
                        <Box paddingLeft="cell">
                            <Icon opaque icon="search" color="black" />
                        </Box>
                        <Box
                            width="100%"
                            height={AVG_ITEM_HEIGHT}
                            display="flex"
                            alignItems="center"
                        >
                            <TextInput
                                autoFocus
                                noBorder
                                grow
                                testId="text_input"
                                clear={this.clearSearchTextField}
                                value={this.props.searchFieldText}
                                changeCriteria="debounced"
                                onChange={this.props.onSearchTextChanged}
                                placeholder={this.props.placeHolder}
                            />
                        </Box>
                    </Stack>
                </Border>
            </Box>
        );
    };

    private renderSearchNoContent = () => {
        return (
            <Box paddingRight="cell" paddingLeft="cell">
                <Box alignItems="center">
                    <img height="30px" src={noSearchResultsIcon} />
                </Box>
                <Box alignItems="center" height={AVG_ITEM_HEIGHT}>
                    <Text style="caption">{t.searchResultsMissing}</Text>
                </Box>
            </Box>
        );
    };

    private getPortalPosition = (): React.CSSProperties => {
        if (!this.triggerElement.current) {
            // Render outside screen
            return { top: '-10000px', left: '-10000px' };
        }

        const triggerPosition = this.triggerElement.current!.getBoundingClientRect();
        const leftPosition = triggerPosition.left;
        // If width is not set the drop down is allowed to grow to fit it's content
        // this can sometimes position the dropdown wrongly in RTL mode
        // specify the width to solve the problem.
        const dropDownWidth = this.props.fillDropdownWidth
            ? this.triggerElement.current.offsetWidth
            : (this.props.width ?? this.props.minWidth ?? DEFAULT_MIN_WIDTH);

        //Left position when in LTR mode
        const leftLTR = this.state.shouldOpenRightwards
            ? leftPosition
            : triggerPosition.right - dropDownWidth;

        // Left position when in RTL mode
        const leftRTL = leftLTR + (triggerPosition.width - dropDownWidth);

        const left = isPageRTL() ? leftRTL : leftLTR;

        /** Distance that dropdown content goes beyond window width to the right (adds 8 px margin too) */
        const distanceBeyondWindowWidth = Math.max(
            left + (this.props.maxWidth ?? dropDownWidth) - window.innerWidth + 8,
            0,
        );

        return {
            top: `${
                this.state.shouldOpenDownwards
                    ? triggerPosition.bottom
                    : triggerPosition.bottom -
                      (triggerPosition.height + 1) -
                      this.calculateRoughContentHeight()
            }px`,
            left: `${left < 0 ? triggerPosition.left : left - distanceBeyondWindowWidth}px`,
        };
    };

    private getCentralizedPosition = (): number => {
        const triggerPosition = this.triggerElement.current
            ? this.triggerElement.current.getBoundingClientRect().width / 2
            : undefined;
        const contentPosition = this.props.width ? this.props.width / 2 : 0;

        return triggerPosition && contentPosition ? triggerPosition - contentPosition : 0;
    };

    private getPositions = () => {
        const topBottomPositions = {
            top: this.state.shouldOpenDownwards ? '100%' : undefined,
            bottom: this.state.shouldOpenDownwards ? undefined : '100%',
        };

        if (this.props.centerAlignContent) {
            return {
                ...topBottomPositions,
                left: `${this.getCentralizedPosition()}px`,
            };
        }

        return isPageRTL()
            ? {
                  ...topBottomPositions,
                  left: this.state.shouldOpenLeftwards ? undefined : '0',
                  right: this.state.shouldOpenLeftwards ? '0' : undefined,
              }
            : {
                  ...topBottomPositions,
                  right: this.state.shouldOpenRightwards ? undefined : '0',
                  left: this.state.shouldOpenRightwards ? '0' : undefined,
              };
    };

    /**
     * Toggles the drop down, unless
     * hasInput is true in which case it
     * won't close the drop down.
     */
    private toggleDropDown = () => {
        if (!this.props.contents) {
            return;
        }
        this.props.hasInput ? this.open() : this.state.isOpen ? this.close() : this.open();
    };

    private setShouldOpenDownwards = (shouldOpenDownwards: boolean) => {
        this.setState({ shouldOpenDownwards });
    };

    private setShouldOpenRightwards = (shouldOpenRightwards: boolean) => {
        this.setState({ shouldOpenRightwards });
    };

    private setShouldOpenLeftwards = (shouldOpenLeftwards: boolean) => {
        this.setState({ shouldOpenLeftwards });
    };

    private updateOpeningDirection = () => {
        const { shouldOpenDownwards, shouldOpenRightwards, shouldOpenLeftwards } = this.state;
        const calculatedShouldOpenDownwards = getShouldOpenDownwards(
            this.triggerElement,
            this.calculateRoughContentHeight(),
            60,
        );
        const calculatedShouldOpenRightwards = getShouldOpenRightwards(this.triggerElement, 200);
        const calculatedShouldOpenLeftwards = getShouldOpenLeftwards(this.triggerElement, 200);

        if (shouldOpenDownwards !== calculatedShouldOpenDownwards) {
            this.setShouldOpenDownwards(calculatedShouldOpenDownwards);
        }

        if (shouldOpenRightwards !== calculatedShouldOpenRightwards) {
            this.setShouldOpenRightwards(calculatedShouldOpenRightwards);
        }
        if (shouldOpenLeftwards !== calculatedShouldOpenLeftwards) {
            this.setShouldOpenLeftwards(calculatedShouldOpenLeftwards);
        }
    };
    /**
     * Open the drop down list
     */
    private open = () => {
        if (this.state.isOpen) {
            return;
        }
        this.setState(
            {
                isOpen: true,
            },
            this.addContentEventHandlers,
        );

        if (!this.props.hasInput) {
            const index =
                this.props.focusedItemIndex !== undefined
                    ? this.props.focusedItemIndex
                    : this.activeDescendant;
            setTimeout(() => this.focusItem(index), 0);
        }
    };

    /**
     * Close the drop down
     */
    private close = () => {
        if (!this.state.isOpen) {
            return;
        }
        this.setState({
            isOpen: false,
        });
        this.searchText = '';
        this.clearSearchTextField();
    };

    /**
     * Get the height of the drop down contents
     * by using the average item height multiplied
     * with the number of items.
     */
    private calculateRoughContentHeight = () => {
        const contentLength = this.props.contentLengthOverride
            ? this.props.contentLengthOverride
            : this.props.contents && Array.isArray(this.props.contents)
              ? this.props.contents.length
              : MAX_ITEMS_SHOWN;
        return contentLength < MAX_ITEMS_SHOWN
            ? contentLength * AVG_ITEM_HEIGHT
            : MAX_CONTENT_HEIGHT;
    };

    /**
     * Get if all child elements in list are disabled
     * @param contentElement content element
     * @returns true if all elements disabled, false otherwise
     */
    private areAllElementsDisabled = (contentElement: HTMLDivElement) => {
        for (let i = 0; i < contentElement.children.length; i++) {
            const e = contentElement.children[i] as HTMLElement;
            if (e.getAttribute('aria-disabled') !== 'true') {
                return false;
            }
        }
        return true;
    };

    /**
     * Set the focus to the item in the drop
     * down list with the provided index.
     * Will loop when providing too high or low
     * index values.
     */
    private focusItem = (index: number, focusPreviousItemIfDisabled = false): void => {
        this.unFocusItem(this.activeDescendant);

        if (!this.contentElement.current) {
            return;
        }

        const contentElement = this.contentElement.current;

        const areAllItemsDisabled = this.areAllElementsDisabled(contentElement);
        if (areAllItemsDisabled) {
            return;
        }

        const newActiveDescendant =
            index >= contentElement.children.length
                ? 0
                : index < 0
                  ? contentElement.children.length - 1
                  : index;

        const element = contentElement.children[newActiveDescendant] as HTMLElement;
        const elementIsDisabled = element.getAttribute('aria-disabled') === 'true';

        if (elementIsDisabled && contentElement.children.length > 1) {
            this.activeDescendant = newActiveDescendant;
            return focusPreviousItemIfDisabled ? this.focusPreviousItem() : this.focusNextItem();
        }

        element.setAttribute('tabIndex', '0');
        element.focus();

        this.activeDescendant = newActiveDescendant;
    };

    /**
     * Remove focus from the element in
     * the drop down list with the provided
     * index.
     */
    private unFocusItem = (index: number) => {
        if (!this.contentElement.current) {
            return;
        }
        const contentElement = this.contentElement.current;
        const element = contentElement.children[index];
        if (element) {
            element.setAttribute('tabIndex', '-1');
        }
    };

    private focusNextItem = () => {
        this.focusItem(this.activeDescendant + 1);
    };

    private focusPreviousItem = () => {
        this.focusItem(this.activeDescendant - 1, true);
    };

    /**
     * Set the focus to the trigger element.
     * Only used when hasInput is true to
     * set the focus on the input element
     */
    private focusTrigger = () => {
        if (!this.triggerElement.current) {
            return;
        }
        const inputElement = this.triggerElement.current.getElementsByTagName('input')[0];
        inputElement.focus();
    };

    private addTriggerEventHandlers = () => {
        const ref = this.triggerElement;

        if (!ref.current) {
            return;
        }

        const dropDownElement = ref.current;
        dropDownElement.addEventListener('keydown', this.keyActionsForTrigger);
        const inputElement = dropDownElement.getElementsByTagName('input')[0];

        if (this.props.hasInput) {
            if (inputElement === document.activeElement) {
                // Input already has focus, open the dropdown
                this.open();
            }
            inputElement.addEventListener('focus', () => {
                this.openOnFocus && this.open();
            });
            inputElement.addEventListener('mousedown', () => {
                // disable open on focus during this event loop to prevent the focus
                // event from opening the dropdown (open on click is handled separately)
                this.openOnFocus = false;
                setTimeout(() => (this.openOnFocus = true), 10);
            });
        }
    };

    private removeTriggerEventHandlers = () => {
        const ref = this.triggerElement;

        if (!ref.current) {
            return;
        }

        const dropDownElement = ref.current;
        dropDownElement.removeEventListener('keydown', this.keyActionsForTrigger);

        if (this.props.hasInput) {
            dropDownElement
                .getElementsByTagName('input')[0]
                .removeEventListener('focus', this.open);
        }
    };

    private addContentEventHandlers = () => {
        const ref = this.contentElement;

        if (!ref.current) {
            return;
        }
        const dropDownElement = ref.current;

        dropDownElement.addEventListener('keydown', this.keyActionsForContent);
        dropDownElement.addEventListener('click', (event) => {
            this.activeDescendant = this.getSelectedIndex(
                dropDownElement,
                event,
                this.activeDescendant,
            );

            if (this.props.stayOpen) {
                this.focusItem(this.activeDescendant);
            }
        });

        if (!this.props.stayOpen) {
            dropDownElement.addEventListener('click', this.close);
            dropDownElement.addEventListener('keyup', this.closeAfterEnter);
        }
    };

    private getSelectedIndex = (
        dropDownElement: HTMLDivElement,
        event: Event,
        oldIndex: number,
    ) => {
        const array = Array.from(dropDownElement.children);
        const index = array.findIndex(
            (child) => this.isElement(event.target) && child.contains(event.target),
        );
        return index >= 0 ? index : oldIndex;
    };

    private isElement(event: EventTarget | null): event is Element {
        return isElement(event);
    }

    private removeContentEventHandlers = () => {
        const ref = this.contentElement;

        if (!ref.current) {
            return;
        }
        const dropDownElement = ref.current;

        dropDownElement.removeEventListener('keydown', this.keyActionsForContent);

        if (!this.props.stayOpen) {
            dropDownElement.removeEventListener('click', this.close);
            dropDownElement.removeEventListener('keyup', this.closeAfterEnter);
        }
    };

    private updateContentEventHandlers = () => {
        const ref = this.contentElement;

        if (!ref.current) {
            return;
        }
        const dropDownElement = ref.current;
        if (this.props.stayOpen) {
            dropDownElement.removeEventListener('click', this.close);
            dropDownElement.removeEventListener('keyup', this.closeAfterEnter);
        } else {
            dropDownElement.addEventListener('click', this.close);
            dropDownElement.addEventListener('keyup', this.closeAfterEnter);
        }
    };

    /**
     * Allow the user to open the drop down
     * with arrow up or down, as well as close
     * it when tabbing away from the trigger.
     */
    private keyActionsForTrigger = (event: Event) => {
        const theEvent = event as KeyboardEvent;
        switch (theEvent.key) {
            case 'Tab':
                this.close();
                break;
            case 'Up':
            case 'ArrowUp':
                event.preventDefault();
                this.open();
                if (this.activeDescendant === 0) {
                    this.focusItem(-1);
                }
                if (this.props.hasInput) {
                    this.focusItem(-1);
                }
                break;
            case 'Down':
            case 'ArrowDown':
                event.preventDefault();
                this.open();
                if (this.props.hasInput) {
                    this.focusItem(0);
                }
                break;
        }
    };

    /**
     * Allow the user to navigate the drop
     * down list with the arrow keys and close
     * the drop down when tabbing away from it.
     *
     * When hasInput is true it allows the user
     * to press any other key to return focus to
     * the input element.
     */
    private keyActionsForContent = (event: Event) => {
        const theEvent = event as KeyboardEvent;
        switch (theEvent.key) {
            case 'Tab':
                this.close();
                return;
            case 'Down':
            case 'ArrowDown':
            case 'ArrowRight':
                event.preventDefault();
                this.focusNextItem();
                return;
            case 'Up':
            case 'ArrowUp':
            case 'ArrowLeft':
                event.preventDefault();
                this.focusPreviousItem();
                return;
        }

        const keyIsLetterOrBackspace =
            theEvent.key !== ' ' && (theEvent.key === 'Backspace' || theEvent.key.length === 1);

        if (this.props.hasInput) {
            if (keyIsLetterOrBackspace) {
                event.preventDefault();
                this.focusTrigger();
            }
        } else {
            if (keyIsLetterOrBackspace) {
                this.searchText = this.updateSearchText(theEvent.key);
                this.focusItemByLabel(this.searchText);
                this.clearSearchText();
            }
        }
    };

    /**
     * Focus the item with the label starting
     * with the provided string.
     */
    private focusItemByLabel = (searchText: string) => {
        const itemIndex = this.findItemIndexByLabel(searchText);
        if (itemIndex !== -1) {
            this.focusItem(itemIndex);
        }
    };

    /**
     * Retrieve the index of the item with the label starting
     * with the provided string.
     */
    private findItemIndexByLabel = (searchText: string) =>
        getNodeLabels(this.props.contents).findIndex((label) =>
            label.toLowerCase().startsWith(searchText.toLowerCase()),
        );

    private updateSearchText = (key: string) => (key === 'Backspace' ? '' : this.searchText + key);

    /**
     * Close the drop down menu when hitting
     * space or enter after the intended action
     * has been executed unless stayOpen is true or
     * the target has the shouldNotCloseDropDown data
     * attribute set to true.
     */
    private closeAfterEnter = (event: Event) => {
        const theEvent = event as KeyboardEvent;
        const target = event.target instanceof HTMLElement ? event.target : false;
        switch (theEvent.key) {
            case ' ':
            case 'Enter':
                if (target && target.dataset.shouldNotCloseDropDown) {
                    setTimeout(() => this.focusItem(this.activeDescendant), 10);
                    break;
                }
                setTimeout(this.close, 10);
                break;
        }
    };

    private clearSearchTextField = () => {
        if (this.props.onSearchTextChanged) {
            this.props.onSearchTextChanged('');
        }
    };
}
