import type { IPiaDevice, PiaId } from 'app/core/pia';
import { PiaRelationTypes } from 'app/core/pia';

export function getVirtuallyIncludedIds(piaDevice: IPiaDevice | null): PiaId[] {
    if (!piaDevice) return [];
    return piaDevice.relations.reduce((ids, relation) => {
        if (relation.relationType === PiaRelationTypes.VirtuallyIncludes) {
            return [...ids, relation.id];
        }
        return ids;
    }, [] as PiaId[]);
}
