import * as React from 'react';
import { t } from 'app/translate';
import type {
    ICustomCameraPropertiesEntity,
    Id,
    IPersistence,
    IProfileEntity,
    IRecordingSettingsEntity,
    IZipstreamSettingsEntity,
} from 'app/core/persistence';
import { isVirtualCamera, Resolution } from 'app/core/persistence';
import type { IFrameRates } from 'app/modules/common';
import {
    getDeviceVirtualChildren,
    getScenarioCategoryString,
    getZipSteamReportString,
    getGlobalUseAverageBitrate,
    getCurrentProjectProjectZipSetting,
    getBandwidthStorageEstimateForItems,
    getPiaItem,
    getPiaCameraForProductId,
    getFrameRatesForItem,
    getZipStreamIsSupported,
    getDeviceAndSubTypeForItem,
    getParentIdOfChildDevice,
    getCurrentProjectItem,
    getVirtualItemBandwidthProps,
} from 'app/modules/common';
import { format } from 'axis-webtools-util';
import type { IAutoTestable } from 'app/components';
import { Stack, Icon, Table, Text } from 'app/components';
import type { IStoreState } from 'app/store';
import type {
    PiaId,
    IPiaCamera,
    IPiaSensorUnit,
    IPiaEncoder,
    IPiaVirtualCamera,
} from 'app/core/pia';
import { connect } from 'react-redux';

interface ISettingsTableOwnProps extends IAutoTestable {
    profile: IPersistence<IProfileEntity>;
    scheduleNames: Record<string, string>;
    piaId: PiaId | null;
    itemId: Id;
}

interface ISettingsTableProps extends IAutoTestable {
    nbrSensors: number | undefined;
    piaCamera: IPiaCamera | IPiaSensorUnit | IPiaVirtualCamera | undefined;
    profile: IPersistence<IProfileEntity> | undefined;
    scheduleNames: Record<string, string>;
    itemId: Id;
    parentOfAnalogCam: IPiaEncoder | undefined | null;
    frameRates: IFrameRates | undefined;
    zipStreamIsSupported: boolean;
    customCameraProps?: ICustomCameraPropertiesEntity;
    continuousBandwidth?: number;
    triggeredBandwidth?: number;
    projectZipSetting: number;
    hasVirtualProducts: boolean;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: ISettingsTableOwnProps,
): ISettingsTableProps => {
    const customCameraProps = getCurrentProjectItem(storeState, ownProps.itemId)?.properties.camera
        ?.customCameraProperties;
    const piaCamera = ownProps.piaId
        ? getPiaCameraForProductId(storeState, ownProps.piaId)
        : undefined;
    const virtualItemNbrSensors =
        getVirtualItemBandwidthProps(storeState, ownProps.itemId).imageSensors ?? 0;
    const mainDeviceNbrOfSensors = getNbrOfSensors(piaCamera as IPiaCamera);
    const totalNbrOfSensors = mainDeviceNbrOfSensors
        ? mainDeviceNbrOfSensors - virtualItemNbrSensors
        : undefined;
    const deviceType = getDeviceAndSubTypeForItem(storeState, ownProps.itemId);
    const parentId = getParentIdOfChildDevice(storeState, ownProps.itemId);
    const parentOfAnalogCam =
        parentId && deviceType === 'analogCamera'
            ? (getPiaItem(storeState, parentId) as IPiaEncoder)
            : undefined;

    const zipStreamIsSupported = getZipStreamIsSupported(storeState, ownProps.itemId);
    const projectZipSetting = getCurrentProjectProjectZipSetting(storeState);

    const frameRates = getFrameRatesForItem(storeState, ownProps.itemId);

    const continuousBandwidth =
        getBandwidthStorageEstimateForItems(storeState)[ownProps.itemId].continuousRecording
            ?.averageBandwidth;

    const triggeredBandwidth =
        getBandwidthStorageEstimateForItems(storeState)[ownProps.itemId].triggeredRecording
            ?.averageBandwidth;
    const hasVirtualProducts = !!(getDeviceVirtualChildren(storeState, ownProps.itemId) ?? [])
        .length;

    return {
        customCameraProps,
        profile: ownProps.profile,
        piaCamera,
        scheduleNames: ownProps.scheduleNames,
        nbrSensors: totalNbrOfSensors,
        itemId: ownProps.itemId,
        parentOfAnalogCam,
        frameRates,
        zipStreamIsSupported,
        testId: ownProps.testId,
        continuousBandwidth,
        triggeredBandwidth,
        projectZipSetting,
        hasVirtualProducts,
    };
};

const getNbrOfSensors = (piaCamera: IPiaCamera | undefined) => {
    if (piaCamera) {
        const imageSensors = piaCamera.properties.imageSensors;
        const channels = piaCamera.properties.channels;
        return imageSensors === channels ? piaCamera.properties.imageSensors : 1;
    } else {
        return undefined;
    }
};

export class SettingsTableComponent extends React.Component<ISettingsTableProps> {
    public render() {
        const { profile } = this.props;

        if (
            profile?.continuousRecording?.schedule === null &&
            profile?.liveView?.schedule === null &&
            profile?.triggeredRecording?.schedule === null
        ) {
            return null;
        }

        const settingsHeader = this.props.hasVirtualProducts
            ? getScenarioCategoryString(this.props.piaCamera?.category, t.settings)
            : isVirtualCamera(this.props.piaCamera)
              ? getScenarioCategoryString(
                    this.props.piaCamera.properties.virtualProductCategory[0],
                    t.settings,
                )
              : t.settings;

        return (
            <Table
                testId={this.props.testId}
                headers={[
                    settingsHeader,
                    t.installationReportSettingsResolution,
                    t.installationReportSettingsFrameRate,
                    t.installationReportSettingsCompression,
                    t.advancedFiltersGROUP.zipStream,
                    t.averageBitrate,
                    t.installationReportSettingsSchedule,
                    t.installationReportSettingsStorageTime,
                ]}
                rows={profile && this.getRows(profile, this.props.nbrSensors)}
                rightAlignLastColumn
                wideFirstColumn
            />
        );
    }

    private renderResolution = (
        nbrOfSensors: number | undefined,
        recordingSettings: IRecordingSettingsEntity,
    ): JSX.Element => {
        const resolution = this.getResolution(recordingSettings.resolution);

        return (
            <Text testId={`${this.props.testId}_resolution_text`} inline>
                {nbrOfSensors && nbrOfSensors > 1 && (
                    <Text inline color="grey5">
                        {`${nbrOfSensors} × `}
                    </Text>
                )}
                {`${resolution.horizontal}×${resolution.vertical}`}
            </Text>
        );
    };

    private getResolution = (resolutionString: string) => {
        const resolution = new Resolution(resolutionString);
        const piaDevice = this.props.parentOfAnalogCam ?? this.props.piaCamera;
        const maxHorizontal =
            piaDevice?.properties.maxVideoResolutionHorizontal ||
            this.props.customCameraProps?.resolutionHorizontal;
        const maxVertical =
            piaDevice?.properties.maxVideoResolutionVertical ||
            this.props.customCameraProps?.resolutionVertical;

        if (
            maxHorizontal &&
            maxHorizontal < resolution.getHorizontal() &&
            maxVertical &&
            maxVertical < resolution.getVertical()
        ) {
            return {
                horizontal: maxHorizontal,
                vertical: maxVertical,
            };
        } else {
            return {
                horizontal: resolution.getHorizontal(),
                vertical: resolution.getVertical(),
            };
        }
    };

    private getRows = (
        settings: IPersistence<IProfileEntity>,
        nbrOfSensors: number | undefined,
    ): Array<Array<string | number | JSX.Element> | null> => [
        settings.triggeredRecording?.schedule
            ? this.getRow(
                  t.installationReportSettingsTriggered,
                  settings.triggeredRecording,
                  settings.zipstream,
                  nbrOfSensors,
                  this.props.frameRates?.motionTriggered,
              )
            : null,
        settings.continuousRecording?.schedule
            ? this.getRow(
                  t.installationReportSettingsContinuous,
                  settings.continuousRecording,
                  settings.zipstream,
                  nbrOfSensors,
                  this.props.frameRates?.continuous,
              )
            : null,
        settings.liveView?.schedule
            ? this.getRow(
                  t.installationReportSettingsLive,
                  settings.liveView,
                  settings.zipstream,
                  nbrOfSensors,
                  this.props.frameRates?.live,
                  false,
              )
            : null,
    ];

    private getRow = (
        title: string,
        recordingSettings: IRecordingSettingsEntity,
        zipstreamSettings: IZipstreamSettingsEntity,
        nbrOfSensors: number | undefined,
        frameRate: number | undefined,
        showRetentionTime = true,
    ): Array<string | number | JSX.Element> => {
        const gopIsApplicable =
            this.props.zipStreamIsSupported && zipstreamSettings.zipStrength !== 0;

        // if averageBitrate is on, display a check-mark and the bitrate used, else 'off' if continuous and '-' if triggered
        const bandwidthString = this.props.continuousBandwidth
            ? format.bandwidth(this.props.continuousBandwidth)
            : undefined;
        // if globalSettings is on, get corresponding global step value for averageBitrate,
        // otherwise get value from profile (undefined if triggered)
        const useAverageBitrate =
            recordingSettings.useAverageBitrate !== undefined
                ? zipstreamSettings.useProjectSetting && this.props.projectZipSetting
                    ? getGlobalUseAverageBitrate(this.props.projectZipSetting)
                    : recordingSettings.useAverageBitrate
                : undefined;
        const averageBitrate = useAverageBitrate ? (
            <Stack spacing="half">
                <Icon opaque size="sm" icon={'check'} color="green" />
                <Text>{bandwidthString}</Text>
            </Stack>
        ) : useAverageBitrate !== undefined ? (
            t.off
        ) : (
            '-'
        );

        const zipStreamString = getZipSteamReportString(
            zipstreamSettings,
            this.props.projectZipSetting,
            this.props.zipStreamIsSupported,
            gopIsApplicable,
        );

        return [
            title,
            this.renderResolution(nbrOfSensors, recordingSettings),
            frameRate ?? recordingSettings.frameRate,
            recordingSettings.compression,
            zipStreamString,
            averageBitrate,
            recordingSettings.schedule ? this.props.scheduleNames[recordingSettings.schedule] : '',
            showRetentionTime && this.props.profile
                ? t.installationReportDays(this.props.profile?.storage.retentionTime)
                : '',
        ];
    };
}

export const SettingsTable = connect(mapStateToProps)(SettingsTableComponent);
