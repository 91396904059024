import * as leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { trigonometry, convert, rotate, distanceFromOrigo } from 'axis-webtools-util';
import type { IRadarArea } from '../../../utils';
import { toPolygon, calculateRadarArea } from '../../../utils';
import type { Colors } from 'app/styles';
import { ColorsEnum } from 'app/styles';
import type { LeafletMap } from '../LeafletMap';
import type {
    IInstallationPointRadar,
    IInstallationPointModel,
    ILatLng,
    PolyLine,
    IInstallationPoint,
} from 'app/core/persistence';
import { DistanceUnit, distanceUnitShortText } from 'app/core/persistence';
import { BaseCone } from './BaseCone';

import { IconStyle } from 'app/components';
import type { IPiaRadarDetector } from 'app/core/pia';
import type { IconTypes } from './utils/iconConstants';
import {
    ICON_OFFSET,
    HUMAN_ICON_HEIGHT,
    HUMAN_ICON_WIDTH,
    VEHICLE_ICON_HEIGHT,
    VEHICLE_ICON_WIDTH,
} from './utils/iconConstants';

import { getIconMaxPosPixels, getOffsetIconPosLatLng, showRangeIcon } from './utils';
import type { INonSensorCoverageArea } from '../../../models';
export interface IRadarDetectionRange {
    vehicle: {
        min: number;
        max: number;
    };
    human: {
        min: number;
        max: number;
    };
}

export class RadarCone extends BaseCone {
    public cameraHeight: number;
    public isGeneric: boolean;
    public targetHeight: number;
    public horizontalAngle: number;
    public targetDistance: number;
    public radarHorizontalFieldOfDetection?: number;
    public radarDetectionRange?: IRadarDetectionRange;

    constructor(
        currentRadar: IInstallationPointRadar,
        private location: ILatLng,
        piaRadar: IPiaRadarDetector,
        height: number,
        map: LeafletMap,
        color: Colors,
        private distanceUnit: DistanceUnit,
    ) {
        super(map, leaflet.latLng(location.lat, location.lng), color);
        this.isGeneric = !piaRadar;
        if (piaRadar) {
            const {
                radarHorizontalFieldOfDetection,
                minRadarDetectionRangeVehicle,
                maxRadarDetectionRangeVehicle,
                minRadarDetectionRangeHuman,
                maxRadarDetectionRangeHuman,
            } = piaRadar.properties;
            this.radarHorizontalFieldOfDetection = parseInt(radarHorizontalFieldOfDetection);
            this.radarDetectionRange = {
                vehicle: {
                    min: minRadarDetectionRangeVehicle,
                    max: maxRadarDetectionRangeVehicle,
                },
                human: {
                    min: minRadarDetectionRangeHuman,
                    max: maxRadarDetectionRangeHuman,
                },
            };
        }

        this.targetHeight = currentRadar.target.height;
        this.targetDistance = currentRadar.target.distance;
        this.horizontalAngle = currentRadar.target.horizontalAngle;
        this.cameraHeight = height;
    }

    public getHasTargetLine = () => {
        return true;
    };

    public updateDoriPixelsOn(): void {
        // noop
    }

    public toggleTinyIcons(): void {
        // noop
    }

    public getRotatedTarget(
        installationPoint: IInstallationPoint,
        rotationOffsetInDeg: number,
    ): DeepPartial<IInstallationPoint> {
        const rotatedTarget = this.rotateTargetAngle(
            'radar',
            installationPoint,
            rotationOffsetInDeg,
        );
        return rotatedTarget;
    }

    public getTargetPopupContent = (targetDistance: number) => {
        const distance =
            this.distanceUnit === DistanceUnit.Feet
                ? convert.metersToFeet(targetDistance)
                : targetDistance;
        const distanceAbbreviation = distanceUnitShortText(this.distanceUnit);

        return `
            <table>
                <tr>
                    <td rowSpan="2">
                        <i
                            class="${IconStyle}"
                            style="color: ${ColorsEnum.grey4};"
                            width="25px">accessibility
                        </i>
                    </td>
                    <td style="text-align: right">${distance.toFixed(1)}</td>
                    <td style="color: ${ColorsEnum.grey4};">${distanceAbbreviation}</td>
                </tr>
            </table>
        `;
    };

    public getFovPopupContent = (_angle: number) => {
        return null;
    };

    public getAnalyticsPopupContent() {
        return null;
    }

    public getIsFovAdjustable() {
        return false;
    }

    public getIsTargetAdjustable() {
        return !this.isGeneric;
    }

    public update = (ip: IInstallationPointModel, blockers: PolyLine[] | undefined) => {
        const radar = ip.radar;
        if (!radar) return;
        const newLocation = leaflet.latLng(ip.location.lat, ip.location.lng);
        // Update properties affected by changing device
        this.setRadarProperties(ip.parentPiaDevice as IPiaRadarDetector);
        if (this.location.lat !== newLocation.lat || this.location.lng !== newLocation.lng) {
            this.location = newLocation;
        }

        this.reDraw(blockers, {
            targetDistance: radar.target.distance,
            cameraHeight: ip.height,
            targetHeight: radar.target.height,
            horizontalAngle: radar.target.horizontalAngle,
            location: newLocation,
        });
    };

    public updateRangeAnalytics() {
        // noop
    }

    public getIconInfo() {
        if (!this.radarDetectionRange) {
            return undefined;
        }
        const rotationAngleRad = trigonometry.toRadians(this.horizontalAngle + 90);
        const iconInfo = {} as Record<IconTypes, { latLng: ILatLng; visible: boolean }>;

        let offsetHumanIcon = 0;
        let offsetVehicleIcon = 0;
        if (this.radarDetectionRange) {
            const personRangeLimit = this.radarDetectionRange.human;

            const vehicleRangeLimit = this.radarDetectionRange.vehicle;

            const humanIconPosPixels = getIconMaxPosPixels(
                personRangeLimit.max,
                this.targetDistance,
                this.map,
                this.latLng,
            );
            const vehicleIconPosPixels = getIconMaxPosPixels(
                vehicleRangeLimit.max,
                this.targetDistance,
                this.map,
                this.latLng,
            );

            if (
                personRangeLimit.max > this.targetDistance &&
                vehicleRangeLimit.max > this.targetDistance
            ) {
                offsetVehicleIcon = ICON_OFFSET;
                offsetHumanIcon = -ICON_OFFSET;
            } else if (vehicleIconPosPixels.x - VEHICLE_ICON_HEIGHT <= humanIconPosPixels.x) {
                offsetVehicleIcon = ICON_OFFSET;
                offsetHumanIcon = -ICON_OFFSET;
            }
        }
        if (this.radarDetectionRange?.human) {
            const targetRotationOffset = this.getTargetRotationOffset();
            // Human icon
            const iconPosWithOffset = getOffsetIconPosLatLng(
                HUMAN_ICON_HEIGHT,
                this.radarDetectionRange.human.max,
                rotationAngleRad,
                this.cameraHeight,
                this.targetHeight,
                this.targetDistance,
                targetRotationOffset,
                this.latLng,
                this.map,
                offsetHumanIcon,
            );

            const visible = showRangeIcon(
                {
                    cameraHeight: this.cameraHeight,
                    targetHeight: this.targetHeight,
                    rangeLimit: this.radarDetectionRange.human.max,
                    targetDistance: this.targetDistance,
                    rotationAngleRad: rotationAngleRad,
                    targetRotationOffset: targetRotationOffset,
                },
                {
                    resolutionGuidePolygon: this.resolutionGuidePolygon,
                    visibleAreaPolygon: this.visibleAreaPolygon,
                },
                {
                    iconHeight: HUMAN_ICON_HEIGHT,
                    iconWidth: HUMAN_ICON_WIDTH,
                    offsetY: offsetHumanIcon,
                },
                this.latLng,
                this.map,
                this.map.getZoom(),
                false,
            );
            iconInfo['human'] = { latLng: iconPosWithOffset, visible };
        }
        if (this.radarDetectionRange?.vehicle) {
            // Car icon
            const targetRotationOffset = this.getTargetRotationOffset();

            const iconPosWithOffset = getOffsetIconPosLatLng(
                VEHICLE_ICON_HEIGHT,
                this.radarDetectionRange.vehicle.max,
                rotationAngleRad,
                this.cameraHeight,
                this.targetHeight,
                this.targetDistance,
                targetRotationOffset,
                this.latLng,
                this.map,
                offsetVehicleIcon,
            );

            const visible = showRangeIcon(
                {
                    cameraHeight: this.cameraHeight,
                    targetHeight: this.targetHeight,
                    rangeLimit: this.radarDetectionRange.vehicle.max,
                    targetDistance: this.targetDistance,
                    rotationAngleRad: rotationAngleRad,
                    targetRotationOffset: targetRotationOffset,
                },
                {
                    resolutionGuidePolygon: this.resolutionGuidePolygon,
                    visibleAreaPolygon: this.visibleAreaPolygon,
                },
                {
                    iconHeight: VEHICLE_ICON_HEIGHT,
                    iconWidth: VEHICLE_ICON_WIDTH,
                    offsetY: offsetVehicleIcon,
                },
                this.latLng,
                this.map,
                this.map.getZoom(),
                false,
            );

            iconInfo['vehicle'] = { latLng: iconPosWithOffset, visible };
        }

        return Object.keys(iconInfo).length > 0 ? iconInfo : undefined;
    }

    public getNonSensorCoverageAreaInfo(): INonSensorCoverageArea {
        const target = {
            distance: this.targetDistance,
            height: this.targetHeight,
            horizontalAngle: this.horizontalAngle,
        };
        return { radar: { target } };
    }

    public reDraw(
        blockers: PolyLine[] | undefined,
        {
            targetDistance = this.targetDistance,
            cameraHeight = this.cameraHeight,
            targetHeight = this.targetHeight,
            horizontalAngle = this.horizontalAngle,
            location = this.latLng,
            radarHorizontalFieldOfDetection = this.radarHorizontalFieldOfDetection,
            radarDetectionRange = this.radarDetectionRange,
        },
    ) {
        try {
            if (this.isGeneric) {
                // hide the visible area if the camera is generic
                this.applyBlockerShadows(blockers, {
                    renderHorizon: 0,
                    resolutionGuide: toPolygon([]),
                });
                return;
            }

            this.latLng = location;
            this.horizontalAngle = horizontalAngle;
            this.targetDistance = targetDistance;
            this.cameraHeight = cameraHeight;
            this.targetHeight = targetHeight;
            this.radarHorizontalFieldOfDetection = radarHorizontalFieldOfDetection;
            this.radarDetectionRange = radarDetectionRange;

            // Add 90 degrees since the cone assumes 0 is to the right (it is down in our map)
            const rotationAngleRad = trigonometry.toRadians(this.horizontalAngle + 90);

            if (this.radarDetectionRange && this.radarHorizontalFieldOfDetection) {
                const { humanArea, visibleArea }: IRadarArea = calculateRadarArea(
                    this.radarHorizontalFieldOfDetection,
                    this.radarDetectionRange,
                    this.targetDistance,
                );

                const rotatedHumanArea = humanArea.map(rotate(rotationAngleRad));

                const renderHorizon = visibleArea.reduce((acc, point) => {
                    return Math.max(acc, distanceFromOrigo(point));
                }, 0);

                const visibleAreaRotated = visibleArea.map(rotate(rotationAngleRad));

                this.applyBlockerShadows(blockers, {
                    renderHorizon,
                    visibleArea: toPolygon(visibleAreaRotated),
                    outline: toPolygon(visibleAreaRotated),
                    resolutionGuide: toPolygon(rotatedHumanArea),
                });
                this.onIconPositionsChanged && this.onIconPositionsChanged(this.getIconInfo());
            }
        } catch (error) {
            console.error(error);
        }
    }
    public getHasDirectionalArrow() {
        return this.getIsTargetAdjustable();
    }

    private setRadarProperties(piaDevice: IPiaRadarDetector | null) {
        this.isGeneric = !piaDevice;
        if (piaDevice) {
            const {
                radarHorizontalFieldOfDetection,
                minRadarDetectionRangeVehicle,
                maxRadarDetectionRangeVehicle,
                minRadarDetectionRangeHuman,
                maxRadarDetectionRangeHuman,
            } = piaDevice.properties;
            this.radarHorizontalFieldOfDetection = parseInt(radarHorizontalFieldOfDetection);
            this.radarDetectionRange = {
                vehicle: {
                    min: minRadarDetectionRangeVehicle,
                    max: maxRadarDetectionRangeVehicle,
                },
                human: {
                    min: minRadarDetectionRangeHuman,
                    max: maxRadarDetectionRangeHuman,
                },
            };
        }
    }
    public getFovHandleLatLng() {
        return undefined;
    }
}
