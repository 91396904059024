import * as React from 'react';
import type { IWithChildren } from 'app/components';
import { css } from '@emotion/css';
import { isPageRTL } from 'app/translate';

interface IAnimatedHoverProps extends IWithChildren {
    isHovering: boolean;
}

const popoverStyle = (isRtl: boolean) => css`
    display: flex;
    opacity: 1;
    position: absolute;
    inset-inline-end: 100%;
    top: -2px;
    padding-inline-end: 8px;
    transition: all 200ms ease-in-out allow-discrete;
    transform-origin: top ${isRtl ? 'left' : 'right'};
`;

const startingStyle = (isRtl: boolean) => css`
    opacity: 0;
    translate: ${isRtl ? '-8px' : '8px'};
    scale: 0.95;
`;

const popoverOpen = (isRtl: boolean) => css`
    ${popoverStyle(isRtl)}
    @starting-style {
        ${startingStyle(isRtl)}
    }
`;

const popoverClose = (isRtl: boolean) => css`
    ${popoverStyle(isRtl)}
    display: none;
    ${startingStyle(isRtl)}
`;

export const AnimatedHover: React.FC<IAnimatedHoverProps> = ({ isHovering, children }) => {
    const isRtl = isPageRTL();
    return <div className={isHovering ? popoverOpen(isRtl) : popoverClose(isRtl)}>{children}</div>;
};

AnimatedHover.displayName = 'AnimatedHover';
