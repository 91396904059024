import * as React from 'react';
import { useState, useEffect } from 'react';
import { t } from 'app/translate';
import { eventTracking } from 'app/core/tracking';
import { convert, trigonometry } from 'axis-webtools-util';
import { SceneRenderView } from './SceneRenderer';
import { SceneRendererComponent } from './SceneRenderer.component';
import { PanoramaModeSwitcher } from './PanoramaModeSwitcher.component';
import { Border, Box, SegmentedControl, Text, Positioned, IconButton } from 'app/components';
import type {
    IFloorPlanEntity,
    PanoramaModes,
    UnitSystem,
    PolyLine,
    ILatLng,
} from 'app/core/persistence';
import { calculateTiltAngleFromRadians, isWideAnglePanoramic } from '../utils';
import { Colors } from './materials';

const RENDER_DISTANCE_THRESHOLD = 150; // don't render if target is further away
const PANORAMIC_FOV_THRESHOLD = 150;

interface IScene3dProps {
    cameraHeight: number;
    targetHeight: number;
    /**
     * The tilt offset is the offset angle from the calculated tilt angle. Used to
     * adjust the tilt angle for sensors that follow a different sensor.
     */
    tiltOffset: number;
    targetDistance: number;
    resolutionLimit?: number;
    desiredHorizontalFov?: number;
    desiredVerticalFov?: number;
    selectedHorizontalFov: number;
    selectedVerticalFov: number;
    horizontalAngle?: number;
    unitSystem: UnitSystem;
    panoramaMode: PanoramaModes;
    tiltable: boolean;
    sensorInfo?: string;
    availableViews?: SceneRenderView[];
    location?: ILatLng;
    floorPlans: IFloorPlanEntity[];
    blockers?: PolyLine[];
    enableDragEvents?: boolean;
    onChangeInstallationHeight?(newHeight: number): void;
    onChangeTargetDistance?(newDistance: number): void;
    onChangePanoramaMode?(panoramaMode: PanoramaModes): void;
    onExpand?(): void;
}

interface IMeasurementProps {
    label: string;
    value: string;
    testId?: string;
}

const getDistanceInUnit = (unitSystem: UnitSystem, distanceInMeter: number) => {
    return unitSystem === 'metric' ? distanceInMeter : convert.metersToFeet(distanceInMeter);
};

const getDistanceUnit = (unitSystem: UnitSystem) => {
    return unitSystem === 'metric' ? 'm' : 'ft';
};

const formatDistance = (
    unitSystem: UnitSystem,
    distanceInMeter: number | undefined,
    decimals = 1,
): string => {
    if (distanceInMeter === undefined) {
        return '-';
    }

    const formattedDistance = getDistanceInUnit(unitSystem, distanceInMeter).toFixed(decimals);
    const unit = getDistanceUnit(unitSystem);

    return `${formattedDistance} ${unit}`;
};

const getTiltAngle = (
    panoramaMode: PanoramaModes,
    horizontalFov: number,
    verticalFov: number,
    cameraHeight: number,
    targetHeight: number,
    targetDistance: number,
    tiltable: boolean,
    tiltOffset: number,
) => {
    if (!tiltable) {
        return 0;
    }

    switch (panoramaMode) {
        case false:
            return -trigonometry.toRadians(
                calculateTiltAngleFromRadians(
                    cameraHeight,
                    targetHeight,
                    targetDistance,
                    verticalFov,
                    tiltOffset,
                ),
            );
        case 'horizontal':
            return Math.PI / 2;
        case 'vertical':
            if (isWideAnglePanoramic(horizontalFov, verticalFov) || horizontalFov < Math.PI) {
                const minTilt = (Math.PI - verticalFov) / 2;

                return Math.min(
                    -trigonometry.toRadians(
                        calculateTiltAngleFromRadians(
                            cameraHeight,
                            targetHeight,
                            targetDistance,
                            verticalFov,
                            tiltOffset,
                        ),
                    ),
                    minTilt,
                );
            } else {
                return 0;
            }
    }
};

export const Scene3d: React.FunctionComponent<IScene3dProps> = ({
    cameraHeight,
    targetHeight,
    tiltOffset,
    targetDistance,
    resolutionLimit,
    desiredHorizontalFov,
    desiredVerticalFov,
    selectedHorizontalFov,
    selectedVerticalFov,
    horizontalAngle,
    unitSystem,
    panoramaMode,
    tiltable,
    sensorInfo,
    availableViews,
    location,
    floorPlans,
    blockers,
    enableDragEvents = true,
    onChangeInstallationHeight,
    onChangeTargetDistance,
    onChangePanoramaMode,
    onExpand,
}) => {
    // figure out which views to show
    const views = [
        {
            text: t.cameraSelectorFieldOfViewTop,
            id: SceneRenderView.TOP,
        },
        {
            text: t.cameraSelectorFieldOfViewSide,
            id: SceneRenderView.SIDE,
        },
        {
            text: t.cameraSelectorFieldOfViewLens,
            id: SceneRenderView.LENS,
        },
        {
            text: t.cameraSelectorFieldOfViewOrbit,
            id: SceneRenderView.ORBIT,
        },
    ].filter(({ id }) => !availableViews || availableViews.includes(id));

    // initialize selected view to SIDE if available
    const [selectedView, setSelectedView] = useState(
        views.some(({ id }) => id === SceneRenderView.SIDE) ? SceneRenderView.SIDE : views[0].id,
    );

    useEffect(() => {
        // if selected view is no longer available, pick another
        if (!views.some(({ id }) => id === selectedView)) {
            setSelectedView(views[0].id);
        }
    }, [views, selectedView]);

    const [blindSpot, setBlindSpot] = useState<number | undefined>(undefined);
    const [widthAtTarget, setWidthAtTarget] = useState<number | undefined>(undefined);

    const selectedTiltAngle = getTiltAngle(
        panoramaMode,
        selectedHorizontalFov,
        selectedVerticalFov,
        cameraHeight,
        targetHeight,
        targetDistance,
        tiltable,
        tiltOffset,
    );

    const desiredTiltAngle =
        desiredHorizontalFov &&
        desiredVerticalFov &&
        getTiltAngle(
            panoramaMode,
            desiredHorizontalFov,
            desiredVerticalFov,
            cameraHeight,
            targetHeight,
            targetDistance,
            tiltable,
            tiltOffset,
        );

    const changeView = (view: SceneRenderView) => {
        eventTracking.logUserEvent('DeviceSelector3DView', 'Change render view', view);
        setSelectedView(view);
    };

    const tooWideForLensView =
        selectedView === SceneRenderView.LENS &&
        selectedHorizontalFov > trigonometry.toRadians(PANORAMIC_FOV_THRESHOLD);

    const tooFarAway = targetDistance > RENDER_DISTANCE_THRESHOLD;

    // If target is too far away or fov is too high in the LENS view we hide the
    // 3D view and show an overlay
    const overlayText = tooFarAway
        ? t.cameraSelectorDistanceToTargetTooLargeMessage
        : tooWideForLensView
          ? t.cameraSelectorFovTooHighForLensViewMessage
          : null;

    const shouldShowInfoBox =
        selectedHorizontalFov <= trigonometry.toRadians(PANORAMIC_FOV_THRESHOLD);

    return (
        <Box direction="column" width="100%">
            <SegmentedControl items={views} selectedId={selectedView} onChange={changeView} />
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    backgroundColor: `#${Colors.ground.toString(16)}`,
                }}
            >
                <SceneRendererComponent
                    targetDistance={targetDistance}
                    targetHeight={targetHeight}
                    cameraHeight={cameraHeight}
                    resolutionLimit={resolutionLimit}
                    desiredHorizontalFov={desiredHorizontalFov}
                    desiredVerticalFov={desiredVerticalFov}
                    desiredTiltAngle={desiredTiltAngle}
                    selectedHorizontalFov={selectedHorizontalFov}
                    selectedVerticalFov={selectedVerticalFov}
                    selectedTiltAngle={selectedTiltAngle}
                    horizontalAngle={horizontalAngle}
                    selectedView={selectedView}
                    location={location}
                    floorPlans={floorPlans}
                    blockers={blockers}
                    setBlindSpot={setBlindSpot}
                    setWidthAtTarget={setWidthAtTarget}
                    onChangeInstallationHeight={onChangeInstallationHeight}
                    onChangeTargetDistance={onChangeTargetDistance}
                    enableDragEvents={enableDragEvents}
                />

                {onExpand && (
                    <Positioned position="absolute" top="5px" right="5px">
                        <IconButton icon="open_in_full" color="white" onClick={onExpand} />
                    </Positioned>
                )}

                {onChangePanoramaMode && (
                    <PanoramaModeSwitcher
                        current={panoramaMode}
                        onPanoramaModeChange={onChangePanoramaMode}
                    />
                )}
                {sensorInfo && (
                    <Positioned position="absolute" top="5px" left="5px">
                        <Box paddingX="half" paddingY="quart" color="whiteOpacity">
                            {sensorInfo}
                        </Box>
                    </Positioned>
                )}
                {overlayText && (
                    <Positioned position="absolute" top="0" bottom="0" right="0" left="0">
                        <Box alignItems="center" justifyContent="center" color="grey3">
                            <Text large color="grey7">
                                {overlayText}
                            </Text>
                        </Box>
                    </Positioned>
                )}
                {shouldShowInfoBox && (
                    <Positioned position="absolute" bottom="5px" left="5px">
                        <Border color="grey4">
                            <Box
                                direction="column"
                                paddingX="half"
                                paddingY="quart"
                                color="whiteOpacity"
                            >
                                <Measurement
                                    label={t.cameraSelectorFieldOfViewBlindSpot}
                                    value={formatDistance(unitSystem, blindSpot)}
                                    testId="lbl_blind_spot"
                                />
                                <Measurement
                                    label={t.cameraSelectorFieldOfViewWidthAtTarget}
                                    value={formatDistance(unitSystem, widthAtTarget)}
                                    testId="lbl_width_at_target"
                                />
                                {selectedTiltAngle !== undefined && (
                                    <Measurement
                                        label={`${t.installationReportInstallationTiltTitle}:`}
                                        value={t.installationReportInstallationTiltAngle(
                                            Math.round(trigonometry.toDegrees(selectedTiltAngle)),
                                        )}
                                        testId="lbl_tilt_angle"
                                    />
                                )}
                            </Box>
                        </Border>
                    </Positioned>
                )}
            </div>
        </Box>
    );
};
Scene3d.displayName = 'Scene3d';

const Measurement: React.FC<IMeasurementProps> = ({ label, value, testId }) => (
    <Box justifyContent="between">
        <Box flex="shrinkAndGrow" justifyContent="end">
            <Text>{label}</Text>
        </Box>
        <Box paddingX="quart" width="70px">
            <Text color="grey6" testId={testId}>
                {value}
            </Text>
        </Box>
    </Box>
);
Measurement.displayName = 'Measurement';
