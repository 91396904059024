import { injectable } from 'inversify';
import type {
    Id,
    IScheduleModel,
    IBaseProfileModel,
    IPersistence,
    IItemEntity,
    ICameraPropertiesEntity,
    IAnalogCameraPropertiesEntity,
    ProjectZipType,
    BandwidthVersion,
} from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import { ProfileBandwidthStorageEstimateService } from './bandwidth/ProfileBandwidthStorageEstimate.service';
import type { IPiaCameraProperties } from 'app/core/pia';
import { format } from 'axis-webtools-util';
import type { IBandwidthStorageCameraEstimateModel } from '../models';
import { ScenarioService } from '../../profile/services';
import type { Frequency } from '../../models';
import { AppStore } from 'app/store';
import { getVirtualItemBandwidthProps } from '../../piaDevices';

@injectable()
export class CameraEstimateService {
    constructor(
        private scenarioService: ScenarioService,
        private profileBandwidthStorageEstimateService: ProfileBandwidthStorageEstimateService,
        private appStore: AppStore,
    ) {}

    public getCameraEstimate(
        projectZipSetting: ProjectZipType,
        camera: IPersistence<IItemEntity>,
        productProperties: IPiaCameraProperties,
        frequency: Frequency,
        getSchedule: (id: Id) => IScheduleModel | undefined,
        getMergedProfileFromDevice: (
            item: ICameraPropertiesEntity | IAnalogCameraPropertiesEntity,
        ) => IBaseProfileModel | undefined,
        projectBandwidthVersion: BandwidthVersion,
    ): IBandwidthStorageCameraEstimateModel | undefined {
        if (!camera.properties.camera) {
            throw Error('Item is not a camera');
        }
        const profile = getMergedProfileFromDevice(camera.properties.camera);
        if (!profile) {
            return undefined;
        }

        const scenario = this.scenarioService.getScenarioOrThrow(profile.scenario.scenarioId);
        const customBandwidth = isCustomCamera(camera)
            ? camera.properties.camera.customCameraProperties?.bandwidth
            : camera.properties.camera.profileOverride
              ? camera.properties.camera.profileOverride.customBandwidth
              : undefined;
        const isGenericCamera = isCustomCamera(camera);
        const profileEstimate = this.profileBandwidthStorageEstimateService.getProfileEstimate(
            projectZipSetting,
            scenario,
            profile,
            productProperties,
            frequency,
            getSchedule,
            projectBandwidthVersion,
            customBandwidth,
            isGenericCamera,
        );

        const quantity = camera.quantity;

        const virtualItemChannels = getVirtualItemBandwidthProps(
            this.appStore.Store.getState(),
            camera._id,
        ).channels;
        /** Devices with virtual items list the total number of channels and sensors INCLUDING those of its virtual items. We deduct those here since estimates are calculated separately for virtual items. */
        const videoChannels =
            (productProperties.channels || productProperties.imageSensors || 1) -
            (virtualItemChannels ?? 0);

        const recordingBandwidthPerCamera = profileEstimate.recordingBandwidth * videoChannels;
        const liveViewBandwidthPerCamera = profileEstimate.liveViewBandwidth * videoChannels;
        const storagePerCamera = profileEstimate.storageInMB * videoChannels;

        const totalAverageRecordingBandwidth = recordingBandwidthPerCamera * quantity;
        const totalAverageLiveViewBandwidth = liveViewBandwidthPerCamera * quantity;
        const totalStorageInMB = storagePerCamera * quantity;

        return {
            type: 'camera',
            perCamera: {
                recordingBandwidth: recordingBandwidthPerCamera,
                formattedRecordingBandwidth: format.bandwidth(recordingBandwidthPerCamera),
                liveViewBandwidth: liveViewBandwidthPerCamera,
                formattedLiveViewBandwidth: format.bandwidth(liveViewBandwidthPerCamera),
                storageInMB: storagePerCamera,
                bandwidthInBps: recordingBandwidthPerCamera + liveViewBandwidthPerCamera,
                formattedStorage: format.storage(storagePerCamera),
                formattedBandwidth: format.bandwidth(
                    recordingBandwidthPerCamera + liveViewBandwidthPerCamera,
                ),
                retentionTime: profile.storage.retentionTime,
            },
            total: {
                recordingBandwidth: totalAverageRecordingBandwidth,
                formattedRecordingBandwidth: format.bandwidth(totalAverageRecordingBandwidth),
                liveViewBandwidth: totalAverageLiveViewBandwidth,
                formattedLiveViewBandwidth:
                    totalAverageLiveViewBandwidth !== 0
                        ? format.bandwidth(totalAverageLiveViewBandwidth)
                        : '',
                storageInMB: totalStorageInMB,
                bandwidthInBps: totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth,
                formattedStorage: totalStorageInMB !== 0 ? format.storage(totalStorageInMB) : '',
                formattedBandwidth:
                    totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth !== 0
                        ? format.bandwidth(
                              totalAverageRecordingBandwidth + totalAverageLiveViewBandwidth,
                          )
                        : '',
                retentionTime: profile.storage.retentionTime,
            },
            channels: videoChannels,
            triggeredRecording: profileEstimate.triggeredRecording,
            continuousRecording: profileEstimate.continuousRecording,
        };
    }
}
