import { createSelector } from 'reselect';
import { t } from 'app/translate';
import type { IItemEntity, IPersistence } from 'app/core/persistence';
import { isCustomCamera } from 'app/core/persistence';
import { getPiaItemsRecord } from '../../piaDevices';
import { getCurrentProjectItemsWithNetworkSettings } from './getCurrentProjectItemsWithNetworkSettings';
import { getCurrentProject } from '../../project';
import type { IPiaItem } from 'app/core/pia';

interface IExcelExport {
    fileName: string;
    title: string;
    headers: string[];
    dataRows: string[][];
}

const formatItemName = (item: IPersistence<IItemEntity>, index: number) => {
    if (item.quantity === 1) return item.name;
    return `${item.name} (${index + 1}/${item.quantity})`.trimStart();
};

/** Gets model name for an item. */
const formatItemModel = (item: IPersistence<IItemEntity>, piaItem: IPiaItem | undefined) => {
    if (piaItem) return piaItem.name;
    if (isCustomCamera(item)) return item.properties.camera.customCameraProperties.modelName;
    return t.genericCamera;
};

const formatOptionalField = (
    property: 'subnetMask' | 'defaultRouter',
    item: IPersistence<IItemEntity>,
    index: number,
) => {
    return item.networkSettings && item.networkSettings[index].override
        ? (item.networkSettings[index][property] ?? '')
        : '';
};

const getSomeDeviceHasOverride = createSelector(
    [getCurrentProjectItemsWithNetworkSettings],
    (items) => items.some((item) => item.networkSettings?.some((setting) => setting.override)),
);

/** Selector that retrieves network settings for project items and formats them for Excel exportation. */
export const getIpExcelData = createSelector(
    [
        getCurrentProjectItemsWithNetworkSettings,
        getPiaItemsRecord,
        getCurrentProject,
        getSomeDeviceHasOverride,
    ],
    (items, piaItems, currentProject, someHasOverride): IExcelExport => {
        const headers: string[] = [
            t.name,
            t.modelName,
            t.ipAddress,
            ...(someHasOverride ? [t.ipSubnetMask] : []),
            ...(someHasOverride ? [t.ipDefaultRouter] : []),
        ];
        const dataRows: string[][] = [];

        items.forEach((item) => {
            // Exclude items that don't have network settings or are set to "pick model later".
            if (!item.networkSettings || (!item.productId && !isCustomCamera(item))) return;
            const piaItem = item.productId ? piaItems[item.productId] : undefined;

            // Put all info on one line for single quantities.
            if (item.quantity === 1) {
                return dataRows.push([
                    item.name,
                    formatItemModel(item, piaItem),
                    item.networkSettings[0].addresses.join(', '),
                    ...(someHasOverride ? [formatOptionalField('subnetMask', item, 0)] : []),
                    ...(someHasOverride ? [formatOptionalField('defaultRouter', item, 0)] : []),
                ]);
            }

            // Split item by quantity and display individual IP settings.
            item.networkSettings.forEach((setting, index) =>
                dataRows.push([
                    formatItemName(item, index),
                    formatItemModel(item, piaItem),
                    setting.addresses.join(', '),
                    ...(someHasOverride ? [formatOptionalField('subnetMask', item, index)] : []),
                    ...(someHasOverride ? [formatOptionalField('defaultRouter', item, index)] : []),
                ]),
            );
        });
        return {
            fileName: `${currentProject.name}_${t.network}`,
            title: t.ipAddresses,
            headers,
            dataRows,
        };
    },
);
