import type { IIdRev } from 'app/core/persistence';
import { deviceTypeCheckers, isRelayExpansionModule } from 'app/core/persistence';
import {
    getCurrentProjectInstallationPoints,
    getCurrentProjectItemRelationsArray,
    mapInstallationEntityToModel,
    getCurrentProjectInstallationPointsArray,
} from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import { partition } from 'lodash-es';
import { createSelector } from 'reselect';
import { getCurrentProjectItems } from './getCurrentProjectItems';
import { getMapsState } from './getMapsState';

export const getMultiSelectPositionRecord = createSelector([getMapsState], (mapsState) => {
    return mapsState.selected?.multiSelected ?? {};
});

export const getMultiSelectedInstallationPointIds = createSelector(
    [getMultiSelectPositionRecord],
    (multiSelected) => {
        return Object.keys(multiSelected);
    },
);

export const getMultiSelectedInstallationPointsModels = createSelector(
    [
        getMultiSelectPositionRecord,
        getCurrentProjectInstallationPoints,
        getCurrentProjectItems,
        getCurrentProjectItemRelationsArray,
    ],
    (multiSelected, installationPointRecord, items, relations) =>
        Object.keys(multiSelected)
            .map((installationPointId) => {
                const installationPoint = installationPointRecord[installationPointId];
                if (!installationPoint) return undefined;
                return mapInstallationEntityToModel(installationPoint, items, relations);
            })
            .filter(isDefined),
);

/**
 * Get id and revs of the selected installation points
 * @returns [children, parents] where children are id revs of the selected installation point child items, and parents are id and revs of the selected installation points and their installation point descendants
 */

export const getInstallationPointChildren = createSelector(
    [getCurrentProjectInstallationPointsArray, getMultiSelectedInstallationPointsModels],
    (installationPoints, multiSelected) => {
        const [children, parents] = partition(
            multiSelected,
            ({ parentDevice, parentPiaDevice }) => {
                return (
                    deviceTypeCheckers.isDoor(parentDevice) ||
                    deviceTypeCheckers.isAnalogCamera(parentDevice) ||
                    deviceTypeCheckers.isSensorUnit(parentDevice) ||
                    isRelayExpansionModule(parentPiaDevice)
                );
            },
        );

        const parentWithDescendantsIdRevs = parents.reduce(
            (acc, parent) => {
                const installationPointDescendats = installationPoints.filter(
                    (installationPoint) => installationPoint.parentId === parent._id,
                );
                acc.push(...installationPointDescendats);
                return acc;
            },
            [...parents] as IIdRev[],
        );

        return [children, parentWithDescendantsIdRevs];
    },
);
