import * as React from 'react';
import type { DeviceType } from 'app/core/persistence';
import type { Colors } from 'app/styles';
import { useSelector } from 'react-redux';
import { getFilteredDeviceColors, getFilteredDeviceTypes } from '../selectors';

export const useIsLayerHidden = (
    deviceType: DeviceType | undefined,
    deviceColor: Colors | undefined,
): boolean => {
    const filteredDeviceTypes = useSelector(getFilteredDeviceTypes);
    const filteredDeviceColors = useSelector(getFilteredDeviceColors);

    const isLayerHidden = React.useMemo(() => {
        const isTypeHidden = deviceType !== undefined && filteredDeviceTypes.includes(deviceType);
        const isColorHidden =
            deviceColor !== undefined && filteredDeviceColors.includes(deviceColor);

        return isTypeHidden || isColorHidden;
    }, [deviceColor, deviceType, filteredDeviceColors, filteredDeviceTypes]);

    return isLayerHidden;
};
