import { Border, Box, Button, IconText, Text } from 'app/components';
import { MapsActionService } from 'app/modules/maps';
import { t } from 'app/translate';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { InstallationPointService, isCustomCamera } from 'app/core/persistence';
import { useKeyDown } from 'app/hooks';
import { MultiSelectMenuItem } from '../devices';
import { getCurrentProjectLocked } from 'app/modules/common';
import {
    getInstallationPointChildren,
    getMultiSelectedInstallationPointsModels,
} from '../../../../selectors/';

export const MultiSelectContextMenu: React.FunctionComponent = () => {
    const installationPointService = useService(InstallationPointService);
    const installationPoints = useSelector(getMultiSelectedInstallationPointsModels);
    const mapsActionService = useService(MapsActionService);
    const isProjectLocked = useSelector(getCurrentProjectLocked);
    const [children, parents] = useSelector(getInstallationPointChildren);

    const deleteAll = () => {
        installationPointService.removeInstallationPoints(parents);
        mapsActionService.deselectItem();
    };

    useKeyDown((e) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            deleteAll();
        }
    });

    return (
        <Box direction="column">
            <Box justifyContent="center" padding="quart" width="100%">
                <Text color="grey6" style="semibold">
                    {t.selectedDevices}
                </Text>
            </Box>
            <Border color="grey2" topWidth={2}>
                <Box direction="column" width="100%" paddingBottom="half">
                    {installationPoints.map((ip) => (
                        <MultiSelectMenuItem
                            key={ip._id}
                            piaId={ip.parentDevice.productId}
                            deviceItemId={ip.parentDevice._id}
                            name={ip.parentDevice.name}
                            installationPointName={ip.name}
                            isChild={children.includes(ip)}
                            isCustomCamera={isCustomCamera(ip.parentDevice)}
                            onClick={() => mapsActionService.multiSelect(ip)}
                        />
                    ))}
                </Box>
            </Border>
            <Button primary onClick={deleteAll} disabled={isProjectLocked}>
                {t.removeFromMap}
            </Button>
            {children.length > 0 && (
                <Box padding="half">
                    <IconText
                        spacing="half"
                        icon="info"
                        iconProps={{ color: 'blue', size: 'ms' }}
                        textProps={{ color: 'grey6' }}
                    >
                        {t.deviceNotToBeRemoved}
                    </IconText>
                </Box>
            )}
        </Box>
    );
};

MultiSelectContextMenu.displayName = 'MultiSelectContextMenu';
