import * as React from 'react';
import { eventTracking } from 'app/core/tracking';
import type { IExtendableComponent } from 'app/components';
import {
    Box,
    Clickable,
    DropDownMenuColor,
    Text,
    Checkbox,
    ColorPaletteColors,
    Button,
} from 'app/components';
import { t } from 'app/translate';
import type { Colors } from 'app/styles';
import { useSelector } from 'react-redux';
import { useService } from 'app/ioc';
import { MapLayerFilterService } from '../../services';
import { getFilteredMapLayers } from '../../selectors';
import { LayerDeviceTypeFilterItem } from './LayerDeviceTypeFilterItem';

export const LayerOptions: React.FC<IExtendableComponent> = (__htmlAttributes) => {
    const filteredLayers = useSelector(getFilteredMapLayers);
    const filteredColors = filteredLayers.colors;
    const labelsVisible = !filteredLayers.labelsLayer;
    const textBoxesVisible = !filteredLayers.textBoxesLayer;
    const mapLayerFilterService = useService(MapLayerFilterService);

    const toggleSelectedColor = (color: Colors) => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Color');
        mapLayerFilterService.toggleColorLayerFilter(color);
    };
    const toggleLabelsLayerVisible = () => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Label');
        mapLayerFilterService.toggleLabelsLayerFilter();
    };
    const toggleTextBoxesVisible = () => {
        eventTracking.logApplicationEvent('Maps', 'Layer Options', 'Text Boxes');
        mapLayerFilterService.toggleTextBoxesLayerFilter();
    };

    return (
        <Box direction="column" color="white" padding="base" spacing="panel" {...__htmlAttributes}>
            {/* Colors */}
            <Box direction="column" spacing="base">
                <Text style="semibold" color="grey6">
                    {t.color}
                </Text>
                <Box spacing="half">
                    {ColorPaletteColors.map((theColor) => (
                        <Clickable
                            key={`dropdown-color-${theColor}`}
                            onClick={() => toggleSelectedColor(theColor)}
                        >
                            <DropDownMenuColor
                                color={theColor}
                                size="sm"
                                selected={!filteredColors.includes(theColor)}
                            />
                        </Clickable>
                    ))}
                </Box>
            </Box>

            {/* Device Types */}
            <Box direction="column" spacing="base">
                <Box justifyContent="between">
                    <Text style="semibold" color="grey6">
                        {t.deviceType}
                    </Text>
                    <Text style="semibold" color="grey6">
                        {t.coverageArea}
                    </Text>
                </Box>

                <LayerDeviceTypeFilterItem label={t.cameras} layerGroup="cameras" />
                <LayerDeviceTypeFilterItem label={t.speakers} layerGroup="speakers" />
                <LayerDeviceTypeFilterItem label={t.radarDetectors} layerGroup="radarDetectors" />
                <LayerDeviceTypeFilterItem label={t.doorControllers} layerGroup="doorControllers" />
                <LayerDeviceTypeFilterItem label={t.other} layerGroup="other" />
            </Box>

            {/* Labels and Text Boxes */}
            <Box direction="column" spacing="base">
                <Text style="semibold" color="grey6">
                    {t.text}
                </Text>
                <Box justifyContent="between">
                    <Checkbox
                        slider
                        selected={labelsVisible}
                        onChange={() => toggleLabelsLayerVisible()}
                        testId="labels"
                    >
                        {t.labels}
                    </Checkbox>
                </Box>
                <Box justifyContent="between">
                    <Checkbox
                        slider
                        selected={textBoxesVisible}
                        onChange={() => toggleTextBoxesVisible()}
                        testId="text_boxes"
                    >
                        {t.textBoxes}
                    </Checkbox>
                </Box>
            </Box>
            <Button onClick={() => mapLayerFilterService.resetFilter()}>{t.reset}</Button>
        </Box>
    );
};

LayerOptions.displayName = 'SelectLayerModal';
