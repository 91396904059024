import * as React from 'react';
import {
    MountingChain,
    MountingAccessoriesList,
    DeviceAccessoriesList,
    StandaloneAccessoriesList,
    LocationSelection,
} from './components/';
import { Box } from 'app/components';
import { useSelector } from 'react-redux';
import { getIsStandalone } from '../common';
import type { IStoreState } from 'app/store';
import { ServiceLocator } from 'app/ioc';
import { AccessorySelectorActionService } from './services';
import { useLocation } from 'react-router-dom';
import type { IPiaItem } from 'app/core/pia';
import { PiaItemService } from 'app/core/pia';

export const AccessorySelectorView: React.FC = () => {
    const isStandalone = useSelector<IStoreState, boolean>((state) => getIsStandalone(state));
    const location = useLocation();

    React.useEffect(() => {
        if (isStandalone) {
            const accessorySelectorActionService = ServiceLocator.get(
                AccessorySelectorActionService,
            );
            accessorySelectorActionService.calculateSupportedEnvironments();

            const piaItemService = ServiceLocator.get<PiaItemService<IPiaItem>>(PiaItemService);
            const productId = Number(new URLSearchParams(location.search).get('cameraId'));
            const piaItem = piaItemService.get(productId).first();
            if (piaItem) {
                accessorySelectorActionService.addStandaloneItem(piaItem.id);
            }
        }
    }, [isStandalone, location]);

    return (
        <Box spacing="doublePanel" testId="panel_tab_content_accessories" direction="column">
            <MountingChain />
            {isStandalone ? (
                <>
                    <StandaloneAccessoriesList />
                    <Box alignItems="center" justifyContent="center">
                        <LocationSelection />
                    </Box>
                </>
            ) : (
                <Box spacing="base" flex="evenSpace" alignItems="start">
                    <DeviceAccessoriesList />
                    <MountingAccessoriesList />
                </Box>
            )}
        </Box>
    );
};
