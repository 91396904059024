import * as React from 'react';
import { Border, Box, NoPrint, Positioned } from 'app/components';
import { useMouseHover } from 'app/hooks';
import { isPageRTL } from 'app/translate';
import { LayerOptions } from './LayerOptions';
import { AnimatedHover } from './AnimatedHover';
import { ColorsEnum } from 'app/styles';

const shadow = '0 0 0 2px rgba(0 0 0 / 0.2)';

export const LayersButton: React.FunctionComponent = () => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const isHovering = useMouseHover(containerRef);
    const isRTL = isPageRTL();

    return (
        <NoPrint>
            <Positioned
                position="absolute"
                top={82}
                left={isRTL ? 12 : undefined}
                right={isRTL ? undefined : 12}
                aboveMap
            >
                <Box position="relative">
                    <Box innerRef={containerRef} testId="map_layer_button">
                        <Border radius="2px" width={0} shadow={shadow}>
                            <Box height={32} width={32} color="white" padding="halfQuart">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 56 56"
                                    fill="none"
                                >
                                    <path
                                        d="M28 28L4 38L28 48L52 38L28 28Z"
                                        fill={ColorsEnum.green}
                                    />
                                    <path
                                        d="M28 47.5L51 38L28 28.5"
                                        stroke="black"
                                        strokeOpacity="0.2"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M28 18L4 28L28 38L52 28L28 18Z"
                                        fill={ColorsEnum.yellow}
                                    />
                                    <path
                                        d="M28 37.5L51 28L28 18.5"
                                        stroke="black"
                                        strokeOpacity="0.2"
                                        strokeLinecap="round"
                                    />
                                    <path d="M28 8L4 18L28 28L52 18L28 8Z" fill={ColorsEnum.blue} />
                                    <path
                                        d="M28 27.5L51 18L28 8.5"
                                        stroke="black"
                                        strokeOpacity="0.2"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </Box>
                        </Border>
                        {/* Popover shown when hovering over the button */}
                        <AnimatedHover isHovering={isHovering}>
                            <Border topWidth={2} bottomWidth={2} color="black" shadow={shadow}>
                                <LayerOptions />
                            </Border>
                        </AnimatedHover>
                    </Box>
                </Box>
            </Positioned>
        </NoPrint>
    );
};

LayersButton.displayName = 'LayersButton';
