import {
    getBandwidthStorageEstimateForItems,
    getCurrentProjectItemsArray,
    getDeviceChildrenIds,
    isBandwidthDevice,
    toCacheKey,
} from 'app/modules/common';

import { nameComparator } from 'app/utils';
import { format } from 'axis-webtools-util';
import { sumBy } from 'lodash-es';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';
import { deviceTypeCheckers } from 'app/core/persistence';
import type { IBandwidthTotals } from '..';

export const getBandwidthDeviceIds = createSelector([getCurrentProjectItemsArray], (items) => {
    return (
        items
            .filter(isBandwidthDevice)
            // Filter out virtual products
            .filter((item) => !deviceTypeCheckers.isVirtualProduct(item))
            .sort(nameComparator)
            .map(({ _id }) => _id)
    );
});

export const getStorageBandwidthSum = createCachedSelector(
    [getDeviceChildrenIds, getBandwidthStorageEstimateForItems],
    (childrenIds, deviceBandwidthStorageTotal) => {
        if (childrenIds.length <= 0) {
            return;
        }

        const totalBandwidth = sumBy(
            childrenIds,
            (childId) => deviceBandwidthStorageTotal[childId]?.total.bandwidthInBps,
        );

        const totalStorage = sumBy(
            childrenIds,
            (child) => deviceBandwidthStorageTotal[child]?.total.storageInMB,
        );

        return {
            formattedBandwidth: totalBandwidth > 0 ? format.bandwidth(totalBandwidth) : '',
            formattedStorage: totalStorage > 0 ? format.storage(totalStorage) : '',
        } as IBandwidthTotals;
    },
)(toCacheKey);
