import { trigonometry } from 'axis-webtools-util';

export const calculateTiltAngleFromDegrees = (
    installationHeight: number,
    targetHeight: number,
    targetDistance: number,
    verticalFOVDeg: number,
    tiltOffset = 0,
) => {
    const tiltAngleRad =
        -Math.min(
            Math.atan((installationHeight - targetHeight) / targetDistance) +
                trigonometry.toRadians(verticalFOVDeg) / 2 -
                tiltOffset,
            Math.PI / 2,
        ) || 0;

    return trigonometry.toDegrees(tiltAngleRad);
};

export const calculateTiltAngleFromRadians = (
    installationHeight: number,
    targetHeight: number,
    targetDistance: number,
    verticalFOVRad: number,
    tiltOffset: number,
) => {
    const tiltAngleRad =
        -Math.min(
            Math.atan((installationHeight - targetHeight) / targetDistance) + verticalFOVRad / 2,
            Math.PI / 2 + tiltOffset,
        ) || 0;

    return trigonometry.toDegrees(tiltAngleRad + tiltOffset);
};

// copied this code in order to avoid merge conflicts with the ongoing maps refactoring
// we can probably remove calculateTiltAngleFromRadians later
export const calculateTiltAngle = (
    installationHeight: number,
    targetHeight: number,
    targetDistance: number,
    verticalFOVRad: number,
) => {
    const tiltAngleRad =
        -Math.min(
            Math.atan((installationHeight - targetHeight) / targetDistance) + verticalFOVRad / 2,
            Math.PI / 2,
        ) || 0;

    return tiltAngleRad;
};
