import * as React from 'react';
import type { IStoreState } from 'app/store';
import { useSelector } from 'react-redux';
import { t } from 'app/translate';
import type { Id, IInstallationPointEntity, IItemEntity, IPersistence } from 'app/core/persistence';
import { deviceTypeCheckers } from 'app/core/persistence';
import { DataWidget, Box, DataWidgetMulti } from 'app/components';
import type { IFieldOfView } from '../../selectors';
import {
    getCameraFocalLengthString,
    getCorridor,
    getFovStrings,
    getGeoLocatedInstallationPoint,
    getInstallationHeightText,
    getPixelDensityTarget,
    getTargetDistance,
    getTiltAngleForDevice,
} from '../../selectors';
import { FOV_LIMIT, getCurrentProjectItem, getTiltAnglesForIp } from 'app/modules/common';

interface IInstallationDetailsProps {
    installationPointId?: Id;
    itemId: Id;
    modelName: string;
}

export const InstallationDetails: React.FC<IInstallationDetailsProps> = ({
    installationPointId,
    itemId,
    modelName,
}) => {
    const item = useSelector<IStoreState, IPersistence<IItemEntity> | undefined>((store) =>
        getCurrentProjectItem(store, itemId),
    );

    const installationHeightText = useSelector<IStoreState, string | undefined>((store) =>
        getInstallationHeightText(store, itemId, installationPointId),
    );
    const installationFocalLengthString = useSelector<IStoreState, string>((store) =>
        getCameraFocalLengthString(store, itemId),
    );

    const fovStrings = useSelector<IStoreState, IFieldOfView[]>((store) =>
        getFovStrings(store, itemId, installationPointId),
    );

    const corridorFormat = useSelector<IStoreState, boolean[]>((store) =>
        getCorridor(store, itemId, installationPointId),
    );

    const pixelDensityTarget = useSelector<IStoreState, string[]>((store) =>
        getPixelDensityTarget(store, itemId, installationPointId),
    );

    const targetDistance = useSelector<IStoreState, string[]>((store) =>
        getTargetDistance(store, installationPointId),
    );

    const tiltAngles = useSelector<IStoreState, number[]>((store) =>
        installationPointId && installationPointId !== 'unplaced'
            ? getTiltAnglesForIp(store, installationPointId)
            : getTiltAngleForDevice(store, itemId),
    );

    const tiltAngleStrings = tiltAngles.map((tiltAngle) => `${Math.abs(Math.round(tiltAngle))}°`);

    const fovDescriptions: string[] =
        fovStrings?.length > 1
            ? fovStrings.map((_value, index) => `(${index + 1}) ${t.fieldOfView}`)
            : [t.fieldOfView];

    const pxDensityDescriptions: string[] =
        pixelDensityTarget?.length > 1
            ? pixelDensityTarget.map((_value, index) =>
                  targetDistance
                      ? `(${index + 1}) ${t.pixelDensityAtTarget} ${targetDistance[index]}`
                      : '',
              )
            : targetDistance
              ? [`${t.pixelDensityAtTarget} ${targetDistance[0]}`]
              : [''];

    const tiltAngleDescriptions: string[] =
        tiltAngles.length > 1
            ? fovStrings.map(
                  (_value, index) => `(${index + 1}) ${t.installationReportInstallationTiltTitle}`,
              )
            : [t.installationReportInstallationTiltTitle];

    const installationPoint = useSelector<
        IStoreState,
        IPersistence<IInstallationPointEntity> | undefined
    >((store) => getGeoLocatedInstallationPoint(store, installationPointId));

    if (
        !installationHeightText ||
        (installationHeightText.length === 0 && tiltAngles.length !== 1)
    ) {
        return null;
    }

    const arrowIcons = tiltAngles.map((tiltAngle) =>
        tiltAngle > 0 ? 'arrow_upward' : tiltAngle < 0 ? 'arrow_downward' : undefined,
    );

    const shouldShowTiltAngle =
        item &&
        !deviceTypeCheckers.isDoorStation(item) &&
        !tiltAngles.some((angle) => angle >= FOV_LIMIT);

    return (
        <Box spacing="panel" justifyContent={'start'} alignItems="center" wrap paddingTop="base">
            {installationPoint && (
                <DataWidget
                    testId={`installation_report_location_${modelName}`}
                    text={`${installationPoint.location.lat.toFixed(
                        6,
                    )}, ${installationPoint.location.lng.toFixed(6)}`}
                    description={t.location}
                    textStyle="heading"
                />
            )}
            {installationHeightText.length > 0 && (
                <DataWidget
                    testId={`installation_report_installation_height_${modelName}`}
                    text={installationHeightText}
                    description={t.installationHeight}
                    textStyle={'heading'}
                />
            )}
            {corridorFormat.map(
                (corridor, index) =>
                    corridor && (
                        <Box direction="column">
                            <DataWidget
                                testId={`installation_report_corridor${modelName}`}
                                text={t.cameraSelectorFieldOfViewCorridor}
                                description={
                                    corridorFormat.length > 1
                                        ? `(${index + 1}) ${t.orientation}`
                                        : t.orientation
                                }
                                textStyle="heading"
                            />
                        </Box>
                    ),
            )}
            {installationFocalLengthString.length > 0 && (
                <DataWidget
                    testId={`installation_report_focal_length${modelName}`}
                    text={installationFocalLengthString}
                    description={t.focalLength}
                    textStyle="heading"
                />
            )}
            {shouldShowTiltAngle && (
                <DataWidgetMulti
                    testId={`${modelName}_tilt_angle`}
                    textValues={tiltAngleStrings}
                    descriptions={tiltAngleDescriptions}
                    textStyle="heading"
                    secondaryIcons={arrowIcons}
                />
            )}
            {fovStrings && fovStrings.length > 0 && (
                <DataWidgetMulti
                    testId={`installation_report_fov${modelName}`}
                    textValues={fovStrings.map((fov) => fov.horizontal)}
                    secondaryTextValues={fovStrings.map((fov) => fov.vertical)}
                    textValueDescription={`${t.horizontalShort}:`}
                    secondaryTextValueDescription={`${t.verticalShort}:`}
                    descriptions={fovDescriptions}
                    textStyle="heading"
                    textValueDescriptionStyle="heading"
                />
            )}

            {pixelDensityTarget && pixelDensityTarget.length > 0 && (
                <DataWidgetMulti
                    testId={`installation_report_pixel_density${modelName}`}
                    textValues={pixelDensityTarget}
                    descriptions={pxDensityDescriptions}
                    textStyle="heading"
                />
            )}
        </Box>
    );
};

InstallationDetails.displayName = 'InstallationDetails';
