import type { Icons } from 'app/components';
import type { Id } from 'app/core/persistence';
import { getDeviceType, getSubType, isCustomCamera } from 'app/core/persistence';
import type { IPiaAccessory, IPiaItem } from 'app/core/pia';
import { PiaRelationService } from 'app/core/pia';
import { ServiceLocator } from 'app/ioc';
import {
    createProductAllowlistFilter,
    deviceIcons,
    getCurrentProjectItems,
    getCurrentProjectRelationsRecord,
    getPiaItemsRecord,
    getPiaLensesRecord,
    getProductAllowlist,
    getRelatedPiaItemsForSelectedDevice,
    getUseProductAllowlist,
} from 'app/modules/common';
import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';

const selectedDeviceInMaps = (store: IStoreState) => {
    return store.maps.selected?.deviceId ?? store.maps.selected?.mapItem?.parentDeviceId;
};

const selectedProjectDevice = (store: IStoreState) => store.projectDevices.panelItem?.itemId;

const selectedMapOrProjectDeviceId = createSelector(
    [selectedDeviceInMaps, selectedProjectDevice],
    (mapDevice, projectDevice): Id | undefined => {
        return window.location.href.includes('maps') ? mapDevice : projectDevice;
    },
);

const standAloneSelectedDevice = (store: IStoreState) => store.accessorySelector.deviceId;

export const getSelectedDeviceId = createSelector(
    [selectedMapOrProjectDeviceId, standAloneSelectedDevice],
    (selectedDeviceId, standAloneSelectedDeviceId) =>
        selectedDeviceId ?? standAloneSelectedDeviceId ?? undefined,
);

export const getSelectedDeviceIdPiaItem = createSelector(
    [getSelectedDeviceId, getCurrentProjectItems, getPiaItemsRecord],
    (selectedDeviceId, currentProjectItems, piaItems) => {
        const productId = selectedDeviceId
            ? (currentProjectItems[selectedDeviceId]?.productId ?? undefined)
            : undefined;

        return productId ? piaItems[productId] : undefined;
    },
);

export const getSelectedDeviceName = createSelector(
    [getSelectedDeviceId, getCurrentProjectItems],
    (selectedDeviceId, currentProjectItems) => {
        return selectedDeviceId
            ? (currentProjectItems[selectedDeviceId]?.name ?? undefined)
            : undefined;
    },
);

export const getDeviceAndSubTypeForItem = createSelector(
    [getSelectedDeviceId, getCurrentProjectItems, getPiaItemsRecord],
    (selectedDeviceId, currentProjectItems, piaItemsRecord) => {
        const item = selectedDeviceId ? currentProjectItems[selectedDeviceId] : undefined;
        if (!item) {
            return 'camera';
        }

        const piaItem = item.productId ? piaItemsRecord[item.productId] : undefined;

        if (piaItem) {
            const subType = getSubType(piaItem);

            if (subType) {
                return subType;
            }
        }

        return getDeviceType(item, piaItem);
    },
);

export const getIsCustomCamera = createSelector(
    [getSelectedDeviceId, getCurrentProjectItems],
    (selectedDeviceId, currentProjectItems): boolean => {
        const item = selectedDeviceId ? currentProjectItems[selectedDeviceId] : undefined;
        if (!item) {
            return false;
        }
        return isCustomCamera(item);
    },
);

export const getSelectedDevicePiaLoadingIcon = createSelector(
    [getDeviceAndSubTypeForItem, getIsCustomCamera],
    (deviceType, isCustomCameraSelected): Icons => {
        if (isCustomCameraSelected) return 'videocam';
        return deviceIcons.toIcon(deviceType);
    },
);

/** Gets all selected lenses for currently selected item in device details */
export const getSelectedDeviceSelectedLenses = createSelector(
    [
        selectedMapOrProjectDeviceId,
        getCurrentProjectItems,
        getCurrentProjectRelationsRecord,
        getPiaLensesRecord,
    ],
    (selectedId, items, relations, piaLenses) => {
        if (!selectedId || !items) return [];
        const item = items[selectedId];
        if (!item || !relations[item?._id]) return [];

        return relations[item._id].reduce((lensItems, currentRelation) => {
            if (currentRelation.relationType !== 'lenses') {
                return lensItems;
            }
            const lensItem = items[currentRelation.childId];
            if (!lensItem || lensItem.productId === null) {
                return lensItems;
            }
            const lensPiaItem = piaLenses[lensItem.productId];
            if (!lensPiaItem || lensItems.some((lens) => lens.id === lensPiaItem.id)) {
                return lensItems;
            }
            return [...lensItems, lensPiaItem];
        }, [] as IPiaItem[]);
    },
);

/** Gets all available pia lenses for currently selected item in device details */
export const getSelectedDevicePiaLenses = createSelector(
    [
        selectedMapOrProjectDeviceId,
        getCurrentProjectItems,
        getRelatedPiaItemsForSelectedDevice,
        getProductAllowlist,
        getUseProductAllowlist,
        getSelectedDeviceSelectedLenses,
    ],
    (selectedId, currentProjectItems, piaAccessories, allowList, useAllowList, selectedLenses) => {
        if (!selectedId) return [];
        const cameraItem = currentProjectItems[selectedId];
        if (!cameraItem?.productId) return [];

        const lenses = piaAccessories.reduce((items, item) => {
            if (
                item.category !== 'lenses' ||
                !(
                    createProductAllowlistFilter(allowList, useAllowList)(item.id) ||
                    selectedLenses.some((selectedLens) => item.id === selectedLens.id)
                ) ||
                cameraItem?.productId === null
            ) {
                return items;
            }
            const relation = ServiceLocator.get(PiaRelationService).getRelationProperties(
                cameraItem.productId,
                item.id,
            );
            if (!relation?.horizontalFOV?.max || !relation.horizontalFOV.min) {
                return items;
            }
            return [...items, item];
        }, [] as IPiaAccessory[]);

        return lenses;
    },
);
