import { createSelector } from 'reselect';
import {
    getAccessoriesByIdRecord,
    getProposalDeviceDetails,
} from '../../installationReport/selectors';
import type { IProposalDetailedDevice } from '../../models/IProposalDetailedDevice';
import { getProjectInstallationPoints } from 'app/modules/maps';
import { getCurrentProjectCustomCameras, getCurrentProjectProfiles } from 'app/modules/common';
import { isDefined } from 'axis-webtools-util';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import type { PiaId } from 'app/core/pia';
import type {
    IInstallationBaseReportAccessory,
    IInstallationReportCameraDevice,
    IInstallationReportDoors,
    IInstallationReportPacDevice,
} from '../../installationReport';
import { nameComparator } from 'app/utils';

export interface IProposalOrCustomDevice {
    id: Id;
    name: string;
    quantity: number;
    model: string;
    piaId: PiaId | null;
    analogCameras: IInstallationReportCameraDevice[];
    sensorUnits: IInstallationReportCameraDevice[];
    expansionModules: IInstallationReportPacDevice[];
    doors: IInstallationReportDoors[];
    isOnMaps: boolean;
    accessories: IInstallationBaseReportAccessory[];
    triggeredRecordingFrameRate?: number;
    triggeredRecordingResolution?: string;
    triggeredRecordingScheduleId?: string | null;
    continuousRecordingFrameRate?: number;
    continuousRecordingResolution?: string;
    continuousRecordingScheduleId?: string | null;
    retentionTime?: number;
}

export const getProposalDetailedDevices = createSelector(
    [getProposalDeviceDetails, getProjectInstallationPoints, getAccessoriesByIdRecord],
    (proposalDetailDevices, installationPoints, accessoriesByIdRecord) =>
        proposalDetailDevices
            .map((item) => {
                const isOnMaps = installationPoints.filter(
                    (ip) => ip.parentDevice._id === item.id,
                ).length;
                return {
                    piaId: item.piaId,
                    id: item.id,
                    name: item.name,
                    model: item.model,
                    quantity: item.quantity,
                    accessories: accessoriesByIdRecord[item.id] ?? [],
                    analogCameras: 'analogCameras' in item ? item.analogCameras : [],
                    sensorUnits: 'sensors' in item ? item.sensors : [],
                    expansionModules: 'expansionModules' in item ? item.expansionModules : [],
                    doors: 'doors' in item ? item.doors : [],
                    settings: 'settings' in item ? item.settings : {},
                    isOnMaps: isOnMaps > 0,
                };
            })
            .filter(isDefined)
            .sort(nameComparator) as IProposalDetailedDevice[],
);

function isPiaDevice(
    device: IProposalDetailedDevice | IPersistence<IItemEntity>,
): device is IProposalDetailedDevice {
    return device.hasOwnProperty('piaId');
}

export const getAllProposalDevices = createSelector(
    [
        getProposalDetailedDevices,
        getCurrentProjectCustomCameras,
        getProjectInstallationPoints,
        getCurrentProjectProfiles,
    ],
    (
        devices,
        customCameras,
        installationPoints,
        currentProjectProfiles,
    ): IProposalOrCustomDevice[] =>
        [...devices, ...customCameras].map((item) => {
            const customDeviceMergedProfile =
                !isPiaDevice(item) && item.properties.camera
                    ? {
                          ...item.properties.camera?.profileOverride,
                          ...currentProjectProfiles[item.properties.camera.associatedProfile],
                      }
                    : undefined;

            const triggeredRecording = isPiaDevice(item)
                ? item.settings.triggeredRecording
                : customDeviceMergedProfile?.triggeredRecording;

            const continuousRecording = isPiaDevice(item)
                ? item.settings.continuousRecording
                : customDeviceMergedProfile?.continuousRecording;

            const customProps = isPiaDevice(item)
                ? undefined
                : item.properties.camera?.customCameraProperties;

            return {
                id: isPiaDevice(item) ? item.id : item._id,
                name: item.name,
                quantity: item.quantity,
                piaId: isPiaDevice(item) ? item.piaId : null,
                model: isPiaDevice(item)
                    ? item.model
                    : (item.properties.camera?.customCameraProperties?.modelName ?? ''),
                analogCameras: isPiaDevice(item) ? item.analogCameras : [],
                sensorUnits: isPiaDevice(item) ? item.sensorUnits : [],
                expansionModules: isPiaDevice(item) ? item.expansionModules : [],
                doors: isPiaDevice(item) ? item.doors : [],
                isOnMaps: isPiaDevice(item)
                    ? item.isOnMaps || false
                    : installationPoints.some((ip) => ip.parentDevice._id === item._id),
                accessories: isPiaDevice(item) ? item.accessories : [],
                triggeredRecordingFrameRate: triggeredRecording?.frameRate,
                triggeredRecordingResolution: isPiaDevice(item)
                    ? triggeredRecording?.resolution?.toString()
                    : `${customProps?.resolutionHorizontal}x${customProps?.resolutionVertical}`,
                triggeredRecordingScheduleId: triggeredRecording?.schedule,
                continuousRecordingFrameRate: continuousRecording?.frameRate,
                continuousRecordingResolution: isPiaDevice(item)
                    ? continuousRecording?.resolution?.toString()
                    : `${customProps?.resolutionHorizontal}x${customProps?.resolutionVertical}`,
                continuousRecordingScheduleId: continuousRecording?.schedule,
                retentionTime: isPiaDevice(item)
                    ? item.settings.retentionTime
                    : customDeviceMergedProfile?.storage.retentionTime,
            };
        }),
);
