import { injectable } from 'inversify';
import type { Id, IEntity } from '../../entities';
import { ProjectDbOrigin } from '../../entities';
import type { IIdRev } from '../../models';
import {
    RepositoryConflictError,
    RepositoryDbNotDefined,
    RepositoryInvalidRevError,
} from '../errors';
import { RepositoryAction } from '../RepositoryAction';
import { EntitySettings } from './EntitySettings';
import type { IPersistenceRepository } from './models';
import { uniqBy } from 'lodash-es';
import { RepositoryMissingEntityVersionError } from '../errors/RepositoryMissingEntityVersionError';
import { EventEmitter } from 'events';
import { isDefined } from 'axis-webtools-util';
import { isErrorType } from '../../../utils/toErrorMessage';

/**
 * Manages high-level operations when communicating with PouchDB.
 */
@injectable()
export class PersistenceDatabaseRepository implements IPersistenceRepository {
    public emitter = new EventEmitter();
    // User to do prefix range search in pouch
    private readonly HIGH_VALUE_UNICODE = '\uffff';

    private localDb: PouchDB.Database | undefined;

    constructor(private entitySettings: EntitySettings) {
        this.localDb = undefined;
    }

    /**
     * Initializes the repository, making it ready to handle repository actions.
     */
    public initialize(localDb: PouchDB.Database): Promise<void> {
        this.localDb = localDb;
        this.listenToChanges();
        return this.createDescendantsDesign(this.localDb);
    }

    /**
     * It seems Pouch DB sometimes puts view indexes the local db.
     * These were saved in the format of "554project:....."
     * This function gets all ids that starts with "554" and removes them.
     */
    public async localDbIndexCleanup(): Promise<number> {
        if (!this.localDb) {
            return 0;
        }
        const docs = await this.localDb.allDocs({
            startkey: '554',
            endkey: `${'554'}${this.HIGH_VALUE_UNICODE}`,
            include_docs: true,
        });
        const deletedDocs = await this.bulkDelete(docs.rows.map((r) => r.doc).filter(isDefined));
        return deletedDocs.length;
    }

    /**
     * Cleans the database of old indexes.
     */
    public async viewCleanup(): Promise<void> {
        if (!this.localDb) {
            return Promise.resolve();
        }
        await this.localDb.viewCleanup();
    }

    /**
     * Deletes the database, with exception for the local pouchDb which doesn't replicate - see WT-5094
     */
    public async clear(): Promise<void> {
        if (this.localDb && this.localDb.name !== ProjectDbOrigin.asdLocalUserData) {
            await this.localDb.destroy();
            this.localDb = undefined;
        }
        return Promise.resolve();
    }

    /**
     * Gets the latest item using its id.
     * @param id the id of the entity to retrieve.
     */
    public get(id: Id): Promise<IEntity> {
        if (!this.localDb) {
            throw new RepositoryDbNotDefined(RepositoryAction.Get);
        }
        return this.localDb.get<IEntity>(id);
    }

    /**
     * Check if an entity exists.
     * @param id the id of the entity.
     */
    public async exists(id: Id): Promise<boolean> {
        try {
            await this.get(id);
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     *
     * @returns The name of the database
     */
    public getDbName(): string {
        return this.localDb?.name ?? '';
    }

    /**
     * Gets all items of a type based on the idPrefix
     * @param idPrefix the type for which all objects are queried
     */
    public async getAll(idPrefix: string = ''): Promise<IEntity[]> {
        if (!this.localDb) {
            return Promise.resolve([]);
        }
        const allDocs = await this.localDb.allDocs({
            include_docs: true,
            startkey: idPrefix,
            endkey: `${idPrefix}${this.HIGH_VALUE_UNICODE}`,
        });

        // Filter out all design documents
        return allDocs.rows
            .filter((r) => !r.id.startsWith('_design'))
            .map((entity) => {
                return {
                    ...entity.doc,
                } as IEntity;
            });
    }

    /**
     * Gets all descendants of an entity including itself. It will always return itself as first in the array.
     * @param id the id of the entity we want the descendants for.
     */
    public async getDescendants(id: Id): Promise<IEntity[]> {
        if (!this.localDb) {
            return Promise.resolve([]);
        }
        const descendants = await this.localDb.query('descendants', {
            include_docs: true,
            startkey: [id],
            endkey: [id, {}],
        });
        return descendants.rows.map((item) => {
            return {
                ...item.doc,
            } as IEntity;
        });
    }

    /**
     * Adds an item.
     * @param entity the entity to add.
     */
    public async add(entity: IEntity): Promise<IEntity> {
        if (!this.localDb) {
            throw new RepositoryDbNotDefined(RepositoryAction.Add);
        }

        // Delete _rev if it exists, as it should not be set when adding a new entity.
        delete (entity as Partial<IEntity>)._rev;

        const response = await this.localDb.put(entity);
        if (response.ok) {
            return {
                ...entity,
                _rev: response.rev,
            };
        }
        throw new RepositoryConflictError(
            RepositoryAction.Add,
            new Array<PouchDB.Core.Response>(response),
        );
    }

    /**
     * Updates an item.
     * @param entity the updated entity.
     * @throws if the item does not exist.
     */
    public async update(entity: IEntity): Promise<IEntity> {
        if (!entity._rev) {
            throw new RepositoryInvalidRevError(RepositoryAction.Update, [entity._id]);
        }
        if (!entity.entityVersion) {
            throw new RepositoryMissingEntityVersionError(RepositoryAction.Update, [entity._id]);
        }
        if (!this.localDb) {
            throw new RepositoryDbNotDefined(RepositoryAction.Update);
        }
        const response = await this.localDb.put(entity);

        if (response.ok) {
            return {
                ...entity,
                _rev: response.rev,
            };
        }
        throw new RepositoryConflictError(
            RepositoryAction.Update,
            new Array<PouchDB.Core.Response>(response),
        );
    }

    /**
     * Deletes an item. Any references to the item will not be updated.
     * @param doc the entity to delete.
     */
    public async delete(doc: IIdRev): Promise<IIdRev> {
        const response = await this.bulkDelete([doc]);
        return response[0];
    }

    /**
     * Adds items in bulk.
     */
    public bulkAdd(entities: IEntity[]): Promise<IEntity[]> {
        // Delete _rev if it exists, as it should not be set when adding a new entity.
        entities.forEach((entity) => {
            delete (entity as Partial<IEntity>)._rev;
        });

        return this.bulkOperation(entities as IEntity[], RepositoryAction.BulkAdd);
    }

    /**
     * Updates items in bulk.
     */
    public async bulkUpdate(entities: IEntity[]): Promise<IEntity[]> {
        entities.forEach((entity) => {
            if (!entity._rev) {
                throw new RepositoryInvalidRevError(RepositoryAction.BulkUpdate, [entity._id]);
            }
            if (!entity.entityVersion) {
                throw new RepositoryMissingEntityVersionError(RepositoryAction.BulkUpdate, [
                    entity._id,
                ]);
            }
        });
        return this.bulkOperation(entities, RepositoryAction.BulkUpdate);
    }

    /**
     * Deletes items in bulk.
     */
    public async bulkDelete(docs: IIdRev[]): Promise<IIdRev[]> {
        if (!this.localDb) {
            return Promise.resolve([]);
        }

        const uniqueDocs = uniqBy(docs, (idRev) => idRev._id);

        if (uniqueDocs.length === 0) {
            // return early if no docs to delete
            return [];
        }

        const fullDocs = await this.getDocs(docs);

        // store path and archived props in deleted docs for efficient filtered
        // replication and sync
        const deleteDocs = fullDocs.map((doc) => ({
            _deleted: true,
            _id: doc._id,
            _rev: doc._rev,
            path: doc.path,
            archived: doc.archived,
        }));

        const responses = await this.localDb.bulkDocs(deleteDocs);
        const invalidResponses = this.getInvalidResponses(responses);

        if (invalidResponses.length === 0) {
            // invalidResponses filters out all of type PouchDB.Core.Error
            return (responses as PouchDB.Core.Response[]).map((response) => ({
                _id: response.id,
                _rev: response.rev,
            }));
        }
        throw new RepositoryConflictError(RepositoryAction.BulkDelete, invalidResponses);
    }

    private async bulkOperation(entities: IEntity[], action: RepositoryAction) {
        if (!this.localDb) {
            return [];
        }
        const responses = await this.localDb.bulkDocs(entities);
        const invalidResponses = this.getInvalidResponses(responses);

        if (invalidResponses.length === 0) {
            return this.updateRevFromResponses(entities, responses as PouchDB.Core.Response[]);
        }
        throw new RepositoryConflictError(action, invalidResponses);
    }

    /**
     * Gets entities from DB by ids
     */
    private async getDocs(idRevs: IIdRev[]): Promise<IEntity[]> {
        if (!this.localDb) {
            return Promise.resolve([]);
        }
        const allDocs = await this.localDb.allDocs({
            include_docs: true,
            keys: idRevs.map((idRev) => idRev._id),
        });

        // Filter out all design documents
        return allDocs.rows
            .filter((r) => !r.id.startsWith('_design'))
            .map((entity) => {
                return {
                    ...entity.doc,
                } as IEntity;
            })
            .filter((doc) => idRevs.find(({ _id, _rev }) => _id === doc._id && _rev === doc._rev));
    }

    private getInvalidResponses(
        responses: Array<PouchDB.Core.Response | PouchDB.Core.Error>,
    ): Array<PouchDB.Core.Response | PouchDB.Core.Error> {
        return responses.filter((response) => {
            return (
                !(response as PouchDB.Core.Response).ok || (response as PouchDB.Core.Error).error
            );
        });
    }

    /**
     * Creates index for path tracking for all descendants to an entity including itself.
     * This will slice the path array so we can do a quick lookup everywhere in the tree.
     * For example an entity with id `c` and path `[a, b, c]` will emit `[a, b, c]`, `[b, c]` and `[c]`.
     * This will make it work correctly when asking for all descendants for `a`, `b` and `c` without
     * needing to provide the full path.
     */
    private async createDescendantsDesign(db: PouchDB.Database) {
        if (!this.localDb) {
            return;
        }
        const design = {
            _id: '_design/descendants',
            version: this.entitySettings.version,
            views: {
                descendants: {
                    // This javascript code will be executed by PouchDB as a string. This have to work
                    // on all browsers so keep it as vanilla as possible (no arrow functions etc) because
                    // this won't get transpiled.

                    map: function (doc: IEntity) {
                        doc.path &&
                            doc.path.forEach(function (_key, index, self) {
                                emit(self.slice(index));
                            });
                    }.toString(),
                },
            },
        };

        try {
            const designInDatabase = await this.localDb.get<typeof design>(design._id);

            // Update design document if it is out-of-date
            if (designInDatabase.version !== design.version) {
                designInDatabase.version = design.version;
                designInDatabase.views = design.views;
                await db.put(designInDatabase);
            }
        } catch (e) {
            // Add the design document if it is missing
            if (isErrorType(e) && e.name === 'not_found') {
                await db.put(design as PutDocumentWithView<typeof design>);
            }
        }
    }

    private updateRevFromResponses(
        entities: IEntity[],
        responses: PouchDB.Core.Response[],
    ): IEntity[] {
        return entities.map((entity) => {
            const foundResponse = responses.find((response) => response.id === entity._id);
            if (foundResponse) {
                return {
                    ...entity,
                    _rev: foundResponse.rev,
                };
            }
            throw new Error(`Could not map id: ${entity._id} to response`);
        });
    }

    private listenToChanges() {
        if (!this.localDb) {
            return;
        }
        this.localDb
            .changes({ live: true, include_docs: true, since: 'now' })
            .on('change', (change) => {
                if (change.deleted) {
                    this.emitter.emit('delete', change.doc);
                } else {
                    this.emitter.emit('change', change.doc);
                }
            });
    }
}
