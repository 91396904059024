import { getDefaultColor } from '../utils/getDefaultColor';
import { createSelector } from 'reselect';
import { getCurrentProjectItemsArray } from 'app/modules/common';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';

export const getCurrentProjectItems = createSelector([getCurrentProjectItemsArray], (items) =>
    items.reduce(
        (acc, item) => {
            acc[item._id] = {
                ...item,
                color: item.color ?? getDefaultColor(item),
            };
            return acc;
        },
        {} as Record<Id, IPersistence<IItemEntity>>,
    ),
);
