import type { IInstallationPointSensorModel } from 'app/core/persistence';

/**
 * Calculate the sensors based on the changes to a sensor, assuming that the sensors
 * are independent and can move freely
 */
export const calculateIndependentSensors = (
    sensors: IInstallationPointSensorModel[],
    changedSensor: IInstallationPointSensorModel,
): IInstallationPointSensorModel[] => {
    const angle = changedSensor.target.horizontalAngle;
    const distance = changedSensor.target.distance;
    const height = changedSensor.target.height;
    const fov = changedSensor.settings.horizontalFov;

    // Since the sensors are independent, just update the changed sensor
    return sensors.map((s) => {
        if (s.sensorId === changedSensor.sensorId) {
            const newSensor = {
                ...s,
            };

            newSensor.target.horizontalAngle = angle;
            newSensor.target.distance = distance;
            newSensor.target.height = height;
            newSensor.settings.horizontalFov = fov;
            newSensor.settings.tiltOffset = 0;
            return newSensor;
        }

        // Return the other sensors unchanged
        return s;
    });
};
