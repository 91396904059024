import { createSelector } from 'reselect';
import type { IMapsDevice } from '../models';
import {
    getAllPiaDevices,
    isCeilingCompatible,
    isWallCompatible,
    getCurrentProjectItemsArray,
    isTiltableMultisensor,
} from 'app/modules/common';
import { t } from 'app/translate';
import type { Id, IItemEntity, IPersistence, PanoramaModes } from 'app/core/persistence';
import { getDeviceType, getParentId, isCustomCamera } from 'app/core/persistence';
import { PiaItemPacCategory, type IPiaDevice } from 'app/core/pia';
import type { Colors } from 'app/styles';
import { defaultColors } from 'app/core/common';
import { creationDateComparator } from 'app/utils';
import { isSupportedChildDevice, isSupportedDevice } from '../utils/supportedDevices';
import { getDefaultColor } from '../utils/getDefaultColor';

const getValidDevices = createSelector(
    [getCurrentProjectItemsArray, getAllPiaDevices],
    (devices, piaProducts) =>
        devices
            .filter((currentDevice) =>
                isSupportedDevice(
                    currentDevice,
                    getPiaProduct(piaProducts)(currentDevice.productId),
                ),
            )
            .map((device) =>
                createMapDevice(
                    device,
                    getPiaProduct(piaProducts)(device.productId),
                    (device.properties.sensorUnit ||
                        device.properties.analogCamera ||
                        device.properties.door ||
                        (device.properties.pac &&
                            getPiaProduct(piaProducts)(device.productId)?.category ===
                                PiaItemPacCategory.RELAYEXPMODULES)) &&
                        !device.color
                        ? getParentColor(device, devices)
                        : device.color,
                ),
            ),
);

const getValidChildDevices = createSelector(
    [getCurrentProjectItemsArray, getAllPiaDevices],
    (devices, piaProducts) =>
        devices
            .filter((currentDevice) =>
                isSupportedChildDevice(
                    currentDevice,
                    getPiaProduct(piaProducts)(currentDevice.productId),
                ),
            )
            .map((device) =>
                createMapDevice(
                    device,
                    getPiaProduct(piaProducts)(device.productId),
                    (device.properties.sensorUnit ||
                        device.properties.analogCamera ||
                        device.properties.door ||
                        (device.properties.pac &&
                            getPiaProduct(piaProducts)(device.productId)?.category ===
                                PiaItemPacCategory.RELAYEXPMODULES)) &&
                        !device.color
                        ? getParentColor(device, devices)
                        : device.color,
                ),
            ),
);

/**
 * Returns all devices we currently can handle in maps, including child units.
 */
export const getAllValidMapDevices = createSelector(
    [getValidDevices, getValidChildDevices],
    (devices, childDevices) => [...devices, ...childDevices].sort(creationDateComparator),
);

/**
 * NOTE: Now that sensor units exist, getListableDevices should only be used when listing devices e.g. DevicesContextMenu,
 * since it excludes certain devices not wanted shown in device list (children e.g sensor units and analog cameras).
 */
export const getListableMapDevices = createSelector([getValidDevices], (devices) => {
    return devices.sort(creationDateComparator);
});

/**
 * A version of getListableMapDevices that returns a map from device id to device.
 */
export const getListableMapDevicesMap = createSelector([getListableMapDevices], (devices) => {
    return devices.reduce(
        (acc, device) => {
            acc[device.id] = device;
            return acc;
        },
        {} as Record<Id, IMapsDevice>,
    );
});

const getPiaProduct = (piaProducts: IPiaDevice[]) => (productId: number | null) =>
    piaProducts.find((product) => product.id === productId);

const getModelName = (device: IPersistence<IItemEntity>, piaProduct?: IPiaDevice): string => {
    if (piaProduct) {
        return piaProduct.name;
    }

    return isCustomCamera(device)
        ? device.properties.camera.customCameraProperties.modelName
        : t.notSelected;
};

const createMapDevice = (
    device: IPersistence<IItemEntity>,
    piaProduct?: IPiaDevice,
    color?: Colors,
): IMapsDevice => {
    const mapDevice = {
        color: color || getDefaultColor(device),
        deviceType: getDeviceType(device) || 'camera',
        id: device._id,
        rev: device._rev,
        locked: device.locked,
        model: getModelName(device, piaProduct),
        name: device.name,
        notes: device.notes,
        productId: device.productId,
        quantity: device.quantity,
        piaProduct,
        creationDate: new Date(device.creationDate),
    };

    if (device.properties.speaker) {
        return {
            ...mapDevice,
            placement: device.properties.speaker.filter.placement ?? 'ceiling',
            canChangePlacement: canChangePlacement(piaProduct),
        };
    } else {
        return {
            ...mapDevice,
            pixelDensity: (device.properties.camera || device.properties.sensorUnit)?.filter
                .pixelDensity,
            panoramaMode: getPanoramaMode(device, piaProduct),
            creationDate: new Date(device.creationDate),
            canChangePanoramaMode: canChangePanoramaMode(device, piaProduct),
        };
    }
};

const isCustomPanoramicCamera = (device: IPersistence<IItemEntity>): boolean => {
    return (
        isCustomCamera(device) &&
        device.properties.camera.customCameraProperties.horizontalFovMax >= 170
    );
};

const canChangePanoramaMode = (
    device: IPersistence<IItemEntity>,
    piaProduct?: IPiaDevice,
): boolean => {
    if (isCustomCamera(device)) {
        return isCustomPanoramicCamera(device);
    }

    if (!piaProduct) {
        return device.properties.camera !== undefined || device.properties.sensorUnit !== undefined;
    }

    if (isTiltableMultisensor(piaProduct)) {
        // special handling for stitching wide angle multisensor cameras such as Q3709.
        // They are actually tiltable but we show them as ceiling- or wall mounted
        return true;
    }

    return 'canChangePanoramaMode' in piaProduct.properties
        ? piaProduct.properties.canChangePanoramaMode
        : false;
};

const getPanoramaMode = (
    device: IPersistence<IItemEntity>,
    piaProduct?: IPiaDevice,
): PanoramaModes => {
    return (
        (device.properties.camera ?? device.properties.sensorUnit)?.filter.panoramaMode ||
        (piaProduct && isTiltableMultisensor(piaProduct) ? 'vertical' : false)
    );
};

const canChangePlacement = (piaProduct?: IPiaDevice) => {
    return !piaProduct || (isCeilingCompatible(piaProduct) && isWallCompatible(piaProduct));
};

const getParentColor = (
    device: IPersistence<IItemEntity>,
    devices: IPersistence<IItemEntity>[],
): Colors => {
    const parentId = getParentId(device);
    const parents = devices.filter((deviceItem) => deviceItem._id === parentId);
    const color =
        parents.length > 0
            ? parents[0].color || (defaultColors.DEFAULT_DEVICE_COLOR as Colors)
            : (defaultColors.DEFAULT_DEVICE_COLOR as Colors);
    return color;
};
