import { defaultColors } from 'app/core/common';
import type { IPersistence, IItemEntity, DeviceType } from 'app/core/persistence';
import type { Colors } from 'app/styles';

export const getDefaultColor = (item: IPersistence<IItemEntity> | undefined): Colors => {
    if (item?.properties.speaker || item?.properties.pagingConsole) {
        return defaultColors.DEFAULT_SPEAKER_COLOR;
    }
    if (item?.properties.radarDetector) {
        return defaultColors.DEFAULT_RADAR_COLOR;
    }
    if (item?.properties.doorController) {
        return defaultColors.DEFAULT_DOOR_CONTROLLER_COLOR;
    }
    return defaultColors.DEFAULT_DEVICE_COLOR;
};

export const getDefaultColorByType = (deviceType: DeviceType): Colors => {
    switch (deviceType) {
        case 'speaker':
        case 'pagingconsole':
            return defaultColors.DEFAULT_SPEAKER_COLOR;
        case 'radardetector':
            return defaultColors.DEFAULT_RADAR_COLOR;
        case 'doorcontroller':
            return defaultColors.DEFAULT_DOOR_CONTROLLER_COLOR;
        default:
            return defaultColors.DEFAULT_DEVICE_COLOR;
    }
};
