import type { IInstallationPointSensorModel } from 'app/core/persistence';
import { trigonometry } from 'axis-webtools-util';
import { isVirtualProductSensor } from '../virtualProducts';
import { clamp } from 'lodash-es';
import { normalize, mul, sub, rotatePoint, type Point } from 'axis-webtools-util';
import { getAspectRatio } from './getAspectRatio';

/**
 * Calculate the sensors based on the changes to a sensor, assuming that the sub-sensors
 * can move inside of the parent sensor's fov
 */
export const calculateSemiFixedSubSensors = (
    sensors: IInstallationPointSensorModel[],
    changedSensor: IInstallationPointSensorModel,
): IInstallationPointSensorModel[] => {
    const angle = changedSensor.target.horizontalAngle;
    const distance = changedSensor.target.distance;
    const fov = changedSensor.settings.horizontalFov;
    const height = changedSensor.target.height;

    // Deep copy the sensors to avoid modifying the original sensors
    const newSensors = sensors.map((s) => ({
        ...s,
        target: {
            ...s.target,
        },
        settings: {
            ...s.settings,
        },
    }));

    // Find the main sensor. The other sensors can move inside of the fov of the main sensor
    const mainSensor = newSensors.find((s) => !isVirtualProductSensor(s));

    if (!mainSensor) {
        throw new Error('No main sensor found');
    }

    const mainSensorChanged = changedSensor.sensorId === mainSensor.sensorId;
    const mainAngleChanged = mainSensorChanged && angle !== mainSensor.target.horizontalAngle;
    const mainDistanceChanged = mainSensorChanged && distance !== mainSensor.target.distance;
    const mainHeightChanged = mainSensorChanged && height !== mainSensor.target.height;

    return (
        newSensors
            // sort the sensors so that the main sensor is the first sensor
            .sort((a) => (a.sensorId === mainSensor.sensorId ? -1 : 1))
            .map((sensor) => {
                if (sensor.sensorId === mainSensor.sensorId) {
                    if (sensor.sensorId === changedSensor.sensorId) {
                        // main sensor was changed
                        sensor.target.distance = distance;
                        sensor.target.height = height;
                        sensor.target.horizontalAngle = angle;
                        sensor.settings.horizontalFov = fov;
                        sensor.settings.tiltOffset = 0;
                    }

                    // main sensor wasn't changed. Keep as is
                    return sensor;
                }

                if (sensor.sensorId === changedSensor.sensorId) {
                    // this sensor was changed. Make sure it stays withing the fov
                    // of the main sensor
                    sensor.settings.horizontalFov = fov;

                    const angleChanged = angle !== sensor.target.horizontalAngle;
                    const distanceChanged = distance !== sensor.target.distance;
                    const heightChanged = height !== sensor.target.height;

                    if (angleChanged) {
                        const { min, max } = getAngleLimits(mainSensor, sensor);
                        sensor.target.horizontalAngle = clampAngle(angle, min, max);
                    }

                    if (distanceChanged) {
                        const { min, max } = getDistanceLimits(mainSensor, sensor);
                        sensor.target.distance = clamp(distance, min, max);
                    }

                    if (heightChanged) {
                        const { min, max } = getHeigthLimits(mainSensor, sensor);
                        sensor.target.height = clamp(height, min, max);
                    }

                    return sensor;
                }

                // this sensor wasn't changed. Check if it needs to be updated
                // due to changes in the main sensor
                if (mainAngleChanged) {
                    const { min, max } = getAngleLimits(mainSensor, sensor);
                    sensor.target.horizontalAngle = clampAngle(
                        sensor.target.horizontalAngle,
                        min,
                        max,
                    );
                }

                if (mainDistanceChanged) {
                    const { min, max } = getDistanceLimits(mainSensor, sensor);
                    sensor.target.distance = clamp(sensor.target.distance, min, max);
                }

                if (mainHeightChanged) {
                    const { min, max } = getHeigthLimits(mainSensor, sensor);
                    sensor.target.height = clamp(sensor.target.height, min, max);
                }

                return sensor;
            })
    );
};

/**
 * Get the vertical fov of a sensor
 */
const getVFov = (sensor: IInstallationPointSensorModel) => {
    const aspectRatio = getAspectRatio(sensor);
    return sensor.settings.horizontalFov * aspectRatio;
};

/**
 * Get the vector pointing from the camera to the target
 */
const getTopVector = (sensor: IInstallationPointSensorModel) => {
    const cameraLocation: Point = [0, sensor.height];
    const target: Point = [sensor.target.distance, sensor.target.height];
    return normalize(sub(target, cameraLocation));
};

/**
 * Get the angle limits for the follower sensor
 */
const getAngleLimits = (
    mainSensor: IInstallationPointSensorModel,
    followerSensor: IInstallationPointSensorModel,
) => {
    const mainHFov = mainSensor.settings.horizontalFov;
    const followerHFov = followerSensor.settings.horizontalFov;

    const min = mainSensor.target.horizontalAngle - mainHFov / 2 + followerHFov / 2;
    const max = mainSensor.target.horizontalAngle + mainHFov / 2 - followerHFov / 2;

    return { min, max };
};

/**
 * Get the height limits for the follower sensor
 */
const getHeigthLimits = (
    mainSensor: IInstallationPointSensorModel,
    followerSensor: IInstallationPointSensorModel,
) => {
    const mainVFov = getVFov(mainSensor);
    const followerVFov = getVFov(followerSensor);

    const mainTopVector = getTopVector(mainSensor);
    // a vector pointing from the camera main blind spot
    const mainBottomVector = rotatePoint(
        mainTopVector,
        -trigonometry.toRadians(mainVFov - followerVFov),
    );

    // calculate the factor to multiply the main vectors with in order to point at
    // a plane at the target distance
    const topVectorFactor = followerSensor.target.distance / mainTopVector[0];
    const bottomVectorFactor = followerSensor.target.distance / mainBottomVector[0];

    // get the y component of the vectors
    const max = followerSensor.height + mul(mainTopVector, topVectorFactor)[1];
    const min = followerSensor.height + mul(mainBottomVector, bottomVectorFactor)[1];

    // sensor.target.height = clamp(height, minHeight, maxHeight);
    return { min, max };
};

/**
 * Get the distance limits for the follower sensor
 */
const getDistanceLimits = (
    mainSensor: IInstallationPointSensorModel,
    followerSensor: IInstallationPointSensorModel,
) => {
    const mainVFov = getVFov(mainSensor);
    const followerVFov = getVFov(followerSensor);

    const mainTopVector = getTopVector(mainSensor);
    // a vector pointing from the camera main blind spot
    const mainBottomVector = rotatePoint(
        mainTopVector,
        -trigonometry.toRadians(mainVFov - followerVFov),
    );

    const dHeight = followerSensor.height - followerSensor.target.height;

    // calculate the factor to multiply the main vectors with in order to point at
    // the plane at target height
    const topVectorFactor = -dHeight / mainTopVector[1];
    const bottomVectorFactor = -dHeight / mainBottomVector[1];

    // get the x component of the vectors
    const maxDistance = mul(mainTopVector, topVectorFactor)[0];
    const minDistance = mul(mainBottomVector, bottomVectorFactor)[0];

    let max = 0;
    let min = 0;
    if (followerSensor.target.height > followerSensor.height) {
        min = maxDistance;
        max = minDistance > 0 ? minDistance : Number.MAX_VALUE;
    } else {
        min = minDistance;
        max = maxDistance > 0 ? maxDistance : Number.MAX_VALUE;
    }

    return { min, max };
};

/**
 * Clamp an angle between two other angles. The angles can be between -180 and 180
 * degrees.
 * @param angle The angle to clamp
 * @param min The minimum angle
 * @param max The maximum angle
 * @returns The clamped angle
 */
const clampAngle = (angle: number, min: number, max: number) => {
    // Calculate the offset from the mid point between the min and max angles
    const offset = (max + min) / 2;

    // offset angles from the mid point
    const offsetMin = min - offset;
    const offsetMax = max - offset;
    let offsetAngle = (angle - offset + 360) % 360;

    // if the angle is greater than 180, subtract 360. This way the angle is always
    // between -180 and 180 relative to the mid point
    if (offsetAngle > 180) {
        offsetAngle -= 360;
    }

    // clamp the angle between the min and max angles
    const clamped = clamp(offsetAngle, offsetMin, offsetMax);

    // add the offset back to the clamped angle
    return clamped + offset;
};
