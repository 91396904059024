import { createSelector } from 'reselect';
import { getMapsState } from './getMapsState';
import { getSelectedMapOrDefault } from './getFloorPlans';
import { FloorPlanService } from 'app/core/persistence';
import type { BlockerEditState } from '../models';

export const getIsDoriPixelsOn = createSelector([getMapsState], ({ doriOn }) => doriOn);

/** Shows if free text tool is active. When active the next click on map will place a free text item. */
export const getIsFreeTextToolActive = createSelector(
    [getMapsState],
    ({ showFreeTextTool }) => showFreeTextTool,
);

export const getIsMeasureToolActive = createSelector(
    [getMapsState],
    ({ showMeasureTool }) => showMeasureTool,
);

export const getIsScalingToolActive = createSelector(
    [getMapsState],
    ({ enableScalingTool }) => enableScalingTool,
);

export const getIsGoogleMap = createSelector([getSelectedMapOrDefault], (currentFloorPlan) =>
    currentFloorPlan ? FloorPlanService.isGeoMapType(currentFloorPlan) : false,
);

export const getIsRadarWarningDismissed = createSelector(
    [getSelectedMapOrDefault],
    (currentFloorPlan) => currentFloorPlan?.isRadarWarningDismissed,
);

/** Shows current state of blocker tool interaction. When the tool is inactive the state is 'none'.
 * @returns Blocker edit state {@link BlockerEditState} */
export const getBlockerEditState = createSelector(
    [getMapsState],
    (mapsState): BlockerEditState => mapsState.blockerEditState,
);
