import * as React from 'react';
import { useSelector } from 'react-redux';
import { getFilteredMapLayers } from '../../selectors';
import { Box } from 'app/components';
import { ActiveFilterButton } from './ActiveFilterButton';
import { MapLayerFilterService } from '../../services';
import { useService } from 'app/ioc';

export const ActiveLayerFilters: React.FC = () => {
    const mapLayerFilterService = useService(MapLayerFilterService);
    const filters = useSelector(getFilteredMapLayers);

    return (
        <Box noPointerEvents justifyContent="center" spacing="half" wrap>
            {filters.colors.map((color) => (
                <ActiveFilterButton
                    testId={`filter_color_${color}`}
                    key={`filter_color_${color}`}
                    color={color}
                    onToggle={() => mapLayerFilterService.toggleColorLayerFilter(color)}
                />
            ))}
            {filters.deviceLayers.map((deviceGroup) => (
                <ActiveFilterButton
                    testId={`filter_device_${deviceGroup}`}
                    key={`filter_device_${deviceGroup}`}
                    deviceTypeLayerGroup={deviceGroup}
                    onToggle={() => mapLayerFilterService.toggleDeviceTypeLayerFilter(deviceGroup)}
                />
            ))}
            {filters.coverageAreaLayers.map((deviceGroup) => (
                <ActiveFilterButton
                    testId={`filter_coverageArea_${deviceGroup}`}
                    key={`filter_coverageArea_${deviceGroup}`}
                    coverageAreaLayerGroup={deviceGroup}
                    onToggle={() =>
                        mapLayerFilterService.toggleCoverageAreaLayerFilter(deviceGroup)
                    }
                />
            ))}
            {filters.labelsLayer && (
                <ActiveFilterButton
                    testId={`filter_labels`}
                    key={`filter_labels`}
                    labels
                    onToggle={() => mapLayerFilterService.toggleLabelsLayerFilter()}
                />
            )}
            {filters.textBoxesLayer && (
                <ActiveFilterButton
                    testId={`filter_textBoxes`}
                    key={`filter_textBoxes`}
                    textBoxes
                    onToggle={() => mapLayerFilterService.toggleTextBoxesLayerFilter()}
                />
            )}
        </Box>
    );
};

ActiveLayerFilters.displayName = 'ActiveLayerFilters';
