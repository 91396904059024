import * as React from 'react';
import {
    Border,
    Box,
    DragHandle,
    DropDown,
    DropDownMenuButton,
    Icon,
    Positioned,
    Stack,
    Tab,
    Text,
} from 'app/components';
import type { DeviceType } from 'app/core/persistence';
import { getDeviceImageByType } from 'app/core/persistence';
import { deviceIcons, getCurrentProjectLocked, getPiaItemForProductId } from 'app/modules/common';
import { css } from '@emotion/css';
import { useSelector } from 'react-redux';
import {
    getSelectedAddDeviceTab,
    getSelectedAddPiaDevice,
} from '../../../../selectors/getSelectedAddTab';
import { getDragDropDownProducts } from '../../../../selectors/getDragDropDownProducts';
import { getAddCameraSearchFilter } from '../../../../selectors/getAddCameraSearchFilter';
import { useService } from 'app/ioc';
import { MapsActionService } from '../../../../services';
import { useDrag, useIsLayerHidden } from '../../../../hooks';
import { getGenericAddImage } from '../../../../utils/getDeviceImage';
import { t } from 'app/translate';
import type { IStoreState } from 'app/store';
import type { IPiaItem } from 'app/core/pia';
import { toaster } from 'app/toaster';
import { getIsUserOnline } from 'app/modules/application';
import {
    getDeviceAndSubTypeFromPiaCategory,
    getDeviceTypeFromPiaCategory,
    getDefaultColorByType,
} from '../../../../utils';
import type { IAddDeviceTab } from '../../../../models/IMapsState';
import type { Colors } from 'app/styles';

const image = new Image();

interface IDeviceTabWithSettings extends IAddDeviceTab {
    defaultColor: Colors;
    hasSearchField: boolean;
}

const tabs: IDeviceTabWithSettings[] = [
    {
        name: 'cameras',
        deviceTypes: ['camera'],
        defaultColor: 'devicePalette7',
        hasSearchField: true,
    },
    {
        name: 'speakers',
        deviceTypes: ['speaker'],
        defaultColor: 'devicePalette3',
        hasSearchField: false,
    },
    {
        name: 'radars',
        deviceTypes: ['radardetector'],
        defaultColor: 'devicePalette5',
        hasSearchField: false,
    },
    {
        name: 'doorControllers',
        deviceTypes: ['doorcontroller'],
        defaultColor: 'devicePalette8',
        hasSearchField: false,
    },
    {
        name: 'mainUnits',
        deviceTypes: ['mainUnit'],
        defaultColor: 'devicePalette7',
        hasSearchField: false,
    },
    {
        name: 'others',
        deviceTypes: [
            'alerter',
            'encoder',
            'decoder',
            'peopleCounter',
            'dockingStation',
            'systemController',
            'pac',
            'connectivitydevice',
            'pagingconsole',
        ],
        defaultColor: 'devicePalette7',
        hasSearchField: true,
    },
];

const grabHandle = css`
    cursor: move;
`;

const noPointerEvents = css`
    pointer-events: none;
`;

/**
 * When introducing oxxo (q1656-DLE) a product could appear in two deviceType categories.
 * We therefore need to set the product as the type of its category
 * (we do not want the q1656 to be a radar type although it appears also below radars since it has 'radardetectors' as well as 'fixed' in categories)
 *
 * We have an exception for doorstations that we want to appear as a camera although their category is 'doorstations'.
 *
 * Door stations from 2N should be treated as pac.
 */
const convertToDragDeviceType = (
    selectedPiaItemDeviceType: DeviceType,
    vendor?: string,
): DeviceType => {
    if (selectedPiaItemDeviceType === 'doorstation' && vendor?.toUpperCase() === 'AXIS') {
        // Axis door stations should be treated as cameras
        return 'camera';
    }

    if (selectedPiaItemDeviceType === 'doorstation' && vendor?.toUpperCase() !== 'AXIS') {
        // 2N door stations should be treated as pacs
        return 'pac';
    }

    return selectedPiaItemDeviceType;
};

const DEFAULT_ITEM_LIMIT = 20;
const MAX_ITEM_LIMIT = 1000;

export const AddDeviceSelectorMenuItem: React.FC = () => {
    const actions = useService(MapsActionService);
    const selectedTab = useSelector<IStoreState, IDeviceTabWithSettings | undefined>((store) => {
        const selectedAddDeviceTab = getSelectedAddDeviceTab(store);
        return tabs.find((tab) => tab.name === selectedAddDeviceTab.name);
    });
    const selectedPiaId = useSelector(getSelectedAddPiaDevice);
    const selectedPiaItem = useSelector<IStoreState, IPiaItem | undefined>(
        (state) => getPiaItemForProductId(state, selectedPiaId) ?? undefined,
    );
    const searchFieldText = useSelector(getAddCameraSearchFilter);
    const isOnline = useSelector(getIsUserOnline);
    const dragRef = React.useRef<HTMLDivElement>(null);
    const dragImageRef = React.useRef<HTMLDivElement>(null);
    const dragColor = selectedTab?.defaultColor ?? 'blue';
    const dropDownItems = useSelector(getDragDropDownProducts);
    const isLocked = useSelector(getCurrentProjectLocked);
    const selectedPiaItemDeviceType =
        selectedPiaItem && getDeviceTypeFromPiaCategory(selectedPiaItem.category);
    const [maxNumberOfItems, setMaxNumberOfItems] = React.useState(DEFAULT_ITEM_LIMIT);

    const getDragImage = React.useCallback(() => {
        if (selectedPiaItem) {
            return getDeviceImageByType(selectedPiaItem.id, selectedPiaItemDeviceType, dragColor);
        }
        return selectedTab ? getGenericAddImage(selectedTab?.deviceTypes[0]) : undefined;
    }, [selectedPiaItem, selectedTab, selectedPiaItemDeviceType, dragColor]);

    React.useEffect(() => {
        image.src = getDeviceImageByType(selectedPiaItem?.id, selectedPiaItemDeviceType, dragColor);
    }, [dragColor, selectedTab, selectedPiaItem, selectedPiaItemDeviceType]);

    const deviceType = convertToDragDeviceType(
        selectedPiaItemDeviceType ?? selectedTab?.deviceTypes[0] ?? 'camera',
        selectedPiaItem?.properties.vendor,
    );
    const dragState = useDrag({
        effect: 'link',
        ref: dragRef,
        imageRef: dragImageRef,
        imageOffsetX: 25,
        imageOffsetY: 25,
        data: {
            id: '',
            type: deviceType,
            piaId: selectedPiaItem?.id,
        },
    });

    const isLayerHidden = useIsLayerHidden(deviceType, getDefaultColorByType(deviceType));
    React.useEffect(() => {
        if (isLayerHidden && dragState.dragState === 'dragEnd') {
            toaster.info(t.deviceIsHidden);
        }
    }, [dragState.dragState, isLayerHidden]);

    // when changing device or going online/offline try to render device image first by setting shouldRenderIcon to false
    // shouldRenderIcon is then set to true if img src fails to load
    React.useEffect(() => {
        setShouldRenderIcon(false);
    }, [selectedPiaItem, isOnline]);

    const [shouldRenderIcon, setShouldRenderIcon] = React.useState<boolean>(false);
    const setSearchFilter = (text: string) => {
        if (text === '') setMaxNumberOfItems(DEFAULT_ITEM_LIMIT);
        actions.setAddCameraSearchFilter(text);
    };

    const renderTabs = () => {
        return tabs.map((tab) => {
            const isTabActive = tab.name === selectedTab?.name;
            return (
                <Tab
                    key={tab.name}
                    hasBorderRadius
                    onClick={() => actions.setSelectedAddTab(tab)}
                    isActive={isTabActive}
                    paddingX="half"
                    testId={`add_device_tab_${tab.name}`}
                >
                    <Icon
                        icon={
                            tab.deviceTypes.length > 1
                                ? 'more_horiz'
                                : deviceIcons.toIcon(tab.deviceTypes[0])
                        }
                        opaque={isTabActive}
                        color="grey9"
                    />
                </Tab>
            );
        });
    };

    const renderDragIcon = () => {
        return (
            <Box
                innerRef={dragRef}
                color="white"
                alignItems="center"
                __htmlAttributes={{ className: grabHandle }}
                testId="drag_to_map"
            >
                <Border
                    radius="50%"
                    color="grey2"
                    width={3}
                    __htmlAttributes={{ className: noPointerEvents }}
                >
                    <Box
                        innerRef={dragImageRef}
                        padding="half"
                        width="48px"
                        height="48px"
                        alignItems="center"
                        justifyContent="center"
                        color="white"
                    >
                        {!shouldRenderIcon && (
                            <img
                                src={getDragImage()}
                                width="24"
                                height="24"
                                onError={() => setShouldRenderIcon(true)}
                                data-test-id={`add_drag_image`}
                                className={noPointerEvents}
                            />
                        )}
                        {shouldRenderIcon && (
                            <img
                                src={getGenericAddImage(
                                    selectedPiaItemDeviceType ??
                                        selectedTab?.deviceTypes[0] ??
                                        'camera',
                                    selectedPiaItem?.properties.vendor,
                                )}
                                width="24"
                                height="24"
                                data-test-id={`add_generic_drag_image`}
                                className={noPointerEvents}
                            />
                        )}
                    </Box>
                </Border>
                <DragHandle />
            </Box>
        );
    };

    const renderDropDown = () => {
        if (!selectedTab) return null;
        return (
            <DropDown
                maxWidth={300} //Necessary to prevent dropdown item from rendering beyond window screen when in RTL mode
                testId="map_add_device_drop_down"
                openInPortal
                trigger={
                    <Box alignItems="center" maxWidth="128px">
                        <Text inline color="blue" style="semibold" whiteSpace="nowrap">
                            {selectedPiaItem
                                ? selectedPiaItem.name
                                : selectedTab.deviceTypes.length > 1
                                  ? t.selectDevice
                                  : t.devicesGROUP[selectedTab.deviceTypes[0]]}
                        </Text>
                        <Icon opaque size="sm" icon="arrow_down" color="blue" />
                    </Box>
                }
                searchFieldText={searchFieldText}
                onSearchTextChanged={setSearchFilter}
                includeSearchField={selectedTab?.hasSearchField}
                contents={
                    <>
                        {selectedTab?.deviceTypes.length === 1 && (
                            <DropDownMenuButton
                                icon={deviceIcons.toIcon(selectedTab.deviceTypes[0])}
                                iconProps={{ size: 'md' }}
                                onClick={() =>
                                    actions.setSelectedDragProduct(selectedTab.name, null)
                                }
                                testId="map-quick-add-pick-model-later"
                            >
                                {t.pickModelLater}
                            </DropDownMenuButton>
                        )}
                        {dropDownItems.slice(0, maxNumberOfItems).map((item) => {
                            const icon =
                                selectedTab.name === 'others'
                                    ? deviceIcons.categoryToIcon(item.category)
                                    : deviceIcons.toIcon(selectedTab.deviceTypes[0]);
                            const itemDeviceType = getDeviceAndSubTypeFromPiaCategory(
                                item.category,
                            );
                            return (
                                <DropDownMenuButton
                                    key={item.id}
                                    selected={item.id === selectedPiaId}
                                    piaImageProps={{
                                        piaId: item.id,
                                        icon,
                                        imageSize: 'md',
                                    }}
                                    onClick={() =>
                                        actions.setSelectedDragProduct(selectedTab.name, item.id)
                                    }
                                    testId={`map_add_device_${item.name}`}
                                >
                                    {item.name}
                                    {itemDeviceType && selectedTab?.deviceTypes.length > 1 && (
                                        <Text color="grey5">{t.devicesGROUP[itemDeviceType]}</Text>
                                    )}
                                </DropDownMenuButton>
                            );
                        })}
                        {dropDownItems.length > maxNumberOfItems && (
                            <DropDownMenuButton
                                shouldKeepDropDownOpen={false}
                                stopPropagation
                                onClick={() => setMaxNumberOfItems(MAX_ITEM_LIMIT)}
                                testId="map-show-all-products"
                            >
                                {t.showAll}
                            </DropDownMenuButton>
                        )}
                    </>
                }
            ></DropDown>
        );
    };

    if (isLocked) return null;

    // We only allow generic items from tabs with only one device type
    const canDragItem = selectedTab?.deviceTypes.length === 1 || selectedPiaId;

    return (
        <Box direction="column" width="100%">
            <Positioned position="relative" bringToFront>
                <Box paddingLeft="half">{renderTabs()}</Box>
            </Positioned>
            <Border radius="4px" width={1} color="grey3">
                <Box color="white" padding="quart" paddingY="base" width="100%">
                    <Stack flex="fullWidth" spacing="half">
                        <Box direction="column" padding="half" flex="fullWidth">
                            <Text inline style="semibold">
                                {`${t.addNew} `}
                                {renderDropDown()}
                            </Text>

                            <Text style="small" color="grey6">
                                {t.dragToMap}
                            </Text>
                        </Box>
                        {canDragItem && renderDragIcon()}
                    </Stack>
                </Box>
            </Border>
        </Box>
    );
};

AddDeviceSelectorMenuItem.displayName = 'AddDeviceSelectorMenuItem';
