import type { IStoreState } from 'app/store';
import { createSelector } from 'reselect';
import { RECOMMENDATION_VALIDATION_MAX_DEVICES } from './common';
import type { IDeviceRequirement, ISDCardComponent } from './common';
import { isSystemARecommendationSuperset, areBasicRequirementsFulfilled } from './validation';
import type { IValidationResult } from './validation';
import { getDeviceRequirements, getCompanionSDCardsNeeded } from './recommendations';
import { getRecordingSolutionComponents } from './getRecordingSolution';
import { t } from 'app/translate';
import { createCachedSelector } from 're-reselect';
import {
    getCurrentProjectItemRelationsArray,
    getCurrentProjectItemsArray,
    getRecordingSolutionTypeFromProject,
    toCacheKey,
} from 'app/modules/common';

export const getSystemUnderCapacityForValidationResult = (
    validationResult: IValidationResult | null,
) => {
    if (validationResult === null) {
        return {
            nrOfChannels: 0,
            nrOfLicenses: 0,
            storage: 0,
            bandwidth: 0,
            power: 0,
            ports: {
                poeClass1Ports: 0,
                poeClass2Ports: 0,
                poeClass3Ports: 0,
                poeClass4Ports: 0,
                poeClass5Ports: 0,
                poeClass6Ports: 0,
                poeClass7Ports: 0,
                poeClass8Ports: 0,
                highPoEPorts: 0,
                totalPorts: 0,
            },
            video: 0,
            additionalStorage: 0,
        };
    }

    const underCapacity = validationResult.underCapacity;
    return {
        nrOfChannels: underCapacity.maxCameraCount,
        nrOfLicenses: underCapacity.vmsChannelLicenses,
        storage: underCapacity.maxRecordingStorageMegaBytes,
        bandwidth: underCapacity.maxRecordingBandwidthBits,
        power: underCapacity.poeTotalPower,
        ports: {
            poeClass1Ports: underCapacity.poeClass1PortCount,
            poeClass2Ports: underCapacity.poeClass2PortCount,
            poeClass3Ports: underCapacity.poeClass3PortCount,
            poeClass4Ports: underCapacity.poeClass4PortCount,
            poeClass5Ports: underCapacity.poeClass5PortCount,
            poeClass6Ports: underCapacity.poeClass6PortCount,
            poeClass7Ports: underCapacity.poeClass7PortCount,
            poeClass8Ports: underCapacity.poeClass8PortCount,
            highPoEPorts: underCapacity.highPoEPortCount,
            totalPorts:
                underCapacity.poeClass1PortCount +
                underCapacity.poeClass2PortCount +
                underCapacity.poeClass3PortCount +
                underCapacity.poeClass4PortCount +
                underCapacity.poeClass5PortCount +
                underCapacity.poeClass6PortCount +
                underCapacity.poeClass7PortCount +
                underCapacity.poeClass8PortCount +
                underCapacity.highPoEPortCount,
        },
        video: 0,
        additionalStorage: 0,
    };
};

const getRequiredSDCardModel = (
    device: IDeviceRequirement,
    sdCardRequirements: ISDCardComponent[],
) => {
    if (device) {
        return sdCardRequirements.find(({ deviceIds }) => deviceIds.includes(device.itemId))
            ?.component?.name;
    }
};

export const getValidationResult = (state: IStoreState) =>
    state.recordingSelector.validation.result;

export const getSystemUnderCapacity = createSelector(
    [getValidationResult],
    getSystemUnderCapacityForValidationResult,
);

export const showInfoForS30Limitations = createSelector(
    [getRecordingSolutionComponents, getRecordingSolutionTypeFromProject],
    (solutionComponents, recordingSolutionType) => {
        const isS30Solution =
            recordingSolutionType === 'AxisS30' || recordingSolutionType === 'AxisS30Pro';

        const isCompanionSolution =
            recordingSolutionType === 'AxisCompanionSDCard' ||
            recordingSolutionType === 'AxisCompanionS30';

        const hasS30Recorders = solutionComponents.some(
            ({ component }) =>
                component.properties.series === 'S30' && component.category === 'recorders2',
        );

        return isS30Solution || (!isCompanionSolution && hasS30Recorders);
    },
);

/*
 * Returns true if selected recording solution is valid or if no solution is selected
 */
export const isRecordingSolutionValid = (state: IStoreState) => {
    const recordingSolutionComponents = getRecordingSolutionComponents(state);

    if (recordingSolutionComponents.length === 0) {
        // No solution is a valid solution, this prevents spamming the user with errors
        return true;
    }

    const basicCheckOk = areBasicRequirementsFulfilled(state);

    if (!basicCheckOk) {
        // If basic check fails it is impossible for the real validation to succeed
        return false;
    }

    const validationResult = getValidationResult(state);

    if (validationResult?.success ?? true) {
        // Real validation was successful
        return true;
    }

    const isRecommendationSuperset = isSystemARecommendationSuperset(state);

    if (isRecommendationSuperset) {
        // The current solution is a recommendation superset and hence valid
        return true;
    }

    return false;
};

// return doors for project
export const getNbrOfDoorsForProject = createCachedSelector(
    [getCurrentProjectItemsArray, getCurrentProjectItemRelationsArray],
    (currentProjectItems, itemRelations) => {
        let doorQuantity = 0;
        for (const item of currentProjectItems) {
            const relations = itemRelations.filter((relation) => relation.parentId === item._id);

            for (const relation of relations) {
                const childItem = currentProjectItems.find(
                    (relationItem) => relationItem._id === relation.childId,
                );

                if (childItem) {
                    if (relation.relationType === 'door') {
                        doorQuantity += item.quantity * childItem.quantity;
                    }
                }
            }
        }

        return doorQuantity;
    },
)(toCacheKey);

const getSystemUnderCapacityProperties = (underCapacity: IValidationResult['underCapacity']) => {
    const propsWithUnderCapacity = [];
    if (underCapacity.maxCameraCount < 0) {
        propsWithUnderCapacity.push(t.devices);
    }
    if (underCapacity.vmsChannelLicenses < 0) {
        propsWithUnderCapacity.push(t.licenses);
    }
    if (underCapacity.maxRecordingStorageMegaBytes < 0) {
        propsWithUnderCapacity.push(t.storage);
    }
    if (underCapacity.maxRecordingBandwidthBits < 0) {
        propsWithUnderCapacity.push(t.bandwidth);
    }
    if (underCapacity.poeTotalPower < 0) {
        propsWithUnderCapacity.push(t.power);
    }
    if (
        [
            underCapacity.poeClass1PortCount,
            underCapacity.poeClass2PortCount,
            underCapacity.poeClass3PortCount,
            underCapacity.poeClass4PortCount,
            underCapacity.poeClass5PortCount,
            underCapacity.poeClass6PortCount,
            underCapacity.poeClass7PortCount,
            underCapacity.poeClass8PortCount,
            underCapacity.highPoEPortCount,
        ].some((value) => value < 0)
    ) {
        propsWithUnderCapacity.push(t.ports);
    }
    return propsWithUnderCapacity;
};

export const getValidationError = createSelector(
    [getValidationResult, getCompanionSDCardsNeeded],
    (validationResult, sdCardRequirements) => {
        if (!validationResult) {
            return null;
        }

        const underCapacityProperties = getSystemUnderCapacityProperties(
            validationResult.underCapacity,
        );

        let validationErrorMessage = undefined;
        switch (validationResult.validationErrorType) {
            case 'AlternativeUnderCapacity':
                validationErrorMessage = t.recorderValidationAlternativeUnderCapacity(
                    underCapacityProperties[0],
                    underCapacityProperties[1],
                    validationResult.unassignedDevices.length,
                );
                break;
            case 'CombinedUnderCapacity':
                validationErrorMessage = t.recorderValidationCombinedUnderCapacity(
                    underCapacityProperties[0],
                    validationResult.unassignedDevices.length,
                );
                break;
            case 'CompanionIncompatible':
                validationErrorMessage = t.recorderValidationCompanionUnsupportedDevice(
                    validationResult?.incompatibleDevices?.[0].modelName ??
                        validationResult?.incompatibleDevices?.[0].name ??
                        '',
                );
                break;

            case 'CompanionSDCardMissing':
                validationErrorMessage = t.recorderValidationCompanionMissingSDCard(
                    validationResult.unassignedDevices[0]?.name ?? '',
                    getRequiredSDCardModel(
                        validationResult.unassignedDevices[0],
                        sdCardRequirements,
                    ) ?? '',
                );
                break;
            default:
                break;
        }

        if (validationResult.validationErrorType) {
            return {
                validationErrorType: validationResult.validationErrorType,
                validationErrorMessage,
            };
        }
    },
);

export const getValidationInfo = createSelector([getDeviceRequirements], (devices) => {
    if (devices.length > RECOMMENDATION_VALIDATION_MAX_DEVICES) {
        return {
            validationInfoMessage: t.recorderValidationAddMarginsToLargeSystems,
        };
    }

    return null;
});

export const getUnassignedDevicesCount = createSelector(
    [getValidationResult],
    (validationResult) => {
        return validationResult?.unassignedDevices?.length ?? 0;
    },
);
