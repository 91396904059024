import type { PiaCategory } from 'app/core/pia';
import { t } from 'app/translate';
import { getCategoryName } from './getCategoryName';

export const getScenarioCategoryString = (
    /** The category represented. I.E category === "ptz" => "Scenario (PTZ)"" */
    category: PiaCategory | undefined,
    /** Beginning of string. Defaults to "scenario".  */
    stringStart: string = t.deviceListProfile,
): string => `${stringStart} (${getCategoryName(category)})`;
