import type { Action, Dispatch } from '@reduxjs/toolkit';
import { injectable } from 'inversify';
import { AppActions } from '../AppActions';
import type { IUserPreferences } from 'app/core/persistence';
import {
    ProjectDbOrigin,
    UserPreferencesService,
    EulaService,
    WelcomeService,
    getItemLocalStorageJSON,
} from 'app/core/persistence';
import {
    CommonActionService,
    getIsEstimates,
    getIsStandalone,
    InitializeDataStorageService,
} from 'app/modules/common';
import { IAction, ActionCreator, ThunkAction } from 'app/store';
import { PiaClient, PiaClientEvents } from 'app/core/pia';
import { FeedbackService } from './Feedback.service';
import { NavigateFunction } from 'react-router-dom';

@injectable()
export class ApplicationActionService {
    constructor(
        private userPreferencesService: UserPreferencesService,
        private piaClient: PiaClient<any, any>,
        private initializeDataStorageService: InitializeDataStorageService,
        private eulaService: EulaService,
        private welcomeService: WelcomeService,
        private feedbackService: FeedbackService,
        private commonActionService: CommonActionService,
    ) {}

    @ActionCreator()
    public setUserPreferences(
        userPreferences: Partial<IUserPreferences>,
    ): IAction<IUserPreferences> {
        this.userPreferencesService.set(userPreferences);

        return {
            type: AppActions.SetUserPreferences,
            payload: this.userPreferencesService.get(),
        };
    }

    @ActionCreator()
    public getUserPreferences(): IAction<IUserPreferences> {
        return {
            type: AppActions.SetUserPreferences,
            payload: this.userPreferencesService.get(),
        };
    }

    @ActionCreator()
    public setUserOnline(): IAction<boolean> {
        return {
            type: AppActions.UserNetworkChange,
            payload: true,
        };
    }

    @ActionCreator()
    public setUserOffline(): IAction<boolean> {
        return {
            type: AppActions.UserNetworkChange,
            payload: false,
        };
    }

    @ActionCreator()
    public setUpdateAvailable(): IAction<boolean> {
        return {
            type: AppActions.UpdateAvailable,
            payload: true,
        };
    }

    @ActionCreator()
    public resizeWindow(): IAction<void> {
        return {
            type: AppActions.WindowResize,
            payload: this.dispatchResizeEvent(),
        };
    }

    @ActionCreator()
    public isPiaAndStorageReady() {
        return (dispatch: Dispatch<Action>) => {
            this.piaClient.initialized
                .then(() => {
                    dispatch({
                        type: AppActions.PiaReady,
                        payload: this.piaClient.initialReplicationPromise,
                    });

                    this.piaClient.initialReplicationPromise
                        .then(this.commonActionService.loadPiaDevices)
                        .then(this.commonActionService.loadLocations)
                        .then(this.initializeStorage);
                })
                .catch((e) => {
                    dispatch({
                        type: AppActions.PiaReady,
                        payload: Promise.reject(e),
                    });
                });

            this.piaClient.on(PiaClientEvents.Progress, (progress) => {
                dispatch({
                    type: AppActions.PiaProgress,
                    payload: progress,
                });
            });
        };
    }

    @ActionCreator()
    public getUserFinishedWelcome() {
        return {
            type: AppActions.GetUserFinishedWelcome,
            payload: this.welcomeService.hasUserFinished(),
        };
    }

    @ActionCreator()
    public onUserAcceptedEula(navigate: NavigateFunction): ThunkAction {
        return (dispatch: Dispatch<Action>) => {
            dispatch({
                type: AppActions.UserAcceptedEulaVersion,
                payload: this.eulaService.setAsAccepted(),
            });
            navigate('/');
        };
    }

    @ActionCreator()
    public onUserFinishedWelcome() {
        return {
            type: AppActions.UserFinishedWelcome,
            payload: this.welcomeService.setAsFinished(),
        };
    }

    @ActionCreator()
    public initializeStorage(): ThunkAction {
        return (dispatch: Dispatch<Action>, getState) => {
            const isStandalone = getIsStandalone(getState());
            const isEstimates = getIsEstimates(getState());
            const currentView = getItemLocalStorageJSON(
                'LatestProjectView',
                ProjectDbOrigin.asdUserData,
            );

            dispatch({
                type: AppActions.InitializingStorage,
                payload: this.initializeDataStorageService.initializeStorage(
                    isStandalone || isEstimates,
                    currentView as ProjectDbOrigin,
                ),
            });

            this.initializeDataStorageService.onProgress((progress: number) => {
                dispatch({
                    type: AppActions.UserDataProgress,
                    payload: progress,
                });
            });
        };
    }

    @ActionCreator()
    public sendFeedback(message: string): IAction<Promise<void>> {
        return {
            type: AppActions.SendFeedback,
            payload: this.feedbackService.sendFeedback(message),
        };
    }

    private dispatchResizeEvent() {
        if (typeof Event === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            const evt = window.document.createEvent('UIEvents');

            // initUIEvent seems to not exist in TypeScript 3.7+ anymore hence the `any`
            (evt as any).initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    }
}
